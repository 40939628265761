import axiosInstance from '../../../Api'

const baseUrl = deltax.apiBaseUrl
export class ApiService {
  getReserveOrders (filters) {
    const getAllReserveOrders = baseUrl.concat(`portals/${deltax.portalId}/ReserveOrders`)
    const promise = axiosInstance({
      url: getAllReserveOrders,
      method: "GET",
      params: filters
    })
    return promise
  }
  saveIncomingBillLog (incomingBillLogObj) {
    const saveIncomingBillLogUrl = baseUrl
      .concat(`portal/${deltax.portalId}/IncomingBillLog`)
    const promise = axiosInstance({
      url: saveIncomingBillLogUrl,
      method: 'POST',
      data: incomingBillLogObj
    });
    return promise
  }
  getIncomingBillLogs (queryParams) {
    const getAllIncomingBillLogsUrl = baseUrl
      .concat(`portal/${deltax.portalId}/IncomingBillLog`)
    const promise = axiosInstance({
      url: getAllIncomingBillLogsUrl,
      method: 'GET',
      params: queryParams
    })
    return promise
  }
  uploadIncomingBillLogAttachments (incomingBillLogId, attachment) {
    const incomingBillLogAttachmentsUrl = baseUrl
      .concat(`portal/${deltax.portalId}/IncomingBillLog/${incomingBillLogId}/Upload`)
    const promise = axiosInstance({
      url: incomingBillLogAttachmentsUrl,
      method: 'POST',
      data: attachment,
      cache: false,
      processData: false,
      contentType: false
    });
    return promise;
  }
  downloadIncomingBillLogAttachments (incomingBillLogId) {
    var url = deltax.apiBaseUrl
      .concat(`portal/${deltax.portalId}/IncomingBillLog/${incomingBillLogId}/Download`);
    const promise = axiosInstance.get(
      url,
      { responseType: 'blob' }
    );
    return promise;
  }
  getSuppliers () {
    const getAllSuppliers = baseUrl
      .concat(`portal/${deltax.portalId}/Suppliers`)
    const promise = axiosInstance.get(getAllSuppliers)
    return promise
  }
  getIncomingBillLogById (id) {
    const getAllIncomingBillLogUrl = baseUrl
      .concat(`portal/${deltax.portalId}/IncomingBillLog/${id}`)
    const promise = axiosInstance({
      url: getAllIncomingBillLogUrl,
      method: 'GET'
    })
    return promise
  }
  getAgencyUnits () {
    const getAgencyUnits = baseUrl
      .concat(`portal/${deltax.portalId}/agencyUnit`)
    const promise = axiosInstance({
      url: getAgencyUnits,
      method: 'GET'
    })
    return promise
  }
  getLineItemInfoByReserveOrderId (bpId, queryParams) {
    const getLineItemInfoByReserveOrderId = baseUrl
      .concat(`businessprofiles/${bpId}/lineitems`)
    const promise = axiosInstance({
      url: getLineItemInfoByReserveOrderId,
      method: 'GET',
      params: queryParams
    })
    return promise
  }
  deleteIncomingBillLogAttachments (incomingBillLogAttachmentid) {
    let url = baseUrl.concat(`portal/${deltax.portalId}/IncomingBillLogAttachment/${incomingBillLogAttachmentid}`)
    var promise = axiosInstance({
      method: "Delete",
      url: url
    });
    return promise;
  }
  getClients () {
    const getAllClients = baseUrl
      .concat(`portal/${deltax.portalId}/Client`)
    const promise = axiosInstance.get(getAllClients)
    return promise
  }
  getAttachmentsByIbLogId (ibLogId) {
    const getAttachments = baseUrl.concat(`portal/${deltax.portalId}/IncomingBillLogAttachment`)
    return axiosInstance({
      url: getAttachments,
      method: 'GET',
      params: {
        IncomingBillLogId: ibLogId
      }
    });
  }
}
