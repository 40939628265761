<template>
  <div>
    <button class="btn btn-white" v-on:click="openConfigurationModal = true" :disabled="showSpinner || !mediaPlanId">
      Campaign & Target <i class="fa fa-cog" v-show="!showSpinner" > </i><i
               class="fa fa-spinner fa-spin"
               v-show="showSpinner"
               ></i>
    </button>
    <Modal
      v-model="openConfigurationModal"
      :scrollable="true"
      :mask-closable="false"
      width="98%"
      :styles="{ top: '10px', position: 'relative' }"
    >
      <div slot="header">
        <div>
  <div class="alert alert-danger" role="alert" v-if="showFailureAlertBox">
                        <button type="button" class="close" data-dismiss="alert"  @click = "ResetFailureAlert()">&times;</button>
                        {{failureReason}}
                    </div>
   <div class="alert alert-success" role="alert" v-if="showAlertBox">
                        <button type="button" class="close" data-dismiss="alert" @click = "ResetAlert()">&times;</button>
                        {{saveDetails}}
                    </div>
  </div>
        <Row :gutter="16" type="flex" align="middle">
          <Row-Col span="1">
            <i class="fa fa-list-alt icon-size"></i>
          </Row-Col>
          <Row-Col span="3" class="lineitem-mapper-mediaplan-input">
            <Input v-model="mediaPlan.name" placeholder="Media Plan Name" :disabled="!isCreateMode" :class="{ 'mediaplan-validation': showNameValidation }"/>
          </Row-Col>
          <Row-Col span="1">
            <i class="fa fa-bullseye icon-size"></i>
          </Row-Col>
          <Row-Col span="3" class="lineitem-mapper-mediaplan-input">
            <Select :disabled="!isCreateMode" v-model="mediaPlan.objectives.selected" :class="{ 'mediaplan-validation': showObjectiveValidation }" placeholder="Objective">
              <OptionGroup v-for="(group, category) in mediaPlan.objectives.listGroupedByCategory" :label="category" :key="category">
                <Option
                  v-for="item in group"
                  :value="item.id"
                  :key="item.id"
                >{{item.name}}</Option>
              </OptionGroup>
            </Select>
            <!-- <Input v-model="mediaPlan.goal" placeholder=""/> -->
          </Row-Col>
          <Row-Col span="1">
            <i class="fa fa-calendar icon-size"></i>
          </Row-Col>
          <Row-Col span="4" class="lineitem-mapper-mediaplan-input">
            <Tooltip :content="businessProfileTimezone" :transfer="true" placement="right-start">
            <DatePicker
              type="daterange"
              placement="bottom-end"
              format="MMM d, yyyy"
              :clearable="false"
              :split-panels="true"
              :editable="false"
              v-model="mediaPlan.schedule"
              class="date-picker-width"
              :disabled="!isCreateMode"
            ></DatePicker>
            </Tooltip>
          </Row-Col>
          <Row-Col span="1">
            <i class="fa fa-usd icon-size"></i>
          </Row-Col>
          <Row-Col span="3" class="lineitem-mapper-mediaplan-input">
            <Input v-model="mediaPlan.cost" type="number" placeholder="Potential Spend" disabled :class="{ 'mediaplan-validation': showBudgetValidation }"/>
          </Row-Col>
          <Row-Col span="1">
            <i class="fa fa-tag icon-size"></i>
          </Row-Col>
          <Row-Col span="3" class="lineitem-mapper-mediaplan-input">
            <Select :disabled="!isCreateMode" v-model="mediaPlan.labels.selected" multiple placeholder="Labels">
              <Option v-for="item in mediaPlan.labels.options" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </Row-Col>
          <!-- <Row-Col span="1">
            <i class="fa fa-external-link icon-size"></i>
          </Row-Col>
          <Row-Col span="3" class="lineitem-mapper-mediaplan-input">
            <Input v-model="mediaPlan.landingUrl" placeholder="Landing Url" :disabled="!isCreateMode"/>
          </Row-Col> -->
        </Row>
      </div>
      <div v-if="showAsinAlertBox" class="alert alert-warning" role="alert">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <strong>
          Invalid ASIN(s)
        </strong> - Could not save ASIN for <b>{{ erroredAsinLineItemNames }}</b> as <b>"{{ invalidAsinStrig }}"</b> could not be found in the linked product catalog.
        <button type="button" class="close" @click="showAsinAlertBox = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div v-if="deltax.isBulkLineItemMapperEnabled && !isCreateMode && selectedPublisher == publisher.Adwords">
        <Layout>
          <Layout style="background: #ffffff">
            <Content style="background: #ffffff">
              <lineitem-config-sheet
              :lineItems="lineItems"
              ref="bulkLineItemMapperSheet"
              >
              </lineitem-config-sheet>
            </Content>
          </Layout>
          <div
              id="bulk-asset-sider-trigger"
              ref="bulkAssetSiderTrigger"
              @click="toggleAssetSider"
            >
              <Icon type="ios-arrow-back" v-show="bulkAssetSider.show"/>
              <Icon type="ios-arrow-forward" v-show="!bulkAssetSider.show"/>
              <div id="asset-sider-text">
                Assets
              </div>
          </div>
          <Sider
            ref="bulkAssetSider"
            collapsible
            hide-trigger
            default-collapsed
            v-model="bulkAssetSider.show"
            reverse-arrow
            :collapsed-width="0"
            :width="assetSiderWidth"
          >
            <div :class="assetSiderClass" style="padding: 0px">
              <div class="cla-resize-bar-bulk">
                <div class="cla-resize-bar-bulk-hov"></div>
              </div>
              <VuePerfectScrollbar class="asset-scroll-area" :settings="{ maxScrollbarLength: 200 }">
                <config-widget
                    :config="selectedCampaignLauncherConfiguration.config"
                    :defaultFormConfig="getDefaultFormConfig()"
                    :campaignConfigurations="getEnabledConfigs"
                    v-on:newCampaignConfigSaved="updateCampaignConfigs($event)"
                ></config-widget>
                <bulk-target-widget></bulk-target-widget>
                <store-widget></store-widget>
              </VuePerfectScrollbar>
            </div>
          </Sider>
        </Layout>
      </div>
        <div v-else class="lineitem-mapper-modal">
            <table class="lineitem-mapper-table" style="width:100%">
              <tr>
                <th class="col-md-4 lineitem-mapper-header">Strategy Line Item</th>
                <th class="col-md-4">
                  <div class="col-md-6 lineitem-mapper-header">
                    Launch Configuration
                  </div>
                  <div class="col-md-1 pull-right lineitem-mapper-header plus-button">
                    <campaign-configuration
                      :config="selectedCampaignLauncherConfiguration.config"
                      :defaultFormConfig="getDefaultFormConfig()"
                      :campaignConfigurations="getEnabledConfigs"
                      v-on:newCampaignConfigSaved="
                        updateCampaignConfigs($event)
                      "
                      v-on:campaignConfigModalOpen="onConfigModalOpen()"
                    ></campaign-configuration>
                  </div>
                </th>
                <th v-if="isFacebookAds" class="col-md-4">
                  <div class="col-md-3 lineitem-mapper-header">
                    Targets
                  </div>
                  <div class="col-md-1 pull-right lineitem-mapper-header plus-button">
                    <target
                      :getAudiences="deltax.getAudiencesURL"
                      :createTarget="deltax.createTargetURL"
                      :targets="accountTargets"
                    ></target>
                  </div>
                </th>
                <th v-if="selectedPublisher == publisher.LinkedIn" class="col-md-4">
                  <div class="col-md-3 lineitem-mapper-header">
                    Targets
                  </div>
                  <div class="col-md-1 pull-right lineitem-mapper-header plus-button">
                    <linkedin-target-creation />
                  </div>
                </th>
                <th v-if="selectedPublisher == publisher.Twitter" class="col-md-4">
                  <div class="col-md-3 lineitem-mapper-header">
                    Targets
                  </div>
                  <div class="col-md-1 pull-right lineitem-mapper-header plus-button">
                    <twitter-target-creation />
                  </div>
                </th>
                <th v-if="selectedPublisher == publisher.DV360" class="col-md-4">
                  <div class="col-md-3 lineitem-mapper-header">
                    Targets
                  </div>
                  <div class="col-md-1 pull-right lineitem-mapper-header plus-button">
                    <DV360TargetModal />
                  </div>
                </th>
                <th v-if="selectedPublisher == publisher.AMS.SponsoredProducts || selectedPublisher == publisher.AMS.SponsoredBrands || selectedPublisher == publisher.AMS.SponsoredDisplay" class="col-md-4">
                  <div class="col-md-6 lineitem-mapper-header">
                    Products To Advertise
                  </div>
                    <div v-if="selectedPublisher == publisher.AMS.SponsoredProducts || selectedPublisher == publisher.AMS.SponsoredDisplay" class="col-md-1 pull-right lineitem-mapper-header plus-button">
                      <ams-cluster-creation
                      @ClusterAdded = ClusterAdded($event)
                      @ClusterFailed = ClusterFailed($event)
                    ></ams-cluster-creation>
                  </div>
                </th>
                <th v-if="deltax.isAdwordsCampaignLaucherFlow && selectedPublisher == publisher.Adwords" class="col-md-4">
                  <div class="col-md-6 lineitem-mapper-header">
                    Targets
                  </div>
                  <div class="col-md-1 pull-right lineitem-mapper-header plus-button">
                    <adwords-target-creation />
                  </div>
                </th>
                <th v-if="selectedPublisher == publisher.Moloco" class="col-md-4">
                  <div class="col-md-3 lineitem-mapper-header">
                    Targets
                  </div>
                  <div class="col-md-1 pull-right lineitem-mapper-header plus-button">
                    <moloco-target-creation/>
                  </div>
                </th>
              </tr>
              <template v-for="(item, index) in lineItems">
                <lineitem-config-row
                  :row="item"
                  :isFacebookAds="isFacebookAds"
                  :campaignLauncherConfigurations="campaignLauncherConfigurations"
                  v-on:configUpdated="setCampaignConfigForLineItem($event)"
                  v-on:targetsUpdated="setTargetsForLineItem($event)"
                  v-on:locationsUpdated="setLocationsForLineItem($event)"
                  v-on:locationsListUpdated="setLocationsListForLineItem($event)"
                  v-on:labelsUpdated="setLabelsForLineItem($event)"
                  v-on:lineItemConfigurationRow-removeLineItem="removeLineItemRow($event)"
                  @updatedDpaSettings="updateDpaSettings"
                  v-on:asinsUpdated="setAsinForLineItem($event)"
                  @updatedPageSetSettings="updatePageSetSettings"
                  @setNewRnfPredictions="setRnfPredictionsForLineItem"
                  @addLineItemFraction="addLineItemFraction"
                  @removeLineItemFraction="removeLineItemFraction"
                  @updateIsRefreshAmsFlag = "updateIsRefreshAmsFlag"
                  :getAudiences="deltax.getAudiencesURL"
                  :createTarget="deltax.createTargetURL"
                  :targets="accountTargets"
                  :index="index"
                  :key="'lineitem-row-' + item.internalId + '-' + item.fractionId + item.internalFractionId + '-' + isCreateMode"
                  :isCreateMode="isCreateMode"
                  :mediaPlan="mediaPlan"
                  :mapperTriggeredFromElementsView="mapperTriggeredFromElementsView"
                  :isRefreshAmsTargets = "isRefreshAmsTargets"
                  ref="lineitemConfigRow"
                  v-on:lineItemConfigurationRow-lineItemUpdated="computePotentialSpend()"
                ></lineitem-config-row>
              </template>
              <tr v-if="lineItems.length==0">
                <td class="lineitem-data-na" colspan="3">
                  <div v-if="mediaPlanLineItems.length==0">
                    No line items available.
                  </div>
                  <div v-else>
                    No line items available for mapping since they have been mapped to other publishers.
                  </div>
                </td>
              </tr>
            </table>
            <div v-if="isCreateMode" style="padding-top:15px">
              <button class="btn btn-white" v-on:click="addLineItemRow()"><i class="fa fa-plus"></i> Add Line Item</button>
            </div>
        </div>
          <div
            v-if="selectedPublisher == 5 && !isCreateMode && lineItems.length>0"
            id="summary-sider-trigger"
            @click="togglesummarySider"
            ref="summarySiderTrigger"
          >
            <Icon type="ios-arrow-back" v-show="summarySider.show" />
            <Icon type="ios-arrow-forward" v-show="!summarySider.show" />
            <div id="asset-sider-text" style="margin-top: 50px">
              Summary
            </div>
          </div>
          <Sider
            ref="summarySider"
            id="bulk-asset-sider"
            collapsible
            hide-trigger
            default-collapsed
            v-model="summarySider.show"
            reverse-arrow
            :collapsed-width="0"
            width="280"
            v-if="!isCreateMode"
          >
            <div :class="summarySiderClass">
               <SummaryWidget
               :summary.sync="summary"
               :summaryDataProcessing="processing"
               >
               </SummaryWidget>
            </div>
          </Sider>
        <div slot="footer">
        <input
          type="button"
          class="btn btn-white mediaplan-campaign-target-save-padding"
          v-on:click="close()"
          value="Close"
        />
        <button
          class="btn btn-success"
          v-on:click="save()"
          :disabled="processing"
        >
          Save <i class="fa fa-spinner fa-spin" v-show="processing"></i>
        </button>
      </div>
    </Modal>
  </div>
</template>
<script>
import { Modal, Icon, Sider, Row, Col, Input, Select, Option, OptionGroup, DatePicker, Tooltip, Layout, Content } from "iview";
import { mapState, mapMutations, mapGetters } from "vuex";
import { APIService } from "./../ApiService.js";
import { ApiService as MediaPlanBulkCreationApiService } from "../../MediaPlanGrid/Services/mediaPlanBulkCreateApiService.js"
import { EventBus } from "../EventBus.js";
// import CampaignConfigSelect from "./CampaignConfigurationSelect.vue"
import LineItemRow from "./LineItemConfigurationRow.vue";
import CampaignConfiguration from "./CampaignConfiguration.vue";
import target from "./FacebookTargetCreation.vue";
import SummaryWidget from "./SummaryWidget.vue";
import FacebookCampaignLauncherConfig from "../Mixins/FacebookCampaignLauncherConfig.js";
import MediaPlanBulkCreation from "../Mixins/MediaPlanBulkCreation.js";
import * as fbEnums from "../Constants/FacebookEnums.js";
import LinkedinTargetCreation from "./LinkedIn/TargetCreationModal.vue"
import TwitterTargetCreation from "./Twitter/TargetCreationModal.vue"
import DV360TargetModal from "./DBM/DV360TargetModal.vue";
import AdwordsTargetCreation from './AdWords/AdwordsTargetCreation.vue'
import AmsClusterCreationModal from './AmsClusterCreation.vue'
import MolocoTargetCreation from "./Moloco/TargetCreationModal.vue"
import LineItemConfigurationSheet from "./LineItemConfigurationSheet.vue";
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ConfigurationWidget from "./ConfigurationWidget.vue";
import BulkTargetWidget from "./BulkTargetWidget.vue";
import StoreWidget from "./StoreWidget.vue";
import { adwordsEnums } from '../Constants/AdwordsEnums.js';

export default {
  props: {
    selectedMediaPlanId: 0,
    labels: { type: Array, default: () => [] },
    getAudiences: { type: String, default: "" },
    createTarget: { type: String, default: "" },
    targets: {
      type: Array,
      default: () => {
        return [];
      }
    },
    fromMediaPlan: {
      type: Boolean,
      default: false
    }
  },
  mixins: [FacebookCampaignLauncherConfig, MediaPlanBulkCreation],
  data () {
    return {
      showAlertBox: false,
      successMesssage: "",
      showFailureAlertBox: false,
      saveDetails: '',
      failureReason: '',
      mediaPlan: {
        id: 0,
        name: "",
        schedule: [
          moment().startOf("day").toDate(),
          moment().add(30, "days").startOf("day").toDate()
        ],
        cost: "",
        labels: {
          options: [],
          selected: ""
        },
        landingUrl: "",
        objectives: {
          source: {},
          listGroupedByCategory: {},
          selected: ""
        },
        keymetrics: []
      },
      isCreateMode: false,
      isRefreshAmsTargets: false,
      lineItems: [],
      bpTimeZone: "",
      processing: false,
      openConfigurationModal: false,
      lineItemMapperColumns: [
        {
          title: "Line Item Name",
          key: "name"
        },
        {
          title: "Fraction",
          key: "fractionAlias"
        },
        {
          title: "Campaign Launcher Configuration",
          key: "config",
          slot: "campaignLauncherConfiguration"
        },
        {
          title: "Targets",
          key: "targets",
          slot: "targets"
        }
      ],
      selectedCampaignLauncherConfiguration: {
        id: "",
        config: this.getDefaultFormConfig()
      },
      summarySider: {
        show: false,
        collapseWidth: 0
      },
      summary: {
        data: "",
        failed: false,
        message: ""
      },
      additionalInfo: {
        budget: 0,
        countOfLineItems: 0
      },
      accountTargets: [],
      mediaPlanLineItemsUnwatch: {},
      mediaPlanBulkCreationApiService: {},
      showNameValidation: false,
      showBudgetValidation: false,
      showObjectiveValidation: false,
      fbEnums,
      showAsinAlertBox: false,
      invalidAsinStrig: "",
      amsLineItemTargets: [],
      erroredAsinLineItemNames: "",
      mapperTriggeredFromElementsView: false,
      isWizardFlow: true,
      bulkAssetSider: {
        show: false,
        collapseWidth: 0
      },
      assetSiderWidth: this.isWizardFlow ? 395 : 380
    };
  },
  async created () {
    this.mediaPlanBulkCreationApiService = new MediaPlanBulkCreationApiService();
    this.getAllLabels();
    var self = this;
    self.setMediaPlanData();
    const mediaPlanPromise = self.mediaPlanBulkCreationApiService.getMediaPlanObjectives();
    mediaPlanPromise.then(res => {
      self.mediaPlan.objectives.source = res.data;

      var groupBy = function (objectives, key) {
        return objectives.reduce(function (element, x) {
          (element[x[key]] = element[x[key]] || []).push(x);
          return element;
        }, {});
      };
      self.mediaPlan.objectives.listGroupedByCategory = groupBy(self.mediaPlan.objectives.source, 'category')
    });
    // kpi key metrics
    const lineItemDataParametersPromise = this.mediaPlanBulkCreationApiService.getLineItemDataParameters();
    lineItemDataParametersPromise
      .then(res => {
        var lineItemDataParametersAPIData = res.data;

        lineItemDataParametersAPIData.filter(x => x.name.includes('.')).map(x => x).forEach(element => {
          var index = lineItemDataParametersAPIData.findIndex(item => item.id === element.id);
          var newItem = {
            id: element.id,
            name: (element.name.replace(/\./g, '')),
            isComputed: element.isComputed,
            higherIsBetter: element.higherIsBetter,
            categoryId: element.categoryId
          }
          lineItemDataParametersAPIData.splice(index, 1, newItem);
        });

        self.mediaPlan.keymetrics = lineItemDataParametersAPIData
          .filter(x => x.name != "Fixed" && x.name != "Days")
          .map(x => x);
      })
      .catch(x => {
        console.log("Error occured in getting data parameters");
      });
  },
  mounted () {
    this.selectedCampaignLauncherConfiguration.Id = this.campaignConfigId;

    if (!this.fromMediaPlan) {
      EventBus.$on("lineItemConfiguration-setCreateMode", data => {
        this.setCreateMode(data);
      });
    }

    EventBus.$on("open-campaign-and-target-mapper", (value) => {
      this.mapperTriggeredFromElementsView = value
      this.openCampaignAndTargetMapper(value);
    });
    EventBus.$on("bulk-lineitem-sheet-mounted", (value) => {
      this.addAssetsResizeListeners();
    });
    this.watchMediaPlanLineItems();
    this.watchMediaPlanHeaderData();
    window.lineItemConfiguration = this;
  },
  beforeDestroy () {
    if (!this.fromMediaPlan) EventBus.$off("lineItemConfiguration-setCreateMode")
    EventBus.$off("open-campaign-and-target-mapper")
    EventBus.$off("bulk-lineitem-sheet-mounted")
  },
  components: {
    Modal,
    // "campaign-config-select": CampaignConfigSelect,
    "linkedin-target-creation": LinkedinTargetCreation,
    "twitter-target-creation": TwitterTargetCreation,
    DV360TargetModal,
    "lineitem-config-row": LineItemRow,
    "ams-cluster-creation": AmsClusterCreationModal,
    Sider,
    Icon,
    CampaignConfiguration,
    target,
    SummaryWidget,
    "Row-Col": Col,
    Row,
    Input,
    Select,
    Option,
    OptionGroup,
    DatePicker,
    Tooltip,
    'adwords-target-creation': AdwordsTargetCreation,
    'moloco-target-creation': MolocoTargetCreation,
    "lineitem-config-sheet": LineItemConfigurationSheet,
    VuePerfectScrollbar,
    "config-widget": ConfigurationWidget,
    "bulk-target-widget": BulkTargetWidget,
    "store-widget": StoreWidget,
    Layout,
    Content
  },
  watch: {
    openConfigurationModal: function (value) {
      if (value && !this.isCreateMode) {
        this.setMediaPlanData();
        this.lineItems = this.getSavedLineItems();
        if (this.lineItems) {
          this.getLineItemsToBeSaved();
        }
      }
    },
    displaySummary: function (value) {
      if (value) {
        this.summary.data = this.displaySummaryInSidebar();
      }
    }
  },
  methods: {
    ...mapMutations(["set_mediaPlanLineItems", "set_campaignLauncherConfigurations", "set_selectedCampaignLauncherConfig", "set_amsLineItemsUpdate"]),
    updateIsRefreshAmsFlag: function () {
      this.isRefreshAmsTargets = false;
    },
    ResetAlert: function () {
      this.showAlertBox = false;
      this.saveDetails = '';
    },
    ResetFailureAlert: function () {
      this.showFailureAlertBox = false;
      this.failureReason = '';
    },
    ClusterAdded: function (message) {
      this.saveDetails = message;
      this.showAlertBox = true;
      this.isRefreshAmsTargets = true;
    },
    ClusterFailed: function (message) {
      this.failureReason = message;
      this.showFailureAlertBox = true;
    },
    setMediaPlanData () {
      if (this.selectedMediaPlanId && this.selectedMediaPlanId > 0) {
        var mediaPlan = this.getMediaPlanById(this.selectedMediaPlanId);
        this.mediaPlan.id = this.selectedMediaPlanId;
        this.mediaPlan.name = mediaPlan.Name;
        this.mediaPlan.objectives.selected = mediaPlan.Objective;
        this.mediaPlan.cost = mediaPlan.Budget;
        this.mediaPlan.labelIds = mediaPlan.LabelIds
        if (this.mediaPlan.id > 0 && this.mediaPlan.labelIds) {
          this.mediaPlan.labels.selected = this.mediaPlan.labelIds.split(',').map(x => parseInt(x));
        }
        var startDate = mediaPlan.StartDate;
        var endDate = mediaPlan.EndDate;
        this.mediaPlan.schedule = [new Date(startDate), new Date(endDate)];
      }
    },
    removeLineItemRow (lineItemId) {
      this.lineItems = this.lineItems.filter(li => li.internalId != lineItemId);
    },
    removeLineItemFraction (fractionInfo) {
      var index = this.lineItems.findIndex(li => li.internalId == fractionInfo.lineItemId && li.internalFractionId == fractionInfo.fractionId);
      this.lineItems.splice(index, 1);
      var filteredLineItems = this.lineItems.filter(li => li.internalId == fractionInfo.lineItemId)
      if (filteredLineItems.length == 1) {
        filteredLineItems[0].isFractionEnabled = 0;
      }
    },
    getAllLabels () {
      this.mediaPlan.labels.options = [];
      this.mediaPlanBulkCreationApiService.getLabels().then(res => {
        this.mediaPlan.labels.options = res.data.result
      })
    },
    getEmptyLineItem () {
      var defaultSchedule = this.mediaPlan.schedule;
      var lineItem = {
        isLineItemMapped: false,
        id: 0,
        name: "",
        fractionId: 0,
        fractionAlias: "",
        config: "",
        targets: [],
        targetsInfo: [],
        labels: [],
        labelsInfo: [],
        budget: 0,
        isPublished: false,
        startDate: defaultSchedule[0],
        endDate: defaultSchedule[1],
        reachAndFrequencyData: [],
        isReachAndFrequencyConfig: false,
        keymetricId: "",
        keymetricValue: "",
        budgetType: 1,
        scheduleDays: "",
        isFractionEnabled: 0
      }
      return lineItem;
    },
    addLineItemRow () {
      var newLineItem = this.getEmptyLineItem();
      newLineItem.internalId = this.lineItems.length > 0 ? this.lineItems[this.lineItems.length - 1].internalId + 1 : 1;
      newLineItem.internalFractionId = 0;
      this.lineItems.push(newLineItem);
    },
    initializeLineItemRow () {
      var newLineItem = this.getEmptyLineItem();
      newLineItem.internalId = this.lineItems.length + 1;
      newLineItem.internalFractionId = 0;
      this.lineItems = [newLineItem];
    },
    addLineItemFraction (lineItemId) {
      var newLineItem = this.getEmptyLineItem();
      var lastFractionIndex = this.lineItems.map(e => e.internalId).lastIndexOf(lineItemId);
      newLineItem.internalId = lineItemId;
      newLineItem.isFractionEnabled = 1;
      // For fractions in lite mode, we use the first value in the list of lineitems
      // with the same internal Id to store LI related data like name, budget, etc
      // The rest of the list will contain fraction related data like alias, config, targets, etc.
      // Therefore, when the first fraction is added to a lineitem, we mark the first entity's
      // 'isFractionEnabled' value to 1 in the next line
      this.lineItems[lastFractionIndex].isFractionEnabled = 1;
      newLineItem.internalFractionId = this.lineItems[lastFractionIndex].internalFractionId + 1;
      this.lineItems.splice(lastFractionIndex + 1, 0, newLineItem);
    },
    setCreateMode (data) {
      this.unwatchMediaPlanHeaderData();
      this.showNameValidation = false;
      this.showBudgetValidation = false;
      this.showObjectiveValidation = false;
      var publisher = data.publisher;
      if (publisher == 5) {
        var self = this;
        var now = moment().startOf("day").toDate();
        self.isCreateMode = true;
        self.mediaPlan.id = 0;
        var day = now.getDate();
        var month = now.getMonth() + 1;
        var year = now.getFullYear();
        self.mediaPlan.name = this.getPublisherNameById(publisher) + "-" + `${month}/${day}/${year}`;
        self.mediaPlan.cost = "";
        self.mediaPlan.labelIds = "";
        self.mediaPlan.labels.selected = []
        self.mediaPlan.objectives.selected = 1;
        self.mediaPlan.schedule = [
          now,
          moment().add(30, "days").startOf("day").toDate()
        ];
        this.initializeLineItemRow();
        self.openConfigurationModal = true;
        this.$nextTick(function () {
          this.watchMediaPlanHeaderData();
        });
      }
    },
    unwatchMediaPlanLineItems () {
      this.mediaPlanLineItemsUnwatch();
    },
    watchMediaPlanLineItems () {
      this.mediaPlanLineItemsUnwatch = this.$watch("mediaPlanLineItems", function () {
        this.lineItems = this.getSavedLineItems();
        if (this.lineItems) {
          this.getLineItemsToBeSaved(false);
        }
      })
    },
    mediaPlanNameWatch (value) {
      if (value == "") {
        this.showNameValidation = true;
      } else {
        this.showNameValidation = false;
      }
    },
    mediaPlanCostWatch (value) {
      if (value == "") {
        this.showBudgetValidation = true;
      } else {
        this.showBudgetValidation = false;
      }
    },
    mediaPlanObjectiveWatch (value) {
      if (value == "") {
        this.showObjectiveValidation = true;
      } else {
        this.showObjectiveValidation = false;
      }
    },
    unwatchMediaPlanHeaderData () {
      this.mediaPlanNameUnwatch();
      this.mediaPlanCostUnwatch();
      this.mediaPlanObjectiveUnwatch();
    },
    watchMediaPlanHeaderData () {
      this.mediaPlanNameUnwatch = this.$watch("mediaPlan.name", this.mediaPlanNameWatch);
      this.mediaPlanCostUnwatch = this.$watch("mediaPlan.cost", this.mediaPlanCostWatch);
      this.mediaPlanObjectiveUnwatch = this.$watch("mediaPlan.objectives.selected", this.mediaPlanObjectiveWatch);
    },
    getSavedLineItems () {
      let mpLineItemsCopy = JSON.parse(JSON.stringify(this.$store.state.mediaPlanLineItems));
      mpLineItemsCopy = mpLineItemsCopy.filter(mpLI => {
        const searchEngineMatch =
          mpLI.config in this.campaignLauncherConfigs &&
          this.campaignLauncherConfigs[mpLI.config].searchEngineTypeId ==
            this.selectedPublisher;
        return !mpLI.config || searchEngineMatch;
      });
      if (this.selectedPublisher == this.publisher.AMS.SponsoredDisplay) {
        mpLineItemsCopy = mpLineItemsCopy.filter(lineItem => lineItem.name.includes("Product Display"))
      }
      if (this.selectedPublisher == this.publisher.AMS.SponsoredProducts) {
        mpLineItemsCopy = mpLineItemsCopy.filter(lineItem => lineItem.name.includes("Sponsored Products"))
      }
      if (this.selectedPublisher == this.publisher.AMS.SponsoredBrands) {
        mpLineItemsCopy = mpLineItemsCopy.filter(lineItem => lineItem.name.includes("Sponsored Brand"))
      }
      if (this.unsupportedPublisherForFactions()) {
        // For adwords, don't show line items having fractions
        let lineItemIdCount = {};
        mpLineItemsCopy.forEach(lineItem => {
          lineItemIdCount[lineItem.id] = 1 + (lineItemIdCount[lineItem.id] || 0);
        })
        return mpLineItemsCopy.filter(li => lineItemIdCount[li.id] == 1 && li.fractionId == 0);
      }
      return mpLineItemsCopy;
    },
    saveGoogleMappingConfig (response) {
      this.processing = false;
      if ("errorMessage" in response) {
        let lineItemError = "Failed to map one of the line items. "
        this.$Message.error({ background: true,
          content: lineItemError + response["errorMessage"],
          duration: 10
        });
        this.lineItems.forEach((lineItem) => {
          if (lineItem.isUpdated) {
            lineItem.isUpdated = false
          }
        })
      } else {
        this.openConfigurationModal = false;
        this.$Message.success({ background: true, content: "Line item mapping updated successfully" });
        this.lineItems.forEach((lineItem, index) => {
          if (lineItem.config) {
            var isTargetSelected = Array.isArray(lineItem.targets) ? lineItem.targets.length > 0 : lineItem.targets != "";
            this.lineItems[index].isLineItemMapped = !this.deltax.isAdwordsCampaignLaucherFlow || isTargetSelected
          }
          lineItem.isUpdated = false;
        });
      }
      this.set_mediaPlanLineItems(JSON.parse(JSON.stringify(this.lineItems)));
      this.$emit("updatedMappedLineItems", { mappedLineItems: response })
    },
    saveInCreateMode () {
      this.$Message.success({ background: true, content: "Media plan saved successfully" });
      EventBus.$emit("campaignLauncherApp-selectNewMediaPlan", { mediaPlan: this.mediaPlan });
      this.close();
    },
    saveFacebookMappingConfig (response) {
      this.processing = false;
      if ("errorMessage" in response) {
        let lineItemError = "Failed to map one of the line items. ";
        this.$Message.error({ background: true,
          content: lineItemError + response["errorMessage"],
          duration: 10
        });
        this.lineItems.forEach((lineItem) => {
          if (lineItem.isUpdated) {
            lineItem.isUpdated = false
          }
        })
      } else {
        // this.openConfigurationModal = false;
        if (this.isCreateMode) {
          this.saveInCreateMode();
          return;
        }
        this.$Message.success({ background: true, content: "Line item mapping updated successfully" });
        this.lineItems.forEach((lineItem, index) => {
          const isByLabels = lineItem.config
            ? JSON.parse(this.campaignLauncherConfigs[lineItem.config].config)
              .adset.targetSelection === "By Label"
            : false;
          if (isByLabels) {
            this.lineItems[index].isLineItemMapped =
              Array.isArray(lineItem.labels) && lineItem.labels.length;
          } else {
            var isTargetSelected = Array.isArray(lineItem.targets) ? lineItem.targets.length > 0 : lineItem.targets != "";
            this.lineItems[index].isLineItemMapped =
              lineItem.config && isTargetSelected;
          }
          lineItem.isUpdated = false;
        });
        let lineItemReachAndFrequencies = JSON.parse(response["lineItem_ReachAndFrequencies"]);
        lineItemReachAndFrequencies.forEach((elm) => {
          var lineItem = this.lineItems.find(x => x.id == elm.lineItemId)
          var rnfData = lineItem.reachAndFrequencyData.find(x => x.targetId == elm.targetId)
          if (rnfData) {
            rnfData.reachAndFrequencyPredictionId = elm.reachAndFrequencyID;
          }
        })
      }
      this.mediaPlanLineItemsUnwatch();
      this.set_mediaPlanLineItems(JSON.parse(JSON.stringify(this.lineItems)));
      if (this.selectedCampaignLauncherConfigId > 0 && this.getIfSelectedConfigIsRnF) {
        EventBus.$emit("getReachAndFrequency")
      }
      this.$emit("updatedMappedLineItems", { mappedLineItems: response })
      this.$nextTick(function () {
        this.watchMediaPlanLineItems();
      })
    },
    saveAMSMappingConfig (response) {
      this.processing = false;
      if ("errorMessage" in response) {
        let lineItemError = "Failed to map one of the line items. ";
        this.$Message.error({ background: true,
          content: lineItemError + response["errorMessage"],
          duration: 10
        });
        this.lineItems.forEach((lineItem) => {
          if (lineItem.isUpdated) {
            lineItem.isUpdated = false
          }
        })
      } else {
        // this.openConfigurationModal = false;
        this.$Message.success({ background: true, content: "Line item mapping updated successfully" });
        this.lineItems.forEach((lineItem, index) => {
          this.lineItems[index].isLineItemMapped =
           lineItem.config && lineItem.targets != "" && lineItem.targets;
          lineItem.isUpdated = false;
        });
      }
      this.mediaPlanLineItemsUnwatch();
      this.set_mediaPlanLineItems(JSON.parse(JSON.stringify(this.lineItems)));
      this.set_amsLineItemsUpdate(true);
      this.$emit("updatedMappedLineItems", { mappedLineItems: response })
      this.$nextTick(function () {
        this.watchMediaPlanLineItems();
      })
    },
    saveDV360MappingConfig (response) {
      this.processing = false;
      if ("errorMessage" in response) {
        let lineItemError = "Failed to map one of the line items. ";
        this.$Message.error({ background: true,
          content: lineItemError + response["errorMessage"],
          duration: 10
        });
        this.lineItems.forEach((lineItem) => {
          if (lineItem.isUpdated) {
            lineItem.isUpdated = false
          }
        })
      } else {
        // this.openConfigurationModal = false;
        this.$Message.success({ background: true, content: "Line item mapping updated successfully" });
        this.lineItems.forEach((lineItem, index) => {
          this.lineItems[index].isLineItemMapped =
           lineItem.config && lineItem.targets != "" && lineItem.targets;
          lineItem.isUpdated = false;
        });
      }
      this.mediaPlanLineItemsUnwatch();
      this.set_mediaPlanLineItems(JSON.parse(JSON.stringify(this.lineItems)));
      this.$emit("updatedMappedLineItems", { mappedLineItems: response })
      this.$nextTick(function () {
        this.watchMediaPlanLineItems();
      })
    },
    saveLinkedInMappingConfig (response) {
      this.processing = false;
      if ("errorMessage" in response) {
        let lineItemError = "Failed to map one of the line items. ";
        this.$Message.error({ background: true,
          content: lineItemError + response["errorMessage"],
          duration: 10
        });
        this.lineItems.forEach((lineItem) => {
          if (lineItem.isUpdated) {
            lineItem.isUpdated = false
          }
        })
      } else {
        // this.openConfigurationModal = false;
        this.$Message.success({ background: true, content: "Line item mapping updated successfully" });
        this.lineItems.forEach((lineItem, index) => {
          this.lineItems[index].isLineItemMapped =
           lineItem.config && lineItem.targets != "" && lineItem.targets;
          lineItem.isUpdated = false;
        });
      }
      this.mediaPlanLineItemsUnwatch();
      this.set_mediaPlanLineItems(JSON.parse(JSON.stringify(this.lineItems)));
      this.$emit("updatedMappedLineItems", { mappedLineItems: response })
      this.$nextTick(function () {
        this.watchMediaPlanLineItems();
      })
    },
    saveDefaultMappingConfig (response) {
      this.processing = false;
      if ("errorMessage" in response) {
        let lineItemError = "Failed to map one of the line items. ";
        this.$Message.error({ background: true,
          content: lineItemError + response["errorMessage"],
          duration: 10
        });
        this.lineItems.forEach((lineItem) => {
          if (lineItem.isUpdated) {
            lineItem.isUpdated = false
          }
        })
      } else {
        // this.openConfigurationModal = false;
        this.$Message.success({ background: true, content: "Line item mapping updated successfully" });
        this.lineItems.forEach((lineItem, index) => {
          this.lineItems[index].isLineItemMapped =
           lineItem.config && lineItem.targets != "" && lineItem.targets;
          lineItem.isUpdated = false;
        });
      }
      this.mediaPlanLineItemsUnwatch();
      this.set_mediaPlanLineItems(JSON.parse(JSON.stringify(this.lineItems)));
      this.$emit("updatedMappedLineItems", { mappedLineItems: response })
      this.$nextTick(function () {
        this.watchMediaPlanLineItems();
      })
    },
    saveMappingErrorCallback () {
      this.$Message.error({ background: true, content: "An error occurred while processing your request" });
    },
    saveMappingAlwaysCallback () {
      this.processing = false;
      if (this.selectedPublisher == this.publisher.AMS.SponsoredProducts || this.selectedPublisher == this.publisher.AMS.SponsoredDisplay) {
        EventBus.$emit("getAmsMediaPlanLineItems");
      }
    },
    async save () {
      this.$store.commit("set_savedMappedLocations", this.$store.state.tempMappedLocations)
      this.$store.commit("set_mappedLocationsChanged", ++this.$store.state.mappedLocationsChanged)

      if (this.isCreateMode) {
        var self = this;
        self.processing = true;
        var validLineItems = this.getValidLineItems();
        var validLineitemIds = validLineItems.map(li => li.internalId);
        validLineItems = this.lineItems.filter(li => validLineitemIds.includes(li.internalId)) // This includes all the fractions of the lineitem
        var mediaPlanRequest = this.getMediaPlanRequest(this.mediaPlan, validLineItems, this.deltax.userId);
        if (this.mediaPlan && this.mediaPlan.labels && this.mediaPlan.labels.selected && this.mediaPlan.labels.selected.length > 0) {
          mediaPlanRequest.Label = this.mediaPlan.labels.options.filter(x => this.mediaPlan.labels.selected.includes(x.id))
          this.mediaPlan.Label = mediaPlanRequest.Label
        }
        if (mediaPlanRequest.error) {
          if (mediaPlanRequest.error.showNameValidation) {
            this.showNameValidation = true;
          }
          if (mediaPlanRequest.error.showObjectiveValidation) {
            this.showObjectiveValidation = true;
          }
          if (mediaPlanRequest.error.showBudgetValidation) {
            this.showBudgetValidation = true;
          }
          self.processing = false;
        } else {
          // save brief
          var briefObject = {
            "description": mediaPlanRequest.Name,
            "name": mediaPlanRequest.Name,
            "budget": mediaPlanRequest.Budget,
            "phases": [{
              "name": mediaPlanRequest.Name,
              "budget": mediaPlanRequest.Budget,
              "schedule": [mediaPlanRequest.StartDate, mediaPlanRequest.EndDate],
              "startDate": mediaPlanRequest.StartDate,
              "endDate": mediaPlanRequest.EndDate
            }],
            "businessLeadId": this.deltax.userId,
            "planningLeadId": this.deltax.userId,
            "objectiveId": mediaPlanRequest.Objective
          }
          let briefPhases = [];
          var savedBrief = await APIService.saveBriefNote(briefObject);
          if (savedBrief > 0) {
            // get brief phases
            briefPhases = await APIService.getPhasesByBriefId(this.businessProfileId, savedBrief);
          } else {
            console.error("Something went wrong while saving brief");
          }
          // set brief id and phase id in mediaPlanRequest
          var briefPhaseId = briefPhases.length > 0 ? briefPhases[0].id : 0;
          if (briefPhaseId > 0) {
            mediaPlanRequest.BriefPhaseId = briefPhaseId;
          } else {
            console.error("Something went wrong while fetching brief phases");
          }
          const saveMediaPlanPromie = this.mediaPlanBulkCreationApiService.saveBulkMediaPlan(mediaPlanRequest);
          saveMediaPlanPromie.then(res => {
            var mediaPlanId = res.data.mediaPlanId;
            self.mediaPlan.id = mediaPlanId;

            var tempMappedLocations = self.tempMappedLocations;
            for (let key in self.tempMappedLocations) {
              let newKey = key;
              if (key.includes("new")) {
                newKey = key.replace("new", mediaPlanId)
                tempMappedLocations[newKey] = JSON.parse(JSON.stringify(self.tempMappedLocations[key]))
                tempMappedLocations[key] = []
              }
            }
            self.$store.commit("set_tempMappedLocations", tempMappedLocations)
            self.$store.commit("set_savedMappedLocations", tempMappedLocations)
            self.$store.commit("set_mappedLocationsChanged", ++self.$store.state.mappedLocationsChanged)

            return mediaPlanId;
          }).then(function (mediaPlanId) {
            return APIService.getMediaPlanItems(
              deltax.apiEndPoint +
              "businessProfiles/" +
              self.businessProfileId +
              "/MediaPlans/MediaPlan?mediaPlanId=" +
              mediaPlanId);
          }).then(lineItemsResponse => {
            var mediaPlan = lineItemsResponse.data.data;
            var lineItems = mediaPlan.lineItems;
            for (var li of lineItems) {
              if (li.isFractionEnabled) {
                let fractionIds = li.lineItemFractions.map(f => f.id).sort();
                var lineItemFractions = self.lineItems.filter(l => li.orderId == l.internalId && l.internalFractionId != 0)
                for (var i = 0; i < lineItemFractions.length; i++) {
                  lineItemFractions[i].id = li.id;
                  lineItemFractions[i].fractionId = fractionIds[i];
                }
              } else {
                var lineItem = self.lineItems.find(l => li.orderId == l.internalId);
                lineItem.id = li.id;
              }
              if (!Array.isArray(li.targets)) {
                li.targets = li.targets != "" ? [li.targets] : []
              }
            }
            var lineItemsToBeMapped = this.getLineItemsToBeSaved(false);
            if (lineItemsToBeMapped.length == 0) {
              this.saveInCreateMode();
              this.close();
              return;
            }

            if (this.isFacebookAds) {
              let dpaConfigIds = this.campaignLauncherConfigurations.filter(x => x.searchEngineTypeId == this.publisher.Facebook && JSON.parse(x.ConfigJson).campaign.objective == fbEnums.objectives["Product Catalog Sales"]).map(y => y.Id);
              let dpaLineItems = lineItemsToBeMapped.filter(lineItem => dpaConfigIds.includes(lineItem.config));
              if (!this.areDpaSettingsValid(dpaLineItems)) {
                this.$Message.error({ background: true, content: "Please select/create a product set" });
                return;
              }
              if (!this.validatePageSets(lineItemsToBeMapped)) {
                this.$Message.error({ background: true, content: "Please select a page set" });
                return;
              }
              if (!this.validateRnfBudget(lineItemsToBeMapped)) {
                this.$Message.error({ background: true, content: "The total Reach and Frequency budget is greater than the LineItem Budget" });
                return;
              }
            }
            lineItemsToBeMapped.forEach(li => {
              if (li.reachAndFrequencyData) {
                li.reachAndFrequencyData.forEach(lid => {
                  lid.reachAndFrequencySettings = JSON.stringify(lid.reachAndFrequencySettings);
                  lid.dpaSettings = JSON.stringify(lid.dpaSettings);
                })
                li.dpaSettings = li.dpaSettings ? JSON.stringify(li.dpaSettings) : "";
              }
            })
            var requestData = {
              lineItems: lineItemsToBeMapped,
              searchEngineType: this.selectedPublisher
            }
            APIService.post(
              `${deltax.apiEndPoint}businessProfiles/${this.businessProfileId}/LineItemCampaignConfigurations`,
              requestData,
              self.saveFacebookMappingConfig,
              self.saveMappingErrorCallback,
              self.saveMappingAlwaysCallback
            );
          })
        }
        return;
      }
      this.amsLineItemTargets = await this.saveAmsAsinClusters();
      let lineItemsToBeSaved = this.getLineItemsToBeSaved(true);
      if (lineItemsToBeSaved.length > 0) {
        this.processing = true;

        var successCallback = null;
        switch (this.selectedPublisher) {
          case this.publisher.Adwords:
            successCallback = this.saveGoogleMappingConfig
            break;
          case this.publisher.Facebook:
            let dpaConfigIds = this.campaignLauncherConfigurations.filter(x => x.searchEngineTypeId == this.publisher.Facebook && JSON.parse(x.ConfigJson).campaign.objective == fbEnums.objectives["Product Catalog Sales"]).map(y => y.Id);
            let dpaLineItems = lineItemsToBeSaved.filter(lineItem => dpaConfigIds.includes(lineItem.config));
            if (!this.areDpaSettingsValid(dpaLineItems)) {
              this.$Message.error({ background: true, content: "Please select/create a product set" });
              return;
            }
            if (!this.validatePageSets(lineItemsToBeSaved)) {
              this.$Message.error({ background: true, content: "Please select a page set" });
              return;
            }
            if (!this.validateRnfBudget(lineItemsToBeSaved)) {
              this.$Message.error({ background: true, content: "The total Reach and Frequency budget is greater than the LineItem Budget" });
              return;
            }
            successCallback = this.saveFacebookMappingConfig;
            break;
          case this.publisher.AMS.SponsoredBrands:
          case this.publisher.AMS.SponsoredProducts:
          case this.publisher.AMS.SponsoredDisplay:
            successCallback = this.saveAMSMappingConfig;
            break;
          case this.publisher.DV360:
            successCallback = this.saveDV360MappingConfig;
            break;
          case this.publisher.LinkedIn:
            successCallback = this.saveLinkedInMappingConfig;
            break;
          default:
            successCallback = this.saveDefaultMappingConfig;
        }

        if (this.isFacebookAds) {
          let dpaConfigIds = this.campaignLauncherConfigurations.filter(x => x.searchEngineTypeId == this.publisher.Facebook && JSON.parse(x.ConfigJson).campaign.objective == fbEnums.objectives["Product Catalog Sales"]).map(y => y.Id);
          let dpaLineItems = lineItemsToBeSaved.filter(lineItem => dpaConfigIds.includes(lineItem.config));
          if (!this.areDpaSettingsValid(dpaLineItems)) {
            this.$Message.error({ background: true, content: "Please select/create a product set" });
            return;
          }
        }

        lineItemsToBeSaved.forEach(li => {
          if (li.reachAndFrequencyData) {
            li.reachAndFrequencyData.forEach(lid => {
              lid.reachAndFrequencySettings = JSON.stringify(lid.reachAndFrequencySettings);
              lid.dpaSettings = JSON.stringify(lid.dpaSettings);
            })
            li.dpaSettings = li.dpaSettings ? JSON.stringify(li.dpaSettings) : "";
          }
        })
        var requestData = {
          lineItems: lineItemsToBeSaved,
          searchEngineType: this.selectedPublisher
        }
        APIService.post(
          `${deltax.apiEndPoint}businessProfiles/${this.businessProfileId}/LineItemCampaignConfigurations`,
          requestData,
          successCallback,
          this.saveMappingErrorCallback,
          this.saveMappingAlwaysCallback
        );

        // if called from mediaplan popup
        // if ReachAndFrequencyConfig is selected, then emit and update rnf data of lineitems in mediaplan popup.
        if (this.fromMediaPlan) {
          var rnfDataToBeSetInMediaPlan = [];
          lineItemsToBeSaved.forEach(lineItem => {
            if (lineItem.isReachAndFrequencyConfig && lineItem.reachAndFrequencyData && lineItem.reachAndFrequencyData.length > 0) {
              var rnfData = lineItem.reachAndFrequencyData[0]
              rnfDataToBeSetInMediaPlan.push({
                id: lineItem.id,
                reach: rnfData.reachAndFrequencySettings.reach,
                frequency: rnfData.reachAndFrequencySettings.frequency,
                budget: rnfData.reachAndFrequencySettings.budget,
                universe: rnfData.reachAndFrequencySettings.universe
              })
            }
          })

          if (rnfDataToBeSetInMediaPlan.length > 0) {
            EventBus.$emit("updateRnfInMediaPlan", rnfDataToBeSetInMediaPlan);
            EventBus.$emit("updatedRnFInMapper", true);
          }
        } else if (this.mapperTriggeredFromElementsView) {
          var rnfAttached = false
          lineItemsToBeSaved.forEach(lineItem => {
            if (lineItem.reachAndFrequencyData) {
              rnfAttached = true
              let totalBudget = lineItem.reachAndFrequencyData.map(rnf => Number(rnf.reachAndFrequencySettings.budget)).reduce((a, b) => a + b, 0)
              APIService.updateLineItems(lineItem.id, [{ "name": "budget", "value": totalBudget }])
            }
          })
          if (rnfAttached) {
            EventBus.$emit("updatedRnFInMapper", true);
          }
        }
      } else {
        if (deltax.isBulkLineItemMapperEnabled && !this.isCreateMode && this.$store.state.invalidMapperScreenLineItems.length && this.selectedPublisher == this.publisher.Adwords) {
          this.$Message.warning({ background: true, content: "No line items were updated. Some line items have invalid config/targets." });
        } else {
          this.$Message.info({ background: true, content: "No line items were updated" });
        }
      }
    },
    areDpaSettingsValid (lineItemsToBeSaved) {
      let self = this;
      for (var i = 0; i < lineItemsToBeSaved.length; i++) {
        let lineItem = lineItemsToBeSaved[i];
        let dpaSettings = lineItem.dpaSettings;
        if (dpaSettings == null || !self.isValidProductSet(dpaSettings.promotedProductSet) || !self.isValidProductSet(dpaSettings.targetProductSet)) {
          return false;
        }
      }

      return true;
    },
    validatePageSets (selectedLineItems) {
      let pageSetConfigIds = this.campaignLauncherConfigurations.filter(x => x.searchEngineTypeId == this.publisher.Facebook && JSON.parse(x.ConfigJson).campaign.objective == fbEnums.objectives["Store Visits"]).map(y => y.Id);
      let pageSetLineItems = selectedLineItems.filter(lineItem => pageSetConfigIds.includes(lineItem.config));
      for (var pageSetLineItem of pageSetLineItems) {
        if (!pageSetLineItem.pageSetSettings || !pageSetLineItem.pageSetSettings.pageSets || pageSetLineItem.pageSetSettings.pageSets.length == 0) {
          return false;
        }
      }
      return true;
    },
    validateRnfBudget (selectedLineItems) {
      let rnfConfigIds = this.campaignLauncherConfigurations.filter(x => x.searchEngineTypeId == this.publisher.Facebook && x.isReachAndFrequencyConfig).map(y => y.Id);
      let rnfLineItems = selectedLineItems.filter(lineItem => rnfConfigIds.includes(lineItem.config));
      for (var rnfLineItem of rnfLineItems) {
        var totalNetBudget = 0;
        for (var targetId of rnfLineItem.targets) {
          var rnfData = rnfLineItem.reachAndFrequencyData.find(rnf => rnf.targetId == targetId)
          totalNetBudget += rnfData ? Number(rnfData.reachAndFrequencySettings.budget) : 0
        }
        if (totalNetBudget > rnfLineItem.budget) {
          return false;
        }
      }
      return true;
    },
    isValidProductSet (item) {
      if (item.option == "Create a new set") {
        if (item.filters.length == 0) {
          return false;
        }

        for (var i = 0; i < item.filters.length; i++) {
          let itemFilter = item.filters[i];
          if (["i_contains", "i_not_contains"].includes(itemFilter.operator)) {
            if (itemFilter.tags.length == 0) {
              return false;
            }
          } else {
            if (itemFilter.value == "" || itemFilter.value == null) {
              return false;
            }
          }
        }
      } else if (item.option == "Use existing") {
        if (item.selected.Id == 0) {
          return false;
        }
      }

      return true;
    },
    getLineItemsToBeSaved (triggerBySave = false) {
      var self = this
      let lineItemsToBeSaved = [];
      var multipleConfigsDictionary = {};
      // consolidate line item info
      var lineItemInfo = {}
      if (this.$refs["lineitemConfigRow"]) {
        this.$refs["lineitemConfigRow"].forEach(e => {
          var x = e.getLineItemInfoToSave()
          if (Object.keys(x).length != 0) {
            lineItemInfo[Object.entries(x)[0][0]] = Object.entries(x)[0][1]
          }
        });
      }

      // update lineitems from mapper screen sheet when BulkLineItemMapper enabled
      if (deltax.isBulkLineItemMapperEnabled && !this.isCreateMode && this.selectedPublisher == this.publisher.Adwords) {
        var sheetData = [];
        let lineItemMapperInstance = this.$refs['bulkLineItemMapperSheet'].$refs['hotLineItemMapper'];
        if (lineItemMapperInstance) {
          var hotInstance = lineItemMapperInstance.hotInstance;
          if (hotInstance) {
            sheetData = hotInstance.getData();
          }
        }

        this.$store.state.invalidMapperScreenLineItems = [];
        if (sheetData && sheetData.length) {
          this.lineItems.forEach((lineItem, index) => {
            let configString = sheetData[index][1];
            let targetString = sheetData[index][2];
            let locationString = sheetData[index][3];
            let config = configString && Number(configString.split('-').pop().trim());
            let targets = targetString && targetString.split(',').map(x => Number(x.split('-').pop().trim()));
            let locations = locationString && locationString.split(',').map(x => x.split(`DXS-${Number.parseInt(deltax.businessProfileId).toString(36)}-`).pop().trim());

            let validConfig = false;
            let configUpdated = false;
            let validTargets = false;
            let targetsUpdated = false;
            let locationsUpdated = false;

            if ((lineItem.config || config) && lineItem.config != config) {
              configUpdated = true;
            }
            targetsUpdated = self.isArrayUpdated(lineItem.targets, targets);
            locationsUpdated = self.isArrayUpdated(lineItem.locations, locations);

            validConfig = self.validateConfig(config);
            // if config not valid, then no need to validate targets.
            validTargets = validConfig ? self.validateTargets(config, targets) : false;

            // check for hyperlocal config
            let hyperlocalConfig = self.isHyperlocalConfig(config);

            // change the sheet updated to true in the sheet component
            if (configUpdated || targetsUpdated || locationsUpdated) {
              self.$refs['bulkLineItemMapperSheet'].isSheetUpdated = true;
            }
            // set locationsUpdated to false if non hyperlocal config to avoid saving.
            if (triggerBySave && locationsUpdated && !hyperlocalConfig) {
              locationsUpdated = false;
              lineItem.locations = [];
              // clear it from the sheet.
              sheetData[index][3] = "";
              hotInstance.setDataAtCell(index, 3, "");
            }

            // only update when updated with valid targets and configs and save is clicked.
            if (triggerBySave && validConfig && validTargets && (configUpdated || targetsUpdated || locationsUpdated)) {
              lineItem.config = config;
              lineItem.targets = targets;
              // save store locations for hyperlocal only
              if (hyperlocalConfig) {
                lineItem.locations = locations;
              } else {
                lineItem.locations = [];
                sheetData[index][3] = "";
                hotInstance.setDataAtCell(index, 3, "");
              }
              lineItem.isUpdated = true;
            }

            // push invalid lineitems
            if ((configUpdated || targetsUpdated) && (!validConfig || !validTargets)) {
              this.$store.state.invalidMapperScreenLineItems.push(index);
            }
          });

          // Rerender the table. It will remove the red marked columns if no invalid columns are present
          hotInstance.render();
        }
      }

      this.lineItems.forEach((lineItem, index) => {
        var li = JSON.parse(JSON.stringify(lineItem));
        let isLineItemMapped = li.config !== "";
        if (this.selectedPublisher != this.publisher.Adwords || this.deltax.isAdwordsCampaignLaucherFlow) {
          isLineItemMapped = isLineItemMapped && ((li.targets && li.targets.length !== 0) || (li.labels && li.labels.length !== 0));
        }
        var liIdentifier = lineItem.internalId + "-" + lineItem.fractionId;
        if (lineItemInfo[liIdentifier] && li.isPublished != true) {
          li.dpaSettings.targetProductAudience = lineItemInfo[liIdentifier].targetProductAudience;
          li.dpaSettings.selectedAudienceType = lineItemInfo[liIdentifier].selectedAudienceType
          li.isUpdated = true;
          var value = {}
          value["lineItemId"] = this.isCreateMode ? li.internalId : li.id
          value["lineItemFractionId"] = this.isCreateMode ? li.internalFractionId : li.fractionId
          value["dpaSettings"] = li.dpaSettings
          value["isReachAndFrequencyConfig"] = li.isReachAndFrequencyConfig
          this.updateDpaSettings(value);
        }
        let isAsinErrorOccuerd = false;
        let isLineItemUpdated = li.isUpdated
        let campaignConfig = self.campaignLauncherConfigs[lineItem["config"]]
        if (isLineItemMapped && isLineItemUpdated && campaignConfig) {
          if (!Array.isArray(li.targets)) {
            li.targets = li.targets != "" ? [li.targets] : []
          }
          if (!Array.isArray(li.labels)) {
            li.labels = li.labels !== "" ? [li.labels] : []
          }
          li["bpseId"] = self.campaignLauncherConfigBpseIdMapper[li["config"]].BpseId
          li["entityConfig"] = campaignConfig.config

          if (triggerBySave &&
          (this.selectedPublisher == this.publisher.AMS.SponsoredProducts ||
          this.selectedPublisher == this.publisher.AMS.SponsoredBrands ||
          this.selectedPublisher == this.publisher.AMS.SponsoredDisplay)) {
            let ams = this.$refs.lineitemConfigRow[index].getAmsDetails();
            li["targetTypeId"] = ams.targetType
            if (ams.targetType == this.amsEnums.targetType.ASIN) {
              // Handling Save for ASIN's
              li["asins"] = ams.asins;
              li["targetTypeId"] = ams.targetType;
              let obj = this.amsLineItemTargets.find(x => x.id == li["id"]);
              if (obj != undefined) {
                li["targets"] = obj.targets;
                isAsinErrorOccuerd = obj.errorOccured;
              }
            }
          }

          if (!isAsinErrorOccuerd) {
            lineItemsToBeSaved.push(li);
          }
        }
        if (campaignConfig && isLineItemMapped) {
          multipleConfigsDictionary = self.GetSummaryRequestData(multipleConfigsDictionary, li, campaignConfig.config)
        }
      });
      if (Object.keys(multipleConfigsDictionary).length > 0) {
        var requestData = {
          lineItemsGroupedByConfig: JSON.stringify(multipleConfigsDictionary),
          creativeSpec: "[]",
          mediaPlanId: this.mediaPlanId
        };
        if (triggerBySave && this.isFacebookAds) {
          if (this.deltax.isDeltaxApiFlowEnabled && !this.isPromotionMode) {
            APIService.PostCampaignLauncherRequestData(
              this.deltax.apiEndPoint +
              "businessProfiles/" +
              this.businessProfileId +
              "/PublishStageData/GenerateMultipleEntities",
              requestData
            ).then(response => {
              this.summary.data = response.data;
              this.processing = false
            }).catch((response) => {
              this.fetchErrorCallback(response.data)
            }).finally(this.fetchAlwaysCallback);
          } else {
            APIService.getGroupEntities(
              deltax.fetchMultipleEntitiesURL,
              requestData,
              self.fetchSuccessCallback,
              self.fetchErrorCallback
            );
          }
        }
      }
      self.getAdditionalInfoFromMapper(multipleConfigsDictionary);
      self.processing = false;
      return lineItemsToBeSaved;
    },
    saveAmsAsinClusters: async function () {
      var self = this;
      let lineItemsAsinToBeSaved = [];
      let invalidAsins = [];
      let erroredLineItems = [];
      this.processing = true;
      this.showAsinAlertBox = false;
      for (let [index, val] of this.lineItems.entries()) {
        var lineItem = val;
        if (this.selectedPublisher == this.publisher.AMS.SponsoredProducts || this.selectedPublisher == this.publisher.AMS.SponsoredBrands || this.selectedPublisher == this.publisher.AMS.SponsoredDisplay) {
          var li = JSON.parse(JSON.stringify(lineItem));
          let isLineItemUpdated = li.isUpdated;
          if (isLineItemUpdated) {
            if (!Array.isArray(li.targets)) {
              li.targets = li.targets != "" ? [li.targets] : [];
            }
            var bpseId = self.campaignLauncherConfigBpseIdMapper[li["config"]].BpseId;
            let ams = this.$refs.lineitemConfigRow[index].getAmsDetails();
            if (ams.targetType == this.amsEnums.targetType.ASIN) {
              let id = 0;
              li.errorOccured = false;
              if (li.targets.length > 0) {
                id = li.targets[0];
              }
              var cluster = {
                Id: parseInt(id),
                Name: "AmsAsinTarget-" + moment().format("DDMMYYYY-HHmmss"),
                Type: ams.targetType,
                BusinessProfilesSearchEngineId: bpseId,
                AsinsToAdvertise: ams.asins.join(","),
                AsinsToTarget: ""
              };

              if (id > 0) {
                await APIService.updateAmsAsinTarget(cluster).then((result) => {
                  li.errorOccured = false;
                }).catch((err) => {
                  li.errorOccured = true;
                  var errorAsins = err.response.data.response.filter(x => x.status == false).map(y => y.asin);
                  invalidAsins.push(errorAsins);
                  erroredLineItems.push(li.name);
                });
              } else {
                await APIService.saveAmsAsinTarget(cluster).then((result) => {
                  var targetId = result.data.response.targetId;
                  li.targets.push(targetId);
                  li.errorOccured = false;
                }).catch((err) => {
                  li.errorOccured = true;
                  var errorAsins = err.response.data.response.asins.filter(x => x.status == false).map(y => y.asin);
                  invalidAsins.push(errorAsins);
                  erroredLineItems.push(li.name);
                });
              }
              lineItemsAsinToBeSaved.push(li);
            }
          }
        }
      }

      if (invalidAsins.length > 0) {
        this.invalidAsinStrig = invalidAsins.join(", ");
        this.erroredAsinLineItemNames = erroredLineItems.join(", ")
        this.showAsinAlertBox = true;
      }
      this.processing = false;
      return lineItemsAsinToBeSaved;
    },
    getAdditionalInfoFromMapper (multipleConfigsDictionary) {
      let configs = [];
      let configId = Object.keys(multipleConfigsDictionary).map(Number)
      if (configId) {
        configs = this.campaignLauncherConfigurations.filter(obj => {
          return configId.includes(obj.Id)
        })
        var data = { configs };
      }
      this.$emit("additionalInfo", data);
    },
    GetSummaryRequestData (multipleConfigsDictionary, lineItem, campaignConfig) {
      if (multipleConfigsDictionary.hasOwnProperty(lineItem.config)) {
        multipleConfigsDictionary[lineItem.config].lineItemConfig.push(lineItem)
      } else {
        multipleConfigsDictionary[lineItem.config] = { campaignConfig: campaignConfig, lineItemConfig: [lineItem] };
      }
      return multipleConfigsDictionary;
    },
    close () {
      this.openConfigurationModal = false;
      this.mapperTriggeredFromElementsView = false;
      this.isCreateMode = false;
      if (this.fromMediaPlan) {
        EventBus.$emit("updateRnfInMediaPlan", []);
      }
    },
    displaySummaryInSidebar () {
      return JSON.parse(this.summaryResponse);
    },
    fetchSuccessCallback (response) {
      this.summary.data = JSON.parse(response);
      this.processing = false
    },
    fetchErrorCallback (response) {
      if (this.isAssetClaimRequiredException(response)) {
        this.summary.message = this.exceptionLabels.find(x => x.family === "assetClaimRequiredException").label
      } else {
        this.summary.message = "There was a problem while generating the summary."
      }
      this.summary.failed = true
      this.processing = false
    },
    getLineItemToUpdate (lineItemData) {
      if (this.isCreateMode) {
        return this.lineItems.find(l => l.internalId == lineItemData.lineItemId && l.internalFractionId == lineItemData.lineItemFractionId)
      } else {
        return this.lineItems.find(l => l.id == lineItemData.lineItemId && l.fractionId == lineItemData.lineItemFractionId)
      }
    },
    setCampaignConfigForLineItem (updatedLineItem) {
      var lineItem = this.getLineItemToUpdate(updatedLineItem)
      lineItem.config = updatedLineItem.config;
      lineItem.isUpdated = true;
      lineItem.isReachAndFrequencyConfig = updatedLineItem.isReachAndFrequencyConfig;
      // this.set_mediaPlanLineItems(this.mediaPlanLineItems);
    },
    setTargetsForLineItem (updatedLineItem) {
      var lineItem = this.getLineItemToUpdate(updatedLineItem)
      lineItem.targets = updatedLineItem.targets;
      lineItem.isUpdated = true;
    },
    setLocationsForLineItem (updatedLineItem) {
      var lineItem = this.getLineItemToUpdate(updatedLineItem)
      lineItem.locations = updatedLineItem.locations;
      lineItem.isUpdated = true;
    },
    setLocationsListForLineItem (updatedLineItem) {
      var lineItem = this.getLineItemToUpdate(updatedLineItem)
      lineItem.locationsList = updatedLineItem.locationsList;
      lineItem.isUpdated = true;
    },
    setLabelsForLineItem (updatedLineItem) {
      var lineItem = this.getLineItemToUpdate(updatedLineItem)
      lineItem.labels = updatedLineItem.labels;
      lineItem.isUpdated = true;
    },
    setAsinForLineItem (updatedLineItem) {
      var lineItem = this.getLineItemToUpdate(updatedLineItem)
      lineItem.asins = updatedLineItem.asins;
      lineItem.isUpdated = true;
    },
    setRnfPredictionsForLineItem (updatedLineItem) {
      var lineItem = this.getLineItemToUpdate(updatedLineItem)
      lineItem.reachAndFrequencyData = updatedLineItem.reachAndFrequencyData;
      lineItem.isUpdated = true;
    },
    onConfigModalOpen () {
      let selectedConfigId = this.selectedCampaignLauncherConfiguration.id;
      if (!selectedConfigId) {
        return;
      }
      this.selectedCampaignLauncherConfiguration.config = JSON.parse(
        this.campaignLauncherConfigs[selectedConfigId].config
      );
    },
    updateCampaignConfigs (r) {
      let campaignConfigs = this.campaignLauncherConfigurations;
      if (r.isNewConfig) {
        campaignConfigs.unshift(r.newCampaignConfig);
      } else {
        let index = campaignConfigs.findIndex(
          obj => obj.Id == r.newCampaignConfig.Id
        );
        Object.assign(campaignConfigs[index], {
          BpseId: r.newCampaignConfig.BusinessProfiles_SearchEngineId,
          Name: r.newCampaignConfig.Name,
          UpdatedAt: r.newCampaignConfig.UpdatedAt,
          UpdatedByUserId: r.newCampaignConfig.UpdatedByUserId,
          ConfigJson: r.newCampaignConfig.ConfigJson
        });
        if (this.selectedCampaignLauncherConfigId == r.newCampaignConfig.Id) {
          this.set_selectedCampaignLauncherConfig(JSON.parse(r.newCampaignConfig.ConfigJson));
        }
      }
      // set store settings
      this.campaignLauncherConfigBpseIdMapper[r.newCampaignConfig.Id] = {
        BpseId: r.newCampaignConfig.BusinessProfiles_SearchEngineId
      };
      this.campaignLauncherConfigs[r.newCampaignConfig.Id] = {
        config: r.newCampaignConfig.ConfigJson,
        searchEngineTypeId: this.selectedPublisher,
        isPromotion: false
      };
      this.set_campaignLauncherConfigurations(campaignConfigs);
    },
    loadIframe () {
      this.showModal = true;
      this.iframeUrl =
        this.createTarget.replaceAll("amp;", "") +
        this.businessProfilesSearchEngineId;
    },
    summarySiderClass () {
      return !this.summarySider.show ? ["open-sider"] : ["collapsed-sider"];
    },
    togglesummarySider () {
      if (this.summarySider.show) {
        this.$refs.summarySiderTrigger.style.right = "280px";
      } else {
        this.$refs.summarySiderTrigger.style.right = "0px";
      }
      this.$refs.summarySider.toggleCollapse();
    },
    updateDpaSettings (updatedLineItem) {
      var lineItem = this.getLineItemToUpdate(updatedLineItem)
      lineItem.dpaSettings = updatedLineItem.dpaSettings;
      lineItem.isUpdated = true;
      lineItem.isReachAndFrequencyConfig = updatedLineItem.isReachAndFrequencyConfig
    },
    updatePageSetSettings (updatedLineItem) {
      var lineItem = this.getLineItemToUpdate(updatedLineItem)
      lineItem.pageSetSettings = updatedLineItem.pageSetSettings;
      lineItem.isUpdated = true;
    },
    computePotentialSpend () {
      var x = this.getValidLineItems().reduce((s, e) => {
        if (e.budgetType == 2) {
          return parseFloat(s) + parseFloat(e.budget * e.scheduleDays);
        } else {
          return parseFloat(s) + parseFloat(e.budget);
        }
      }, 0);
      this.mediaPlan.cost = x.toFixed(2);
    },
    getValidLineItems () {
      return this.lineItems.filter(function (item, index) {
        var isNameNotEmpty = item.name && item.name != "";
        if (item.isReachAndFrequencyConfig) {
          var totalNetBudget = 0;
          for (var targetId of item.targets) {
            var rnfData = item.reachAndFrequencyData.find(rnf => rnf.targetId == targetId)
            totalNetBudget += rnfData && rnfData.reachAndFrequencySettings ? Number(rnfData.reachAndFrequencySettings.budget) : 0
          }
          item.budget = totalNetBudget
        }
        var isBudgetNotEmpty = item.budget && item.budget > 0;
        var isKpiValueNotEmpty = item.keymetricValue && item.keymetricValue != "";
        var isKpimetricNotEmpty = item.keymetricId && item.keymetricId != "";
        return (isNameNotEmpty && isBudgetNotEmpty && isKpiValueNotEmpty && isKpimetricNotEmpty);
      });
    },
    isAssetClaimRequiredException (response) {
      return this.selectedPublisher == this.publisher.Facebook && response && response.responseText && response.responseText.includes("AssetsClaimRequiredException")
    },
    unsupportedPublisherForFactions () {
      return this.selectedPublisher == this.publisher.AMS.SponsoredProducts ||
      this.selectedPublisher == this.publisher.AMS.SponsoredBrands ||
      this.selectedPublisher == this.publisher.AMS.SponsoredDisplay ||
      this.selectedPublisher == this.publisher.LinkedIn ||
      this.selectedPublisher == this.publisher.Twitter ||
      this.selectedPublisher == this.publisher.Moloco
    },
    openCampaignAndTargetMapper (value) {
      if (value) {
        this.openConfigurationModal = true
      }
    },
    addAssetsResizeListeners () {
      const resizeBar = document.querySelector('.cla-resize-bar-bulk');
      if (resizeBar) {
        resizeBar.addEventListener("mousedown", (event) => {
          if (window.getSelection) { window.getSelection().removeAllRanges(); }

          this.assetSiderStartWidth = this.assetSiderWidth;
          this.mouseStartX = event.pageX;

          document.querySelector('.cla-resize-bar-bulk-hov').style.backgroundColor = "rgb(76, 154, 255)";

          document.body.addEventListener("mousemove", this.processMouseMove);
          document.body.addEventListener("mouseup", this.processMouseUp);
        });
      }
    },
    getClampedXcord (xCord) {
      if (xCord < window.innerWidth >> 1) {
        xCord = window.innerWidth >> 1
      } else if (window.innerWidth - xCord < 250) {
        xCord = window.innerWidth - 250
      }
      return xCord
    },
    processMouseUp (event) {
      const diff = this.mouseStartX - this.getClampedXcord(event.pageX);
      this.assetSiderWidth = this.assetSiderWidth + diff;
      if (this.$refs.bulkAssetSiderTrigger) {
        if (diff != 0) {
          this.$refs.bulkAssetSiderTrigger.style.right = (this.assetSiderWidth + 16) + "px";
        } else {
          this.$refs.bulkAssetSiderTrigger.style.right = this.assetSiderWidth + diff;
        }
      }

      this.assetSiderStartWidth = null
      this.mouseStartX = null;
      document.querySelector('.cla-resize-bar-bulk').style.transform = "";
      document.querySelector('.cla-resize-bar-bulk-hov').style.backgroundColor = "initial";

      document.body.removeEventListener("mousemove", this.processMouseMove);
      document.body.removeEventListener("mouseup", this.processMouseUp);
    },
    processMouseMove (event) {
      if (this.mouseStartX == null) { return }

      const diff = this.getClampedXcord(event.pageX) - this.mouseStartX;
      document.querySelector('.cla-resize-bar-bulk').style.transform = `translateX(${diff}px)`;
    },
    toggleAssetSider () {
      if (this.bulkAssetSider.show) {
        this.$refs.bulkAssetSiderTrigger.style.right = (this.assetSiderWidth + 16) + "px";
      } else {
        this.$refs.bulkAssetSiderTrigger.style.right = "0px"
      }
      this.$refs.bulkAssetSider.toggleCollapse();
    },
    validateConfig (configId) {
      return this.campaignLauncherConfigurations.some(config => config.Id == configId && config.searchEngineTypeId == this.selectedPublisher)
    },
    validateTargets (configId, targets) {
      if (!targets) {
        return false;
      }
      let isValidTargets = true;
      let config = this.campaignLauncherConfigurations.find(config => config.Id == configId);
      if (config) {
        targets.forEach(targetId => {
          let target = this.savedTargets.find(target => target.id == targetId);
          if (!target || target.bpseId != config.BpseId) {
            isValidTargets = false;
          }
        });
        return isValidTargets;
      } else {
        return false;
      }
    },
    isArrayUpdated (oldArr, newArr) {
      if (!oldArr) {
        oldArr = [];
      }
      if (!newArr) {
        newArr = [];
      }

      if (oldArr.length !== newArr.length) {
        return true;
      }
      let sortedOld = oldArr.slice().sort();
      let sortednew = newArr.slice().sort();
      for (let i = 0; i < sortedOld.length; i++) {
        if (sortedOld[i] !== sortednew[i]) {
          return true;
        }
      }
      return false;
    },
    isHyperlocalConfig (configId) {
      let config = this.campaignLauncherConfigurations.find(config => config.Id == configId);
      if (config) {
        let configJson = JSON.parse(config.ConfigJson);
        return configJson.campaign.locationMode == adwordsEnums.locationMode.Hyperlocal;
      }
      return false;
    }
  },
  computed: {
    ...mapState([
      "businessProfilesSearchEngineId",
      "businessProfileId",
      "campaignLauncherConfigurations",
      "savedTargetsMapperByBpseId",
      "campaignLauncherConfigBpseIdMapper",
      "mediaPlanLineItems",
      "campaignLauncherConfigs",
      "selectedCampaignLauncherConfigId",
      "deltax",
      "mediaPlanId",
      "selectedPublisher",
      "showSpinner",
      "isPromotionMode",
      "mediaPlans",
      "amsEnums",
      "publisher",
      "mediaPlans",
      "exceptionLabels",
      "savedMappedLocations",
      "tempMappedLocations",
      "accounts",
      "savedTargets"
    ]),
    ...mapGetters(["getIfSelectedConfigIsRnF", "getMediaPlanById", "getPublisherNameById", "businessProfileTimezone"]),
    getEnabledConfigs () {
      if (this.selectedPublisher != this.publisher.Facebook) {
        return this.campaignLauncherConfigurations.filter(
          config =>
            config.Status && config.searchEngineTypeId == this.selectedPublisher
        );
      } else {
        var odaxAccounts = this.accounts.filter(a => a.isOdaxEnabled).map(a => a.Id) || []
        var fbConfigs = this.campaignLauncherConfigurations.filter(
          config =>
            config.Status && config.searchEngineTypeId == this.selectedPublisher && (!odaxAccounts.includes(config.BpseId) || (config.configJson && this.isOdaxConfig(JSON.parse(config.configJson))))
        );
        return fbConfigs;
      }
    },
    isFacebookAds () {
      return this.selectedPublisher == 5
    },
    showTargetsOrLabels () {
      return this.selectedPublisher == this.publisher.Facebook ||
      this.selectedPublisher == this.publisher.LinkedIn
    },
    assetSiderClass () {
      return !this.bulkAssetSider.show ? ["open-sider"] : ["collapsed-sider"]
    }
  }
};
</script>
<style scoped>
.lineitem-mapper-header {
  vertical-align: middle;
  padding: 5px;
  top: 12.75px;
  text-align: left;
}
#bulk-asset-sider-trigger {
  top: 76px;
  right: 4px;
  position: absolute;
  background: #f5f5f5;
  text-align: center;
  border-color: #ddd;
  border-style: solid;
  border-width: 1px 0px 1px 1px;
  height: 80px;
  width:40px;
  cursor: pointer;
  transition: all 0.3s;
  z-index: 102;
}
#summary-sider-trigger {
  top: 76px;
  right: 4px;
  position: absolute;
  background: #f5f5f5;
  text-align: center;
  border-color: #ddd;
  border-style: solid;
  border-width: 1px 0px 1px 1px;
  height: 100px;
  width:25px;
  cursor: pointer;
  transition: all 0.3s;
  z-index: 102;
}
#asset-sider-text {
  -ms-transform: rotate(270deg); /* IE 9 */
  -webkit-transform: rotate(270deg); /* Safari 3-8 */
  transform: rotate(270deg);
  margin-top: 20px;
}
#bulk-asset-sider {
  position: absolute;
  right: 1px;
  top : 76px;
  height: 416px;
}
.open-sider {
  display: block;
}
.collapsed-sider {
  display: none;
}
.scroll-area {
  position: relative;
  margin: auto;
  height: auto;
}
.plus-button{
  top:5px
}
.lineitem-data-na {
  text-align: center;
  padding: 10px;
}
</style>
<style>
.lineitem-mapper-table th {
  background-color: #f0f0f0;
  overflow: hidden;
}
.lineitem-mapper-table td {
  overflow: visible;
}
.lineitem-mapper-table th, .lineitem-mapper-table td {
  border-left: 1px solid #CCC;
  border-right: 1px solid #CCC;
  border-bottom: 1px solid #CCC;
  padding: 0 4px 0 4px;
}
.lineitem-mapper-table {
  border: 1px solid #CCC;
}
.icon-size {
  font-size: 20px;
  cursor: initial;
}
.lineitem-mapper-mediaplan-input {
  margin-left: -20px;
  margin-right: 10px;
}
.mediaplan-validation input, .mediaplan-validation .ivu-select-selection {
  border: 1px solid #CC3F44 !important;
}
.ivu-layout-sider {
  background: #e9eaed !important;
}
.ivu-layout {
  background: #ffffff !important;
}
.cla-resize-bar-bulk {
  bottom: 0px;
  left: -10px;
  position: absolute;
  top: 0px;
  transform: translateZ(0px);
  width: 20px;
  z-index: 1000;
  cursor: ew-resize;
  will-change: transform;
  text-align: center;
}
.cla-resize-bar-bulk-hov {
  display: inline-block;
  height: 100%;
  left: 3px;
  transition: background-color 200ms ease 0s;
  width: 2px;
}
.cla-resize-bar-bulk:hover > .cla-resize-bar-bulk-hov {
  background-color: rgb(76, 154, 255) !important;
}
.open-sider {
  display: block;
}
.collapsed-sider {
  display: none;
}
.asset-scroll-area {
  position: relative;
  margin: auto;
}
.action-icon {
  cursor: pointer;
  color: #aaa;
}
.action-icon:hover {
  color: #5a5d5f;
}
.action-icon:active {
  transform: translateY(1px);
}
.cl-widget {
  margin-bottom: 10px;
}
.cl-widget .ivu-card-head {
  padding-bottom: 0px !important;
}
.cl-widget .ivu-card-body {
  padding: 10px !important;
}
.cl-widget .ivu-card {
  font-size: 13px !important;
  border-radius:0;
}
.cl-widget .cl-ivu-row {
  padding-bottom: 10px !important;
}
.cl-widget-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
