<template>
  <div class="panel panel-default">
    <div class="panel-body">
      <div>
        <Row type="flex" align="middle">
          <Column span="2">
            <button
              class="btn btn-success"
              @click="insertIBLog()"
            >
              <span class="fa fa-plus"></span> Log
            </button>
          </Column>
          <Column span="20">
            <div class="header">
              <h3>&nbsp;</h3>
            </div>
          </Column>
          <Column span="2" class="align-left">
            <Button class="filter-icon" type="default" @click="isFilterModalOpen=true">
              <i class="bi bi-filter"></i> Filter
            </Button>
          </Column>
        </Row>
        <Row>
          <div class="alert alert-success" role="alert" v-if="alertBox.showSuccessAlert">
            <button
              on
              type="button"
              class="close"
              data-dismiss="alert"
              @click="alertBox.showSuccessAlert = false">
              &times;
            </button>
              {{alertBox.successMessage}}
          </div>
        </Row>
        <Row v-show="filterList && filterList.length > 0" class="filters">
          <Tag
            v-for="item in filterList"
            :key="item.value"
            :name="item.label"
            closable
            @on-close="removeSelectedFilter"
            >{{ item.label }}</Tag
          >
          <span class="results">{{ totalRecords }} Results</span>
          <Button type="text" class="removeText" @click="removeAllFilters"
            ><i class="bi bi-x-circle-fill"></i> Remove All Filters</Button
          >
        </Row>
        <div id="table-container">
          <i-table
            ref="iViewTable"
            :columns="iColumns"
            :data="incomingBillLogList"
            border
            :loading="loadingGrid"
            disabled-hover
          >
            <template slot-scope="{ row }" slot="actions">
              <div class="margin-top-2 action-list">
                <i-tooltip content="Attach" class="upload-tooltip">
                  <span @click="setAttachmentRow(row)">
                    <i-upload
                      :accept="'application/pdf'"
                      :show-upload-list="false"
                      :before-upload="uploadMedia"
                      action=""
                      :format="['pdf']"
                      :disabled="!canEditRow(row)"
                    >
                      <i
                        class="fa fa-paperclip"
                        aria-hidden="true"
                        :class="canEditRow(row) ? '' : 'disabled'"
                      ></i>
                    </i-upload>
                  </span>
                </i-tooltip>
                <i-tooltip content="Edit">
                  <span
                    @click="canEditRow(row) ? editIBLog(row) : ''"
                  >
                    <i
                      class="fa fa-pencil"
                      aria-hidden="true"
                      :class="canEditRow(row) ? '' : 'disabled'"
                    ></i>
                  </span>
                </i-tooltip>
                <i-tooltip
                  content="Download"
                  style="width: 10px"
                >
                  <span @click="downloadIBLog(row)" class="margin-right-10">
                    <i class="fa fa-download" aria-hidden="true"></i>
                  </span>
                </i-tooltip>
              </div>
            </template>
            <template slot-scope="{ row }" slot="activityPeriod">
              <div>{{ row.activityPeriod | dateRangeFormatter }}</div>
            </template>
            <template slot-scope="{ row }" slot="invoicePeriod">
              <div>{{ row.invoicePeriod | dateRangeFormatter }}</div>
            </template>
            <template slot-scope="{ row }" slot="receivedDate">
              <div>{{ row.receivedDate | dateFormatter }}</div>
            </template>
          </i-table>
        </div>
        <modal
          v-model="isFilterModalOpen"
          style="color: black"
          @cancel="isFilterModalOpen = false"
          title="Filter Incoming Bill Logs"
          :backdrop="false"
        >
          <div slot="modal-body" class="modal-body" style="min-height: 0px">
            <IncomingBillLogFilter
              :suppliers="suppliers"
              :clients="clientsList"
              @UpdateQuery="UpdateQuery($event)"
              :applyFilterProp="applyFilters"
              :updatedFiltersProp="updatedFilters"
            />
          </div>
          <div slot="modal-footer">
            <div class="footer-div">
              <button
                type="button"
                class="btn btn-white"
                v-on:click="isFilterModalOpen = false"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-success"
                v-on:click="applyFilters = true"
              >
                Apply Filters
              </button>
            </div>
          </div>
        </modal>
        <incoming-bill-popup
          ref="incomingBillPopup"
          @save='handleIncomingBillPopupSave'
          @cancel='handleIncomingBillPopupCancel'>
        </incoming-bill-popup>
        <br />

        <i-page
          class="ivu-page"
          :current="currentPage"
          :total="totalRecords"
          :page-size="pageSize"
          @on-change="ChangePage"
        />
      </div>
    </div>
  </div>
</template>
<script src="~/Content/DeltaX/jquery.fileDownload.js"></script>
<script>
import {
  Table,
  locale,
  Select,
  Option,
  Input,
  Tooltip,
  Row,
  Button,
  Tag,
  Col,
  Upload,
  DatePicker,
  Page,
} from "iview";
import lang from "iview/dist/locale/en-US";
import { ApiService } from ".././Services/ApiService.js";
import IncomingBillLogFilter from "./IncomingBillLogFilter.vue";
import { modal } from "vue-strap";
locale(lang); // iview is default chinese
import IncomingBIllPopup from "./IncomingBillLogRowPopup.vue";

export default {
  mounted(){
  },
  components: {
    "i-table": Table,
    "i-select": Select,
    "i-option": Option,
    "i-input": Input,
    "i-tooltip": Tooltip,
    "i-upload": Upload,
    "i-page": Page,
    DatePicker,
    Row,
    modal,
    Column: Col,
    Button,
    Tag,
    IncomingBillLogFilter,
    "incoming-bill-popup": IncomingBIllPopup
  },
  filters: {
    dateRangeFormatter: function (dates) {
      if (dates) {
        var startDate = moment(dates[0]).format("MMM DD, YYYY");
        var endDate = moment(dates[1]).format("MMM DD, YYYY");
        return startDate + " - " + endDate;
      }
    },
    dateFormatter: function (date) {
      if (date) {
        return moment(date).format("MMM DD, YYYY");
      }
    }
  },
  created() {
    this.apiService = new ApiService();
    let getAllClients = this.apiService.getClients();
    getAllClients
      .then(res => {
        const clients = res.data.data.clients;
        this.clientsList = clients.map(item => ({id: item.id, name: item.displayName || item.name}));
      })
      .catch(err=>{
        alert("error occurred in fetching clients");
      })

    var getAllSuppliersPromise = this.apiService.getSuppliers();
    getAllSuppliersPromise
      .then((res) => {
        this.suppliers = res.data.suppliers;
      })
      .catch((err) => {
        alert("error occurred in fetching businessProfiles");
      });

    this.fetchIncomingBillLogs();
  },
  data() {
    return {
      iColumns: [
        {
          title: "Client",
          key: "clientName",
          minWidth: 160,
          align: "left"
        },
        {
          title: "Supplier",
          key: "supplierName",
          minWidth: 120,
          align: "left"
        },
        {
          title: "RO Number",
          key: "reserveOrderNumber",
          minWidth: 160,
          align: "left"
        },
        {
          title: "RO Value",
          key: "reserveOrderValue",
          minWidth: 120,
          className: "read-only-class",
          align: "right"
        },
        {
          title: "Total Invoice Value",
          key: "totalInvoiceValue",
          minWidth: 140,
          align: "right"
        },
        {
          title: "Activity Period",
          key: "invoicePeriod",
          minWidth: 205,
          slot: "invoicePeriod",
          align: "center"
        },
        {
          title: "Vendor Invoice",
          key: "invoiceNumber",
          minWidth: 160,
          align: "left"
        },
        {
          title: "Received Date",
          key: "receivedDate",
          minWidth: 125,
          slot: "receivedDate",
          align: "center"
        },
        {
          title: "Vendor Billed Date",
          key: "generationDate",
          minWidth: 125,
          align: "center"
        },
        {
          title: "Actions",
          key: "actions",
          minWidth: 105,
          slot: "actions",
          align: "center"
        }
      ],
      iData: [],
      roList: [],
      clientsList: [],
      ibLogEditMode: false,
      fileUploadList: [],
      selectedRowForUpload: [],
      pageSize: 25,
      incomingBillLogList: [],
      currentPage: 1,
      isFilterModalOpen: false,
      filterList: [],
      totalRecords: 0,
      applyFilters: false,
      updatedFilters: {},
      suppliers: [],
      invoiceMonthFilter: "",
      invoiceYearFilter: "",
      reserveOrderNameFilter: "",
      selectedSuppliers: [],
      selectedClients: [],
      loadingGrid: false,
      invoiceId: 0,
      alertBox: {
        showSuccessAlert: false,
        successMessage: ''
      }
    };
  },
  methods: {
    canEditRow(row) {
      return row.searchEngineId != 1 && row.searchEngineId != 5;
    },
    handleIncomingBillPopupCancel() {
      this.ibLogEditMode=false;
    },
    handleIncomingBillPopupSave(data) {
      this.loadingGrid = true;
      var reserveOrders = data.roDetailsData.map(ro => {
        return {
          reserveOrderId: ro.id,
          incomingBillLogId: data.incomingBillLog.id,
          status: 1,
        }
      });
      var costBreakdown = {};
      costBreakdown.taxInformation = []
      if (data.taxInformationData != undefined && data.taxInformationData != null && data.taxInformationData.length > 0) {
        data.taxInformationData.forEach(tax => {
          costBreakdown.taxInformation.push({
            name: tax.taxType,
            rate: tax.taxPercentage,
            value: tax.taxValue
          });
        });
      }
      costBreakdown.lineItemsInformation = data.lineItemsInformation;

      var ibLogObj = {
        id: data.incomingBillLog.id,
        voucher: data.incomingBillLog.voucher,
        businessProfileId: data.incomingBillLog.businessProfileId,
        reserveOrders: reserveOrders,
        startDate: moment(data.incomingBillLog.invoicePeriod[0]).format("MM/DD/YYYY"),
        endDate: moment(data.incomingBillLog.invoicePeriod[1]).format("MM/DD/YYYY"),
        invoiceNumber: data.incomingBillLog.invoiceNumber,
        receivedDate: moment(data.incomingBillLog.receivedDate).format("MM/DD/YYYY"),
        amount: data.incomingBillLog.totalInvoiceValue,
        notes: data.incomingBillLog.notes,
        generationDate: moment(data.incomingBillLog.generationDate).format("MM/DD/YYYY"),
        costBreakdown: JSON.stringify(costBreakdown),
        agencyUnitId: data.incomingBillLog.agencyUnitId,
        baseCost:  Number(data.totalCost),
        verification: Number(data.incomingBillLog.verificationId),
        clientId: data.incomingBillLog.clientId,
        status: 1
      };

      if (data.incomingBillLog.supplierId && data.incomingBillLog.supplierId > 0) {
        ibLogObj.supplierId = data.incomingBillLog.supplierId;
      }

      this.fileUploadList = data.supportingFiles;
      this.invoiceId = 0;
      this.apiService.saveIncomingBillLog(ibLogObj)
      .then((res) => {
        this.alertBox.successMessage = `Incoming Bill Log ${data.incomingBillLog.id==0 ? 'Saved' : 'Updated'} Successfully`;
        this.alertBox.showSuccessAlert = true;

        setTimeout(()=>this.alertBox.showSuccessAlert = false, 3000);

        this.saveSupportingFiles(res.data.data, data.allFiles);
      })
      .catch(() => alert("Failed to save your IB log. Please contact support for further assistance."));
      this.ibLogEditMode=false;
    },
    fetchIncomingBillLogs: function () {
      var queryParams = {
        PageSize: this.pageSize,
        PageNumber: this.currentPage,
        clientIds:
          this.selectedClients && this.selectedClients.length > 0
            ? this.selectedClients.join()
            : "",
        SupplierIds:
          this.selectedSuppliers && this.selectedSuppliers.length > 0
            ? this.selectedSuppliers.join()
            : "",
        Month: this.invoiceMonthFilter,
        Year: this.invoiceYearFilter,
        ReserveOrderName: this.reserveOrderNameFilter,
      };

      this.iData = [];
      this.incomingBillLogList = [];
      this.loadingGrid = true;
      this.apiService
        .getIncomingBillLogs(queryParams)
        .then((response) => {
          this.iData = [];
          this.incomingBillLogList = [];
          this.iData = response.data.data.data;
          this.currentPage = response.data.data.pageNumber;
          this.totalRecords = response.data.data.totalRecords;
          this.loadingGrid = false;

          this.iData.forEach((element) => {
            element["totalInvoiceValue"] = parseFloat(element.amount)
              .toLocaleString('en-US', { minimumFractionDigits: 4 });
            element["generationDate"] = moment(element.generationDate).format("MMM DD, YYYY");
            element["invoicePeriod"] = [
              moment(element.startDate).toDate(),
              moment(element.endDate).toDate(),
            ];
            if (element.supplierInvoiceDate != undefined) {
              element["supplierInvoiceDate"] = moment(
                element.supplierInvoiceDate
              ).toDate();
            }
            if (element.receivedDate != undefined) {
              element["receivedDate"] = moment(element.receivedDate).toDate();
            }
            if(element.reserveOrderValue != undefined) {
              element['reserveOrderValue'] = parseFloat(element.reserveOrderValue).toLocaleString('en-US', { minimumFractionDigits: 4 });
            }

            this.incomingBillLogList.push(element);
          });
        })
        .catch((x) => {
          this.loadingGrid = false;
        });
    },
    ChangePage: function (pageNumber) {
      this.ibLogEditMode = false;
      this.currentPage = pageNumber;
      this.fetchIncomingBillLogs();
    },
    removeSelectedFilter: function (event, name) {
      this.currentPage = 1;
      var newFilters = {
        client: this.updatedFilters.client,
        suppliers: this.updatedFilters.suppliers,
        month: this.invoiceMonthFilter,
        year: this.invoiceYearFilter,
        reserveOrderName: this.updatedFilters.reserveOrderName,
      };
      let index = this.filterList.findIndex((s) => s.label == name);
      if (index != -1) {
        let element = this.filterList.filter((s) => s.label == name);
        if (name.includes("Client:")) {
          const ind = this.selectedClients.findIndex(
            (s) => s == element[0].value
          );
          this.selectedClients.splice(ind, 1);
          newFilters.client = this.selectedClients;
        }
        if (name.includes("Supplier")) {
          const ind = this.selectedSuppliers.findIndex(
            (s) => s == element[0].value
          );
          this.selectedSuppliers.splice(ind, 1);
          newFilters.suppliers = this.selectedSuppliers;
        }
        if (name.includes("Month")) {
          this.invoiceMonthFilter = null;
          newFilters.month = this.invoiceMonthFilter;
        }
        if (name.includes("Year")) {
          this.invoiceMonthFilter = null;
          newFilters.month = this.invoiceMonthFilter;
          this.invoiceYearFilter = null;
          newFilters.year = this.invoiceYearFilter;

          var monthIndex = this.filterList.findIndex((s) =>
            s.label.includes("Month")
          );
          this.filterList.splice(monthIndex, 1);
          index = this.filterList.findIndex((s) =>
            s.label.includes("Year")
          );
        }
        if (name.includes("Reserve Order:")) {
          this.reserveOrderNameFilter = "";
          newFilters.reserveOrderName = this.reserveOrderNameFilter;
        }
        this.updatedFilters = newFilters;
        this.filterList.splice(index, 1);
      }
      this.fetchIncomingBillLogs();
    },
    UpdateQuery: function (query) {
      this.currentPage = 1;
      this.ConstructFilterList(query);
      this.fetchIncomingBillLogs();
      this.isFilterModalOpen = false;
      this.applyFilters = false;
    },
    ConstructFilterList: function (query) {
      this.filterList = [];

      this.selectedSuppliers = query.selectedSuppliers;
      this.selectedClients = query.selectedClients;
      this.invoiceMonthFilter = query.invoiceMonthFilter;
      this.invoiceYearFilter = query.invoiceYearFilter;
      this.reserveOrderNameFilter = query.reserveOrderNameFilter;
      if (query.client != undefined && query.client.length > 0) {
        query.client.forEach((element) => {
          this.filterList.push({
            label: "Client:" + element.Name,
            value: element.Id,
          });
        });
      }
      if (query.suppliers != undefined && query.suppliers.length > 0) {
        query.suppliers.forEach((element) => {
          this.filterList.push({
            label: "Supplier:" + element.Name,
            value: element.Id,
          });
        });
      }
      if (
        query.invoiceMonthFilter != undefined &&
        query.invoiceMonthFilter != ""
      ) {
        this.filterList.push({
          label: "Received Month:" + query.monthName,
        });
      }
      if (
        query.invoiceYearFilter != undefined &&
        query.invoiceYearFilter != ""
      ) {
        this.filterList.push({
          label: "Received Year:" + query.invoiceYearFilter,
        });
      }
      if (
        query.reserveOrderNameFilter != undefined &&
        query.reserveOrderNameFilter != ""
      ) {
        this.filterList.push({
          label: "Reserve Order:" + query.reserveOrderNameFilter,
        });
      }
    },
    editIBLog: function (row) {
      this.$refs.incomingBillPopup.openModal(row.id);
    },
    insertIBLog: function () {
      var ibLogData = 0;
      this.$refs.incomingBillPopup.openModal(ibLogData);
      this.roList = [];
    },
    removeAllFilters: function () {
      var newFilters = {
        client: [],
        suppliers: [],
        month: null,
        year: null,
        reserveOrderName: null,
      };
      this.updatedFilters = newFilters;
      this.currentPage = 1;
      this.selectedClients = [];
      this.selectedSuppliers = [];
      this.filterList = [];
      this.invoiceMonthFilter = null;
      this.invoiceYearFilter = null;
      this.reserveOrderNameFilter = null;
      this.fetchIncomingBillLogs();
    },
    uploadMedia: function (file) {
      this.fileUploadList.push(file);
      if (
        !this.selectedRowForUpload.edit &&
        !this.selectedRowForUpload.insert &&
        this.selectedRowForUpload.id > 0
      ) {
        this.saveAttachment(this.selectedRowForUpload.id);
      }
    },
    saveAttachment: function (incomingBillLogId) {
      this.fileUploadList.forEach((file) => {
        var formData = new FormData();
        formData.append("file", file);
        this.fileUploadList = this.fileUploadList.filter(
          (x) => x.uid != file.uid
        );
        var incomingBillLogAttachment =
          this.apiService.uploadIncomingBillLogAttachments(
            incomingBillLogId,
            formData
          );
        incomingBillLogAttachment
          .then((res) => {
            var index = this.iData.findIndex((x) => x.id == incomingBillLogId);
            let editedRow = this.iData[index];
            editedRow.isAttachmentPresent = true;
            this.iData.splice(index, 1, editedRow);

            var paginatedIndex = this.incomingBillLogList.findIndex(
              (x) => x.id == incomingBillLogId
            );
            let paginatedRow = this.incomingBillLogList[paginatedIndex];
            paginatedRow.isAttachmentPresent = true;
            this.incomingBillLogList.splice(paginatedIndex, 1, paginatedRow);

            alert("IncomingBill log attachment uploaded successfully.")
          })
          .catch((err) => {
            alert("Failed to upload IncomingBill log attachment.");
          });
      });
    },
    downloadIBLog: function (row) {
      if (row.id > 0) {
        if (row.isAttachmentPresent) {
          var promise = this.apiService.downloadIncomingBillLogAttachments(
            row.id
          );
          promise.then(
            (successResponse) => {
              const url = window.URL.createObjectURL(
                new Blob([successResponse.data], {
                  type: successResponse.headers["content-type"],
                })
              );
              const link = document.createElement("a");
              link.href = url;
              var fileName = "";
              if (
                successResponse.headers["content-disposition"] &&
                successResponse.headers["content-disposition"] != ""
              ) {
                fileName = successResponse.headers["content-disposition"]
                  .split("filename=")[1]
                  .split(";")[0];
              }
              link.setAttribute("download", fileName);
              document.body.appendChild(link);
              link.click();
            },
            (failureResponse) => {
              alert("Attachment download failed");
            }
          );
        } else {
          alert("No Attachments found for this Incoming Bill Log");
        }
      }
    },
    setAttachmentRow: function (row) {
      this.selectedRowForUpload = row;
    },
    saveSupportingFiles (incomingBillLogId, allFiles) {
      var self = this;
      var promises = [];
      this.fileUploadList.forEach((file) => {
        if(file.id != undefined && file.id != 0) {
          return;
        }
        if(typeof file.type == 'string') {
          var formData = new FormData();
          formData.append("file", file);
          promises.push(this.apiService.uploadIncomingBillLogAttachments(
            incomingBillLogId,
            formData
          ));
        }
      });
      allFiles.forEach((file) => {
        if(!this.fileUploadList.includes(file) && file.id != undefined && file.id > 0) {
          promises.push(this.apiService.deleteIncomingBillLogAttachments(file.id));
        }
      });
      Promise.all(promises)
      .then(() => {
        self.fetchIncomingBillLogs();
      });
    }
  },
};
</script>
<style scoped>
i.fa.fa-times,
i.fa.fa-pencil,
i.fa.fa-paperclip,
i.fa.fa-check,
i.fa.fa-download {
  font-size: 14px !important;
}
::v-deep .ivu-table td.read-only-class {
  background-color: #f0f0f0;
}
::v-deep .ivu-table td {
  height: 30px !important;
}
.disabled {
  color: #999;
  cursor: not-allowed;
}
.field-empty-error {
  border: red solid 1px;
}
::v-deep .ivu-select-selection {
  box-shadow: none !important;
}
.pagination-iview {
  float: right;
  padding-top: 15px;
}
.panel-body {
  padding-top: 0px !important;
  padding-bottom: 30px !important;
}
.footer-div {
  padding-top: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.filters {
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 1%;
}
.filter-icon {
  font-size: 13px;
  height: 32px;
}

::v-deep .ivu-table-tip {
  overflow-x: scroll;
}
::v-deep .ivu-table-column-center {
  height: 30px;
}
::v-deep .ivu-page {
  text-align: right !important;
}
.align-left {
  text-align: end;
}
.upload-tooltip{
  width: 19px; height: 15px
}
.margin-top-2{
  margin-top: 2px
}
.margin-right-10{
  margin-right: 10px
}
.action-list {
  display: flex;
  gap: 5px;
  justify-content: center;
}
::v-deep .ivu-table-cell {
  padding: 0;
}
</style>
<style src="../../../Styles/dx-iview.css"></style>

<style scoped src = "../../../../../../node_modules/bootstrap-icons/font/bootstrap-icons.css">
</style>
