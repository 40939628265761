<template>
  <div class="hot-div" style="width: 100%; height: 530px; overflow: hidden;" :key="rerenderKey">
		<hot-table class="ad-sheet-hot" ref="hot" :settings="hotSettings"></hot-table>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex"
import { HotTable } from "@handsontable/vue"
import { columnWidths } from "../../columnConfig.js"
import Handsontable from 'handsontable'
import { APIService } from "../../ApiService.js"
import { linkedInEnums } from '../../Constants/LinkedInEnums.js'
import { dbmEnums } from '../../Constants/DbmEnums.js';

export default {
  components: {
    "hot-table": HotTable
  },
  props: {
    tab: { default: '' },
    creativeSpec: { default: function () { return {} } },
    colHeaders: { default: function () { return [] } }, // ["HOT_Ad Name", "HOT_Call To Action"]
    displayColHeaders: { default: function () { return [] } }, // ["Ad Name", "Call To Action"]
    requiredCols: { default: function () { return {} } },
    callToAction: { default: function () { return [] } }
  },
  data: function () {
    return {
      htData: [],
      hotSettings: {},
      startRows: 2,
      uniqueId: null,
      publishedRows: [],
      publishedCreativesSize: 0,
      previewable: {},
      hardcodedCardsCount: 8,
      rerenderKey: 0,
      availableWidth: 0,
      alreadyProcessed: {},
      mainTableData: {},
      adsheetLvlAlert: {
        "HOT_Headline": null,
        "HOT_Primary Text": null,
        "HOT_Description": null,
        "HOT_Image/Video": null,
        "HOT_Call To Action": null
      },
      colNameIndexMap: this.colHeaders.reduce(
        (obj, colName, colIndex) => {
          obj[colName] = colIndex
          return obj
        },
        {}
      ),
      bulkUpdateQueries: {}
    }
  },
  computed: {
    ...mapState([
      "builtImageIdUrlMap",
      "campaignLauncherConfigs",
      "imageIdUrlMap",
      "mappedLineItems",
      "publishedCreatives",
      "selectedCampaignLauncherConfig",
      "selectedCampaignLauncherConfigId",
      "selectedFbObjective",
      "mediaPlanId",
      "mediaPlans",
      "publisher",
      "connectedInstagramAccountId",
      "tableData",
      "imagesData",
      "linkedInLeadForms"
    ]),
    ...mapGetters(["getMediaPlanById"])
  },
  created () {
    this.selectedMediaPlanDetails = this.getMediaPlanById(this.mediaPlanId)
    this.hotSettings = this.buildHotSettings()
  },
  mounted () {
    this.availableWidth = document.querySelector('.hot-div').offsetWidth
  },
  watch: {
    publishedCreatives: {
      immediate: true,
      handler: function () {
        if (this.$store.state.editSheetLoadProgress == this.$store.state.adFormats.length) {
          this.$store.commit("set_editSheetLoadProgress", 0)
        }
        this.publishedCreativesSize = 0;
        if (this.tab in this.publishedCreatives) {
          this.publishedCreatives[this.tab].forEach(element => {
            if (element["HOT_IsNotPublished"] != true) {
              this.publishedCreativesSize++
            }
          });
        }
        this.previewable = {}
        this.publishedRows = []
        let newHtData = [];
        if (this.tab in this.publishedCreatives && this.publishedCreatives[this.tab].length) {
          let publishedRowObjects = JSON.parse(JSON.stringify(this.publishedCreatives[this.tab]))
          publishedRowObjects.forEach((rowObject, rowIndex) => {
            let htRow = []
            this.colHeaders.forEach(header => {
              if (header in rowObject) {
                htRow.push(rowObject[header])
              } else {
                htRow.push(null)
              }
            })
            newHtData.push(htRow)
            if (rowObject.HOT_IsNotPublished) {
              htRow.forEach((cellData, colIndex) => {
                this.updatePreviewColumn(rowIndex, colIndex, null, cellData, null);
              });
            }
          })
          this.publishedRows = JSON.parse(JSON.stringify(newHtData))
        } else {
          for (let i = 0; i < this.startRows; i++) {
            newHtData.push(Array(this.colHeaders.length).fill(null))
          }
        }
        this.htData = newHtData;
        var currentData = this.tableData
        if (currentData == null) {
          currentData = {}
        }
        newHtData == null ? currentData[this.tab] = [] : currentData[this.tab] = newHtData;
        this.set_tableData(currentData);

        this.$nextTick(() => {
          let updatedSettings = {
            data: this.htData
          }

          // If handsontable width is less than available width, stretch all cols equally except 1st col(preview ad)
          let totalColWidth = [...Array(this.colHeaders.length).keys()]
            .map(index => this.getColWidthByIndex(index))
            .reduce((accu, curVal) => accu + Number(curVal), 0)
          if (totalColWidth < this.availableWidth) {
            let equalSize = (this.availableWidth - 100) / (this.colHeaders.length - 1)
            updatedSettings['colWidths'] = [100, ...Array(this.colHeaders.length - 1).fill(equalSize)]
          }
          this.$refs['hot'].hotInstance.updateSettings(updatedSettings)
          this.$store.commit("set_editSheetLoadProgress", this.$store.state.editSheetLoadProgress + 1)
        })
      }
    },
    builtImageIdUrlMap () {
      if (this.builtImageIdUrlMap) {
        this.rerender()
      }
    }
  },
  methods: {
    ...mapMutations(["set_tableData"]),
    rerender () {
      this.$nextTick(() => {
        this.$refs['hot'].hotInstance.render()
      })
    },
    imageColRenderer (instance, td, row, col, prop, value, cellProperties) {
      if (row < this.publishedCreativesSize) {
        td.classList.add('disabled-cell')
      }
      value = Handsontable.helper.stringify(value)

      if (value in this.imageIdUrlMap) {
        let img = td.querySelector("img")
        if (img && img.src == this.imageIdUrlMap[value]['src']) {
          return td
        }
        img = document.createElement('IMG')
        img.src = this.imageIdUrlMap[value]['src']
        img.classList.add('hot-img')
        Handsontable.dom.addEvent(img, 'mousedown', function (event) {
          event.preventDefault()
        })

        Handsontable.dom.empty(td)
        td.appendChild(img)
      } else {
        Handsontable.renderers.TextRenderer.apply(this, arguments)
      }
      return td
    },
    previewBtnRenderer (instance, td, row, col, prop, value, cellProperties) {
      if (!(td.firstElementChild)) {
        let previewTdWrapper = document.createElement('div')
        previewTdWrapper.classList.add('preview-td-wrapper')

        let previewIcon = document.createElement('span')
        previewIcon.classList.add('eye')
        previewIcon.innerHTML = '<i class="fa fa-check preview-icon"></i>'
        previewTdWrapper.appendChild(previewIcon)
        td.appendChild(previewTdWrapper)
      }
      td.firstElementChild.classList.remove('disabled-cell')
      if (value !== "true") {
        td.firstElementChild.innerHTML = '<span class="eye"><i class="fa fa-times preview-icon"></i></span>'
        td.style.cursor = 'not-allowed';
        td.firstElementChild.classList.add('preview-icon-disabled')
      } else {
        td.style.cursor = 'default';
        if (row < this.publishedCreativesSize) {
          td.firstElementChild.classList.add('disabled-cell')
        }
        td.firstElementChild.innerHTML = '<span class="eye"><i class="fa fa-check preview-icon"></i></span>'
        td.firstElementChild.classList.remove('preview-icon-disabled')
        td.style.cursor = 'not-allowed';
      }
      td.classList.add('trim')
      return td
    },
    checkIfReq (col) {
      if (this.colHeaders[col] === undefined) { return [false, -1] }
      let colName = this.colHeaders[col]
      if (col < this.requiredCols['baseLength']) {
        return (this.requiredCols['base'].has(colName)) ? [true, -1] : [false, -1]
      }
      let space = colName.lastIndexOf(' ')
      if (this.requiredCols['card'].has(colName.substring(0, space))) {
        let cardIndex = Math.floor((col - this.requiredCols['baseLength']) / this.requiredCols['cardLength'])
        return [true, cardIndex]
      }
      return [false, -1]
    },
    checkIfPreviewable (previewRow) {
      let validCard =
        previewRow["card"].filter((x) => x >= this.requiredCols["card"].size)
          .length > 1;
      let validBase = previewRow["base"] >= this.requiredCols["base"].size;
      return (validCard && validBase);
    },
    createNewPreviewableObj () {
      return {
        'validCard': false,
        'validBase': false,
        'card': new Array(this.hardcodedCardsCount).fill(0),
        'base': 0
      }
    },
    getColWidthByIndex (index) {
      var colWidthByPublisher = columnWidths(this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config);
      for (let [width, headerNames] of Object.entries(colWidthByPublisher)) {
        if (width === "default") { continue }
        for (let headerName of headerNames) {
          if (this.colHeaders[index].indexOf(headerName) != -1) {
            return width
          }
        }
      }
      return colWidthByPublisher["default"]
    },
    setHotCellData (row, col, value, instance, lazyUpdate) {
      if (lazyUpdate) {
        this.bulkUpdateQueries[[row, col]] = [row, col, value]
      } else {
        setTimeout(() => { instance.setDataAtCell(row, col, value) })
      }
    },
    updatePreviewColumn (row, col, oldVal, newVal, instance, lazyUpdate = false) {
      if (!(row in this.previewable)) {
        this.previewable[row] = this.createNewPreviewableObj()
      }
      let [required, cardIndex] = this.checkIfReq(col)
      if (required) {
        if (!oldVal && newVal) {
          if (cardIndex != -1) {
            this.previewable[row]['card'][cardIndex] += 1
          } else {
            this.previewable[row]['base'] += 1
          }
        } else if (oldVal && !newVal) {
          if (cardIndex != -1) {
            this.previewable[row]['card'][cardIndex] -= 1
          } else {
            this.previewable[row]['base'] -= 1
          }
        }
        if (instance) {
          const isPreviewable = this.checkIfPreviewable(this.previewable[row]);
          // returns the value for preview column
          if (this.htData[row]) {
            return [row, 0, this.htData[row][0], isPreviewable.toString()]
          } else {
            return [row, 0, 'false', isPreviewable.toString()]
          }
        }
      }

      // handles undo and redo cases. In undo and redo true/false values are passed into updatePreview method.
      if (col == 0) {
        if (instance) {
          const isPreviewableRow = this.checkIfPreviewable(this.previewable[row]);
          if (this.htData[row]) {
            return [row, 0, this.htData[row][0], isPreviewableRow.toString()]
          } else {
            return [row, 0, 'false', isPreviewableRow.toString()]
          }
        }
      }
    },
    buildHotSettings () {
      let config = JSON.parse(this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config);
      let columnMaxChars = {
        "HOT_Call To Action": 10,
        "HOT_Headline": 15,
        "HOT_Long Headline": config.adGroup.adFormat == dbmEnums.liAdformat['In-feed video'] ? 100 : 90,
        "HOT_Description": config.adGroup.adFormat == dbmEnums.liAdformat['In-feed video'] ? 35 : 70,
        "HOT_Description2": 35
      };

      return {
        data: this.htData,
        startCols: this.colHeaders.length,
        startRows: this.startRows,
        minSpareRows: 100,
        manualColumnResize: true,
        autoRowSize: true,
        autoColumnSize: true,
        viewportColumnRenderingOffset: 35,
        comments: true,
        colHeaders: (index) => {
          var header = this.colHeaders[index];
          var displayName = this.displayColHeaders[index];
          var displayText = displayName;
          if (this.requiredCols.base.has(header)) {
            displayText += "<span style='color:#b94a48'>*</span>";
          }
          return displayText;
        },
        rowHeights: 74,
        // stretchH: 'all',
        colWidths: this.getColWidthByIndex,
        // comments: true,
        afterRenderer: (td, row, col, prop, value, cellProperties) => {},
        beforeRenderer: (td, row, col, prop, value, cellProperties) => {},
        columns: (index) => {
          if (index >= this.colHeaders.length) { return }
          let settings = {}
          if (
            this.colHeaders[index].indexOf("HOT_Image") != -1 ||
            this.colHeaders[index].indexOf("HOT_Video") != -1
          ) {
            settings.renderer = this.imageColRenderer;
          } else if (index == 0 && this.colHeaders[index] == "HOT_Is Valid") {
            settings.readOnly = true
            settings.copyable = false
            settings.editor = false
            settings.renderer = this.previewBtnRenderer
          } else if (["HOT_No Scale To Fit", "HOT_Skip Button"].includes(this.colHeaders[index])) {
            settings.type = 'dropdown'
            settings.source = ["Yes", "No"]
            settings.allowInvalid = false
          } else if (["HOT_Display URL", "HOT_Landing Page URL"].includes(this.colHeaders[index])) {
            settings.validator = this.validateUrls
            settings.allowInvalid = false
          } else if (this.colHeaders[index] == "HOT_Youtube URL") {
            settings.validator = this.validateYoutubeVideoUrl
            settings.allowInvalid = false
          } else if (this.colHeaders[index] in columnMaxChars) {
            settings.validator = this.validateInputLength(
              columnMaxChars[this.colHeaders[index]]
            );
            settings.allowInvalid = false
          } else if (this.colHeaders[index].indexOf("HOT_AdGroup") != -1) {
            settings.renderer = this.adGroupNameRenderer;
          } else if (this.colHeaders[index].indexOf("HOT_Target") != -1) {
            settings.renderer = this.targetNameRenderer;
          }
          return settings
        },
        cells: (row, col, prop) => {
          const savedRowsCount = (this.tab in this.publishedCreatives) ? this.publishedCreatives[this.tab].length : 0;
          let cellProperties = {}
          if (this.tab in this.publishedCreatives && row < this.publishedCreativesSize) {
            cellProperties.readOnly = 'true'
            cellProperties.className = 'disabled-cell-text'
          }
          return cellProperties
        },
        afterValidate: (isValid, value, row, col, source) => {
          if (isValid) {
            return;
          }

          const commentsPlugin = this.$refs['hot'].hotInstance.getPlugin('comments');
          const showComment = (message) => {
            setTimeout(() => {
              commentsPlugin.updateCommentMeta(row, col, {
                value: message,
                readOnly: true
              });
              commentsPlugin.showAtCell(row, col);
              setTimeout(() => {
                commentsPlugin.removeCommentAtCell(row, col);
              }, 6000);
            })
          }

          if (this.colHeaders[col] in columnMaxChars) {
            let maxChars = columnMaxChars[this.colHeaders[col]];
            showComment(`Input cannot be more than ${maxChars} characters`);
          } else if (this.colHeaders[col] == "HOT_Youtube URL") {
            showComment(`Invalid YouTube video URL`);
          } else if (["HOT_Display URL", "HOT_Landing Page URL"].includes(this.colHeaders[col])) {
            showComment(
              value.startsWith("http") ? "Please enter a valid URL" : "The URL must start with http/https"
            );
          }

          return false;
        },
        beforePaste: (data, coords) => {
        },
        beforeColumnResize: (newSize, column, isDoubleClick) => {
          return isDoubleClick && newSize > window.innerWidth ? window.innerWidth : newSize
        },
        beforeAutofill: (st, ed, data) => {
        },
        beforeChange: (changes, source) => {
          let instance = this.$refs['hot'].hotInstance
          let requireRerender = false;
          this.bulkUpdateQueries = {};

          // stores the valid column value with respect to the row.
          let previewBtnRowMapper = {};

          for (let index = 0; index < changes.length; index++) {
            let change = changes[index];
            let [row, col, oldVal, newVal] = change;
            if (row < this.publishedCreativesSize || col >= this.colHeaders.length) {
              return;
            }
            if (
              newVal &&
              (this.colHeaders[col].indexOf("HOT_Image/Video") != -1 ||
              this.colHeaders[col].indexOf("HOT_Video") != -1 ||
              this.colHeaders[col].indexOf("HOT_Image") != -1)
            ) {
              let errorMessage = null
              if (!(newVal in this.imageIdUrlMap)) {
                errorMessage = "Image/Video not found."
              } else if (this.colHeaders[col] === "HOT_Video" && newVal.split('-')[0] === 'DXC') {
                errorMessage = "Please select a Video."
              } else if (newVal.split('-')[0] === 'DXC' && newVal.split('-')[2]) {
                var supportedType = linkedInEnums.imageRecipe_adType[this.tab]
                var selectedImageRecipe = this.imagesData.find(x => x.id === newVal).linkedInRecipeId
                if (selectedImageRecipe != supportedType) {
                  errorMessage = "Image not supported for " + this.tab + " ad type. Please select a valid image."
                }
              }
              if (errorMessage !== null) {
                this.$Message.error({
                  background: true,
                  content: errorMessage
                })
                changes[index] = null
                continue;
              }
            }
            if (newVal && this.colHeaders[col].indexOf("HOT_AdGroup") != -1) {
              let cellProperties = {};
              let td = instance.getCell(row, col);
              var adgIds = newVal.split(",");
              var isInvalidAdgroup = false;
              adgIds.forEach(adgId => {
                let adGroupId = adgId.split("-")[2];
                if (!this.$store.state.dv360AdGroups.some(x => x.id == adGroupId)) {
                  isInvalidAdgroup = true;
                };
              })
              changes[index][3] = [...new Set(newVal.split(','))].join(",");
              if (isInvalidAdgroup) {
                this.$Message.error({
                  background: true,
                  content: "Please select a valid AdGroup"
                })
                changes[index] = null;
                continue;
              } else if (td) {
                this.adGroupNameRenderer(instance, td, row, col, this.colHeaders[col], newVal, cellProperties);
              }
              instance.render();
            }
            if (newVal && this.colHeaders[col].indexOf("HOT_Target") != -1) {
              let cellProperties = {};
              let td = instance.getCell(row, col);
              let targetIds = newVal.split(",");
              let isInvalidTarget = false;
              targetIds.forEach(tId => {
                let targetId = tId.split("-")[2];
                if (!this.$store.state.savedTargets.some(x => x.id == targetId)) {
                  isInvalidTarget = true;
                };
              });
              changes[index][3] = [...new Set(newVal.split(','))].join(",");
              if (isInvalidTarget) {
                this.$Message.error({
                  background: true,
                  content: "Please select a valid Target"
                })
                changes[index] = null;
                continue;
              } else if (td) {
                this.targetNameRenderer(instance, td, row, col, this.colHeaders[col], newVal, cellProperties);
              }
              instance.render();
            }

            // return valid column value
            let validRow = this.updatePreviewColumn(row, col, oldVal, newVal, instance, true);
            if (validRow && validRow.length) {
              previewBtnRowMapper[row] = validRow;
            }
          }

          // update the changes array with the value from mapper.
          for (let [, value] of Object.entries(previewBtnRowMapper)) {
            if (value) {
              const [row, col] = value.slice(0, 2);
              // handles the case for images with direct URLs. In that case, changes are set null.
              for (let i = changes.length - 1; i >= 0; i--) {
                if (!changes[i]) {
                  changes.splice(i, 1);
                }
              }
              const existingIndex = changes.findIndex(([r, c]) => r === row && c === col);
              if (existingIndex < 0) {
                changes.push(value);
              } else {
                changes[existingIndex] = value;
              }
            }
          }
          let bulkUpdatesArray = Object.values(this.bulkUpdateQueries);
          setTimeout(() => {
            instance.setDataAtRowProp(bulkUpdatesArray);
          })
          if (requireRerender) {
            this.rerender();
          }
        }
      }
    },
    regularSpec (row) {
      let headerValMap = this.colHeaders.reduce(
        (obj, colName, colIndex) => {
          obj[colName] = row[colIndex]
          return obj
        },
        {}
      );

      let spec = {};

      if (this.tab == "image") {
        const dxImageId = headerValMap["HOT_Image"];

        spec = {
          landingPageUrl: headerValMap["HOT_Landing Page URL"],
          previewImageUrl: this.imageIdUrlMap[dxImageId].src,
          imageId: this.imageIdUrlMap[dxImageId].hash,
          trackingTag: headerValMap["HOT_HTML Tracking Tag"],
          imageDimension: {
            width: this.imageIdUrlMap[dxImageId].width,
            height: this.imageIdUrlMap[dxImageId].height
          },
          dxTargetId: headerValMap["HOT_Target"]
        }
      } else if (this.tab == "youtube") {
        let videoUrl = headerValMap["HOT_Youtube URL"].trim();
        let videoId = "";

        // reference: https://stackoverflow.com/a/9102270
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|shorts\/)([^#\&\?]*).*/;
        var match = videoUrl.match(regExp);
        if (match && match[2].length == 11) {
          videoId = match[2];
        }
        spec = {
          landingPageUrl: headerValMap["HOT_Landing Page URL"],
          videoId,
          callToAction: headerValMap["HOT_Call To Action"],
          headline: headerValMap["HOT_Headline"],
          longHeadline: headerValMap["HOT_Long Headline"],
          description: headerValMap["HOT_Description"],
          description2: headerValMap["HOT_Description2"],
          displayUrl: headerValMap["HOT_Display URL"],
          dxTargetId: headerValMap["HOT_Target"],
          dxAdGroupTargetId: headerValMap["HOT_AdGroup"]
        }
      }

      return spec;
    },
    getPreviewableRows () {
      const adTypeIdMap = {
        image: 1,
        video: 2,
        audio: 3,
        youtube: 4
      };

      this.uniqueId = this.publishedCreativesSize + 1
      let validSpec = []; let validRows = []
      let instance = this.$refs['hot'].hotInstance
      for (let [rowNum, previewRow] of Object.entries(this.previewable)) {
        if (this.checkIfPreviewable(previewRow)) {
          let rowData = instance.getDataAtRow(rowNum) // [true, ad name, Dlow, null]
          let rowDataObj = this.buildRowDataObj(rowData, rowNum)
          let adNameIndex = this.colHeaders.indexOf("HOT_Ad Name")
          let adName = (adNameIndex != -1 && rowData[adNameIndex]) ? rowData[adNameIndex] : ""
          let creativeSpec = this.buildCreativeSpec(rowData, rowNum);
          let spec = {
            'hotRowId': `${this.tab}-${this.uniqueId++}`,
            'adName': `${adName}`,
            [this.tab == 'youtube' ? 'youtubeCreative' : 'creative']: creativeSpec,
            'type': adTypeIdMap[this.tab]
          }
          validSpec.push(spec)
          validRows.push(rowDataObj)
        }
      }
      return [validSpec, validRows]
    },
    buildCreativeSpec (row, index) {
      return this.regularSpec(row)
    },
    getCreativeStructure (tableData) {
      this.uniqueId = this.publishedCreativesSize + 1
      let validSpec = []; let validRows = []
      for (let [rowNum, previewRow] of Object.entries(this.previewable)) {
        if (this.checkIfPreviewable(previewRow)) {
          let rowData = tableData[rowNum]
          let rowDataObj = this.buildRowDataObj(rowData, rowNum)
          let adNameIndex = this.colHeaders.indexOf("HOT_Ad Name")
          let adName = (adNameIndex != -1 && rowData[adNameIndex]) ? rowData[adNameIndex] : ""
          let creativeSpec = this.buildCreativeSpec(rowData, rowNum);
          let spec = {
            'hotRowId': `${this.tab}-${this.uniqueId++}`,
            'AdName': `${adName}`,
            'Creative': creativeSpec["creative"]
          }
          validSpec.push(spec)
          validRows.push(rowDataObj)
        }
      }
      return [validSpec, validRows]
    },
    getAllRows () {
      this.uniqueId = this.publishedCreativesSize + 1
      let validRows = []
      let instance = this.$refs['hot'].hotInstance
      let rowNum = 0;
      var gridData = instance.getData();
      var cleanedGridData = [];
      gridData.forEach((rowData, index) => {
        if (!instance.isEmptyRow(index)) {
          cleanedGridData.push(rowData);
        }
      })
      for (let rowData of cleanedGridData) {
        let rowDataObj = this.buildRowDataObj(rowData, rowNum)
        validRows.push(rowDataObj)
        rowNum++;
      }
      rowNum = 0;
      validRows.forEach(elem => {
        if (rowNum < this.publishedCreativesSize) {
          elem["HOT_IsNotPublished"] = false;
        } else {
          elem["HOT_IsNotPublished"] = true;
        }
        rowNum++;
      });
      return validRows;
    },
    buildRowDataObj (rowData, rowNum) {
      let rowObj = Object.assign(
        ...rowData.map((cellValue, index) => ({
          [this.colHeaders[index]]: cellValue
        }))
      );
      return rowObj;
    },
    getRowValueForColumn (rowData, headerName) {
      var indexValue = this.colHeaders.indexOf(headerName);
      return indexValue != -1 && rowData[indexValue] ? rowData[indexValue] : null
    },
    validateInputLength (inputLength) {
      return (value, callBack) => {
        if (!value) {
          callBack(true);
          return;
        }
        if (String(value).length > inputLength) {
          callBack(false);
        }
        callBack(true);
      }
    },
    validateYoutubeVideoUrl (value, callBack) {
      if (value == "" || value == null) {
        callBack(true);
        return;
      }

      if (!value.match(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/)) {
        callBack(false);
        return;
      }

      var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|shorts\/)([^#\&\?]*).*/;
      var match = value.match(regExp);
      if (match && match[2].length == 11) {
        callBack(true);
      } else {
        callBack(false);
      }
    },
    validateUrls (value, callBack) {
      if (value == "" || value == null) {
        callBack(true);
        return;
      }
      if (value.startsWith("http") && value.match(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/)) {
        callBack(true);
      } else {
        callBack(false);
      }
    },
    adGroupNameRenderer (instance, td, row, col, prop, value, cellProperties) {
      let self = this;
      if (row < this.publishedCreativesSize) {
        td.classList.add('disabled-cell')
      }
      if (value) {
        var adGroupIds = value.split(",");
        var names = [];
        adGroupIds.forEach(adg => {
          let adgroup = self.$store.state.dv360AdGroups.find(x => x.id == adg.split("-")[2]);
          if (adgroup) {
            names.push(adgroup.name);
          } else {
            names.push(adg);
          }
        });

        if (names.length) {
          td.innerHTML = '';
          let tdWrapper = document.createElement('div');
          tdWrapper.innerHTML = [...new Set(names)].join(",");
          td.appendChild(tdWrapper);
        } else {
          td.innerHTML = '';
        }
        return td;
      } else {
        td.innerHTML = '';
        return td;
      }
    },
    targetNameRenderer (instance, td, row, col, prop, value, cellProperties) {
      let self = this;
      if (row < this.publishedCreativesSize) {
        td.classList.add('disabled-cell')
      }
      if (value) {
        var targetIds = value.split(",");
        let names = [];
        targetIds.forEach(tId => {
          let target = self.$store.state.savedTargets.find(x => x.id == tId.split("-")[2]);
          if (target) {
            names.push(target.name);
          } else {
            names.push(tId);
          }
        });
        if (names.length) {
          td.innerHTML = '';
          let tdWrapper = document.createElement('div');
          tdWrapper.innerHTML = [...new Set(names)].join(",");
          td.appendChild(tdWrapper);
        } else {
          td.innerHTML = '';
        }
        return td;
      } else {
        td.innerHTML = '';
        return td;
      }
    }
  }
}
</script>

<style>
.hot-img {
  display: block;
  padding: 2px;
  margin: 0 auto;
  max-height: 70px;
  max-width: 100px;
}
.ad-sheet-hot .handsontable td,
.ad-sheet-hot .handsontable tr,
.ad-sheet-hot .handsontable th {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}

.preview-td-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.preview-td-wrapper > span {
  display: inline-flex;
  flex-grow:1;
  flex-basis: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
	cursor: pointer;
}
.preview-icon {
	font-size: 18px;
	opacity: 1;
}
.preview-icon-disabled > span.eye {
	pointer-events: none;
  opacity: 0.5;
}

.disabled-cell {
	background-color: #f6f6f6 !important;
}
.disabled-cell-text {
  background-color: #f6f6f6 !important;
  color: #bbb !important;
}
.post-cell {
  cursor: not-allowed;
}
.trim {
  margin: 0 !important;
  padding: 0 !important;
}
.handsontable td.htInvalid {
  background-color: white !important;
  border: 1px solid red
}
</style>

<style src="../../../../../../../node_modules/handsontable/dist/handsontable.full.css"></style>
