<template>
  <div class="cl-widget">
    <Card>
      <div slot="title">
        <div class="row">
          <h4 class="col-md-4" style="float: left;">
            {{title}}
          </h4>
          <div class="col-md-1 flex-center">
            <i v-show="processingData" class="fa fa-spinner fa-spin" />
          </div>
          <Icon @click="isFacebookPosts=false" type="logo-instagram" class="header-icon float-right" />
          <Icon @click="isFacebookPosts=true" type="logo-facebook" class="header-icon float-right" />
        </div>
      </div>
      <div class="search-padding">
        <Input
          v-model="userQuery"
          search
          placeholder="Search"
          class="input-search-bar"
        />
      </div>
      <VuePerfectScrollbar class="widget-body">
        <div v-show="isFacebookPosts">
          <FacebookPosts
          :isFacebookPosts = "isFacebookPosts"
          :allPosts="facebookPosts"
          :searchQuery="userQuery"
          @processing="processingStatus" />
        </div>
        <div v-show="!isFacebookPosts">
          <InstagramPosts
          :isFacebookPosts = "isFacebookPosts"
          :allPosts="instagramPosts"
          :searchQuery="userQuery"
          @processing="processingStatus"/>
        </div>
      </VuePerfectScrollbar>
    </Card>
  </div>
</template>

<script>
import { Card, Input, Icon } from "iview";
import { APIService } from "./../ApiService.js";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import FacebookPosts from "./FacebookPagePosts.vue";
import InstagramPosts from "./InstagramPosts.vue";
import { mapState, mapMutations } from "vuex";

export default {
  components: {
    Card,
    VuePerfectScrollbar,
    Input,
    FacebookPosts,
    InstagramPosts,
    Icon
  },
  props: {},
  data () {
    return {
      processingData: false,
      userQuery: "",
      alert: {},
      postIdsaddedToAdSheet: {},
      isFacebookPosts: true,
      selectedPostType: 2,
      title: "Page Posts",
      facebookPosts: [],
      instagramPosts: []
    };
  },
  mounted () {
    this.$watch(
      vm => [
        vm.$store.state.facebookPosts,
        vm.$store.state.instagramPosts
      ],
      callback => {
        this.facebookPosts = this.$store.state.facebookPosts;
        this.instagramPosts = this.$store.state.instagramPosts;
      }
    );

    this.$watch(
      vm => [vm.createSheetCreatives],
      callback => {
        if (
          this.publishedCreatives &&
          this.publishedCreatives["existing posts"] &&
          this.publishedCreatives["existing posts"].length > 0
        ) {
          this.publishedCreatives["existing posts"].forEach(rowObject => {
            // skip current row if it's already published (and not in promotion mode)
            if (
              !this.isPromotionMode &&
 rowObject["HOT_IsNotPublished"] && rowObject["HOT_IsNotPublished"].toString() == "false"
            ) {
              return;
            }
            let post;
            if ((this.facebookPosts || []).length > 0) {
              post = this.facebookPosts.find(
                x => x.id == rowObject["HOT_Post ID"]
              );

              if (post) {
                post.pagePostId = post.id;
                post.title = (post.attachments || { data: [{}] }).data[0].title;
              }
            } if (!post && (this.instagramPosts || []).length > 0) {
              post = this.instagramPosts.find(
                x => x.id == rowObject["HOT_Post ID"]
              )
            }
            if (post) {
              this.$set(this.postIdsaddedToAdSheet, post.id, true);
              this.set_existingPostAds([...this.existingPostAds, post]);
            }
          });
        }
      },
      {
        immediate: true
      }
    );
  },
  watch: {},
  methods: {
    ...mapMutations(["set_existingPostAds", "set_connectedInstagramAccountId", "set_instagramPosts", "set_facebookPosts", "set_isPostsFetched"]),
    processingStatus (status) {
      this.processingData = status
    }
  },
  computed: {
    ...mapState([
      "businessProfilesSearchEngineId",
      "businessProfileId",
      "selectedCampaignLauncherConfig",
      "existingPostAds",
      "posts",
      "publishedCreatives",
      "isPromotionMode",
      "createSheetCreatives",
      "isPostsFetched"
    ])
  }
};
</script>

<style>
.input-search-bar .ivu-input {
  height: 30px;
}
</style>
<style scoped>
.search-padding {
  padding: 0 0 5px 5px;
}
.flex-center {
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-center > i {
  font-size: 16px;
}
.list-image-poptip {
  max-width: 250px;
  max-height: 250px;
}
.list-image-wrapper {
  position: relative;
  width: 2rem;
  height: 2rem;
}
.list-image {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center;
  transition: transform 0.05s linear;
  transform: translate(-50%, -50%);
}
.header-icon {
  padding-top: 4px;
  display: inline-block;
  line-height: 3rem;
  font-size: 18px;
  cursor: pointer;
  color: #7D7F7F;
}
.float-right {
  float: right;
  margin-right: 15px;
}
</style>
