<template>
   <div>
      <div class ="ad-summary-container">
         <h4 class="summary-heading">
            Summary
            <i
               class="fa fa-spinner fa-spin"
               v-show="!summaryAlert.status && processing"
               ></i>
         </h4>
          <alert
            class="error-message"
            v-model="summaryAlert.status"
            :type="summaryAlert.type"
            ><span v-html="summaryAlert.message">
              </span>
            </alert>
         <ul class="summmary-list" v-show="!summaryAlert.status">
            <VuePerfectScrollbar
               class="scroll-area"
               :settings="{ maxScrollbarLength: 200 }"
               >
               <li v-for="(config,configIndex) in getCampaignsData"
                  :key="configIndex">
                  <div
                     data-toggle="collapse"
                     class="summary-collapse collapsed summary-element "
                     v-bind:href="'#config-summary-' + configIndex"
                     >
                     <span class="entity-label">
                        <Poptip transfer placement="left-start" trigger="hover">
                           <span class="ellipsis-text">{{ config.name }}</span>
                           <div slot="content">
                              {{ config.name }} (Budget : {{config.bp_currency}}{{ parseFloat(config.budget).toFixed(2) }})
                           </div>
                        </Poptip>
                        <span class="pull-right"> (Budget : {{config.bp_currency}}{{ parseFloat(config.budget).toFixed(2) }}) </span>
                     </span>
                  </div>
                  <ul
                     v-bind:id="'config-summary-' + configIndex"
                     class="collapse-close collapse campaign-summary"
                     >
                     <li class="panel-collapse"  v-for="(campaign,campaignIndex) in config.childEntities"
                        :key="campaignIndex">
                        <div
                           data-toggle="collapse"
                           class="summary-collapse collapsed"
                           :href="`#campaign-summary-${configIndex}-${campaignIndex}`"
                           >
                           <span class="entity-label">
                              <Poptip transfer placement="left-start" trigger="hover">
                                 <div class="ellipsis-text">{{ campaign.Name }}</div>
                                 <div slot="content">
                                    {{ campaign.Name }} (Budget : {{config.bp_currency}}{{ parseFloat(campaign.RowData.Budget).toFixed(2) + (budgetTypeHelpText[campaign.RowData.BudgetType])}})
                                 </div>
                              </Poptip>
                              <span
                                class="pull-right"
                                >(Budget : {{config.bp_currency}}{{ parseFloat(campaign.RowData.Budget).toFixed(2) }})</span>
                           </span>
                        </div>
                        <ul
                           v-bind:id="`campaign-summary-${configIndex}-${campaignIndex}`"
                           class="collapse-close collapse adgroup-summary"
                           >
                           <li
                              class="panel-collapse"
                              v-for="(adgroup,adgroupIndex) in campaign.ChildEntities"
                              :key="adgroupIndex"
                              >
                              <div>
                                 <span>
                                    <span v-if="adgroup.Id != ''">({{ adgroup.Id }})</span>
                                    <span class="entity-label">
                                       <Poptip transfer placement="left-start" trigger="hover">
                                          <div>
                                            <span class="ellipsis-adset-text">{{ adgroup.Name }}</span>
                                            <span class="product-count-span" v-if="campaign.ApiData.objective == 'PRODUCT_CATALOG_SALES'">
                                              (Selected Products: {{ adgroup.TargetProductCount }})
                                            </span>
                                          </div>
                                          <div slot="content">
                                            {{ adgroup.Name }}
                                            <span v-if="campaign.ApiData.objective == 'PRODUCT_CATALOG_SALES'">
                                              (Selected Products: {{ adgroup.TargetProductCount }})
                                            </span>
                                          </div>
                                       </Poptip>
                                    </span>
                                 </span>
                              </div>
                           </li>
                        </ul>
                     </li>
                  </ul>
               </li>
            </VuePerfectScrollbar>
         </ul>
      </div>
   </div>
</template>
<script>
import { Poptip } from "iview";
import { alert } from "vue-strap";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
export default {
  components: {
    VuePerfectScrollbar,
    Poptip,
    alert
  },
  props: {
    summaryDataProcessing: true,
    summary: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data: function () {
    return {
      allCampaignData: {},
      processing: true,
      summaryAlert: {
        type: "",
        message: "",
        status: false
      },
      budgetTypeHelpText: {
        "daily_budget": "/day",
        "lifetime": "lifetime"
      }
    };
  },
  computed: {
    getCampaignsData: function () {
      if (this.summary.failed) {
        this.summaryAlert.type = "danger"
        this.summaryAlert.message = this.summary.message
        this.summaryAlert.status = true
      }
      if (this.summary) this.allCampaignData = this.summary.data;
      if (!this.allCampaignData || !this.allCampaignData.facebookEntities) {
        return [];
      }
      this.processing = false;
      return this.allCampaignData.facebookEntities
    },
    summaryDataReload: function () {
      this.processing = this.summaryDataProcessing;
      return this.processing;
    }
  }
};
</script>
<style scoped>
.entity-label {
  color: #979898;
}
.ad-summary-container {
  position: sticky;
  right: 20px;
  top: 65px;
  border: 1px solid #ddd;
  background-color: white;
  margin-top: 0px;
  height: 416px;
}
.ad-summary-container ul.summmary-list {
  height: 385px;
  overflow-y: auto;
  width: 100%;
}
.summmary-list {
  padding: 0px 0px 2px 9px;
}
span.pull-right {
  font-size: 1.0em;
  margin-right: 10px
}
.no-pointer {
  cursor: default;
}
.summary-collapse {
  cursor: pointer;
  padding:3px
}
.summary-collapse:before {
  font-family: "FontAwesome";
  content: "\f0d7";
  color: grey;
  font-size: 1.3em;
  padding-right: 5px;
  vertical-align: text-bottom;
}
.summary-collapse.collapsed:before {
  content: "\f0da";
}
.campaign-summary {
  list-style: none;
  padding-left: 20px;
}
.adgroup-summary {
  padding-left: 35px;
}
.scroll-area {
  position: relative;
  margin: auto;
  height: 380px;
}
.summary-heading{
    margin:10px 0 0px 10px;
}
.summary-element{
  margin:10px 0 0px 0px;
}
.ellipsis-text{
  max-width: 120px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsis-adset-text{
  max-width: 170px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}
.error-message {
  white-space: pre-line;
  word-wrap: break-word;
  margin: 10px;
}
.product-count-span {
  display: table;
}
</style>
