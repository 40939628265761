<template>
  <div class="cl-widget">
    <Card>
      <div slot="title">
        <div class="row">
          <h4 v-bind:class="(isTabActive('Videos') && !isPromotionMode) ? 'col-md-8' : 'col-md-5'" style="float: left;">{{headerTitle}} <i v-show="processingData" class="fa fa-spinner fa-spin" /></h4>
          <br v-if="isTabActive('Videos') && !isPromotionMode">
          <div class="col-md-5" style="margin-top: 7px; padding-right: 0px" v-if="isTabActive('Videos') && !isPromotionMode">
            <RadioGroup v-model="videoTypeToggle" @on-change="videoToggleChange">
                <Radio label="Account"></Radio>
                <Radio label="Page"></Radio>
            </RadioGroup>
          </div>
          <div class="col-md-7">
            <i @click="setActiveTab('Images')" :class="{'selected-category': isTabActive('Images')}" class=" col far fa-image float-right header-icon"></i>
            <i @click="setActiveTab('Videos')" :class="{'selected-category': isTabActive('Videos')}" class="col fas fa-video-camera float-right header-icon"></i>
            <picker-api
              :class="{'disable-click': businessProfilesSearchEngineId == 0}"
              @click.native="setActiveTab('Images')"
              @saveImage="uploadMedia"
              class="col float-right header-icon">
            </picker-api>
            <Icon @click="setActiveTab('Mails')" type="md-mail" class="header-icon float-right" />
            <i v-if="!inCreativeSetup" @click="showCreativeBuilder()" :class="{'selected-category': isTabActive('Design')}" class="col float-right header-icon">
              <span><Icon type="ios-color-palette" /></span>
            </i>
          </div>
        </div>
      </div>
      <Modal
        v-model="showCreativeBuilderModal"
        title="Designer"
        footer-hide
        :mask-closable="false"
        :styles="{top: '2rem', position: 'relative', width: '95%'}"
        @on-cancel="closeCreativeBuilderModel"
      >
        <div v-if="showCreativeBuilderLoader" class="creative-builder-spinner">
          <i  class="fa fa-spinner fa-spin" style="font-size: 5rem;" />
        </div>
        <iframe v-else
          :src=iFrameProperties.src
          class="creative-builder-iframe"
          frameborder="0"
        ></iframe>
      </Modal>
      <transition name="clw-alert">
        <div v-show="showAlert" class="alert alert-warning" role="alert" style="margin: 0px 5px 15px 5px;">
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            @click="showAlert = false"
          >×</button>
          {{ alertMessage }}
        </div>
      </transition>
      <div
        v-if="businessProfilesSearchEngineId == 0"
        class="alert alert-info"
        style="margin: 10px 10px 18px"
      >Please select a campaign configuration.</div>
      <template v-else>
        <div class="row" style="margin-left:-10px;margin-right:-10px">
          <div class="col-md-5 search-bar">
            <Input v-if="isTabActive('Images')" v-model="imageSearchWord" search placeholder="Search" />
            <Input v-if="isTabActive('Videos')" v-model="videoSearchWord" search placeholder="Search" />
          </div>
          <Upload
            v-show="isTabActive('Images', 'Videos')"
            id='mediaInput'
            class="upload-style col-md-7"
            type="drag"
            :before-upload="uploadMedia"
            :accept="fileTypeToAccept"
            multiple
            action="">
            <b>Choose a file</b> or drag it here
          </Upload>
        </div>
        <VuePerfectScrollbar v-show="!isTabActive('Mails')" class="widget-body">
          <Row
            v-for="file in getUploadingFiles"
            :key="file.key"
            class="cl-ivu-row"
            style="padding-bottom: 0px !important; margin-top: 10px;"
          >
            <Row-Col span="1">
              <i class="fas fa-upload"></i>
            </Row-Col>
            <Row-Col span="1" offset="1">
              <i class="fas fa-spinner fa-spin upload-green"></i>
            </Row-Col>
            <Row-Col span="14" offset="1">
              <Tooltip max-width="400" :content="file.uploadingFileName" placement="right-start" :transfer="true">
                <span>{{ file.uploadingFileName }}</span>
              </Tooltip>
            </Row-Col>
            <Row-Col span="3" offset="3" v-show="file.showProgress">
              <span class="upload-green"> {{ file.uploadingProgress }}% </span>
            </Row-Col>
          </Row>
          <div v-show="isTabActive('Images')" style="margin-top:10px">
            <div
              v-if="filteredImagesData.length == 0 && !processingData"
              class="alert alert-info no-data"
            >
              No Images Available.
            </div>
            <template v-else>
              <Row class="cl-ivu-row" v-for="(media) in filteredImagesData" :key="`${media['id']}-${media['name']}`">
                <Row-Col span="1">
                  <i v-tooltip="'Click to copy ID'"
                    data-placement="right"
                    class="fas fa-copy action-icon"
                    @click="copyId(media['id'])">
                  </i>
                </Row-Col>
                <!-- <Row-Col span="1" offset="1">
                  <Poptip transfer placement="left-start">
                    <i class="fas fa-eye"></i>
                    <div slot="content">
                      <img v-lazy="media['src']" class="list-image-poptip">
                    </div>
                  </Poptip>
                </Row-Col> -->
                <Row-Col class="cl-widget-text" span="18" offset="1">
                  <Tooltip max-width="400" :content="media['name']" placement="right-start" :transfer="true">
                    <span> {{ media['name'] }} </span>
                  </Tooltip>
                  <i class="fas fa-exclamation-triangle list-icon alert-icon display-none"></i>
                </Row-Col>
                <Row-Col span="3" offset="1" class="list-image-wrapper">
                  <img v-lazy="media['src']" class="list-image">
                </Row-Col>
                <!-- <span v-tooltip="media['id']" class="list-text-id"> {{ media['id'] }} </span> -->
              </Row>
            </template>
          </div>
          <div v-show="isTabActive('Videos')" style="margin-top:10px">
            <div
              v-if="filteredVideosData.length == 0 && !fetchingVideosByTitle"
              class="alert alert-info no-data"
            >
            No Videos Available.
            </div>
            <template v-else>
              <i v-show="fetchingVideosByTitle" class="fa fa-spinner fa-spin video-spinner" />
              <Row v-show="!fetchingVideosByTitle" class="cl-ivu-row" v-for="(media) in filteredVideosData" :key="`${media['id']}-${media['name']}`">
                <Row-Col span="1">
                  <i v-if="!('error' in media)"
                    v-tooltip="'Click to copy ID'"
                    data-placement="right"
                    class="fas fa-copy action-icon"
                    @click="copyId(media['id'])">
                  </i>
                  <i v-else-if="('error' in media) && media.errorType == 1"
                    v-tooltip="'Click to retry'"
                    data-placement="right"
                    @click="retryVideoProcessing(media.Id)"
                    class="fa fa-repeat">
                  </i>
                  <i v-else
                    v-tooltip="'Failed to upload video'"
                    data-placement="right"
                    class="fa fa-exclamation-triangle">
                  </i>
                </Row-Col>
                <!-- <Row-Col span="1" offset="1">
                  <Poptip v-if="!('error' in media)" transfer placement="left-start">
                    <i class="fas fa-eye"></i>
                    <div slot="content">
                      <img v-lazy="media['src']" class="list-image-poptip">
                    </div>
                  </Poptip>
                  <i v-else class="fas fa-eye-slash" style="opacity: 0.7;"></i>
                </Row-Col> -->
                <Row-Col class="cl-widget-text" span="18" offset="1">
                  <Tooltip max-width="400" :content="media['name']" placement="right-start" :transfer="true">
                    <span> {{ media['name'] }} </span>
                  </Tooltip>
                </Row-Col>
                <Row-Col span="3" offset="1" class="list-image-wrapper">
                  <img v-if="!('error' in media)" v-lazy="media['src']" class="list-image">
                </Row-Col>
              </Row>
            </template>
          </div>
        </VuePerfectScrollbar>
        <div v-show="isTabActive('Mails')">
            <mailed-images :show="true" :mails="dxInboundEmails"></mailed-images>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from "vuex"
import { Input, Upload, locale, Card, Row, Col, Icon, Tooltip, Modal, Radio, RadioGroup } from 'iview'
import lang from "iview/dist/locale/en-US"
import { APIService } from './../ApiService.js'
import VueLazyload from 'vue-lazyload'
import PickerApi from "./PickerApi.vue"
import MailedImages from "./MailedImages.vue"
import FacebookCampaignLauncherConfig from "../Mixins/FacebookCampaignLauncherConfig.js";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ApiService } from '../../../../Admin/Features/FeatureUsage/Services/ApiService.js'
import { EventBus } from "../EventBus.js";

locale(lang)
Vue.use(VueLazyload, {
  attempt: 1
})

export default {
  components: {
    Modal,
    Input,
    Upload,
    PickerApi,
    VuePerfectScrollbar,
    Card,
    "Row-Col": Col,
    Row,
    Icon,
    MailedImages,
    Tooltip,
    Radio,
    RadioGroup
  },
  props: {
    fetchMediaUrl: { type: String, default: "" },
    fetchVideosUrl: { type: String, default: "" },
    saveImageURL: { type: String, default: "" },
    inCreativeSetup: { type: Boolean, default: false }
  },
  mixins: [FacebookCampaignLauncherConfig],
  data: function () {
    return {
      processingData: false,
      images: true,
      imageSearchWord: '',
      videoSearchWord: '',
      uploadingFiles: {
        Images: {},
        Videos: {}
      },
      uniqueId: 0,
      headerTitle: "Images",
      showAlert: false,
      alertMessage: "",
      showCreativeBuilderModal: false,
      iFrameProperties: {},
      showCreativeBuilderLoader: false,
      isVideoUploadInProgress: false,
      dbIndexFileIndexMap: {},
      videoIds: new Set(),
      filteredVideosData: [],
      fetchingVideosByTitle: false,
      searchVideoTimeout: null,
      videoTypeToggle: 'Account',
      retryVideos: [],
      filteredImagesData: [],
      searchImageTimeout: null
    }
  },
  created () {
    EventBus.$on("adsheetWidget-publishedCreativesFetched", async () => {
      if (this.publishedSheetCreatives && Object.keys(this.publishedSheetCreatives).length) {
        var mediaResponse = JSON.parse(JSON.stringify(this.mediaResponse))
        if (mediaResponse && Object.keys(mediaResponse).length) {
          await this.setUnfetchedmedia(mediaResponse)
          this.set_imagesData(mediaResponse.images);
          this.set_videosData(JSON.parse(mediaResponse.videos));
          this.set_mediaResponse(mediaResponse);
          this.formatData()
        }
      }
    });
    EventBus.$on("adsheetWidget-configSwitched", async () => {
      if (this.createSheetCreatives && Object.keys(this.createSheetCreatives).length) {
        var mediaResponse = JSON.parse(JSON.stringify(this.mediaResponse))
        if (mediaResponse && Object.keys(mediaResponse).length) {
          await this.setUnfetchedmedia(mediaResponse)
          this.set_imagesData(mediaResponse.images);
          this.set_videosData(JSON.parse(mediaResponse.videos));
          this.set_mediaResponse(mediaResponse);
          this.formatData()
        }
      }
    });
  },
  beforeDestroy () {
    EventBus.$off("adsheetWidget-publishedCreativesFetched")
    EventBus.$off("adsheetWidget-configSwitched")
    EventBus.$off("send-data-CW")
  },
  mounted () {
    this.$watch(
      vm => [vm.processingData, vm.videoSearchWord, vm.videosData],
      callback => {
        this.fetchingVideosByTitle = false;
        clearTimeout(this.searchVideoTimeout);
        if (this.videoSearchWord.trim().length > 0) {
          this.fetchingVideosByTitle = true;
          this.searchVideoTimeout = setTimeout(() => {
            APIService.getVideosByTitle(
              this.businessProfilesSearchEngineId,
              this.videoSearchWord.trim()
            ).then((response) => {
              let responseVideos = JSON.parse(response.data.Data)
              if (responseVideos.length > 0) {
                let formattedVideos = this.formatVideos(responseVideos);
                if (this.videoSearchWord.trim().length > 0) {
                  this.filteredVideosData = this.filterByPage(formattedVideos);
                }
              } else {
                if (this.videoSearchWord.trim().length > 0) {
                  this.filteredVideosData = [];
                }
              }
            }).catch((error) => {
              console.error(error);
            }).finally(() => {
              this.fetchingVideosByTitle = false
            });
          }, 500);
        } else {
          this.filteredVideosData = this.filterByPage(this.videosData);
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      vm => [vm.imageSearchWord, vm.imagesData],
      callback => {
        this.processingData = false;
        clearTimeout(this.searchImageTimeout);
        if (this.imageSearchWord.trim().length > 0) {
          this.processingData = true;
          this.searchImageTimeout = setTimeout(this.addImagesByNameFilter, 500);
        } else {
          this.filteredImagesData = this.imagesData;
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    EventBus.$on('send-data-CW', async (data, totaldata, assetColumnChanges, haveDataToFetch, callback) => {
      if (!haveDataToFetch) {
        this.$store.commit("set_showCampaignLauncherError", false);
        this.$store.commit(
          "set_campaignLauncherAssetsWithError",
          []
        );
        return;
      }
      if (data.length > 0) {
        var errorAssets = [];
        const dxcStrings = data.filter(str => str.startsWith(`DXC-${Number.parseInt(this.businessProfileId).toString(36)}-`))
        const dxvStrings = data.filter(str => str.startsWith(`DXV-${Number.parseInt(this.businessProfileId).toString(36)}-`))
        // const storeStrings = data.filter(str => (/^{{store\.asset([1-9]|[1-4]\d|50)}}$/.test(str)))

        // if (this.isPromotionMode) {
        //   for (let value in storeStrings) {
        //     let defaultStoreAssets = this.$store.state.dbStores.find(s => s.isDefault == true).assets;
        //     let assetId = value.match(/\d+/)[0];
        //     let asset = defaultStoreAssets.find(a => a.id == parseInt(assetId, 10));
        //     if (asset && asset.value.startsWith('DXC') && !(asset.value in this.imageIdUrlMap)) {
        //       dxcStrings.push(asset.value)
        //     } else if (asset && asset.value.startsWith('DXV') && !(asset.value in this.imageIdUrlMap)) {
        //       dxvStrings.push(asset.value)
        //     }
        //   }
        // }

        const imageStrings = data.filter(str => str.includes('ImageName_'));
        const videoStrings = data.filter(str => str.includes('VideoName_'));

        // for (let link of data.filter(str => str.startsWith('http') || str.startsWith('www'))) {
        //   let isValid = await this.isValidImage(link);
        //   if (isValid) {
        //     validLinks.push(link);
        //   }
        // }
        var assetStrings = data.filter(x => !(dxcStrings.includes(x) || dxvStrings.includes(x) || x.startsWith('http') || x.startsWith('www') || x == "" || x == null))

        var parsedDXCStrings = dxcStrings.map(str => {
          const parts = str.split('-');
          return parts[parts.length - 1];
        });
        var parsedDXVStrings = dxvStrings.map(str => {
          const parts = str.split('-');
          return parts[parts.length - 1];
        });

        assetStrings.push(...imageStrings.map(str => str.replace("ImageName_", "")))
        assetStrings.push(...videoStrings.map(str => str.replace("VideoName_", "")))

        if ((dxvStrings && dxvStrings.length > 0) ||
            (dxcStrings && dxcStrings.length > 0) ||
            (assetStrings && assetStrings.length > 0)) {
          this.$store.commit("set_loadingCampiagnLauncherAssets", true);
        }
        var imageResponse = null
        var videosResponse = null

        if (parsedDXCStrings.length > 0 || assetStrings.length > 0) {
          imageResponse = await APIService.getImagesByIdandNames(businessProfileId, this.businessProfilesSearchEngineId, parsedDXCStrings, assetStrings)
          imageResponse = this.createIamgeObject(imageResponse.data.data)
        }
        if (parsedDXVStrings.length > 0 || assetStrings.length > 0) {
          videosResponse = await APIService.getVideosByIdandNames(businessProfileId, this.businessProfilesSearchEngineId, parsedDXVStrings, assetStrings);
        }

        this.formatAndSetUnfetchedData(imageResponse, videosResponse)
        var incorrectAssets = totaldata.filter(x => !(this.imagesData.some(obj => obj.name == x) ||
                                                      (x in this.imageIdUrlMap) ||
                                                      this.videosData.some(obj => obj.Title == x) ||
                                                      (x.startsWith('http') || x.startsWith('www')) ||
                                                      (/^{{store\.asset([1-9]|[1-4]\d|50)}}$/.test(x)))
        ).filter(i => (i != "" && i != null))
        this.$store.commit("set_loadingCampiagnLauncherAssets", false);

        errorAssets.push(...incorrectAssets)

        if (errorAssets.length > 0) {
          this.$store.commit("set_showCampaignLauncherError", true);
          this.$store.commit(
            "set_campaignLauncherAssetsWithError",
            errorAssets
          );
        } else {
          this.$store.commit("set_showCampaignLauncherError", false);
          this.$store.commit(
            "set_campaignLauncherAssetsWithError",
            []
          );
        }
        callback(assetColumnChanges)
      }
    });
  },
  computed: {
    ...mapState([
      "businessProfileId",
      "businessProfilesSearchEngineId",
      "imageIdUrlMap",
      "dxInboundEmails",
      "deltax",
      "selectedPublisher",
      "isPromotionMode",
      "promotionObject",
      "imagesData",
      "videosData",
      "createSheetCreatives",
      "publishedCreatives",
      "publishedSheetCreatives",
      "mediaResponse",
      "loadingCampiagnLauncherAssets"
    ]),
    fileTypeToAccept: function () {
      if (this.headerTitle == 'Images') {
        return 'image/*'
      }
      return 'video/*,.gif'
    },
    getUploadingFiles: function () {
      // return uploading files (images & videos) showing last uploaded file first
      if (!this.isTabActive('Images', 'Videos')) { return [] }
      var files = this.uploadingFiles[this.headerTitle]
      if (this.isTabActive('Videos')) {
        return this.filterByPage(Object.keys(files)
          .sort()
          .reverse()
          .map(key => {
            return { key, ...files[key] };
          }))
      } else {
        return Object.keys(files)
          .sort()
          .reverse()
          .map(key => {
            return { key, ...files[key] };
          })
      }
    },
    noVideoData: function () {
      return (
        this.filterByPage(this.videosData).length == 0 &&
        this.filterByPage(Object.keys(this.uploadingFiles.Videos)
          .sort()
          .reverse()
          .map(key => {
            return { key, ...this.uploadingFiles.Videos[key] };
          })).length == 0
      )
    }
  },
  watch: {
    businessProfilesSearchEngineId: {
      immediate: true,
      handler () {
        if (!this.inCreativeSetup) {
          this.fetchMedia();
        }
      }
    },
    headerTitle () {
      this.showAlert = false
    },
    isPromotionMode () {
      if (this.isPromotionMode) {
        this.videoTypeToggle = 'Page'
      }
    }
  },
  methods: {
    ...mapMutations(["SET_imageIdUrlMap", "set_imagesData", "set_videosData", "set_mediaResponse", "set_loadingCampiagnLauncherAssets"]),
    videoToggleChange () {
      this.videoSearchWord = '';
      this.filteredVideosData = this.filterByPage(this.videosData)
    },
    isValidImage (url) {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
        img.src = url;
      });
    },
    async addImagesByNameFilter () {
      try {
        var images = await APIService.getImages(this.businessProfileId, this.businessProfilesSearchEngineId, null, this.imageSearchWord.trim());
        var modifiedImages = this.createIamgeObject(images.data.data);
        var formattedImages = [];
        var assetUrlMap = this.imageIdUrlMap;
        modifiedImages.forEach((image) => {
          let formattedImageData = this.formatImageData(image);
          formattedImages.push(formattedImageData);
          if (!(formattedImageData.id in assetUrlMap)) {
            assetUrlMap[formattedImageData.id] = { src: formattedImageData.src, hash: formattedImageData.hash };
          }
          if (!(formattedImageData.name in assetUrlMap)) {
            assetUrlMap[formattedImageData.name] = { src: formattedImageData.src, hash: formattedImageData.hash };
          }
        })
        if (this.imageSearchWord.trim().length > 0) {
          this.filteredImagesData = formattedImages;
        }
        this.SET_imageIdUrlMap(assetUrlMap);
      } catch (error) {
        console.error(error);
      } finally {
        this.processingData = false;
      }
    },
    async retryVideoProcessing (Id) {
      if (this.retryVideos.includes(Id)) {
        return;
      }
      this.retryVideos.push(Id);
      const index = this.videosData.findIndex(v => v.Id === Id);

      const removedVideo = this.videosData.splice(index, 1)[0];
      const self = this;
      APIService.retryVideoProcessing(this.businessProfileId, this.businessProfilesSearchEngineId, Id, removedVideo.facebookPageId, this.deltax.userId)
        .then((response) => {
          if (response.status == 200) {
            removedVideo.ProcessingStatus = 1
            self.isVideoUploadInProgress = true
            const fileIndex = self.uniqueId++
            this.$set(self.uploadingFiles['Videos'], fileIndex, {
              uploadingProgress: 100,
              uploadingFileName: removedVideo.Title,
              tooltip: 'Uploading to Facebook',
              showProgress: false,
              dbIndex: removedVideo.Id,
              facebookPageId: removedVideo.FacebookPageId
            })
            self.dbIndexFileIndexMap[Id] = fileIndex
            self.fetchUploadingVideos()
          } else {
            self.videosData.splice(index, 0, removedVideo);
          }
        })
    },
    filterByPage (videoList) {
      return videoList.filter(x => this.videoTypeToggle == "Account" ? !x.facebookPageId : x.facebookPageId == this.$store.state.selectedCampaignLauncherConfig.facebookPageId)
    },
    fetchMedia () {
      var self = this;
      if (this.businessProfilesSearchEngineId > 0) {
        this.processingData = true
        this.initUploadingFiles()
        if (!this.inCreativeSetup) {
          this.$store.commit("set_builtImageIdUrlMap", false)
        }
        this.$store.commit("set_isOauthExpired", false)

        var videoColumnName = "HOT_Image/Video"
        // Video views objective
        if (this.$store.state.selectedFbObjective == 12) {
          videoColumnName = "HOT_Video"
        }
        var videoList = []
        if (!this.isPromotionMode && self.$store.state.publishedCreatives) {
          for (let key in self.$store.state.publishedCreatives) {
            if (key != "adSheetSettings" && self.$store.state.publishedCreatives[key]) {
              for (let item of self.$store.state.publishedCreatives[key]) {
                if (key != "carousel") {
                  let isVideoAd = item[videoColumnName] ? item[videoColumnName].includes("DXV") : false
                  if (isVideoAd) {
                    let videoId = parseInt(item[videoColumnName].split('-')[2])
                    if (!videoList.includes(videoId)) {
                      videoList.push(videoId)
                    }
                  }
                } else {
                  for (let x = 1; x <= 8; x++) {
                    let isVideoAd = item[videoColumnName + " " + x] ? item[videoColumnName + " " + x].includes("DXV") : false
                    if (isVideoAd) {
                      let videoId = parseInt(item[videoColumnName + " " + x].split('-')[2])
                      if (!videoList.includes(videoId)) {
                        videoList.push(videoId)
                      }
                    }
                  }
                }
                let isPlacementCustomization = item["creativeSetupData"] ? item["creativeSetupData"].type == "Placement Customization" && item["creativeSetupData"].hotTableData.length > 1 : false
                let videoColumnIndex = -1
                if (isPlacementCustomization && item["creativeSetupData"].hotTableData[0]) {
                  videoColumnIndex = item["creativeSetupData"].hotTableData[0].findIndex(x => x == videoColumnName)
                }

                if (videoColumnIndex > -1) {
                  for (var [index, i] of item.creativeSetupData.hotTableData.entries()) {
                    if (index == 0) {
                      continue;
                    }
                    let isPCVideoAd = i[videoColumnIndex] ? i[videoColumnIndex].includes("DXV") : false
                    if (isPCVideoAd) {
                      let pcVideoId = parseInt(i[videoColumnIndex].split('-')[2])
                      if (!videoList.includes(pcVideoId)) {
                        videoList.push(pcVideoId)
                      }
                    }
                  }
                }
              }
            }
          }
        }

        // Facebook/Ad/SocialAdCreationCombinedAjaxCalls
        APIService.fetchMedia(
          `${this.fetchMediaUrl}?bseId=${this.businessProfilesSearchEngineId}&accountId=${this.businessProfilesSearchEngineId}&isCampaignLauncherRequest=true&videoList=${videoList.join(',')}&facebookPageId=${this.$store.state.selectedCampaignLauncherConfig.facebookPageId}&createdOnDx=true`
        ).then(({ Data }) => {
          if (!Data) {
            this.processingData = false
            this.$store.commit("set_isOauthExpired", true)
            return
          }
          this.setUnfetchedmedia(Data).then(() => {
            this.set_imagesData(Data.images);
            this.set_videosData(JSON.parse(Data.videos));
            this.set_mediaResponse(Data);
            this.$store.commit("set_isOauthExpired", false)
            this.formatData()
          });
        })
      }
    },
    uploadImage (image) {
      let fileType = /image.*/;
      if (!image.type.match(fileType)) {
        this.alertUser('Incorrect file format. Please choose an image')
        return
      }

      const fileIndex = this.uniqueId++;
      this.$set(this.uploadingFiles['Images'], fileIndex, {
        uploadingProgress: 0,
        uploadingFileName: image.name,
        tooltip: 'Uploading image',
        showProgress: true
      })

      let data = new FormData()
      let request = new XMLHttpRequest()
      data.append('file', image)

      request.addEventListener('load', e => {
        this.$delete(this.uploadingFiles['Images'], fileIndex)
        if (!request.response) {
          alert('Unable to upload image')
          return
        }
        let data = request.response.files[0]
        let formattedImageData = this.formatImageData(data)
        this.set_imagesData([formattedImageData, ...this.imagesData]);
        let assetUrlMap = this.imageIdUrlMap;
        if (!(formattedImageData.id in assetUrlMap)) {
          assetUrlMap[formattedImageData.id] = { src: formattedImageData.src, hash: formattedImageData.hash };
        }
        if (!(formattedImageData.name in assetUrlMap)) {
          assetUrlMap[formattedImageData.name] = { src: formattedImageData.src, hash: formattedImageData.hash };
        }
        this.SET_imageIdUrlMap(assetUrlMap);
      })

      request.upload.addEventListener('progress', (e) => {
        this.uploadingFiles['Images'][fileIndex].uploadingProgress =
          Math.round((e.loaded / e.total) * 100)
      })

      request.responseType = 'json'
      request.open('post', this.saveImageURL + `?bseId=${this.businessProfilesSearchEngineId}`)
      request.send(data)
    },
    uploadVideo (video) {
      let fileType = /video.*|image\/gif/
      if (!video.type.match(fileType)) {
        this.alertUser('Incorrect file format. Please choose a video')
        return
      }

      const fileIndex = this.uniqueId++
      this.$set(this.uploadingFiles['Videos'], fileIndex, {
        uploadingProgress: 0,
        uploadingFileName: video.name,
        tooltip: 'Uploading to Facebook',
        showProgress: false,
        dbIndex: null,
        facebookPageId: this.videoTypeToggle == 'Page' ? this.$store.state.selectedCampaignLauncherConfig.facebookPageId : null
      })

      let formData = {
        bpseId: this.businessProfilesSearchEngineId,
        fromCampaignLauncher: true,
        uniqueFileId: Math.floor(Math.random() * 1e16)
      };
      if (this.isPromotionMode && this.promotionObject.campaignLauncherConfiguration[this.selectedPublisher].configJson.facebookPageId) {
        // for promotion mode we upload the video as 'Page Video'
        formData.facebookPageId = this.promotionObject.campaignLauncherConfiguration[this.selectedPublisher].configJson.facebookPageId;
      } else if (this.videoTypeToggle == 'Page') {
        formData.facebookPageId = this.$store.state.selectedCampaignLauncherConfig.facebookPageId
      }

      var uploadSettings = {
        dropZone: null,
        url: `/App/Facebook/Video/UploadVideo/${this.businessProfileId}`,
        type: "POST",
        singleFileUploads: true,
        autoUpload: true,
        maxChunkSize: 20000000,
        formData: formData,
        add: (e, data) => {
          data.fileIndex = fileIndex
          data.submit()
        },
        done: (e, data) => {
          if (data.result.HttpStatusCode == 200) {
            let dbIndex = Number(data.result.Data)
            this.uploadingFiles.Videos[data.fileIndex].dbIndex = dbIndex
            this.dbIndexFileIndexMap[dbIndex] = data.fileIndex

            if (this.isVideoUploadInProgress == false) {
              this.isVideoUploadInProgress = true
              this.fetchUploadingVideos()
            }
          } else {
            this.videoUploadFailureCb(e, data)
          }
        },
        fail: this.videoUploadFailureCb
      };
      $("#mediaInput").fileupload(uploadSettings);
      $("#mediaInput").fileupload("add", { files: [video] });
    },
    videoUploadFailureCb (e, data) {
      this.videosData.unshift({
        name: data.files[0].name ? data.files[0].name : 'Sample Video',
        facebookPageId: this.uploadingFiles.Videos[data.fileIndex].facebookPageId,
        error: true
      })
      this.$delete(this.uploadingFiles.Videos, data.fileIndex)
    },
    uploadMedia (file) {
      if (this.headerTitle == 'Images') {
        this.uploadImage(file);
      } else if (this.headerTitle == 'Videos') {
        this.uploadVideo(file);
      }
      return false;
    },
    copyId (id) {
      let textarea = document.createElement("textarea")
      textarea.textContent = id
      textarea.style.position = "fixed"
      document.body.appendChild(textarea)
      textarea.select()
      try {
        document.execCommand("copy")
      } catch (e) {
        alert('Unable to copy to clipboard. Please copy manually.')
      }
      document.body.removeChild(textarea)
    },
    formatImageData (imageData) {
      return {
        id: `DXC-${Number.parseInt(this.businessProfileId).toString(36)}-${imageData.imageId}`,
        name: (imageData.imageName != null) ? imageData.imageName : 'Sample Image',
        src: imageData.imageUrl,
        hash: imageData.imageHash
      }
    },
    formatVideoData (videoData) {
      var data = {
        id: `DXV-${Number.parseInt(this.businessProfileId).toString(36)}-${videoData.Id}`,
        name: (videoData.Title != null) ? videoData.Title : 'Sample Video',
        src: videoData.Picture,
        facebookPageId: videoData.FacebookPageId
      }
      data.nameId = data.name;
      return data;
    },
    convertInitialsToUppercase (obj) {
      if (obj === null || typeof obj !== 'object') {
        throw new Error('Input must be a valid object');
      }
      const result = {};
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const value = obj[key];
          const modifiedKey = key.replace(/(?:^|\s|_)\S/g, match => match.toUpperCase());
          result[modifiedKey] = value;
        }
      }
      return result;
    },
    fetchUploadingVideos () {
      setTimeout(async () => {
        let uploadingVideoIds = Object.values(this.uploadingFiles.Videos)
          .map((x) => x.dbIndex)
          .filter((x) => x != null)
          .join(",");

        if (uploadingVideoIds.length > 0) {
          let response = await APIService.fetchUploadingVideos(
            this.fetchVideosUrl,
            this.businessProfilesSearchEngineId,
            uploadingVideoIds
          )
          let videosResponse = JSON.parse(response.data.Data)

          videosResponse.forEach(videoData => {
            if (videoData.ProcessingStatus == 8) {
              let formattedVideoData = this.formatVideoData(videoData)
              this.videosData.unshift(formattedVideoData)
              this.updateAssetUrlMap(formattedVideoData, videoData)
              this.removeVideoFromUploadingFiles(videoData.Id)
            } else if (videoData.ProcessingStatus == 9) {
              this.videosData.unshift({
                name: (videoData.Title != null) ? videoData.Title : 'Sample Video',
                error: true
              })
              this.removeVideoFromUploadingFiles(videoData.Id)
            } else if (videoData.ProcessingStatus == 12) {
              const index = this.videosData.findIndex(v => v.Id === videoData.Id)
              if (index == -1) {
                this.videosData.unshift({
                  name: (videoData.Title != null) ? videoData.Title : 'Sample Video',
                  error: true,
                  Title: videoData.Title,
                  Id: videoData.Id,
                  PageId: videoData.facebookPageId,
                  errorType: 1
                })
              }
              this.removeVideoFromUploadingFiles(videoData.Id)
              this.retryVideos = this.retryVideos.filter(item => item !== videoData.Id);
            }
          })
        }

        this.isVideoUploadInProgress = Object.keys(this.uploadingFiles.Videos).length > 0;

        if (this.isVideoUploadInProgress && !this.inCreativeSetup) {
          this.fetchUploadingVideos()
        }
      }, 20 * 1000);
    },
    removeVideoFromUploadingFiles (dbIndex) {
      let fileIndex = this.dbIndexFileIndexMap[dbIndex]
      this.$delete(this.uploadingFiles.Videos, fileIndex)
    },
    updateAssetUrlMap (formattedVideoData, videoData) {
      if (!(formattedVideoData.id in this.imageIdUrlMap)) {
        this.$set(this.imageIdUrlMap, formattedVideoData.id, {
          'src': formattedVideoData.src,
          'SearchEngineVideoId': videoData.SearchEngineVideoId
        })
      }
      if (!(formattedVideoData.nameId in this.imageIdUrlMap)) {
        this.$set(this.imageIdUrlMap, formattedVideoData.nameId, {
          'src': formattedVideoData.src,
          'SearchEngineVideoId': videoData.SearchEngineVideoId
        })
      }
    },
    formatVideos (videosData) {
      let data = []
      for (let videoData of videosData) {
        if (videoData.ProcessingStatus == 8) {
          let formattedVideoData = this.formatVideoData(videoData)
          if (!data.find(vid => vid.id == formattedVideoData.id && vid.name == formattedVideoData.name)) {
            data.push(formattedVideoData);
          }
          this.updateAssetUrlMap(formattedVideoData, videoData);
        } else {
          if (videoData.ProcessingStatus == 12) {
            data.push({ name: (videoData.Title != null) ? videoData.Title : 'Sample Video', Title: videoData.Title, Id: videoData.Id, error: true, errorType: 1 })
          } else if (videoData.ProcessingStatus == 9) {
            data.push({
              name: (videoData.Title != null) ? videoData.Title : 'Sample Video',
              error: true
            })
          } else {
            this.isVideoUploadInProgress = true
            const fileIndex = this.uniqueId++
            this.$set(this.uploadingFiles['Videos'], fileIndex, {
              uploadingProgress: 100,
              uploadingFileName: videoData.Title,
              tooltip: 'Uploading to Facebook',
              showProgress: false,
              dbIndex: videoData.Id,
              facebookPageId: videoData.FacebookPageId
            })
            this.dbIndexFileIndexMap[videoData.Id] = fileIndex
          }
        }
      }
      return data
    },
    formatAndSetUnfetchedData (imagesData, videosData) {
      if (imagesData) {
        let data = []
        let assetUrlMap = {};
        for (let imageData of imagesData) {
          let formattedImageData = this.formatImageData(imageData)
          if (!this.imagesData.find(img => img.name == formattedImageData.name && img.id == formattedImageData.id) &&
              !data.find(img => img.name == formattedImageData.name && img.id == formattedImageData.id)) {
            data.push(formattedImageData)
          }
          if (!(formattedImageData.id in assetUrlMap)) {
            assetUrlMap[formattedImageData.id] = { src: formattedImageData.src, hash: formattedImageData.hash };
          }
          if (!(formattedImageData.name in assetUrlMap)) {
            assetUrlMap[formattedImageData.name] = { src: formattedImageData.src, hash: formattedImageData.hash };
          }
        }
        this.set_imagesData([...this.imagesData, ...data]);
        this.SET_imageIdUrlMap({ ...this.imageIdUrlMap, ...assetUrlMap });
      }
      if (videosData) {
        videosData = videosData.data.data
        videosData.forEach(videoData => {
          videoData = this.convertInitialsToUppercase(videoData);
          if (videoData.ProcessingStatus == 8) {
            let formattedVideoData = this.formatVideoData(videoData)
            if (!this.videosData.some(vid => vid.id == formattedVideoData.id && vid.name == formattedVideoData.name)) {
              this.videosData.unshift(formattedVideoData)
            }
            this.updateAssetUrlMap(formattedVideoData, videoData)
            this.removeVideoFromUploadingFiles(videoData.Id)
          }
        })
      }
    },
    formatData () {
      let data = []
      this.SET_imageIdUrlMap({});
      let assetUrlMap = {};
      for (let imageData of this.imagesData) {
        let formattedImageData = this.formatImageData(imageData)
        if (!data.find(img => img.name == formattedImageData.name && img.id == formattedImageData.id)) {
          data.push(formattedImageData)
        }
        if (!(formattedImageData.id in assetUrlMap)) {
          assetUrlMap[formattedImageData.id] = { src: formattedImageData.src, hash: formattedImageData.hash };
        }
        if (!(formattedImageData.name in assetUrlMap)) {
          assetUrlMap[formattedImageData.name] = { src: formattedImageData.src, hash: formattedImageData.hash };
        }
      }
      this.set_imagesData(data);

      for (let mail of this.dxInboundEmails) {
        for (let image of mail.Images) {
          let mailImage = {
            id: `DXC-${Number.parseInt(this.businessProfileId).toString(36)}-${image.ImageId}`,
            name: image.ImageName,
            src: image.ImageUrl
          }
          assetUrlMap[mailImage.id] = mailImage.src
        }
      }

      this.SET_imageIdUrlMap(assetUrlMap);

      this.$set(this.uploadingFiles, 'Videos', {})
      this.isVideoUploadInProgress = false
      let formattedVideos = this.formatVideos(this.videosData)
      this.set_videosData(formattedVideos);

      this.processingData = false
      if (!this.inCreativeSetup) {
        this.$store.commit("set_builtImageIdUrlMap", true)
      }
      if (this.isVideoUploadInProgress) {
        this.fetchUploadingVideos()
      }
    },
    setActiveTab (name) {
      this.headerTitle = name;
    },
    isTabActive (...names) {
      return names.includes(this.headerTitle);
    },
    initUploadingFiles () {
      this.uploadingFiles = {
        Images: {},
        Videos: {}
      }
      this.uniqueId = 0
      this.videoIds = new Set();
    },
    alertUser (message) {
      this.showAlert = true
      this.alertMessage = message
      setTimeout(() => {
        this.showAlert = false
      }, 8 * 1000);
    },
    showCreativeBuilder () {
      this.showCreativeBuilderModal = true;
      this.showCreativeBuilderLoader = true;
      APIService.getEmbedToken(
        this.deltax.getEmbedTokenUrl,
        this.deltax.userId,
        this.businessProfileId,
        this.deltax.portalDomain
      )
        .then((response) => {
          if (response && response.data) {
            let result = JSON.parse(response.data);
            var queryParameters = new URLSearchParams();
            queryParameters.append('token', result.embed_token);
            queryParameters.append('embed', 'true');
            queryParameters.append('mode', 'CampaignLauncher');
            queryParameters.append('portalDomain', window.location.host); // setting the current domain as the portal to be used by roimatic app
            queryParameters.append(
              'businessProfileSearchEngineId',
              this.businessProfilesSearchEngineId
            );
            this.iFrameProperties = {
              src: `${this.deltax.creativeBuilderEmbedLoginUrl}?${queryParameters}`
            }
          }
          setTimeout(() => {
            this.showCreativeBuilderLoader = false;
          }, 5000)
        })
        .catch(() => {
          this.showCreativeBuilderModal = false;
          this.showCreativeBuilderLoader = false;
          this.$Message.error({
            background: true,
            content: "An error occurred while processing your request."
          });
        });
    },
    closeCreativeBuilderModel () {
      this.fetchMedia();
    },
    IsAssetUrlOrMacro (value) {
      if (value.startsWith('http') || value.startsWith('www') || (/^{{store\.asset([1-9]|[1-4]\d|50)}}$/.test(value))) {
        return true;
      }
      return false;
    },
    async setUnfetchedmedia (Data) {
      if (Data && Object.keys(Data).length) {
        let unfetchedImageIds = []
        let unfetchedVideoIds = []
        let unfetchedAssetNames = []
        var videosArray = Data.videos ? JSON.parse(Data.videos) : []
        for (let adformat in this.createSheetCreatives) {
          if (Array.isArray(this.createSheetCreatives[adformat])) {
            let creativeArray = this.createSheetCreatives[adformat];
            creativeArray.forEach(adRow => {
              for (let column in adRow) {
                if (column.toLowerCase().includes('image/video')) {
                  if (adRow[column] && adRow[column].startsWith('DXC')) {
                    let imageId = parseInt(adRow[column].split('-')[2])
                    if (Data.images && !Data.images.find(image => image.imageId === imageId) && !unfetchedImageIds.includes(imageId)) {
                      unfetchedImageIds.push(imageId)
                    }
                  } else if (adRow[column] && adRow[column].startsWith('DXV')) {
                    let videoId = parseInt(adRow[column].split('-')[2])
                    if (!videosArray.find(video => video.Id === videoId) && !unfetchedVideoIds.includes(videoId)) {
                      unfetchedVideoIds.push(videoId)
                    }
                  } else {
                    if (adRow[column] && Data.images && !Data.images.find(image => image.imageName === adRow[column]) && !videosArray.find(video => video.Name === adRow[column]) && !unfetchedAssetNames.includes(adRow[column]) && !(adRow[column] == "")) {
                      if (!this.IsAssetUrlOrMacro(adRow[column])) {
                        unfetchedAssetNames.push(adRow[column])
                      }
                    }
                  }
                }
              }
            })
          }
        }
        for (let adformat in this.publishedSheetCreatives) {
          if (Array.isArray(this.publishedSheetCreatives[adformat])) {
            let creativeArray = this.publishedSheetCreatives[adformat];
            creativeArray.forEach(adRow => {
              for (let column in adRow) {
                if (column.toLowerCase().includes('image/video')) {
                  if (adRow[column] && adRow[column].startsWith('DXC')) {
                    let imageId = parseInt(adRow[column].split('-')[2])
                    if (Data.images && !Data.images.find(image => image.imageId === imageId) && !unfetchedImageIds.includes(imageId)) {
                      unfetchedImageIds.push(imageId)
                    }
                  } else if (adRow[column] && adRow[column].startsWith('DXV')) {
                    let videoId = parseInt(adRow[column].split('-')[2])
                    if (!videosArray.find(video => video.Id === videoId) && !unfetchedVideoIds.includes(videoId)) {
                      unfetchedVideoIds.push(videoId)
                    }
                  } else {
                    if (adRow[column] && Data.images && !Data.images.find(image => image.imageName === adRow[column]) && !videosArray.find(video => video.Name === adRow[column]) && !unfetchedAssetNames.includes(adRow[column]) && !(adRow[column] == "")) {
                      if (!this.IsAssetUrlOrMacro(adRow[column])) {
                        unfetchedAssetNames.push(adRow[column]);
                      }
                    }
                  }
                }
              }
            })
          }
        }
        if (unfetchedImageIds.length || unfetchedAssetNames.length) {
          var unfetchedImages = await APIService.getImagesByIdandNames(this.businessProfileId, this.businessProfilesSearchEngineId, unfetchedImageIds, unfetchedAssetNames)
          if (unfetchedImages.status == 200) {
            var modifiedImages = this.createIamgeObject(unfetchedImages.data.data)
            Data.images = [...Data.images, ...modifiedImages]
          }
        }
        if (unfetchedVideoIds.length || unfetchedAssetNames.length) {
          var unfetchedVideos = await APIService.getVideosByIdandNames(this.businessProfileId, this.businessProfilesSearchEngineId, unfetchedVideoIds, unfetchedAssetNames)
          var modifiedVideos = []
          if (unfetchedVideos.status == 200) {
            unfetchedVideos.data.data.forEach(videoObject => {
              videoObject["searchEngineVideoId"] = videoObject["searchEngineVideoId"] ? videoObject["searchEngineVideoId"].toString() : videoObject["searchEngineVideoId"]
              var modifiedVideoObject = this.convertKeysToPascalCase(videoObject)
              modifiedVideos.push(modifiedVideoObject)
            })
          }
          Data.videos = JSON.stringify([...JSON.parse(Data.videos), ...modifiedVideos])
        }
      }
    },
    createIamgeObject (imageList) {
      var modifiedImageList = []
      imageList.forEach((image) => {
        var modifiedImageObject = {
          "imageId": image.id,
          "thumbnailUrl": `/App/Ads/GetThumbnail.png?imageCode=${image.url}&channel=social`,
          "imageUrl": image.url,
          "imageSize": image.size ? (image.size / 1024).toFixed(2) + " kB" : "N/A",
          "imageDimensions": image.dimension,
          "imageName": image.name,
          "imageHash": image.hash
        }
        modifiedImageList.push(modifiedImageObject)
      })
      return modifiedImageList
    }
  }
}
</script>

<style scoped>
.alert-info {
  margin-top: 10px;
}
.display-none {
  display: none;
}
.clw-container {
  display: flex;
  flex-flow: column;
  padding: 0 10px 0 10px;
  height: 100%;
}

.header-container {
  height: 3.5rem;
  line-height: 3.5rem;
}
.header-name {
  font-size: 1.5rem;
}
.float-right {
  float: right;
  margin-right: 15px;
}
.header-icon {
  padding-top: 4px;
  display: inline-block;
  line-height: 3rem;
  font-size: 18px;
  cursor: pointer;
  color: #7D7F7F;
}
.hr-style {
  /* width: calc(100% + 30px); */
  /* margin: 0 0 0 -15px; */
  margin: 0px -15px 0px -15px;
}

.cl-widget-text {
  font-size: 12px;
}
.search-container {
  padding: 8px 0 8px 0;
}
.search-bar {
  max-width: calc(100% - 15px);
  padding: 0 0 0 15px;
}
.upload-style {
  line-height: 3.1rem;
  height: 3.1rem;
}

.list-container {
  height: 139px;
}
.list-container .float-right {
  margin-right: -8px;
}
.no-data {
  margin-top: 0px;
  margin-left: -5px;
  width: calc(100% + 10px);
}
.list-item {
  display: flex;
  width: 100%;
  height: 2.2rem;
  line-height: 2.2rem;
  margin: 8px 0 8px 0;
}
.list-item:first-child {
  margin-top: 0px;
}
img[lazy=loading] {
  min-height: 2rem;
  min-width: 2rem;
  animation: placeholder-animate 1.5s ease-in-out infinite;
  background:rgba(0, 0, 0, 0.08);
}
img[lazy=loading] ~ .fa-copy {
  pointer-events: none;
}
img[lazy=error] {
  min-height: 2rem;
  min-width: 2rem;
  border-radius: 3px;
  border: 1px dotted #555;
}
img[lazy=error] ~ .fa-exclamation-triangle {
  display: initial;
}
img[lazy=error] ~ .fa-copy {
  display: none;
}

.clw-alert-enter-active {
  transition: all 0.25s ease-out;
}
.clw-alert-leave-active {
  transition: all 0.25s ease-in;
}
.clw-alert-enter, .clw-alert-leave-to {
  transform: translate(0px, -15px);
  opacity: 0;
}

.list-text-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 59%;
}
.list-text-id {
  margin-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 21%;
  font-size: 1.2rem;
}
.list-icon {
  margin-top: -2px;
  padding: 5px 5px 5px 15px;
  font-size: 14px;
}
.list-upload-icon {
  min-width: 2rem;
  min-height: 2rem;
  line-height: 2rem;
  margin-right: 10px;
  font-size: 14px;
  text-align: center;
}
.upload-green {
  color: green;
}
.upload-spinner {
  line-height: 11px;
  margin: 5px 5px 5px 15px;
  font-size: 14px;
}
.alert-icon {
  color: #ed5a5a;
}
.spinner {
  text-align: center;
  margin-top: 6rem;
}
.video-spinner {
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin-top: 15px;
}

/* placeholder */
@keyframes placeholder-animate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.selected-category{
    color: #555;
}

.creative-builder-iframe {
  position: relative;
  width: 100%;
  height: 85vh;
}

.creative-builder-spinner {
  position: relative;
  width: 100%;
  height: 50%;
  text-align: center;
  top: 45%;
}

::v-deep .ivu-modal-body {
  padding: 0% !important;
  height: 85vh;
}
</style>

<style>
.upload-style .ivu-upload {
  height: inherit;
  border: 1px dotted #aaa;
  background-color: #e9eaed;
}
.list-image-poptip {
	max-width: 250px;
  max-height: 250px;
}
.list-image-wrapper {
  position: relative;
  width: 2rem;
  height: 2rem;

}
.list-image {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center;
  transition: transform .05s linear;
  transform: translate(-50%, -50%);
}
</style>
<style src="../../../Styles/dx-iview.css"></style>
