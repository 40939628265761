<template>
  <section id="target-location-comp">
    <Collapse v-model="collapseOpen">
      <Panel name="1">
      <div>
      <div id="card-title">
        <div id="title-area">
          <p class="title-text">Location: <span class="title-sub-text">{{locationString}}</span></p>
          <p class="title-text">Location Type: <span class="title-sub-text">{{locationType}}</span></p>
        </div>
        <div id="icon-area">
            <tooltip :content="iconsContent[0]" placement="top">
              <i class="fa fa-plus title-icons icons"
              :class="{ 'disabled-icon': disableIcons }"
              @click.stop="!disableIcons && $emit('add-target')"></i>
            </tooltip>
            <tooltip :content="iconsContent[1]" placement="top">
              <i class="fa fa-copy title-icons icons"
              :class="{ 'disabled-icon': disableIcons }"
              @click.stop="!disableIcons && $emit('copy-target')"></i>
            </tooltip>
            <tooltip :content="iconsContent[2]" placement="top">
              <i class="fa fa-trash title-icons icons"
              :class="{ 'disabled-icon': disableIcons }"
              @click.stop="!disableIcons && $emit('delete-target')"></i>
            </tooltip>
        </div>
      </div>
      </div>
      <div id="card-body" slot="content">
          <div class="card-block" v-if="false">
            <div class="card-body-label">
              <span class="label-text">Location Type &nbsp;</span>
              <tooltip content="If any of the selected Location is Excluded then Location Type for Excluded Location is set to the Default value ('Home')" placement="right">
                <i class="fa fa-info-circle icons"></i>
              </tooltip>
            </div>
            <RadioGroup type="button" class="radiogroup" v-model="locationType" @on-change="setLocationType">
              <Radio label="All"></Radio>
              <Radio label="Home"></Radio>
              <Radio label="Recently Here"></Radio>
              <Radio label="Travelling Here"></Radio>
            </RadioGroup>
          </div>
          <div class="card-block">
            <div class="card-body-label">
              <span class="label-text">Locations &nbsp;</span>
              <tooltip content="Type in the Country, State, City, DMA, Address or Lat & Long. Lat & Long are only support in the following format (latitude,longitude). Eg: (36,-126)" placement="right">
                <i class="fa fa-info-circle icons"></i>
              </tooltip>
            </div>
            <div>
              <Alert type="warning" v-if="locationOverideWarning" class="location-warning">You have overlapping locations specified. Your narrower option '{{narrowerLocation}}'' will override the broader option '{{broaderLocation}}''.</Alert>
              <Alert type="warning" v-if="invalidCustomLocation" class="location-warning">Please give a lag & long in valid format</Alert>

              <div id="empty-location-warning" v-if="selectedLocations.length === 0">* Please select a location</div>
              <div
                class="selected-location-box"
                v-for="(item, index) in parsedSelectedLocations"
                :key="item.key"
              >
                <p class="selected-location-data">
                  {{ formatLabels(item) }} | {{ item.type !== 'custom_location' ? item.type : 'Lat & Long' }}
                </p>
                <p
                  v-if="radiusInput[item.key] && radius[item.key] !== undefined"
                  class="radius-text"
                >
                  {{ radius[item.key] }} Miles
                </p>
                <p v-if="isExcluded[item.key]" class="excluded-location-text">
                  Excluded
                </p>
                <Poptip placement="right-start">
                  <tooltip content="Settings" placement="top">
                    <i class="fa fa-cog icons selected-location-icon"></i>
                  </tooltip>
                  <div slot="title">
                    <div v-if="item.type === 'city' || item.type === 'place' || item.type === 'custom_location'">
                      <p class="poptip-heading">Radius</p>
                      <p class="poptip-text" @click="selectCityOnly(item)">
                        Current City Only
                      </p>
                      <p class="poptip-text" @click="selectCityRadius(item)">
                        Cities within Radius
                      </p>
                      <p v-if="radiusInput[item.key]" class="radius-setting">
                        <tooltip
                          content="Enter a value between 1 - 50 for cities and 0.63 - 50 for custom locations."
                          placement="bottom"
                        >
                          <input
                            type="number"
                            v-model="radius[item.key]"
                            min="1"
                            max="50"
                            class="tooltip-input"
                            @input="selectCityRadius(item)"
                          />
                          <span> Miles</span>
                        </tooltip>
                      </p>
                      <hr />
                    </div>
                    <p class="poptip-heading">Location</p>
                    <p
                      class="poptip-text"
                      @click.stop="includeLocation(index, item.key, item)"
                      v-if="isExcluded[item.key]"
                    >
                      Include this location
                    </p>
                    <p
                      class="poptip-text"
                      @click.stop="excludeLocation(item.key, item)"
                      v-else
                    >
                      Exclude this location
                    </p>
                  </div>
                </Poptip>
                  <tooltip content="Remove" placement="top">
                    <i class="fa fa-trash icons selected-location-icon" @click.stop="deleteLocation(index, item)"></i>
                  </tooltip>
              </div>
              <Select
                class="location-select"
                placeholder="Search Locations"
                multiple
                placement="bottom-start"
                :remote-method="searchLocation"
                v-model="selectedLocations"
                filterable
                :loading="loading"
                remote
                @on-change="storeSelectedLocations"
                prefix="ios-search"
              >
                <Option
                  v-for="(item, index) in locations"
                  :value="JSON.stringify(item)"
                  :key="index"
                >
                  <i class="fa fa-plus-circle icons"></i>
                  <span class="dropdown-text">{{ formatLabels(item) }}</span>
                  <span class="badge">{{ item.type !== 'custom_location' ? item.type : 'Lat & Long' }}</span>
                </Option>
              </Select>
            </div>
          </div>
      </div>
    </Panel>
    </Collapse>
  </section>
</template>

<script>
import {
  RadioGroup,
  Radio,
  Select,
  Option,
  Poptip,
  Alert,
  Collapse,
  Panel
} from "iview";
import { tooltip } from "vue-strap";
import { APIService } from "../../ApiService";
import { targetLocationType, formatLabelsObj } from "../../Constants/FacebookEnums";

export default {
  name: "TargetLocation",
  props: {
    locationData: {
      type: Object
    },
    compKey: {
      type: Number
    },
    bpseId: Number,
    showModal: Boolean,
    loadData: Boolean,
    targetMode: {
      type: String
    }
  },
  components: {
    RadioGroup,
    Radio,
    Select,
    Option,
    Poptip,
    tooltip,
    Alert,
    Collapse,
    Panel
  },
  mixins: [],
  data: function () {
    return {
      collapseOpen: '1',
      locationType: "All",
      locationString: null,
      showCardBody: true,
      locationText: "",
      selectedLocations: [],
      loading: false,
      locations: [],
      searchTimeout: null,
      showpoptip: [],
      isExcluded: {},
      radius: {},
      radiusInput: {},
      broaderLocation: null,
      narrowerLocation: null,
      locationOverideWarning: false,
      componentInitializing: true,
      iconsContent: ['Add', 'Clone', 'Remove'],
      invalidCustomLocation: false
    };
  },
  methods: {
    setLocationType (val) {
      this.locationType = val;
      this.$store.dispatch("set_locationType", {
        locationType: targetLocationType[this.locationType],
        index: this.compKey
      });
    },
    validateRadius (value) {
      if (value > 50) {
        return 50;
      } else if (value <= 0) {
        return 0.63;
      } else {
        return value;
      }
    },
    selectCityRadius (item) {
      this.$set(this.radiusInput, item.key, true);
      if (this.radius[item.key] !== undefined) {
        this.$store.dispatch("set_radiusLocation", {
          data: item,
          radius: this.validateRadius(this.radius[item.key]),
          isExcluded: this.isExcluded[item.key],
          index: this.compKey
        });
      }
    },
    selectCityOnly (item) {
      this.$set(this.radius, item.key, undefined);
      this.$set(this.radiusInput, item.key, false);
      this.$store.dispatch("set_radiusLocation", {
        data: item,
        radius: this.radius[item.key],
        isExcluded: this.isExcluded[item.key],
        index: this.compKey
      });
    },
    formatLabels (value) {
      if (value.type === formatLabelsObj.Country) {
        return value.name ? `${value.name}, (${value.key})` : `(${value.key})`;
      } else if (value.type === formatLabelsObj.Geo_market) {
        return value.country_name ? `${value.name}, ${value.country_name}` : `${value.name}`;
      } else if (value.type === formatLabelsObj.Region) {
        return value.country_name ? `${value.name}, ${value.country_name}` : `${value.name}`;
      } else if (value.type === formatLabelsObj.Country_group) {
        return `${value.name}`;
      } else {
        return value.region && value.country_name
          ? `${value.name}, ${value.region}, ${value.country_name}`
          : `${value.name}`;
      }
    },
    deleteLocation (index, item) {
      this.$store.dispatch("remove_facebookTargetLocationData", {
        data: item,
        index: this.compKey
      });
      this.selectedLocations.splice(index, 1);
      delete (this.radius[item.key]);
      delete (this.radiusInput[item.key]);
      delete (this.isExcluded[item.key]);
    },
    excludeLocation (key, item) {
      this.$store.dispatch("set_excludeLocationData", {
        data: item,
        index: this.compKey
      });
      this.$set(this.isExcluded, key, true);
      if (this.radius[item.key] !== undefined) {
        this.selectCityRadius(item);
      }
    },
    includeLocation (index, key, item) {
      this.$store.dispatch("set_includeLocationData", {
        data: item,
        index: this.compKey
      });
      this.$set(this.isExcluded, key, false);
      // select city radius method call if city is there
      if (this.radius[item.key] !== undefined) {
        this.selectCityRadius(item);
      }
    },
    async searchLocation (query) {
      this.locationText = query;

      if (query !== "") {
        this.loading = true;
        clearTimeout(this.searchTimeout);

        if (query[0] == '(') {
          let tempLocation = {
            'name': query,
            'type': 'custom_location',
            'distance_unit': 'mile',
            'radius': 10
          }
          let selectData = this.selectedLocations.map((item) => JSON.parse(item));
          this.locations = [tempLocation, ...selectData]
          this.loading = false;
        } else {
          this.searchTimeout = setTimeout(async () => {
            try {
              let response = await APIService.getFacebookTargetLocations(
                this.bpseId,
                query
              );
              let excludedLocationsType = ['subcity', 'country_group', 'medium_geo_area', 'small_geo_area']
              let filteredData = response.data.data.filter(obj => !excludedLocationsType.includes(obj.type));

              let selectData = this.selectedLocations.map((item) => JSON.parse(item));
              filteredData = filteredData.filter(item => {
                return !selectData.some(selectedItem => {
                  return item.key === selectedItem.key;
                });
              });
              this.locations = [...filteredData, ...selectData];
              this.loading = false;
            } catch (error) {
              console.log(error);
            }
          }, 500);
        }
      }
    },
    removeExcludeTags (item) {
      if (item) {
        this.$store.commit('remove_ExcludedLocationAfterOverride', { index: this.compKey, item: item });
        this.$set(this.isExcluded, item.key, false);
      }
    },
    locationOverrideFilter (data) {
      // check for included part
      for (let i = data.length - 1; i >= 0; i--) {
        if ((data[i]) && (data[i].type === "city" && !this.isExcluded[data[i].key])) {
          for (let j = data.length - 1; j >= 0; j--) {
            if (data[j]) {
              if ((!this.isExcluded[data[j].key]) && (data[j].type === 'country' && data[i] && data[i].country_code === data[j].country_code)) {
                this.broaderLocation = data[j].name;
                this.narrowerLocation = data[i].name;
                this.locationOverideWarning = true;
                this.removeExcludeTags(data[j]);
                data.splice(j, 1);
                this.selectedLocations.splice(j, 1);
              }
            }
            if (data[j] && data[i] && data[i].region_id) {
              if ((!this.isExcluded[data[j].key]) && (data[j].type === 'region' && data[i].region_id.toString() === data[j].key)) {
                this.broaderLocation = data[j].name;
                this.narrowerLocation = data[i].name;
                this.locationOverideWarning = true;
                this.removeExcludeTags(data[j]);
                data.splice(j, 1);
                this.selectedLocations.splice(j, 1);
              }
            }
          }
        } else if ((data[i]) && (data[i].type === "region" && !this.isExcluded[data[i].key])) {
          for (let j = data.length - 1; j >= 0; j--) {
            if (data[j]) {
              if ((!this.isExcluded[data[j].key]) && (data[j].type === 'country' && data[i] && data[i].country_code === data[j].country_code)) {
                this.broaderLocation = data[j].name;
                this.narrowerLocation = data[i].name;
                this.locationOverideWarning = true;
                this.removeExcludeTags(data[j]);
                data.splice(j, 1);
                this.selectedLocations.splice(j, 1);
              }
            }
          }
        }
      }
      // check for excluded part
      for (let i = data.length - 1; i >= 0; i--) {
        if ((data[i]) && (data[i].type === "city" && this.isExcluded[data[i].key])) {
          for (let j = data.length - 1; j >= 0; j--) {
            if (data[j]) {
              if ((this.isExcluded[data[j].key]) && (data[j].type === 'country' && data[i] && data[i].country_code === data[j].country_code)) {
                this.broaderLocation = data[j].name;
                this.narrowerLocation = data[i].name;
                this.locationOverideWarning = true;
                this.removeExcludeTags(data[j]);
                data.splice(j, 1);
                this.selectedLocations.splice(j, 1);
              }
            }
            if (data[j] && data[i] && data[j].region_id) {
              if ((this.isExcluded[data[j].key]) && (data[j].type === 'region' && data[i].region_id.toString() === data[j].key)) {
                this.broaderLocation = data[j].name;
                this.narrowerLocation = data[i].name;
                this.locationOverideWarning = true;
                this.removeExcludeTags(data[j]);
                data.splice(j, 1);
                this.selectedLocations.splice(j, 1);
              }
            }
          }
        } else if ((data[i]) && (data[i].type === "region" && this.isExcluded[data[i].key])) {
          for (let j = data.length - 1; j >= 0; j--) {
            if (data[j]) {
              if ((this.isExcluded[data[j].key]) && (data[j].type === 'country' && data[i] && data[i].country_code === data[j].country_code)) {
                this.broaderLocation = data[j].name;
                this.narrowerLocation = data[i].name;
                this.locationOverideWarning = true;
                this.removeExcludeTags(data[j]);
                data.splice(j, 1);
                this.selectedLocations.splice(j, 1);
              }
            }
          }
        }
      }

      setTimeout(() => {
        this.locationOverideWarning = false;
      }, 7000);
      return data;
    },
    storeSelectedLocations () {
      if (this.showModal) {
        let data = this.parsedSelectedLocations;
        setTimeout(() => {
          this.invalidCustomLocation = false;
        }, 4000);
        this.$store.dispatch("set_facebookTargetLocationData", {
          data: data,
          index: this.compKey
        });
        this.showpoptip = data.map(() => false);
      }
    },
    setExcludeToFalseWhenDeselected (data) {
      let dataKeys = data.map(item => item.key);
      // Iterate over each key in isExcluded
      for (let key in this.isExcluded) {
        // If the key is not present in data, set its value to false
        if (!dataKeys.includes(key)) {
          this.$set(this.isExcluded, key, false);
        }
      }
    },
    loadLocationData () {
      this.selectedLocations = [];
      this.locations = []
      this.isExcluded = {}
      if (this.locationData) {
        let fieldsToLoop = ['geoLocations', 'excludedGeoLocations'];
        fieldsToLoop.forEach(field => {
          for (let subField in this.locationData[field]) {
            if (this.locationData[field][subField].length > 0) {
              this.locationData[field][subField].forEach(item => {
                let itemString = JSON.stringify(item);
                if (!this.selectedLocations.includes(itemString)) {
                  this.locations.push(item);
                  this.$nextTick(() => {
                    this.selectedLocations.push(itemString);
                  })
                }
                // update exclude and radius value if present for UI visibility
                if (field === 'excludedGeoLocations') {
                  this.$nextTick(() => {
                    this.$set(this.isExcluded, item.key, true);
                  })
                  this.excludeLocation(item.key, item);
                }
                if (item.radius !== undefined && item.distance_unit !== undefined) {
                  this.$set(this.radius, item.key, item.radius);
                  this.$set(this.radiusInput, item.key, true);
                  this.selectCityRadius(item);
                }
              });
            }
          }
        });
      }
    }
  },
  watch: {
    radius: {
      handler (newVal, oldVal) {
        for (let index in newVal) {
          this.radius[index] = this.validateRadius(newVal[index]);
        }
      },
      deep: true
    },
    loadData: {
      handler () {
        this.loadLocationData();
      },
      deep: true
    }
  },
  computed: {
    parsedSelectedLocations () {
      let data = this.selectedLocations.map((item) => JSON.parse(item));
      let locationArray = []
      data.forEach((location) => {
        if (location.type === 'country' && !location.name) {
          locationArray.push(location.key)
        } else {
          locationArray.push(location.name);
        }
        if (location.type === formatLabelsObj.Custom_Location) {
          const trimmed = location.name.trim();
          if (trimmed.startsWith('(') && trimmed.endsWith(')')) {
            const parts = trimmed.substring(1, trimmed.length - 1).split(',');
            if (parts.length === 2) {
              const latitude = parseFloat(parts[0]);
              const longitude = parseFloat(parts[1]);
              if (!isNaN(latitude) && !isNaN(longitude)) {
                location.latitude = latitude;
                location.longitude = longitude;
                location.key = location.name;
                if (!this.isExcluded[location.key]) {
                  this.$set(this.radius, location.key, location.radius);
                  this.$set(this.radiusInput, location.key, true);
                }
              } else {
                this.invalidCustomLocation = true;
              }
            } else {
              this.invalidCustomLocation = true;
            }
          } else {
            this.invalidCustomLocation = true;
          }
          if (this.invalidCustomLocation) {
            this.selectedLocations.pop();
            this.locations = this.locations.filter(obj => obj.type !== formatLabelsObj.Custom_Location);
            data.pop();
          }
        }
      })
      this.locationString = locationArray.join(', ');
      this.setExcludeToFalseWhenDeselected(data);
      return this.locationOverrideFilter(data);
    },
    disableIcons () {
      if (this.targetMode === "New") {
        return false;
      }
      return true;
    }
  },
  mounted () {
    // set clone data in selected locations
    if (this.targetMode === "Edit Existing") {
      this.iconsContent = ["Cannot add while editing target", "Cannot clone while editing target", "Cannot remove while editing target"]
    }
    this.loadLocationData();
  }
};
</script>

<style scoped>
::v-deep .ivu-radio-focus {
  box-shadow: none !important;
}
::v-deep .ivu-radio-group-button .ivu-radio-wrapper {
  display: inline-block;
  height: 32px;
  line-height: 30px;
  margin: 0;
  padding: 0 15px;
  font-size: 13px;
  color: gray !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border: 1px solid #dcdee2 !important;
  background: #fff;
  position: relative;
  border-radius: 0px !important;
}

::v-deep .ivu-radio-group-button .ivu-radio-wrapper-checked {
  background: #ebebeb;
  -webkit-box-shadow: 0 0 0 0 #cccccc;
  box-shadow: 0 0 0 0 #d7d7d7;
  z-index: 1;
}
::v-deep .ivu-radio-group-button .ivu-radio-wrapper:hover {
  color: #292929;
  background: #e5e5e5;
}
::v-deep .ivu-tag {
  display: none;
}
::v-deep .ivu-poptip-body {
  display: none;
}
::v-deep .ivu-poptip-title:after {
  display: none;
}
::v-deep .ivu-poptip-title {
    margin: 0;
    padding: 6px 6px;
    position: relative;
}
::v-deep .ivu-poptip-inner {
    width: 100%;
    background-color: #fbfbfb;
    background-clip: padding-box;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .3);
    white-space: nowrap;
}
::v-deep .ivu-select-item-selected:after {
  display: none;
}
::v-deep .ivu-collapse {
  width: 95%;
}
::v-deep .ivu-icon-ios-arrow-forward {
  display: none;
}
::v-deep .ivu-collapse-header {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 10px;
}
::v-deep .ivu-collapse-content {
  border-top: 2px solid #51b848;
}
::v-deep .ivu-select-prefix {
    padding: 6px 2px;
}
::v-deep .ivu-select-head-flex {
  display: flex;
}
::v-deep .ivu-select-multiple .ivu-select-item-selected:after {
  display: none !important;;
}
.radius-text {
  display: flex;
  width: 20%;
  justify-content: flex-end;
  margin: 10px 2px;
}
.excluded-location-text {
  margin: 10px;
}
.tooltip-input {
  width: 60px;
}
::v-deep .radius-setting .tooltip-inner {
  max-width: max-content !important;
}
.poptip-text:hover {
 background: #cdcdcd;
 color: #292929;
}
.poptip-text {
  margin: 6px 0px;
  padding: 2px;
  color: #777777;
  cursor: pointer;
}
.poptip-heading {
  font-weight: bold;
  color: #000000;
}
.dropdown-text {
  margin-left: 15px;
  font-size: 13px;
}
#empty-location-warning {
  width: 600px;
  color: #cf7171;
}
.selected-location-icon {
  margin: 4px 8px;
  padding: 6px 6px;
  float: left;
  cursor: pointer;
}
.selected-location-data {
  margin: 6px 4px;
  padding: 3px 4px;
  display: flex;
  width: 85%;
  font-weight: bold;
  flex-grow: 1;
}
.selected-location-box:hover {
  background: #f2f2f2;
}
.selected-location-box {
  display: flex;
  border: 1px solid #dcdee2;
  display: flex;
}
.location-warning {
  width: 600px;
}
.location-select {
  width: 600px;
  margin-top: 10px;
}
.card-body-label {
  display: inline-block;
  color: #757575;
  width: 20%;
  justify-content: center;
}
.title-sub-text {
  font-weight: normal;
  margin: 0;
  color: #000000;
}
.title-text {
  font-weight: bold;
  margin: 0;
  color: #000000;
}
.card-block {
  margin-bottom: 20px;
  display: flex;
}
.title-icons {
  margin: 2px 8px;
  padding: 8px 8px;
}
.icons {
  font-size: 13px;
  cursor: pointer;
  color: gray;
}
#title-area {
  width: 80%;
}
#icon-area{
  display: flex;
  flex-grow: 1;
  justify-content: right;
}
#card-title {
  display: flex;
  flex-grow: 1;
  cursor: pointer;
}
#target-location-comp {
  font-family: "sans-serif", sans-serif;
  font-size: 13px;
  font-weight: normal;
  color: #000000;
  margin: 5px 30px;
  position: relative;
}
.badge {
  float: right;
}
hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid #acacac;
}
.badge{
  float: right;
}
.disabled-icon {
  cursor: not-allowed;
  color: lightgray;
}
</style>
