import { adwordsEnums } from "../Constants/AdwordsEnums.js";

const adSheetSettingsRequirements = {
  performanceMaxAssetTypeRequirements: {
    headline: {
      min: 3,
      max: 15
    },
    longHeadline: {
      min: 1,
      max: 5
    },
    description: {
      min: 1,
      max: 4
    },
    marketingImage: {
      min: 1,
      max: 20
    },
    squareMarketingImage: {
      min: 1,
      max: 20
    },
    logo: {
      min: 1,
      max: 5
    },
    youtubeVideo: {
      min: 0,
      max: 5
    },
    portraitMarketingImage: {
      min: 0,
      max: 20
    },
    landscapeLogo: {
      min: 0,
      max: 5
    }
  },
  displayAssetTypeRequirements: {
    headline: {
      min: 1,
      max: 5
    },
    description: {
      min: 1,
      max: 5
    },
    marketingImage: {
      min: 1,
      max: 15
    },
    squareMarketingImage: {
      min: 1,
      max: 15
    },
    logo: {
      min: 1,
      max: 5
    },
    squareLogo: {
      min: 1,
      max: 5
    },
    youtubeVideo: {
      min: 1,
      max: 5
    }
  },
  appAssetTypeRequirements: {
    headline: {
      min: 2,
      max: 5
    },
    description: {
      min: 1,
      max: 5
    },
    image: {
      min: 1,
      max: 20
    },
    youtubeVideo: {
      min: 1,
      max: 20
    }
  },
  demandGenAssetTypeRequirements: {
    discoveryImageAds: {
      headline: {
        min: 1,
        max: 5
      },
      description: {
        min: 1,
        max: 5
      },
      marketingImage: {
        min: 1,
        max: 20
      },
      squareMarketingImage: {
        min: 1,
        max: 20
      },
      portraitMarketingImage: {
        min: 1,
        max: 20
      },
      logo: {
        min: 1,
        max: 5
      }
    },
    discoveryVideoAds: {
      headline: {
        min: 1,
        max: 5
      },
      longHeadline: {
        min: 1,
        max: 5
      },
      description: {
        min: 1,
        max: 5
      },
      video: {
        min: 1,
        max: 5
      }
    },
    discoveryCarouselAds: {
      card: {
        min: 2,
        max: 10
      }
    }
  }
}

const adwordsAssetType = {
  "headline": "Headline",
  "longHeadline": "Long Headline",
  "shortDescription": "Short Description",
  "description": "Description",
  "marketingImage": "Marketing Image",
  "squareMarketingImage": "Square Marketing Image",
  "logo": "Logo",
  "businessName": "Business Name",
  "youtubeVideo": "Youtube Video",
  "squareLogo": "Square Logo",
  "image": "Image",
  "video": "Video",
  "portraitMarketingImage": "Portrait Marketing Image",
  "card": "Card",
  "landscapeLogo": "Landscape Logo",
  "searchThemes": "Search Themes",
  "callToAction": "Call to Action",
  "name": "Name",
  "finalUrl": "Final URL"
}

const adwordsAppAssetType = {
  "headline": "Headline",
  "description": "Description",
  "image": "Image",
  "youtubeVideo": "Youtube Video",
  "finalAppUrl": "App URL"
}

const adwordsDisplayAssetType = {
  "headline": "Headline",
  "longHeadline": "Long Headline",
  "description": "Description",
  "marketingImage": "Marketing Image",
  "squareMarketingImage": "Square Marketing Image",
  "logo": "Logo",
  "businessName": "Business Name",
  "youtubeVideo": "Youtube Video",
  "squareLogo": "Square Logo"
}

const adwordsDemandAssetType = {
  "headlines": "Headline",
  "longHeadlines": "Long Headline",
  "descriptions": "Description",
  "marketingImages": "Marketing Image",
  "squareMarketingImages": "Square Marketing Image",
  "portraitMarketingImages": "Portrait Marketing Image",
  "logoImages": "Logo",
  "youtubeVideos": "Youtube Video",
  "cardAssets": "Card"
}

const adwordsAssetTypeField = {
  "Youtube Video": 7,
  "Marketing Image": 5,
  "Square Marketing Image": 19,
  "Portrait Marketing Image": 20,
  "Logo": 21,
  "Landscape Logo": 22,
  "Headline": 2,
  "Description": 3,
  "Long Headline": 17,
  "Call to Action": 25,
  "Name": 26,
  "Final URL": 27,
  "Search Themes": 28,
  "Business Name": 18,
  "Status": 29
}

function getAssetRequirements (campaignType) {
  if (campaignType === adwordsEnums.campaignType["Display"]) {
    return adSheetSettingsRequirements.displayAssetTypeRequirements;
  } else if (campaignType === adwordsEnums.campaignType["App"]) {
    return adSheetSettingsRequirements.appAssetTypeRequirements;
  } else if (campaignType === adwordsEnums.campaignType["Demand Gen"]) {
    return adSheetSettingsRequirements.demandGenAssetTypeRequirements;
  } else {
    return adSheetSettingsRequirements.performanceMaxAssetTypeRequirements;
  }
}

export { getAssetRequirements, adwordsAssetType, adwordsAppAssetType, adwordsDisplayAssetType, adwordsDemandAssetType, adwordsAssetTypeField };
