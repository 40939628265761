<template>
  <div>
      <Button
        size="small"
        v-on:click="openConfigurationModal = true"
        >
          Ad Sheet Settings
          <i class="fa fa-cog" />
        </Button>
      <Modal
        v-model="openConfigurationModal"
        title="Ad Sheet Settings"
        width="60%"
        @on-cancel="cancel"
        class="ad-sheet-settings"
        :styles="{ top: '20px' }"
      >

        <div class="modal-body">
          <Form
            v-if="campaignType === demandGenCampaign"
            label-position="right"
            :label-width="200"
            :model="adSheetSettings"
            ref="adwordsDemandGenAdSheetSettings"
            :rules="demandGenValidationRules"
          >
            <Divider orientation="left">Single Image</Divider>
            <Form-Item label="Headlines" prop="discoveryImageAds.headline" key="discoveryImageAds.headline">
              <Row>
                <Row-Col span="5" v-if="adSheetSettings.discoveryImageAds">
                  <i-input v-model="adSheetSettings.discoveryImageAds.headline" type="number"/>
                </Row-Col>
              </Row>
            </Form-Item>
            <Form-Item label="Descriptions" prop="discoveryImageAds.description" key="discoveryImageAds.description">
              <Row>
                <Row-Col span="5" v-if="adSheetSettings.discoveryImageAds">
                  <i-input v-model="adSheetSettings.discoveryImageAds.description" type="number"/>
                </Row-Col>
              </Row>
            </Form-Item>
            <Form-Item label="Marketing Images" prop="discoveryImageAds.marketingImage" key="discoveryImageAds.marketingImage">
              <Row>
                <Row-Col span="5" v-if="adSheetSettings.discoveryImageAds">
                  <i-input v-model="adSheetSettings.discoveryImageAds.marketingImage" type="number"/>
                </Row-Col>
              </Row>
            </Form-Item>
            <Form-Item label="Square Marketing Images" prop="discoveryImageAds.squareMarketingImage" key="discoveryImageAds.squareMarketingImage">
              <Row>
                <Row-Col span="5" v-if="adSheetSettings.discoveryImageAds">
                  <i-input v-model="adSheetSettings.discoveryImageAds.squareMarketingImage" type="number"/>
                </Row-Col>
              </Row>
            </Form-Item>
            <Form-Item label="Logo" prop="discoveryImageAds.logo" key="discoveryImageAds.logo">
              <Row>
                <Row-Col span="5" v-if="adSheetSettings.discoveryImageAds">
                  <i-input v-model="adSheetSettings.discoveryImageAds.logo" type="number"/>
                </Row-Col>
              </Row>
            </Form-Item>
            <Form-Item label="Portrait Marketing Images" prop="discoveryImageAds.portraitMarketingImage" key="discoveryImageAds.portraitMarketingImage">
              <Row>
                <Row-Col span="5" v-if="adSheetSettings.discoveryImageAds">
                  <i-input v-model="adSheetSettings.discoveryImageAds.portraitMarketingImage" type="number" />
                </Row-Col>
              </Row>
            </Form-Item>
            <Divider orientation="left">Single Video</Divider>
            <Form-Item label="Headlines" prop="discoveryVideoAds.headline" key="discoveryVideoAds.headline">
              <Row>
                <Row-Col span="5" v-if="adSheetSettings.discoveryVideoAds">
                  <i-input v-model="adSheetSettings.discoveryVideoAds.headline" type="number"/>
                </Row-Col>
              </Row>
            </Form-Item>
            <Form-Item label="Long Headlines" prop="discoveryVideoAds.longHeadline" key="discoveryVideoAds.longHeadline">
              <Row>
                <Row-Col span="5" v-if="adSheetSettings.discoveryVideoAds">
                  <i-input v-model="adSheetSettings.discoveryVideoAds.longHeadline" type="number"/>
                </Row-Col>
              </Row>
            </Form-Item>
            <Form-Item label="Descriptions" prop="discoveryVideoAds.description" key="discoveryVideoAds.description">
              <Row>
                <Row-Col span="5" v-if="adSheetSettings.discoveryVideoAds">
                  <i-input v-model="adSheetSettings.discoveryVideoAds.description" type="number"/>
                </Row-Col>
              </Row>
            </Form-Item>
            <Form-Item label="Video" prop="discoveryVideoAds.video" key="discoveryVideoAds.video">
              <Row>
                <Row-Col span="5" v-if="adSheetSettings.discoveryVideoAds">
                  <i-input v-model="adSheetSettings.discoveryVideoAds.video" type="number" />
                </Row-Col>
              </Row>
            </Form-Item>
            <Divider orientation="left">Carousel Image</Divider>
            <Form-Item label="Card" prop="discoveryCarouselAds.card" key="discoveryCarouselAds.card">
              <Row>
                <Row-Col span="5" v-if="adSheetSettings.discoveryCarouselAds">
                  <i-input v-model="adSheetSettings.discoveryCarouselAds.card" type="number" />
                </Row-Col>
              </Row>
            </Form-Item>
          </Form>

          <Form
            v-else
            label-position="right"
            :label-width="200"
            :model="adSheetSettings"
            ref="adwordsAdSheetSettings"
            :rules="validationRules"
          >
            <Form-Item label="Headlines" prop="headline">
              <Row>
                <Row-Col span="5">
                  <i-input v-model="adSheetSettings.headline" type="number"/>
                </Row-Col>
              </Row>
            </Form-Item>
            <Form-Item v-if="campaignType === pmaxCampaign" label="Long Headlines" prop="longHeadline">
              <Row>
                <Row-Col span="5">
                  <i-input v-model="adSheetSettings.longHeadline" type="number"/>
                </Row-Col>
              </Row>
            </Form-Item>
            <Form-Item label="Descriptions" prop="description">
              <Row>
                <Row-Col span="5">
                  <i-input v-model="adSheetSettings.description" type="number"/>
                </Row-Col>
              </Row>
            </Form-Item>
            <Form-Item v-if="campaignType === pmaxCampaign || campaignType === displayCampaign" label="Marketing Images" prop="marketingImage">
              <Row>
                <Row-Col span="5">
                  <i-input v-model="adSheetSettings.marketingImage" type="number"/>
                </Row-Col>
              </Row>
            </Form-Item>
            <Form-Item v-if="campaignType === pmaxCampaign || campaignType === displayCampaign" label="Square Marketing Images" prop="squareMarketingImage">
              <Row>
                <Row-Col span="5">
                  <i-input v-model="adSheetSettings.squareMarketingImage" type="number"/>
                </Row-Col>
              </Row>
            </Form-Item>
            <Form-Item v-if="campaignType === pmaxCampaign" label="Portrait Marketing Images" prop="portraitMarketingImage">
              <Row>
                <Row-Col span="5">
                  <i-input v-model="adSheetSettings.portraitMarketingImage" type="number"/>
                </Row-Col>
              </Row>
            </Form-Item>
            <Form-Item v-if="campaignType === pmaxCampaign || campaignType === displayCampaign" label="Logo" prop="logo">
              <Row>
                <Row-Col span="5">
                  <i-input v-model="adSheetSettings.logo" type="number"/>
                </Row-Col>
              </Row>
            </Form-Item>
            <Form-Item v-if="campaignType === pmaxCampaign" label="Landscape Logo" prop="landscapeLogo">
              <Row>
                <Row-Col span="5">
                  <i-input v-model="adSheetSettings.landscapeLogo" type="number"/>
                </Row-Col>
              </Row>
            </Form-Item>
            <Form-Item v-if="campaignType === displayCampaign" label="Square Logo" prop="squareLogo">
              <Row>
                <Row-Col span="5">
                  <i-input v-model="adSheetSettings.squareLogo" type="number"/>
                </Row-Col>
              </Row>
            </Form-Item>
            <Form-Item v-if="campaignType === appCampaign || campaignType === displayCampaign || campaignType === pmaxCampaign" label="Youtube Video" prop="youtubeVideo">
              <Row>
                <Row-Col span="5">
                  <i-input v-model="adSheetSettings.youtubeVideo" type="number" />
                </Row-Col>
              </Row>
            </Form-Item>
            <Form-Item v-if="campaignType === appCampaign" label="Image" prop="image">
              <Row>
                <Row-Col span="5">
                  <i-input v-model="adSheetSettings.image" type="number" />
                </Row-Col>
              </Row>
            </Form-Item>
          </Form>
        </div>

        <div slot="footer">
          <input type="button" class="btn btn-white adsheet-settings-padding" v-on:click="cancel()" value="Cancel" />
          <button
          class="btn btn-success adsheet-settings-padding"
          v-on:click="apply(true)"
          :disabled="processing"
        > Apply <i class="fa fa-spinner fa-spin" v-show="processing"></i>
        </button>
        </div>
    </Modal>
  </div>
</template>

<script>

import {
  Button,
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  Divider
} from "iview";
import { mapState } from "vuex";
import { adwordsEnums } from "../../Constants/AdwordsEnums.js";
import { getAssetRequirements, adwordsAssetType } from "../../Constants/AdwordsAdsheetSettingsRequirements.js"

export default {
  components: {
    Button,
    Modal,
    Row,
    "Row-Col": Col,
    Form,
    FormItem,
    "i-input": Input,
    Divider
  },
  watch: {
    "adwordsAdSheetSettingsInfo": {
      immediate: true,
      handler: function (val) {
        if (val && Object.keys(val).length) {
          this.loadFromExisting = true;
          this.loadExistingAdSheetSettings();
        }
      }
    }
  },
  mounted () {
    this.assetTypeRequirements = getAssetRequirements(this.campaignType);
    if (!this.loadFromExisting) {
      this.setAdSheetSettings(this.assetTypeRequirements);
    }
  },
  computed: {
    ...mapState([
      "adwordsAdSheetSettingsInfo"
    ])
  },
  data () {
    return {
      adSheetSettings: {},
      assetTypeRequirements: {},
      processing: false,
      openConfigurationModal: false,
      colHeaderNames: [],
      colHeaderNamesDemandGen: {},
      validationRules: {
        headline: [
          {
            validator: (rule, value, callback) => {
              if (value < this.assetTypeRequirements.headline.min || value > this.assetTypeRequirements.headline.max) {
                callback(new Error(`The value must be greater than or equal to ${this.assetTypeRequirements.headline.min} and less than or equal to ${this.assetTypeRequirements.headline.max}`));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        longHeadline: [
          {
            validator: (rule, value, callback) => {
              if (value < this.assetTypeRequirements.longHeadline.min || value > this.assetTypeRequirements.longHeadline.max) {
                callback(new Error(`The value must be greater than or equal to ${this.assetTypeRequirements.longHeadline.min} and less than or equal to ${this.assetTypeRequirements.longHeadline.max}`));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        description: [
          {
            validator: (rule, value, callback) => {
              if (value < this.assetTypeRequirements.description.min || value > this.assetTypeRequirements.description.max) {
                callback(new Error(`The value must be greater than or equal to ${this.assetTypeRequirements.description.min} and less than or equal to ${this.assetTypeRequirements.description.max}`));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        marketingImage: [
          {
            validator: (rule, value, callback) => {
              let self = this;
              if (value < this.assetTypeRequirements.marketingImage.min || value >= this.assetTypeRequirements.marketingImage.max) {
                callback(new Error(`The value must be greater than or equal to ${this.assetTypeRequirements.marketingImage.min} and less than ${this.assetTypeRequirements.marketingImage.max}`));
              } else if (self.validateImageCount() > this.assetTypeRequirements.marketingImage.max) {
                callback(new Error(`The total of marketing image and square marketing image count should be less than or equal to ${this.assetTypeRequirements.marketingImage.max}`));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        squareMarketingImage: [
          {
            validator: (rule, value, callback) => {
              let self = this;
              if (value < this.assetTypeRequirements.squareMarketingImage.min || value >= this.assetTypeRequirements.squareMarketingImage.max) {
                callback(new Error(`The value must be greater than or equal to ${this.assetTypeRequirements.squareMarketingImage.min} and less than ${this.assetTypeRequirements.squareMarketingImage.max}`));
              } else if (self.validateImageCount() > this.assetTypeRequirements.squareMarketingImage.max) {
                callback(new Error(`The total of marketing image, square marketing and portrait marketing image count should be less than or equal to ${this.assetTypeRequirements.squareMarketingImage.max}`));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        logo: [
          {
            validator: (rule, value, callback) => {
              let self = this;
              if (value < this.assetTypeRequirements.logo.min || value > this.assetTypeRequirements.logo.max) {
                callback(new Error(`The value must be greater than or equal to ${this.assetTypeRequirements.logo.min} and less than or equal to ${this.assetTypeRequirements.logo.max}`));
              } else if (self.validateLogoCount() > this.assetTypeRequirements.logo.max) {
                callback(new Error(`The total of logo image and square logo image count should be less than or equal to ${this.assetTypeRequirements.logo.max}`));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        squareLogo: [
          {
            validator: (rule, value, callback) => {
              let self = this;
              if (value < this.assetTypeRequirements.squareLogo.min || value > this.assetTypeRequirements.squareLogo.max) {
                callback(new Error(`The value must be greater than or equal to ${this.assetTypeRequirements.squareLogo.min} and less than or equal to ${this.assetTypeRequirements.squareLogo.max}`));
              } else if (self.validateLogoCount() > this.assetTypeRequirements.squareLogo.max) {
                callback(new Error(`The total of logo image and square logo image count should be less than or equal to ${this.assetTypeRequirements.squareLogo.max}`));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        youtubeVideo: [
          {
            validator: (rule, value, callback) => {
              if (value < this.assetTypeRequirements.youtubeVideo.min || value > this.assetTypeRequirements.youtubeVideo.max) {
                callback(new Error(`The value must be greater than or equal to ${this.assetTypeRequirements.youtubeVideo.min} and less than or equal to ${this.assetTypeRequirements.youtubeVideo.max}`));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        image: [
          {
            validator: (rule, value, callback) => {
              if (value < this.assetTypeRequirements.image.min || value > this.assetTypeRequirements.image.max) {
                callback(new Error(`The value must be greater than or equal to ${this.assetTypeRequirements.image.min} and less than or equal to ${this.assetTypeRequirements.image.max}`));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        portraitMarketingImage: [
          {
            validator: (rule, value, callback) => {
              let self = this;
              if (value < self.assetTypeRequirements.portraitMarketingImage.min || value > self.assetTypeRequirements.portraitMarketingImage.max) {
                callback(new Error(`The value must be greater than or equal to ${self.assetTypeRequirements.portraitMarketingImage.min} and less than or equal to ${self.assetTypeRequirements.portraitMarketingImage.max}`));
              } else if (self.validateImageCount() > self.assetTypeRequirements.portraitMarketingImage.max) {
                callback(new Error(`The total of marketing image, square marketing and portrait marketing image count should be less than or equal to ${self.assetTypeRequirements.portraitMarketingImage.max}`));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        landscapeLogo: [
          {
            validator: (rule, value, callback) => {
              let self = this;
              if (value < self.assetTypeRequirements.landscapeLogo.min || value > self.assetTypeRequirements.landscapeLogo.max) {
                callback(new Error(`The value must be greater than or equal to ${self.assetTypeRequirements.landscapeLogo.min} and less than or equal to ${self.assetTypeRequirements.landscapeLogo.max}`));
              } else if (self.validateLogoCount() > self.assetTypeRequirements.landscapeLogo.max) {
                callback(new Error(`The total of logo image and landscape logo image count should be less than or equal to ${self.assetTypeRequirements.landscapeLogo.max}`));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ]
      },
      demandGenValidationRules: {
        'discoveryImageAds.headline': [
          {
            validator: (rule, value, callback) => {
              if (value < this.assetTypeRequirements.discoveryImageAds.headline.min || value > this.assetTypeRequirements.discoveryImageAds.headline.max) {
                callback(new Error(`The value must be greater than or equal to ${this.assetTypeRequirements.discoveryImageAds.headline.min} and less than or equal to ${this.assetTypeRequirements.discoveryImageAds.headline.max}`));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        'discoveryImageAds.description': [
          {
            validator: (rule, value, callback) => {
              if (value < this.assetTypeRequirements.discoveryImageAds.description.min || value > this.assetTypeRequirements.discoveryImageAds.description.max) {
                callback(new Error(`The value must be greater than or equal to ${this.assetTypeRequirements.discoveryImageAds.description.min} and less than or equal to ${this.assetTypeRequirements.discoveryImageAds.description.max}`));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        'discoveryImageAds.marketingImage': [
          {
            validator: (rule, value, callback) => {
              let self = this;
              if (value < self.assetTypeRequirements.discoveryImageAds.marketingImage.min || value > self.assetTypeRequirements.discoveryImageAds.marketingImage.max) {
                callback(new Error(`The value must be greater than or equal to ${self.assetTypeRequirements.discoveryImageAds.marketingImage.min} and less than or equal to ${self.assetTypeRequirements.discoveryImageAds.marketingImage.max}`));
              } else if (self.validateDemandGenImagesCount() > self.assetTypeRequirements.discoveryImageAds.marketingImage.max) {
                callback(new Error(`The total of marketing image, square marketing image and portrait marketing image count should be less than or equal to ${self.assetTypeRequirements.discoveryImageAds.marketingImage.max}`));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        'discoveryImageAds.squareMarketingImage': [
          {
            validator: (rule, value, callback) => {
              let self = this;
              if (value < self.assetTypeRequirements.discoveryImageAds.squareMarketingImage.min || value > self.assetTypeRequirements.discoveryImageAds.squareMarketingImage.max) {
                callback(new Error(`The value must be greater than or equal to ${self.assetTypeRequirements.discoveryImageAds.squareMarketingImage.min} and less than or equal to ${self.assetTypeRequirements.discoveryImageAds.squareMarketingImage.max}`));
              } else if (self.validateDemandGenImagesCount() > self.assetTypeRequirements.discoveryImageAds.squareMarketingImage.max) {
                callback(new Error(`The total of marketing image, square marketing image and portrait marketing image count count should be less than or equal to ${self.assetTypeRequirements.discoveryImageAds.squareMarketingImage.max}`));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        'discoveryImageAds.logo': [
          {
            validator: (rule, value, callback) => {
              if (value < this.assetTypeRequirements.discoveryImageAds.logo.min || value > this.assetTypeRequirements.discoveryImageAds.logo.max) {
                callback(new Error(`The value must be greater than or equal to ${this.assetTypeRequirements.discoveryImageAds.logo.min} and less than or equal to ${this.assetTypeRequirements.discoveryImageAds.logo.max}`));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        'discoveryImageAds.portraitMarketingImage': [
          {
            validator: (rule, value, callback) => {
              let self = this;
              if (value < self.assetTypeRequirements.discoveryImageAds.portraitMarketingImage.min || value > self.assetTypeRequirements.discoveryImageAds.portraitMarketingImage.max) {
                callback(new Error(`The value must be greater than or equal to ${self.assetTypeRequirements.discoveryImageAds.portraitMarketingImage.min} and less than or equal to ${self.assetTypeRequirements.discoveryImageAds.portraitMarketingImage.max}`));
              } else if (self.validateDemandGenImagesCount() > self.assetTypeRequirements.discoveryImageAds.portraitMarketingImage.max) {
                callback(new Error(`The total of marketing image, square marketing image and portrait marketing image count count should be less than or equal to ${self.assetTypeRequirements.discoveryImageAds.portraitMarketingImage.max}`));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        'discoveryVideoAds.headline': [
          {
            validator: (rule, value, callback) => {
              if (value < this.assetTypeRequirements.discoveryVideoAds.headline.min || value > this.assetTypeRequirements.discoveryVideoAds.headline.max) {
                callback(new Error(`The value must be greater than or equal to ${this.assetTypeRequirements.discoveryVideoAds.headline.min} and less than or equal to ${this.assetTypeRequirements.discoveryVideoAds.headline.max}`));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        'discoveryVideoAds.longHeadline': [
          {
            validator: (rule, value, callback) => {
              if (value < this.assetTypeRequirements.discoveryVideoAds.longHeadline.min || value > this.assetTypeRequirements.discoveryVideoAds.longHeadline.max) {
                callback(new Error(`The value must be greater than or equal to ${this.assetTypeRequirements.discoveryVideoAds.longHeadline.min} and less than or equal to ${this.assetTypeRequirements.discoveryVideoAds.longHeadline.max}`));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        'discoveryVideoAds.description': [
          {
            validator: (rule, value, callback) => {
              if (value < this.assetTypeRequirements.discoveryVideoAds.description.min || value > this.assetTypeRequirements.discoveryVideoAds.description.max) {
                callback(new Error(`The value must be greater than or equal to ${this.assetTypeRequirements.discoveryVideoAds.description.min} and less than or equal to ${this.assetTypeRequirements.discoveryVideoAds.description.max}`));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        'discoveryVideoAds.video': [
          {
            validator: (rule, value, callback) => {
              if (value < this.assetTypeRequirements.discoveryVideoAds.video.min || value > this.assetTypeRequirements.discoveryVideoAds.video.max) {
                callback(new Error(`The value must be greater than or equal to ${this.assetTypeRequirements.discoveryVideoAds.video.min} and less than or equal to ${this.assetTypeRequirements.discoveryVideoAds.video.max}`));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        'discoveryCarouselAds.card': [
          {
            validator: (rule, value, callback) => {
              if (value < this.assetTypeRequirements.discoveryCarouselAds.card.min || value > this.assetTypeRequirements.discoveryCarouselAds.card.max) {
                callback(new Error(`The value must be greater than or equal to ${this.assetTypeRequirements.discoveryCarouselAds.card.min} and less than or equal to ${this.assetTypeRequirements.discoveryCarouselAds.card.max}`));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ]

      },
      currentMarketingImageLimit: adwordsEnums.assetTypesMarketingImageCount - 1,
      displayCampaign: adwordsEnums.campaignType["Display"],
      pmaxCampaign: adwordsEnums.campaignType["Performance Max"],
      appCampaign: adwordsEnums.campaignType["App"],
      demandGenCampaign: adwordsEnums.campaignType["Demand Gen"],
      loadFromExisting: false,
      adFormats: adwordsEnums.adwordsAdFormats
    }
  },
  props: {
    campaignType: Number,
    adformatType: String
  },
  methods: {
    apply () {
      let form = this.campaignType === this.demandGenCampaign ? 'adwordsDemandGenAdSheetSettings' : 'adwordsAdSheetSettings'
      this.$refs[form].validate((valid) => {
        if (valid) {
          let settings = {};
          this.processing = true;
          if (this.campaignType === this.demandGenCampaign) {
            this.createColHeadersObj();
            settings.customizedColHeaders = this.colHeaderNamesDemandGen;
          } else {
            this.createColHeadersArray();
            settings.customizedColHeaders = this.colHeaderNames;
          }
          settings.saveRequired = true;
          settings.adSheetSettings = this.adSheetSettings;
          this.$emit("savedAdSheetSettings", settings);
          this.processing = false;
          this.openConfigurationModal = false;
          this.colHeaderNames = [];
          this.colHeaderNamesDemandGen = {};
        } else {
          this.$nextTick(function () {
            let errors = document.getElementsByClassName("ivu-form-item-error-tip");
            if (errors.length > 0) {
              errors[0].parentElement.scrollIntoView({ block: 'end' });
            }
          });
        }
      });
    },
    cancel () {
      this.openConfigurationModal = false
    },
    createColHeadersArray () {
      let adwordsAssetTypes = adwordsAssetType;
      let assetTypeRequirements = this.assetTypeRequirements;
      for (let item in adwordsAssetTypes) {
        if (this.adSheetSettings[item]) {
          this.generateColName(item, assetTypeRequirements[item].min, this.adSheetSettings[item])
        }
      }
    },
    createColHeadersObj () {
      let adwordsAssetTypes = adwordsAssetType;
      let assetTypeRequirements = this.assetTypeRequirements;
      let adSheet = this.adSheetSettings
      for (let item in adwordsAssetTypes) {
        for (let assetType in adSheet) {
          if (adSheet[assetType][item]) {
            this.generateColNameDemandGen(item, assetType, assetTypeRequirements[assetType][item].min, adSheet[assetType][item])
          }
        }
      }
    },
    generateColName (headerName, startIndex, endIndex) {
      let colHeaderName = "";
      for (let i = startIndex + 1; i <= endIndex; i++) {
        colHeaderName = headerName + i;
        this.colHeaderNames.push(colHeaderName);
      }
    },
    generateColNameDemandGen (columnName, adFormat, startIndex, endIndex) {
      let colHeaderName = "";
      if (columnName == 'card') {
        for (let i = startIndex + 1; i <= endIndex; i++) {
          let colHeaderName1 = columnName + i + 'MarketingImage';
          let colHeaderName2 = columnName + i + 'SquareMarketingImage';
          let colHeaderName3 = columnName + i + 'PortraitMarketingImage';
          let colHeaderName4 = columnName + i + 'Headline';
          let colHeaderName5 = columnName + i + 'FinalURL';
          let colHeaderName6 = columnName + i + 'CallToAction';
          if (this.colHeaderNamesDemandGen[adFormat]) {
            this.colHeaderNamesDemandGen[adFormat].push(colHeaderName1, colHeaderName2, colHeaderName3, colHeaderName6, colHeaderName4, colHeaderName5);
          } else {
            this.colHeaderNamesDemandGen[adFormat] = [colHeaderName1, colHeaderName2, colHeaderName3, colHeaderName6, colHeaderName4, colHeaderName5];
          }
        }
      } else {
        for (let i = startIndex + 1; i <= endIndex; i++) {
          colHeaderName = columnName + i;
          if (this.colHeaderNamesDemandGen[adFormat]) {
            this.colHeaderNamesDemandGen[adFormat].push(colHeaderName);
          } else {
            this.colHeaderNamesDemandGen[adFormat] = [colHeaderName];
          }
        }
      }
    },
    loadExistingAdSheetSettings () {
      this.adSheetSettings = this.adwordsAdSheetSettingsInfo;
    },
    validateImageCount () {
      return parseInt(this.adSheetSettings.squareMarketingImage) + parseInt(this.adSheetSettings.marketingImage) + parseInt(this.adSheetSettings.portraitMarketingImage);
    },
    validateLogoCount () {
      if (this.campaignType === this.pmaxCampaign) {
        return parseInt(this.adSheetSettings.logo) + parseInt(this.adSheetSettings.landscapeLogo);
      } else {
        return parseInt(this.adSheetSettings.logo) + parseInt(this.adSheetSettings.squareLogo);
      }
    },
    validateDemandGenImagesCount () {
      return parseInt(this.adSheetSettings.discoveryImageAds.marketingImage) + parseInt(this.adSheetSettings.discoveryImageAds.squareMarketingImage) + parseInt(this.adSheetSettings.discoveryImageAds.portraitMarketingImage);
    },
    setAdSheetSettings (assetTypeRequirements) {
      if (this.campaignType === this.demandGenCampaign) {
        this.adSheetSettings = {};
        for (let assetType in assetTypeRequirements) {
          if (typeof assetTypeRequirements[assetType] === "object") {
            this.$set(this.adSheetSettings, assetType, {});
            for (let subAssetType in assetTypeRequirements[assetType]) {
              this.$set(this.adSheetSettings[assetType], subAssetType, assetTypeRequirements[assetType][subAssetType].min);
            }
          }
        }
      } else {
        for (let item in assetTypeRequirements) {
          this.$set(this.adSheetSettings, item, assetTypeRequirements[item].min);
        }
      }
    }
  }
}
</script>

<style scoped>
.modal-body {
  min-height: 120px;
  max-height: 72vh;
  overflow: auto;
}
.split {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    border: 1px dashed #eee;
}
.horizontal-checkbox {
  display: block;
}
.checkbox-label {
  padding-left: 5px;
}
.columns-list {
  margin-left: 24px;
}
.adsheet-settings-padding {
  margin-right: 2px !important;
}
.ld-wrapper {
  margin-top: 20px;
}
.ld-row {
  margin-left: 30px;
}
.optional-field-selector-options {
  width: 50%;
  margin-left: 10px
}
.column-count-text {
  padding-left: 20px;
  font-size: 13px;
}
.divider {
  width: 100%;
  height: 7px;
  border-bottom: 1px solid #ccc;
  position: relative;
  margin-bottom: 36px;
}
.divider > span {
  left: 36px;
  top: -2px;
  position: absolute;
  font-size: 13px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #979898;
  background-color: white;
  padding: 0 10px;
}
</style>
<style>
.fixed-table-checkbox-option {
  table-layout: fixed;
}
.fixed-table-checkbox-option td {
  width: 50%;
  min-width: 160px;
}
.ad-sheet-settings .ivu-modal-body {
  padding-top: 0px !important;
}
.ccHeader {
  margin: 0px;
  font-size: 20px !important;
}
.ad-sheet-settings .ivu-modal-footer {
  padding-bottom: 2px !important;
}
.ad-sheet-settings .ivu-checkbox-checked .ivu-checkbox-inner {
    border-color: var(--main-bg-color) !important;
    background-color: var(--main-bg-color) !important;
}
.dynamic-creative {
  margin-left: 50px;
}
</style>
