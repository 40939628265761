import { adwordsEnums } from "../Constants/AdwordsEnums.js";
import { RelationshipStatus, EducationSatus, locationCategories, Genders } from "../Constants/FacebookEnums.js";

export default {
  methods: {
    appendCopyToConfigName (name) {
      // If name ends with "- Copy(Number)", increment Number, else append "- Copy(1)"
      name = name.trim();
      let regexp = /.*- Copy\(([0-9]+)\)$/;
      let res = name.match(regexp)
      if (res == null) {
        return name + " - Copy(1)"
      } else {
        let num = Number(res[1]) + 1
        let index = name.lastIndexOf("(");
        return name.substring(0, index + 1) + num + ")"
      }
    },
    handleOldAdwordsConfig (config) {
      if (config.campaign.type === "Search") {
        config.campaign.type = 1
      }
      config.campaign.subtype = config.campaign.subtype || 1
      config.campaign.locations = config.campaign.locations || {}
      config.campaign.targetBidAmount = config.campaign.targetBidAmount || null;
      config.campaign.isMaxCPClimitSet = config.campaign.isMaxCPClimitSet || false;
      config.campaign.maxCpcLimit = config.campaign.maxCpcLimit || null;
      config.campaign.targetImpression = config.campaign.targetImpression || null;
      config.campaign.adLocation = config.campaign.adLocation || 2;
      return config
    },
    getDeviceBidModifiers () {
      var deviceBidModifiers = []
      Object.keys(adwordsEnums.deviceTypes).forEach((key) => {
        var deviceinfo = {
          type: key,
          operand: 1,
          value: null,
          name: adwordsEnums.deviceTypes[key]
        }
        deviceBidModifiers.push(deviceinfo)
      });
      return deviceBidModifiers;
    },
    getDefaultAdwordsConfig () {
      let defaultConfig = {
        searchEngineTypeId: 1,
        name: "",
        bpseId: 0,
        googleAccountId: 0,
        adgroup: {
          type: 2
        },
        campaign: {
          adgroupType: "Standard",
          targetBidAmount: null,
          targetImpression: null,
          enhancedCpcEnabled: false,
          isMaxCPClimitSet: false,
          maxCpcLimit: null,
          adLocation: 2,
          deviceBidModifiers: [],
          biddingStrategyType: 9,
          bidAdjustmentType: false,
          schedule: {
            type: 1,
            dayParting_schedules: [ {
              startMinute: 0,
              endMinute: 1440,
              days: [0, 1, 2, 3, 4, 5, 6],
              timezoneType: 'USER'
            }]
          },
          name: {
            userInput: ""
          },
          type: 1,
          networks: [],
          status: 1,
          locationMultiplier: 1,
          scheduling: 1,
          locationMode: adwordsEnums.locationMode.Standard
        },
        kpiMetricId: null
      }
      // let allowedCampaignTypes = ['Search'];
      // Object.keys(adwordsEnums.campaignType)
      //   .forEach((key) => allowedCampaignTypes.includes(key) ||
      // delete adwordsEnums.campaignType[key]);
      defaultConfig.campaign.deviceBidModifiers = this.getDeviceBidModifiers()
      return defaultConfig;
    },
    getAdwordsConfigWithMacros (config, promoName) {
      const macrosToInsert = {
        bpseId: "{{store.bpseId}}",
        googleAccountId: "{{store.adaccountid}}",
        "campaign.name.userInput": `${promoName} - {{store.name}} ({{store.storeCode}})`
      };

      let configCopy = JSON.parse(JSON.stringify(config));

      for (let [path, macroStr] of Object.entries(macrosToInsert)) {
        let toReplace = path.split('.').slice(0, -1).reduce((cur, key) => cur[key], configCopy);
        toReplace[path.split('.').slice(-1)[0]] = macroStr
      }

      return JSON.stringify(configCopy)
    },
    getGender (genderValue) {
      let genderProperties = Object.keys(Genders);
      let genderName = '';
      genderProperties.forEach((gender) => {
        if (this.arraysEqual(Genders[gender], genderValue)) {
          genderName = gender;
        }
      });
      return genderName;
    },
    getRelationshipStatus (relationshipStatusIds) {
      let status = [];
      relationshipStatusIds.forEach(id => {
        RelationshipStatus.forEach((relationshipStatus) => {
          if (relationshipStatus.id === id) {
            status.push(relationshipStatus.name);
          }
        })
      });
      return status.join(', ');
    },
    arraysEqual (arr1, arr2) {
      if (arr1.length !== arr2.length) return false;
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
      }
      return true;
    },
    formatLocationsFromTargetString (location) {
      let countriesObj = []
      let zipsObj = []
      for (const property in location) {
        if (location.hasOwnProperty(property)) {
          for (const childObject of location[property]) {
            if (property === locationCategories.city) {
              childObject.type = "city";
            } else if (property === locationCategories.country) {
              countriesObj.push({ "key": childObject, "type": 'country', "country_code": childObject });
            } else if (property === locationCategories.geo_market) {
              childObject.type = "geo_market";
            } else if (property === locationCategories.region) {
              childObject.type = "region";
            } else if (property === locationCategories.zip) {
              zipsObj.push({ "key": childObject.key, "type": 'zip', "name": childObject.key })
            } else if (property === locationCategories.neighborhood) {
              childObject.type = "neighborhood";
            } else if (property === locationCategories.custom_location) {
              childObject.type = "custom_location";
              childObject.key = `(${childObject.latitude},${childObject.longitude})`;
              childObject.name = `(${childObject.latitude},${childObject.longitude})`;
            }
          }
        }
      }
      location.countries = countriesObj;
      location.zips = zipsObj;
    },
    formatFlexibleSpec (property, flexibleSpec, index) {
      if (property === "relationship_statuses") {
        flexibleSpec[index][property].forEach((item, itemIndex) => {
          let relationship = { id: item, type: property }
          RelationshipStatus.forEach((status) => {
            if (status.id == item) {
              relationship.name = status.name
              flexibleSpec[index][property][itemIndex] = relationship
            }
          })
        });
      } else if (property === "education_statuses") {
        flexibleSpec[index][property].forEach((item, itemIndex) => {
          let educationStatus = { id: item, type: property }
          EducationSatus.forEach((status) => {
            if (status.id == item) {
              educationStatus.name = status.name
              flexibleSpec[index][property][itemIndex] = educationStatus
            }
          })
        });
      }
    }
  }
}
