<template>
  <section id="audience-preview-comp">
    <Collapse v-model="collapseOpen">
      <Panel name="1">
    <div id="card">
      <div id="card-title">
        <div id="title-area">
          <p class="title-text">New Audience Preview</p>
        </div>
        <div id="icon-area download-icon">
          <tooltip content="Download CSV" placement="top">
            <i class="fa fa-download icons preview-icons" @click.stop="saveTargetsAsCSV"></i>
          </tooltip>
        </div>
      </div>
    </div>
      <div slot="content">
        <Collapse id="inner-audience-collapse" v-for="(item, index) in facebookTargetCreationData" :key="index" v-model="showInnerCardBody" >
          <Panel :name="'1-'+index">
              <div id="inner-card-title">
                <div id="inner-title-area">
                  <input type="text" id="preview-title-input" autofocus v-model="targetName" v-if="editingItem === index" @blur="disablePreviewInput(index)"/>
                  <p class="inner-title-text" v-else>{{item.targetName}} </p>
                  <tooltip content="Edit Name" placement="top">
                    <i class="fa fa-pencil icons preview-icons" id="edit-icon" @click.stop="enablePreviewInput(item, index)"></i>
                  </tooltip>
                </div>
                <div id="icon-area">
                  <template v-if="facebookTargetLoading[index]">
                    <Spin size="small"></Spin>
                    <Spin size="small"></Spin>
                    <Spin size="small"></Spin>
                  </template>
                  <template v-else>
                    <template v-if="item.estimatedReach || !item.isLoading">
                      <tooltip content="Save & Mark as Favourite">
                        <i class="fa fa-star icons preview-icons" @click.stop="toggleFavourite(index)" v-if="item.isFavourite"></i>
                        <i class="fa fa-star-o icons preview-icons" @click.stop="toggleFavourite(index)" v-else></i>
                      </tooltip>
                      <span v-if="index == targetSaveSpinner" >
                        <i class="fa fa-spinner fa-spin icons preview-icons" style="padding: 0px;"></i>
                      </span>
                      <tooltip  v-else content="Save">
                        <i class="fa fa-save icons preview-icons" @click.stop="saveTarget(index, 'save')"></i>
                      </tooltip>
                    </template>
                    <template v-else>
                      <tooltip :content="item.estimatedReachErrorMessage" placement="left" class="info-icon-tooltip">
                        <i class="fa fa-exclamation-circle preview-icons"></i>
                      </tooltip>
                    </template>
                  </template>
                  <tooltip :content="removeIconContent">
                    <i class="fa fa-trash icons preview-icons"
                    :class="{ 'disabled-icon': disableIcons }"
                    @click.stop="!disableIcons && deleteTarget(index)"></i>
                  </tooltip>
                </div>
              </div>
              <div id="card-body" slot="content">
                <p class="inner-card-head-text">Estimate Reach: <span class="inner-card-text" v-if="item.lowerBoundEstimate && item.upperBoundEstimate">{{ showEstimateReach(item) }}</span></p>
                <p class="inner-card-head-text">
                  <span>Age: <span class="inner-card-text">{{item.minAge}} - {{item.maxAge}}</span> </span>
                  <span>&nbsp;Gender: <span class="inner-card-text">{{item.genderPreview}}</span></span>
                  <span>&nbsp;Location Types: <span class="inner-card-text">{{item.locationTypesPreview}}</span></span>
                  <span v-if="item.locationsPreview">&nbsp;Included Locations: <span class="inner-card-text">{{item.locationsPreview}}</span></span>
                  <span v-if="item.excludedLocationsPreview">&nbsp;Excluded Locations: <span class="inner-card-text">{{item.excludedLocationsPreview}}</span></span>
                  <span v-if="item.languagesPreview">&nbsp;Languages: <span class="inner-card-text">{{item.languagesPreview}}</span></span>
                  <span v-if="item.relationshipStatusPreview">&nbsp;Relationship Status: <span class="inner-card-text">{{item.relationshipStatusPreview}}</span></span>
                  <span v-if="item.customAudiencePreview.length">&nbsp;Custom Audiences: <span class="inner-card-text">{{item.customAudiencePreview}}</span></span>
                  <span v-if="item.excludeCustomAudiencePreview.length">&nbsp;Excluded Custom Audiences: <span class="inner-card-text">{{item.excludeCustomAudiencePreview}}</span></span>
                </p>
                <p class="inner-card-head-text" v-if="item.flexibleTargetPreview.length > 0">Flexible targets:
                  <span class="inner-card-text">
                    <span v-for="(target, index) in item.flexibleTargetPreview" :key="index">
                      <span v-if="index > 0 && Object.keys(item.flexibleTargetPreview[index]).length > 0 && Object.keys(item.flexibleTargetPreview[index-1]).length"> AND </span>
                      <span v-if="Object.keys(item.flexibleTargetPreview[index]).length > 0">[</span>
                      <span v-for="(value, key, i) in target" :key="i">
                        <span class="inner-card-head-text">{{ key }}: <span class="inner-card-text">{{ value }} </span></span>
                      </span>
                      <span v-if="Object.keys(item.flexibleTargetPreview[index]).length > 0">]</span>
                    </span>
                  </span>
                </p>
                <p class="inner-card-head-text" v-if="Object.keys(item.flexibleExclusionPreview).length > 0">Exclusions:
                  <span class="inner-card-text">
                    [
                    <span v-for="(value, key, index) in item.flexibleExclusionPreview" :key="key">
                      <span class="inner-card-head-text">{{key}}: <span class="inner-card-text">{{value}} </span></span>
                      <span v-if="index < Object.keys(item.flexibleExclusionPreview).length - 1">, </span>
                    </span>
                    ]
                  </span>
                </p>
                <div v-if="Object.keys(extraPropertiesInTargets).length > 0">
                  <Alert type="warning" banner show-icon class="targeting-warn">Some of the targeting criteria is not shown here since they are not supported</Alert>
                </div>
                <div style="margin-top: 5px;" >
                  <Select
                    :key="item.id"
                    placeholder="Select Labels"
                    multiple
                    placement="bottom-start"
                    v-model="selectedLabels[item.locationId+item.flexId]"
                    :remote-method="query => getLabels(query, item.locationId+item.flexId)"
                    filterable
                    remote
                    :loading="labelsLoading"
                  >
                    <Option
                      v-for="(label, labelIndex) in labels[item.locationId+item.flexId]"
                      :value="label.id"
                      :key="labelIndex"
                      :label="label.name"
                    >
                    </Option>
                  </Select>
                </div>
              </div>
          </Panel>
        </Collapse>
        <div id="card-button">
        <Button type="default" @click="deleteTarget()" :disabled="disableIcons"><i class="fa fa-trash icons preview-icons" :class="{ 'disabled-icon': disableIcons }"></i>Remove All</Button>
        </div>
      </div>
      </Panel>
    </Collapse>
    <Modal
      v-model="saveAlert"
      title="Alert"
      cancel-text=""
    >
      <p>{{saveAlertMessage}}</p>
    </Modal>
  </section>
</template>

<script>
import { Button, Spin, Collapse, Panel, Modal, Alert, Select, Option } from "iview";
import { tooltip } from "vue-strap";
import { mapState } from "vuex";
import { APIService } from "../../ApiService";
import { EventBus } from "../.././EventBus.js";
import * as fbEnums from "../../Constants/FacebookEnums.js";
import CLHelpermethods from "../../Mixins/CLHelperMethods.js"

const BusinessProfileId = deltax.businessProfileId;

export default {
  name: "AudiencePreview",
  props: {
    bpseId: Number,
    targetMode: {
      type: String
    }
  },
  components: {
    Button,
    Spin,
    tooltip,
    Collapse,
    Panel,
    Modal,
    Alert,
    Select,
    Option
  },
  mixins: [CLHelpermethods],
  data: function () {
    return {
      collapseOpen: '1',
      targetName: "",
      favourite: false,
      showCardBody: true,
      showInnerCardBody: [],
      editingItem: -1,
      saveAlert: false,
      saveAlertMessage: "",
      removeIconContent: "Remove",
      labels: {},
      selectedLabels: {},
      targetSaveSpinner: null,
      labelsSaved: false,
      labelsLoading: false,
      fetchedLabelsData: [],
      labelsExisted: false
    }
  },
  methods: {
    formatNumberWithComma (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    showSaveAlert (message) {
      this.saveAlertMessage = message;
      this.saveAlert = true;
    },
    enablePreviewInput (item, index) {
      this.targetName = item.targetName;
      this.editingItem = index;
      this.$nextTick(function () {
        document.getElementById('preview-title-input').focus();
      })
    },
    disablePreviewInput (index) {
      this.$store.commit('update_TargetName', { targetName: this.targetName, index: index });
      this.editingItem = -1;
    },
    // toggleInnerCardBody (index) {
    //   this.$set(this.showInnerCardBody, index, !this.showInnerCardBody[index]);
    // },
    toggleInnerCardBody (index) {
      let idx = this.showInnerCardBody.indexOf(index.toString());
      if (idx === -1) {
        this.showInnerCardBody.push(index.toString());
      } else {
        this.showInnerCardBody.splice(idx, 1);
      }
    },
    toggleFavourite (index) {
      this.$store.commit('set_FavouriteTarget', { index: index });
      this.saveTarget(index, 'fav');
    },
    deleteTarget (index) {
      this.$store.commit('delete_Targets', { index: index });
    },
    async saveTarget (index, clickType) {
      let targetString = await this.$store.dispatch('generate_TargetString', this.$store.state.facebookTargetCreationData[index]);
      let targetName = this.$store.state.facebookTargetCreationData[index].targetName;
      let estimatedReach = this.$store.state.facebookTargetCreationData[index].estimatedReach;
      let isFavoriteTarget = this.$store.state.facebookTargetCreationData[index].isFavourite;
      let minAge = this.$store.state.facebookTargetCreationData[index].minAge;
      let maxAge = this.$store.state.facebookTargetCreationData[index].maxAge;
      // api call by validating target id
      let targetId = this.$store.state.facebookTargetCreationData[index].savedTargetId;
      var targetKey = this.$store.state.facebookTargetCreationData[index].locationId + this.$store.state.facebookTargetCreationData[index].flexId
      const targetObj = {
        businessProfilesSearchEngineId: this.bpseId,
        "Name": targetName,
        targetString,
        estimatedReach,
        isFavoriteTarget,
        locationTargetType: 0,
        IsCreatedOnDx: 1,
        minAge,
        Agencyid: parseInt(BusinessProfileId),
        Advertiserid: parseInt(BusinessProfileId),
        BusinessProfileId: parseInt(BusinessProfileId)
      }
      if (maxAge !== "No Max") {
        targetObj.maxAge = maxAge;
      }
      this.targetSaveSpinner = index;
      if (this.targetMode === "Edit Existing" && targetId) {
        targetObj.targetId = targetId;
        await APIService.updateFacebookTarget(targetObj);
        let labelsData = this.fetchLabelsForTarget(targetKey, targetObj.targetId);
        if (labelsData.length) {
          let response = await APIService.addTargetLabels(labelsData, true);
          this.labelsSaved = !!response;
        }
        if (clickType === 'fav' && isFavoriteTarget) {
          this.showSaveAlert("Target updated successfully and added to favourite");
        }
        if (clickType === 'fav' && !isFavoriteTarget) {
          this.showSaveAlert("Target updated successfully and removed from favourite");
        }
        if (labelsData.length && !this.labelsSaved) {
          this.showSaveAlert("Target updated successfully, but failed to apply labels to the target");
        }
        if (clickType === 'save') {
          this.showSaveAlert("Target updated successfully");
        }
        this.targetSaveSpinner = null;
        this.$emit("update-target", { "id": targetId, "targetString": targetString, "targetName": targetName });
        EventBus.$emit('facebookTargetCreation-updateSavedTarget', this.bpseId, this.$store.state.facebookTargetCreationData[index], targetString, true);
      } else {
        let response = await APIService.createFacebookTarget(targetObj);
        if (response) {
          this.$store.commit('set_savedTargetId', { targetId: response, index: index });
          let labelsData = this.fetchLabelsForTarget(targetKey, response);
          if (labelsData.length) {
            let response = await APIService.addTargetLabels(labelsData, false);
            this.labelsSaved = !!response;
          }
          if (clickType === 'fav' && isFavoriteTarget) {
            this.showSaveAlert("Target saved successfully and added to favourite");
          } else if (labelsData.length && !this.labelsSaved) {
            this.showSaveAlert("Target saved successfully, but failed to apply labels to the target");
          } else {
            this.showSaveAlert("Target saved successfully");
          }
          this.targetSaveSpinner = null;
          this.$emit("add-saved-target", { "id": response, "name": targetName, "estimatedReach": estimatedReach, "targetString": targetString, "isFavoriteTarget": isFavoriteTarget, "type": 0 });
          EventBus.$emit('facebookTargetCreation-updateSavedTarget', this.bpseId, this.$store.state.facebookTargetCreationData[index], targetString, false);
        } else {
          this.targetSaveSpinner = null;
          this.showSaveAlert("Unable to save target!!");
        }
      }
    },
    fetchLabelsForTarget (targetKey, targetId) {
      let labelsData = []
      if (this.selectedLabels[targetKey].length) {
        this.selectedLabels[targetKey].forEach((id) => {
          let temp = {
            "TargetId": targetId,
            "LableId": id
          }
          labelsData.push(temp);
        })
      } else if (this.labelsExisted && this.targetMode === "Edit Existing") {
        let temp = {
          "TargetId": targetId,
          "LableId": 0
        }
        labelsData.push(temp);
      }
      return labelsData;
    },
    getLocals (languages) {
      let languagesPreview = [];
      languages.forEach(key => {
        this.facebookLanguages.forEach((language) => {
          if (language.key === key) {
            languagesPreview.push(language.name);
          }
        })
      });
      return languagesPreview.join(', ');
    },
    saveTargetsAsCSV () {
      var transformedTargetData = this.transformTargetDataForDownload();
      var columns = Object.keys(transformedTargetData[0]);
      var rows = [];
      transformedTargetData.forEach(value => {
        rows.push(Object.values(value))
      });

      // Construct the CSV content
      var csvContent = columns.join(",") + "\n";
      rows.forEach(function (row) {
        csvContent += row.join(",") + "\n";
      });

      // Remove non-standard characters
      // eslint-disable-next-line no-control-regex
      csvContent = csvContent.replace(/[^\x00-\x7F]/g, "");

      // Create a Blob with the CSV content
      var blob = new Blob([csvContent], {
        type: "text/csv;charset=utf-8;"
      });

      // Create a temporary anchor element to initiate the download
      var link = document.createElement("a");
      link.setAttribute("href", URL.createObjectURL(blob));

      let currentDateTime = new Date().toLocaleString().split(',');
      let currentDate = currentDateTime[0].replaceAll('/', '-')
      let currentTime = currentDateTime[1].split(' ')[1].replaceAll(':', '');
      let fileName = 'DX-BP' + businessProfileId + '-AudiencePreviews-' + currentDate + '_' + currentTime;
      link.setAttribute("download", fileName + ".csv");

      // Simulate a click on the anchor element to start the download
      link.click();
      link.remove()
    },
    transformTargetDataForDownload () {
      var transformedListOfObjects = []
      this.facebookTargetCreationData.forEach((target) => {
        let targetData = {}
        targetData['Target Name'] = target.targetName.replace(/,/g, '');
        targetData["Estimated Reach"] = target.estimatedReach >= 0 ? `${target.lowerBoundEstimate} - ${target.upperBoundEstimate}` : 'Not Available';
        targetData["Age"] = `${target.minAge} - ${target.maxAge}`;
        targetData["Gender"] = target.genderPreview;
        targetData["Included Locations"] = target.locationsPreview.replace(/,/g, '|');
        targetData["Exlcuded Locations"] = target.excludedLocationsPreview.replace(/,/g, '|');
        targetData["Languages"] = target.languagesPreview.replace(/,/g, '|');
        targetData["Relationship Status"] = target.relationshipStatusPreview.replace(/,/g, '|');
        targetData["Custom Audiences"] = target.customAudiencePreview.join('|').replace(/,/g, '');
        targetData["Excluded Custom Audiences"] = target.excludeCustomAudiencePreview.join('|').replace(/,/g, '');
        targetData["Flexible targets"] = target.flexibleTargetPreview.map(obj => {
          const objString = Object.entries(obj).map(([key, value]) => `${key}: ${value}`).join(" ; ");
          return `[${objString}]`;
        }).join(" AND ").replace(/,/g, ' | ');
        targetData["Exclusions"] = Object.entries(target.flexibleExclusionPreview).map(([key, value]) => `${key}: ${value}`)
          .join(" ; ").replace(/,/g, ' | ');

        let labelsData = this.selectedLabels[target.locationId + target.flexId];
        let labelsNames = labelsData.map(id => {
          const obj = this.fetchedLabelsData.find(item => item.id === id);
          return obj ? obj.name : null;
        });
        targetData["Labels"] = labelsNames.join(' | ');

        transformedListOfObjects.push(targetData);
      })
      return transformedListOfObjects;
    },
    showEstimateReach (estimatedReach) {
      if (estimatedReach.lowerBoundEstimate <= 0 || estimatedReach.upperBoundEstimate <= 0) {
        return 'NA'
      } else {
        return `${this.formatNumberWithComma(estimatedReach.lowerBoundEstimate)} - ${this.formatNumberWithComma(estimatedReach.upperBoundEstimate)}`
      }
    },
    async getLabels (query, index) {
      this.labelsLoading = true;
      clearTimeout(this.searchTimeout);

      this.searchTimeout = setTimeout(async () => {
        try {
          let response = await APIService.getLabels('Name', fbEnums.filterConditions.LIKE, query);
          this.$set(this.labels, index, response.labels);
          this.setFetchedLabelsData(response.labels);
          this.labelsLoading = false;
        } catch (error) {
          console.log(error);
        }
      }, 500);
    },
    setGlobalLabels (value) {
      this.labels = {}
      this.selectedLabels = {}
      this.setFetchedLabelsData(value);
      this.$store.state.facebookTargetCreationData.forEach(item => {
        this.$set(this.labels, item.locationId + item.flexId, value);
        let ids = value.map(l => l.id);
        this.labelsExisted = !!ids.length;
        this.$nextTick(() => {
          this.$set(this.selectedLabels, item.locationId + item.flexId, ids);
        })
      });
    },
    setFetchedLabelsData (newLabelsFetched) {
      newLabelsFetched.forEach(l => {
        if (!this.fetchedLabelsData.some(label => label.id === l.id)) {
          this.fetchedLabelsData.push(l);
        }
      });
    }
  },
  mounted () {
    if (this.targetMode === "Edit Existing") {
      this.removeIconContent = "Cannot remove while editing target"
    }
    // this.$store.dispatch("generate_TargetAudience");
  },
  computed: {
    ...mapState([
      "facebookLanguages",
      "facebookTargetLoading",
      "extraPropertiesInTargets",
      "businessProfileId",
      "globalLabels",
      "facebookFlexibleSpecData"
    ]),
    facebookTargetCreationData () {
      return this.$store.state.facebookTargetCreationData.map(item => {
        // Extract and cutomize data to display in the panel
        let locations = [...item.geoLocations.cities.map(city => city.name), ...item.geoLocations.regions.map(region => region.name), ...item.geoLocations.geo_markets.map(geoMarket => geoMarket.name),
          ...item.geoLocations.neighborhoods.map(neighborhood => neighborhood.name), ...item.geoLocations.custom_locations.map(loc => loc.name)];
        if (item.geoLocations.countries.length > 0) {
          item.geoLocations.countries.forEach((country) => {
            if (!country.country_name) {
              locations.push(country.key);
            } else {
              locations.push(country.country_name);
            }
          })
        }
        item.locationsPreview = locations.length ? locations.join(', ') : '';
        let excludedLocations = [...item.excludedGeoLocations.cities.map(city => city.name), ...item.excludedGeoLocations.regions.map(region => region.name), ...item.excludedGeoLocations.geo_markets.map(geoMarket => geoMarket.name),
          ...item.excludedGeoLocations.neighborhoods.map(neighborhood => neighborhood.name), ...item.excludedGeoLocations.custom_locations.map(loc => loc.name)];
        if (item.excludedGeoLocations.countries.length > 0) {
          item.excludedGeoLocations.countries.forEach((country) => {
            if (!country.country_name) {
              excludedLocations.push(country.key);
            } else {
              excludedLocations.push(country.country_name);
            }
          })
        }
        item.excludedLocationsPreview = excludedLocations.length ? excludedLocations.join(', ') : '';
        item.genderPreview = this.getGender(item.gender);
        item.languagesPreview = this.getLocals(item.locales);
        item.relationshipStatusPreview = this.getRelationshipStatus(item.relationship_statuses);
        item.locationTypesPreview = item.location_types.join(', ');
        item.customAudiencePreview = item.custom_audiences.length ? item.custom_audiences.map(audience => audience.name || audience.id) : [];
        item.excludeCustomAudiencePreview = item.excluded_custom_audiences.length ? item.excluded_custom_audiences.map(audience => audience.name || audience.id) : [];

        let flexibleTargetPreview = [];

        for (let flexibleSpec in item.flexible_spec) {
          if (Object.keys(item.flexible_spec[parseInt(flexibleSpec)]).length > 0) {
            flexibleTargetPreview[flexibleSpec] = {}
            for (let field in item.flexible_spec[flexibleSpec]) {
              if (Array.isArray(item.flexible_spec[flexibleSpec][field])) {
                let names = item.flexible_spec[flexibleSpec][field].map(obj => obj.name);
                if (names.length > 0) {
                  flexibleTargetPreview[flexibleSpec][field] = names.join(', ');
                }
              }
            }
          }
        }
        item.flexibleTargetPreview = flexibleTargetPreview;

        let flexibleExclusionPreview = {};
        for (let field in item.exclusions) {
          if (Array.isArray(item.exclusions[field])) {
            // flexibleExclusionPreview[field] = item.exclusions[field].map(obj => obj.name).join(', ');
            let names = item.exclusions[field].map(obj => obj.name);
            if (names.length > 0) {
              flexibleExclusionPreview[field] = names.join(', ');
            }
          }
        }
        item.flexibleExclusionPreview = flexibleExclusionPreview;
        return item;
      });
    },
    disableIcons () {
      if (this.targetMode === "New") {
        return false;
      }
      return true;
    }
  },
  watch: {
    targetMode: {
      handler (value) {
        if (value !== 'Edit Existing') {
          this.removeIconContent = 'Remove'
        }
      },
      deep: true
    },
    facebookTargetCreationData: {
      handler (newVal, oldVal) {
        if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
          this.setGlobalLabels(this.globalLabels);
        }
      },
      deep: true
    }
  }
};

</script>
<style>
#inner-audience-collapse {
  width: 100%;
  margin-bottom: 5px;
  border-radius: 0px;
}
#inner-audience-collapse>.ivu-collapse-item>.ivu-collapse-header {
    line-height: 38px;
    padding: 4px;
    color: #666;
    cursor: pointer;
    position: relative;
    border-bottom: 1px solid transparent;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}
</style>
<style scoped>
::v-deep .ivu-btn-primary {
  color: #fff;
  background-color: #51b848;
  border-color: #51b848;
}
::v-deep i.fa.fa-download {
    font-size: 13px !important;
}
::v-deep .ivu-spin-dot {
    position: relative;
    display: block;
    border-radius: 50%;
    background-color: #444444 !important;
    width: 10px;
    height: 10px;
    -webkit-animation: ani-spin-bounce 1s 0s ease-in-out infinite;
    animation: ani-spin-bounce 1s 0s ease-in-out infinite;
}
::v-deep .ivu-spin {
    color: #444444 !important;
    vertical-align: middle;
    text-align: center;
    padding: 4px;
}
::v-deep .ivu-collapse {
  width: 95%;
}
::v-deep .ivu-icon-ios-arrow-forward {
  display: none;
}
::v-deep .ivu-collapse-header {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 10px;
}
::v-deep .ivu-collapse-content {
  border-top: 2px solid #51b848;
}
::v-deep .ivu-btn>span {
  font-size: 13px;
}
::v-deep .ivu-alert-icon{
  margin-top: 2px;
}
::v-deep .ivu-alert-message{
  margin-left: 24px;
}

.inner-card-text {
  font-weight: normal;
  font-style: italic;
  margin: 0px 0px;
  color: #5f5f5f;
}
.inner-card-head-text {
  font-weight: bold;
  margin: 0px 0px;
  font-size: 11px;
  color: #000000;
}
#card-button>.ivu-btn {
  padding-left: 4px;
}
#card-button>.ivu-btn:focus {
  box-shadow: none !important;
}
#card-button {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.info-icon-tooltip {
  margin-right: 5px;
  pointer-events: none;
}
.preview-icons{
  margin: 1px 14px 0px 13px;
  cursor: pointer;
  padding-left: 5px;
  pointer-events: auto;
}
#icon-area{
  display: flex;
  flex-grow: 1;
}
#preview-title-input {
  height: 20px;
  width: 150px;
}
#edit-icon {
  margin: 3px 8px;
}
.title-text {
  font-weight: bold;
  margin: 0;
  color: #000000;
}
.inner-title-text {
  font-weight: normal;
  margin: 0px 0px;
  color: #000000;
}
.title-icons {
  margin: 2px 8px;
  padding: 8px 8px;
}
.icons {
  font-size: 13px;
  cursor: pointer;
  color: #4a4a4a;
}
#inner-title-area {
  display: flex;
  width: 80%;
  max-width: 230px;
  overflow-wrap: anywhere;
}
#inner-card-title {
  display: flex;
  flex-grow: 1;
  cursor: pointer;
}
#inner-card {
  border: 1px solid #bababa;
  width: 100%;
  margin-bottom: 5px;
}
#title-area {
  width: 80%;
}
#icon-area {
  float: left;
  justify-content: right;
}
#download-icon {
  display: flex;
  align-items: center;
}
#card-title {
  display: flex;
  flex-grow: 1;
  cursor: pointer;
  justify-content: space-between
}
#audience-preview-comp {
  font-family: "sans-serif", sans-serif;
  font-size: 13px;
  font-weight: normal;
  color: #000000;
  margin: 30px 10px;
  position: relative;
}
.disabled-icon {
  cursor: not-allowed;
  color: lightgray;
}
.targeting-warn {
font-size: 11px;
margin-bottom: 0px;
margin-top: 5px;
}
</style>
