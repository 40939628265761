<template>
  <div class="hot-div" style="width: 100%; height: 530px; overflow: hidden;" :key="rerenderKey">
		<hot-table class="ad-sheet-hot" ref="hot" :settings="hotSettings"></hot-table>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex"
import { HotTable } from "@handsontable/vue"
import { columnWidths } from "../../columnConfig.js"
import Handsontable from 'handsontable'

export default {
  components: {
    "hot-table": HotTable
  },
  props: {
    tab: { default: '' },
    creativeSpec: { default: function () { return {} } },
    colHeaders: { default: function () { return [] } }, // ["HOT_Ad Name", "HOT_Call To Action"]
    displayColHeaders: { default: function () { return [] } }, // ["Ad Name", "Call To Action"]
    requiredCols: { default: function () { return {} } },
    callToAction: { default: function () { return [] } }
  },
  data: function () {
    return {
      htData: [],
      hotSettings: {},
      startRows: 2,
      uniqueId: null,
      publishedRows: [],
      publishedCreativesSize: 0,
      previewable: {},
      hardcodedCardsCount: 8,
      rerenderKey: 0,
      availableWidth: 0,
      alreadyProcessed: {},
      mainTableData: {},
      colNameIndexMap: this.colHeaders.reduce(
        (obj, colName, colIndex) => {
          obj[colName] = colIndex
          return obj
        },
        {}
      ),
      bulkUpdateQueries: {}
    }
  },
  computed: {
    ...mapState([
      "builtImageIdUrlMap",
      "campaignLauncherConfigs",
      "imageIdUrlMap",
      "mappedLineItems",
      "publishedCreatives",
      "selectedCampaignLauncherConfig",
      "selectedCampaignLauncherConfigId",
      "selectedFbObjective",
      "mediaPlanId",
      "mediaPlans",
      "publisher",
      "connectedInstagramAccountId",
      "tableData",
      "imagesData",
      "existingTweets"
    ]),
    ...mapGetters(["getMediaPlanById"])
  },
  created () {
    this.selectedMediaPlanDetails = this.getMediaPlanById(this.mediaPlanId)
    this.hotSettings = this.buildHotSettings()
  },
  mounted () {
    this.availableWidth = document.querySelector('.hot-div').offsetWidth
  },
  watch: {
    publishedCreatives: {
      immediate: true,
      handler: function () {
        if (this.$store.state.editSheetLoadProgress == this.$store.state.adFormats.length) {
          this.$store.commit("set_editSheetLoadProgress", 0)
        }
        this.publishedCreativesSize = 0;
        if (this.publishedCreatives[this.tab]) {
          this.publishedCreatives[this.tab].forEach(element => {
            if (element["HOT_IsNotPublished"] != true) {
              this.publishedCreativesSize++
            }
          });
        }
        this.previewable = {}
        this.publishedRows = []
        let newHtData = [];
        if (this.publishedCreatives[this.tab] && this.publishedCreatives[this.tab].length) {
          let publishedRowObjects = JSON.parse(JSON.stringify(this.publishedCreatives[this.tab]))
          publishedRowObjects.forEach((rowObject, rowIndex) => {
            let htRow = []
            this.colHeaders.forEach(header => {
              if (header in rowObject) {
                htRow.push(rowObject[header])
              } else {
                htRow.push(null)
              }
            })
            newHtData.push(htRow)
            if (rowObject.HOT_IsNotPublished) {
              htRow.forEach((cellData, colIndex) => {
                this.updatePreviewColumn(rowIndex, colIndex, null, cellData, null);
              });
            }
          })
          this.publishedRows = JSON.parse(JSON.stringify(newHtData))
        } else {
          for (let i = 0; i < this.startRows; i++) {
            newHtData.push(Array(this.colHeaders.length).fill(null))
          }
        }
        this.htData = newHtData;
        var currentData = this.tableData
        if (currentData == null) {
          currentData = {}
        }
        newHtData == null ? currentData[this.tab] = [] : currentData[this.tab] = newHtData;
        this.set_tableData(currentData);

        this.$nextTick(() => {
          let updatedSettings = {
            data: this.htData
          }

          // If handsontable width is less than available width, stretch all cols equally except 1st col(preview ad)
          let totalColWidth = [...Array(this.colHeaders.length).keys()]
            .map(index => this.getColWidthByIndex(index))
            .reduce((accu, curVal) => accu + Number(curVal), 0)
          if (totalColWidth < this.availableWidth) {
            let equalSize = (this.availableWidth - 100) / (this.colHeaders.length - 1)
            updatedSettings['colWidths'] = [100, ...Array(this.colHeaders.length - 1).fill(equalSize)]
          }
          this.$refs['hot'].hotInstance.updateSettings(updatedSettings)
          this.$store.commit("set_editSheetLoadProgress", this.$store.state.editSheetLoadProgress + 1)
        })
      }
    },
    builtImageIdUrlMap () {
      if (this.builtImageIdUrlMap) {
        this.rerender()
      }
    }
  },
  methods: {
    ...mapMutations(["set_tableData"]),
    rerender () {
      this.$nextTick(() => {
        this.$refs['hot'].hotInstance.render()
      })
    },
    imageColRenderer (instance, td, row, col, prop, value, cellProperties) {
      if (row < this.publishedCreativesSize) {
        td.classList.add('disabled-cell')
      }
      value = Handsontable.helper.stringify(value)

      if (value in this.imageIdUrlMap) {
        let img = td.querySelector("img")
        if (img && img.src == this.imageIdUrlMap[value]) {
          return td
        }
        img = document.createElement('IMG')
        if (value.split('-')[0] === 'DXC') {
          img.src = this.imageIdUrlMap[value]['src']
        } else {
          img.src = this.imageIdUrlMap[value]['src']
        }
        img.classList.add('hot-img')
        Handsontable.dom.addEvent(img, 'mousedown', function (event) {
          event.preventDefault()
        })

        Handsontable.dom.empty(td)
        td.appendChild(img)
      } else if (value.includes("http") || value.includes("www")) {
        let img = td.querySelector("img")
        if (img && img.src == value) {
          return td
        }
        img = document.createElement('IMG')
        img.src = value;
        img.classList.add('hot-img')
        Handsontable.dom.addEvent(img, 'mousedown', function (event) {
          event.preventDefault()
        })
        Handsontable.dom.empty(td)
        td.appendChild(img)
      } else {
        Handsontable.renderers.TextRenderer.apply(this, arguments)
      }
      return td
    },
    previewBtnRenderer (instance, td, row, col, prop, value, cellProperties) {
      if (!(td.firstElementChild)) {
        let previewTdWrapper = document.createElement('div')
        previewTdWrapper.classList.add('preview-td-wrapper')

        let previewIcon = document.createElement('span')
        previewIcon.classList.add('eye')
        previewIcon.innerHTML = '<i class="fa fa-check preview-icon"></i>'
        previewTdWrapper.appendChild(previewIcon)
        td.appendChild(previewTdWrapper)
      }
      td.firstElementChild.classList.remove('disabled-cell')
      if (value !== "true") {
        td.firstElementChild.innerHTML = '<span class="eye"><i class="fa fa-times preview-icon"></i></span>'
        td.style.cursor = 'not-allowed';
        td.firstElementChild.classList.add('preview-icon-disabled')
      } else {
        td.style.cursor = 'default';
        if (row < this.publishedCreativesSize) {
          td.firstElementChild.classList.add('disabled-cell')
        }
        td.firstElementChild.innerHTML = '<span class="eye"><i class="fa fa-check preview-icon"></i></span>'
        td.firstElementChild.classList.remove('preview-icon-disabled')
        td.style.cursor = 'not-allowed';
      }
      td.classList.add('trim')
      return td
    },
    validateTweetId (value, callBack) {
      if (value == "" || value == null) {
        callBack(true);
        return;
      }
      let self = this
      var validTweet = self.existingTweets.find(x => x.id == value)
      if (!validTweet) {
        self.$Message.error({
          background: true,
          content: "Please enter valid Tweet Id."
        })
        callBack(false)
      }
      callBack(true)
    },
    checkTweetsPresentInSameAdgroup () {
      var allRowData = this.getAllRows()
      var tweetsTargetDictionary = {}

      allRowData.forEach(row => {
        if (!tweetsTargetDictionary[row["HOT_Target"]]) {
          tweetsTargetDictionary[row["HOT_Target"]] = []
        }
        if (!row["HOT_Target"]) {
          for (var key in tweetsTargetDictionary) {
            if (row["HOT_Tweet Id"]) {
              tweetsTargetDictionary[key].push(row["HOT_Tweet Id"])
            }
          }
        } else {
          if (row["HOT_Tweet Id"]) {
            tweetsTargetDictionary[row["HOT_Target"]].push(row["HOT_Tweet Id"])
          }
        }
      })
      let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index)
      var duplicates = []
      for (var key in tweetsTargetDictionary) {
        duplicates.push([...new Set(findDuplicates(tweetsTargetDictionary[key]))])
      }
      return duplicates.flat(1).length > 0 || false;
    },
    checkIfReq (col) {
      if (this.colHeaders[col] === undefined) { return [false, -1] }
      let colName = this.colHeaders[col]
      if (col < this.requiredCols['baseLength']) {
        return (this.requiredCols['base'].has(colName)) ? [true, -1] : [false, -1]
      }
      let space = colName.lastIndexOf(' ')
      if (this.requiredCols['card'].has(colName.substring(0, space))) {
        let cardIndex = Math.floor((col - this.requiredCols['baseLength']) / this.requiredCols['cardLength'])
        return [true, cardIndex]
      }
      return [false, -1]
    },
    checkIfPreviewableTweets (previewRow, rowData) {
      let validCard =
        previewRow["card"].filter((x) => x >= this.requiredCols["card"].size)
          .length > 1;
      let validBase = previewRow["base"] >= this.requiredCols["base"].size;
      let duplicatedTweetsPresent = this.checkTweetsPresentInSameAdgroup()
      let objective = this.selectedCampaignLauncherConfig.campaign.objective
      if (objective == 6) {
        return (validCard && validBase && !duplicatedTweetsPresent);
      }
      return (validCard && validBase);
    },
    checkIfPreviewable (previewRow) {
      let validCard =
        previewRow["card"].filter((x) => x >= this.requiredCols["card"].size)
          .length > 1;
      let validBase = previewRow["base"] >= this.requiredCols["base"].size;
      return (validCard && validBase);
    },
    createNewPreviewableObj () {
      return {
        'validCard': false,
        'validBase': false,
        'card': new Array(this.hardcodedCardsCount).fill(0),
        'base': 0
      }
    },
    getColWidthByIndex (index) {
      var colWidthByPublisher = columnWidths(this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config);
      for (let [width, headerNames] of Object.entries(colWidthByPublisher)) {
        if (width === "default") { continue }
        for (let headerName of headerNames) {
          if (this.colHeaders[index].indexOf(headerName) != -1) {
            return width
          }
        }
      }
      return colWidthByPublisher["default"]
    },
    processImageUrl (row, col, oldVal, newVal, instance) {
      let img = new Image();
      img.onload = () => {
        this.alreadyProcessed[`${row}-${col}`] = true;
        instance.setDataAtCell(row, col, newVal);
      };
      img.onerror = () => {
        this.$Message.error({
          background: true,
          content: 'Please enter a valid image URL'
        })
      };
      img.src = newVal;
    },
    getMainTableData (rowNum) {
      const instance = this.$refs['hot'].hotInstance;
      const rowData = instance.getDataAtRow(rowNum);
      let dataObj = {}
      for (let i = 0; i < rowData.length; i++) {
        dataObj[this.colHeaders[i]] = rowData[i]
      }
      return dataObj;
    },
    setHotCellData (row, col, value, instance, lazyUpdate) {
      if (lazyUpdate) {
        this.bulkUpdateQueries[[row, col]] = [row, col, value]
      } else {
        setTimeout(() => { instance.setDataAtCell(row, col, value) })
      }
    },
    updatePreviewColumn (row, col, oldVal, newVal, instance, lazyUpdate = false) {
      if (!(row in this.previewable)) {
        this.previewable[row] = this.createNewPreviewableObj()
      }
      let [required, cardIndex] = this.checkIfReq(col)
      if (required) {
        if (!oldVal && newVal) {
          if (cardIndex != -1) {
            this.previewable[row]['card'][cardIndex] += 1
          } else {
            this.previewable[row]['base'] += 1
          }
        } else if (oldVal && !newVal) {
          if (cardIndex != -1) {
            this.previewable[row]['card'][cardIndex] -= 1
          } else {
            this.previewable[row]['base'] -= 1
          }
        }
      }
      if (instance) {
        const isPreviewable = this.checkIfPreviewableTweets(this.previewable[row], this.htData[row]);
        // var isExistingTweets = isCombinationValid && (this.selectedCampaignLauncherConfig.campaign.objective == 6)
        if (isPreviewable && !(this.htData[row] && this.htData[row][0] === 'true')) {
          this.htData[row][0] = 'true'
          this.setHotCellData(row, 0, 'true', instance, lazyUpdate)
        } else if (!isPreviewable && !(this.htData[row] && this.htData[row][0] === 'false')) {
          this.htData[row][0] = 'false'
          this.setHotCellData(row, 0, 'false', instance, lazyUpdate)
        }
      }
    },
    buildHotSettings () {
      var self = this;
      return {
        data: this.htData,
        startCols: this.colHeaders.length,
        startRows: this.startRows,
        minSpareRows: 100,
        manualColumnResize: true,
        autoRowSize: true,
        autoColumnSize: true,
        viewportColumnRenderingOffset: 35,
        colHeaders: function (index) {
          var header = self.colHeaders[index];
          var displayName = self.displayColHeaders[index];
          var displayText = displayName;
          if (self.requiredCols.base.has(header)) {
            displayText += "<span style='color:#b94a48'>*</span>";
          }
          return displayText;
        },
        rowHeights: 74,
        // stretchH: 'all',
        colWidths: this.getColWidthByIndex,
        // comments: true,
        afterRenderer: (td, row, col, prop, value, cellProperties) => {},
        beforeRenderer: (td, row, col, prop, value, cellProperties) => {},
        columns: (index) => {
          if (index >= this.colHeaders.length) { return }
          let settings = {}
          if (
            this.colHeaders[index].indexOf("HOT_Image/Video") != -1 ||
            this.colHeaders[index].indexOf("HOT_Image") != -1 ||
            this.colHeaders[index].indexOf("HOT_Video") != -1 ||
            this.colHeaders[index].indexOf("HOT_Intro Card: Image") != -1
          ) {
            settings.renderer = this.imageColRenderer;
          } else if (index == 0 && this.colHeaders[index] == "HOT_Is Valid") {
            settings.readOnly = true
            settings.copyable = false
            settings.editor = false
            settings.renderer = this.previewBtnRenderer
          } else if (this.colHeaders[index] == "HOT_Call To Action") {
            settings.type = 'dropdown'
            settings.source = this.callToAction
            settings.allowInvalid = false
          } else if (this.colHeaders[index].indexOf("HOT_Destination URL") != -1) {
            settings.validator = this.validateUrls
            settings.allowInvalid = false
          } else if (this.colHeaders[index] == "HOT_Targets") {
            settings.validator = this.validateTargets
            settings.allowInvalid = true
          } else if (this.colHeaders[index].indexOf("HOT_Tweet Id") != -1) {
            settings.validator = this.validateTweetId
            settings.allowInvalid = false
          }
          return settings
        },
        cells: (row, col, prop) => {
          const savedRowsCount = (this.publishedCreatives[this.tab]) ? this.publishedCreatives[this.tab].length : 0;
          let cellProperties = {}
          if (this.publishedCreatives[this.tab] && row < this.publishedCreativesSize) {
            cellProperties.readOnly = 'true'
            cellProperties.className = 'disabled-cell-text'
          }
          return cellProperties
        },
        beforePaste: (data, coords) => {
        },
        beforeColumnResize: (newSize, column, isDoubleClick) => {
          return isDoubleClick && newSize > window.innerWidth ? window.innerWidth : newSize
        },
        beforeAutofill: (st, ed, data) => {
        },
        beforeChange: (changes, source) => {
          let instance = this.$refs['hot'].hotInstance
          let requireRerender = false;
          this.bulkUpdateQueries = {};
          for (let index = 0; index < changes.length; index++) {
            let change = changes[index];
            let [row, col, oldVal, newVal] = change;
            if (row < this.publishedCreativesSize || col >= this.colHeaders.length) {
              return;
            }
            if (
              newVal &&
              (this.colHeaders[col].indexOf("HOT_Image/Video") != -1 ||
              this.colHeaders[col].indexOf("HOT_Video") != -1 ||
              this.colHeaders[col].indexOf("HOT_Image") != -1)
            ) {
              if (newVal.includes("http") || newVal.includes("www")) {
                if (this.alreadyProcessed[`${row}-${col}`]) {
                  this.alreadyProcessed[`${row}-${col}`] = false;
                } else {
                  changes[index] = null;
                  this.processImageUrl(row, col, oldVal, newVal, instance);
                  continue
                }
              } else {
                let errorMessage = null
                if (!(newVal in this.imageIdUrlMap)) {
                  errorMessage = "Image/Video not found."
                } else if (this.colHeaders[col] === "HOT_Video" && newVal.split('-')[0] === 'DXC') {
                  errorMessage = "Please select a Video."
                }
                if (errorMessage !== null) {
                  this.$Message.error({
                    background: true,
                    content: errorMessage
                  })
                  changes[index] = null
                  continue;
                }
              }
            }
            // this.updatePreviewColumn(row, col, oldVal, newVal, instance, true);
          }

          let bulkUpdatesArray = Object.values(this.bulkUpdateQueries);
          setTimeout(() => {
            instance.setDataAtRowProp(bulkUpdatesArray);
          })
          if (requireRerender) {
            this.rerender();
          }
        },
        afterGetColHeader: function (col, TH) {
          // das
        },
        afterChange: (changes, source) => {
          if (!changes) {
            return null;
          }
          let instance = this.$refs['hot'].hotInstance
          for (let index = 0; index < changes.length; index++) {
            let change = changes[index];
            let [row, col, oldVal, newVal] = change;
            this.updatePreviewColumn(row, col, oldVal, newVal, instance, true);
          }
          this.rerender();
        }
      }
    },
    /**
     * let obj = {
     *    link: "HOT_Link",
     *    call_to_action: {
     *        name: "HOT_Headline"
     *    }
     * }
     * let cardNum = 1
     *
     * 1st call:
     *    Object.keys(obj) == [link, call_to_action]
     *    foreach Object.keys(obj)
     *       1. obj["link"] == "HOT_Link" (not an object type)
     *             - set obj["link"] = HOT_Link 1
     *
     *       2. obj["call_to_action"] == { name: "HOT_Headline" } (it is an object)
     *             - call setCarouselCardNum(obj["call_to_action"], cardNum)
     *
     * 2nd call:
     *    obj = {
     *       name: "HOT_Headline"
     *    }
     *
     *    foreach Object.keys(obj)  // ["name"]
     *       1. obj["name"] == "HOT_Headline" (not an object type)
     *          - set obj["name"] = HOT_Headline 1
     */
    setCarouselCardNum (obj, cardNum) {
      Object.keys(obj).forEach(key => {
        if (obj[key] !== null && typeof obj[key] === "object") {
          this.setCarouselCardNum(obj[key], cardNum);
          return;
        }
        if (typeof obj[key] === "string" && obj[key].startsWith("HOT_")) {
          obj[key] = `${obj[key]} ${cardNum}`;
        }
      });
    },
    recurse (obj, headerValMap) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] !== null && typeof obj[key] === 'object') {
          this.recurse(obj[key], headerValMap)
          return
        }
        if (typeof obj[key] === 'string' && obj[key].startsWith("HOT_")) {
          if (obj[key].startsWith("HOT_Image/Video")) {
            if (headerValMap[obj[key]].startsWith("http://") || headerValMap[obj[key]].startsWith("https://")) {
              // use picture
              obj[key] = headerValMap[obj[key]]
            } else {
              // use image hash
              obj["image_hash"] = headerValMap[obj[key]]
              delete obj[key];
            }
          } else {
            obj[key] = headerValMap[obj[key]]
          }
        }
      })
    },
    saveSetupData (newData) {
      const rowNum = this.creativeSetupRowNum
      this.creativeSetupData[rowNum] = newData;
      this.$emit("onSetAdSheetAlert", {
        show: false,
        tab: this.tab,
        message: ""
      });
    },
    getImageHashOrUrl (key) {
      return this.imageIdUrlMap[key]['hash'] ? this.imageIdUrlMap[key]['hash'] : this.imageIdUrlMap[key]['src']
    },
    regularSpec (row) {
      let headerValMap = {}
      let creativeSpec = JSON.parse(JSON.stringify(this.creativeSpec));
      let spec = JSON.parse(JSON.stringify(this.creativeSpec))
      for (let i = 0; i < this.colHeaders.length; i++) {
        if ((this.colHeaders[i] === 'HOT_Image/Video' || this.colHeaders[i] === 'HOT_Video') && row[i]) {
          if (row[i].split('-')[0] === 'DXC') {
            spec = ('image' in spec) ? spec['image'] : spec;
            headerValMap[this.colHeaders[i]] = this.getImageHashOrUrl(row[i]);
          } else if (row[i].includes("http") || row[i].includes("www")) {
            spec = spec['image']
            headerValMap[this.colHeaders[i]] = row[i]
          } else {
            spec = spec['video']
            headerValMap[this.colHeaders[i]] = this.imageIdUrlMap[row[i]]['src']
            headerValMap['HOT_SearchEngineVideoId'] = this.imageIdUrlMap[row[i]]['SearchEngineVideoId']
          }
        } else {
          headerValMap[this.colHeaders[i]] = row[i]
        }
        if (this.colHeaders[i] === 'HOT_Tweet Id') {
          headerValMap['HOT_Tweet Text'] = this.existingTweets.find(x => x.id == row[i]).full_text;
        }
      }
      this.recurse(spec, headerValMap)
      return spec
    },
    getPreviewableRows () {
      this.uniqueId = this.publishedCreativesSize + 1
      let validSpec = []; let validRows = []
      let instance = this.$refs['hot'].hotInstance
      for (let [rowNum, previewRow] of Object.entries(this.previewable)) {
        let rowData = instance.getDataAtRow(rowNum) // [true, ad name, Dlow, null]
        if (this.checkIfPreviewable(previewRow) && rowData[0] == 'true') {
          let rowDataObj = this.buildRowDataObj(rowData, rowNum)
          let adNameIndex = this.colHeaders.indexOf("HOT_Ad Name")
          let adName = (adNameIndex != -1 && rowData[adNameIndex]) ? rowData[adNameIndex] : ""
          let creativeSpec = this.buildCreativeSpec(rowData, rowNum);
          let spec = {
            'hotRowId': `${this.tab}-${this.uniqueId++}`,
            'adName': `${adName}`,
            'creative': creativeSpec["creative"]
          }
          validSpec.push(spec)
          validRows.push(rowDataObj)
        }
      }
      return [validSpec, validRows]
    },
    getCreativeStructure (tableData) {
      this.uniqueId = this.publishedCreativesSize + 1
      let validSpec = []; let validRows = []
      for (let [rowNum, previewRow] of Object.entries(this.previewable)) {
        if (this.checkIfPreviewable(previewRow)) {
          let rowData = tableData[rowNum]
          let rowDataObj = this.buildRowDataObj(rowData, rowNum)
          let adNameIndex = this.colHeaders.indexOf("HOT_Ad Name")
          let adName = (adNameIndex != -1 && rowData[adNameIndex]) ? rowData[adNameIndex] : ""
          let creativeSpec = this.buildCreativeSpec(rowData, rowNum);
          let spec = {
            'hotRowId': `${this.tab}-${this.uniqueId++}`,
            'AdName': `${adName}`,
            'Creative': creativeSpec["creative"]
          }
          validSpec.push(spec)
          validRows.push(rowDataObj)
        }
      }
      return [validSpec, validRows]
    },
    buildCreativeSpec (row, index) {
      return this.regularSpec(row)
    },
    getAllRows () {
      this.uniqueId = this.publishedCreativesSize + 1
      let validRows = []
      let instance = this.$refs['hot'].hotInstance
      let rowNum = 0;
      var gridData = instance.getData();
      var cleanedGridData = [];
      gridData.forEach((rowData, index) => {
        if (!instance.isEmptyRow(index)) {
          cleanedGridData.push(rowData);
        }
      })
      for (let rowData of cleanedGridData) {
        let rowDataObj = this.buildRowDataObj(rowData, rowNum)
        validRows.push(rowDataObj)
        rowNum++;
      }
      rowNum = 0;
      validRows.forEach(elem => {
        if (rowNum < this.publishedCreativesSize) {
          elem["HOT_IsNotPublished"] = false;
        } else {
          elem["HOT_IsNotPublished"] = true;
        }
        rowNum++;
      });
      return validRows;
    },
    buildRowDataObj (rowData, rowNum) {
      let rowObj = Object.assign(
        ...rowData.map((cellValue, index) => ({
          [this.colHeaders[index]]: cellValue
        }))
      );
      return rowObj;
    },
    getRowValueForColumn (rowData, headerName) {
      var indexValue = this.colHeaders.indexOf(headerName);
      return indexValue != -1 && rowData[indexValue] ? rowData[indexValue] : null
    },
    validateUrls (value, callBack) {
      if (value == "" || value == null) {
        callBack(true);
        return;
      }
      var self = this
      if (value.match(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/)) {
        callBack(true);
        return;
      } else {
        self.$Message.error({
          background: true,
          content: "Please enter a valid URL"
        })
        callBack(false)
      }
      callBack(true)
    },
    validateTargets (value, callBack) {
      if (value == "" || value == null) {
        callBack(true);
        return;
      }
      let self = this
      let targets = value.split(",")
      let targetNotMapped = []
      targets.forEach(function (target) {
        let splitTarget = target.split("-")
        if (target != "" && (splitTarget.length != 3 || splitTarget[0] != 'DXT' || splitTarget[1] != Number.parseInt(deltax.businessProfileId).toString(36) || isNaN(splitTarget[2]))) {
          self.$Message.error({
            background: true,
            content: "Target '" + target + "' is invalid"
          })
          callBack(false)
        } else {
          let flag = false
          if (target != "") {
            flag = self.mappedLineItems.map(x => {
              return x.targets
            }).flat().includes(parseInt(splitTarget[2]))
            if (!flag) {
              targetNotMapped.push(target)
            }
          }
        }
      })
      if (targetNotMapped.length > 0) {
        self.$Message.error({
          background: true,
          content: "Targets " + targetNotMapped.join(", ") + " is not mapped to a line item"
        })
        callBack(false)
      }
      callBack(true)
    }
  }
}
</script>

<style>
/* .ht_clone_left table.htCore {
  box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.2);
} */
.hot-img {
  display: block;
  padding: 2px;
  margin: 0 auto;
  max-height: 70px;
  max-width: 100px;
}
.ad-sheet-hot .handsontable td,
.ad-sheet-hot .handsontable tr,
.ad-sheet-hot .handsontable th {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}

.preview-td-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.preview-td-wrapper > span {
  display: inline-flex;
  flex-grow:1;
  flex-basis: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
	cursor: pointer;
}
.preview-icon {
	font-size: 18px;
	opacity: 1;
}
.preview-icon-disabled > span.eye {
	pointer-events: none;
  opacity: 0.5;
}

.disabled-cell {
	background-color: #f6f6f6 !important;
}
.disabled-cell-text {
  background-color: #f6f6f6 !important;
  color: #bbb !important;
}
.post-cell {
  cursor: not-allowed;
}
.trim {
  margin: 0 !important;
  padding: 0 !important;
}
.handsontable td.htInvalid {
  background-color: white !important;
  border: 1px solid red
}
</style>

<style src="../../../../../../../node_modules/handsontable/dist/handsontable.full.css"></style>
