<template>
    <div>
        <div v-if="adgroupsPivot.length > 0" class="adwords-pivot">
        <div class="title-sticky">
            <label class="element-label">Adgroups and Ads</label>
        </div>
        <div class="adwords-table">
            <table class="table table-bordered">
            <thead>
                <th
                v-for="(item, index) in adwordsEnums.adwordsColumns"
                :key="index"
                style="border:1px solid #ddd;padding:10px"
                :style="`width:${item.width}%`"
                >
                {{ item.title }}
                </th>
            </thead>
            <tbody>
              <template v-for="(adgroup, index) in adgroupsPivot">
                <tr class="ad-brief-row" :key="index" @click="toggle(index)">
                    <td>
                        {{ adgroup.name }}
                    </td>
                    <td class="data-center upper-case">
                        {{ adgroup.type }}
                    </td>
                    <td>
                        {{ adgroup.adExtensions }}
                    </td>
                    <td>
                      {{ adgroup.adsCount }}
                    </td>
                </tr>
                <tr :key="index+'a'" class="ad-preview-row" v-if="expandedRows.includes(index)">
                  <td colspan="4">
                    <table class="table table-bordered">
                      <thead>
                        <th class="preview-table-header" style="width:20%">Keywords</th>
                        <th class="preview-table-header" style="width:80%">
                          <span class="ad-header">Ad </span>
                          <span class="preview-icons">
                            <Icon  class="preview-icon" :class="{ 'active': isMobilePreview , 'disable':!isMobilePreview}" size="15" type="md-phone-portrait" @click="togglePreview(true)" />
                            <Icon  class="preview-icon" :class="{ 'active': !isMobilePreview , 'disable':isMobilePreview}" size="15"  type="md-laptop" @click="togglePreview(false)" />
                          </span>
                        </th>
                      </thead>
                      <tbody>
                        <tr>
                          <td :rowspan="adgroup.adsCount + 1">
                            <template v-for="(keyword, kwIndex) in adgroup.keywords">
                              <EntityStatus
                              :key="kwIndex"
                              :name="keyword.text"
                              :matchType="adwordsEnums.keywordsMatchTypeMapper[keyword.matchType]"
                              :tooltip-text="getFailurereason(keyword.failureReason)"
                              :is-icon-shown="keyword.processingStatus == adwordsEnums.exportStatus.Failed ? true : false"
                              :icon="`fa fa-exclamation-triangle red`"/>
                              <template v-if="kwIndex < adgroup.keywords.length-1">,</template>
                            </template>
                          </td>
                        </tr>
                        <tr v-for="(ad, index) in adgroup.ads" :key="index">
                          <td>
                            <AdWordAdPreview :ad="ad" :isMobilePreview="isMobilePreview"/>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </template>
            </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { locale, Icon } from "iview";
import lang from "iview/dist/locale/en-US";
import { mapState } from "vuex";
import { adwordsEnums } from "../../Constants/AdwordsEnums";
import AdWordAdPreview from "./GoogleAdpreview.vue";
import EntityStatus from "./EntityStatus.vue"

locale(lang); // iview is default chinese

export default {
  components: {
    AdWordAdPreview,
    EntityStatus,
    Icon
  },
  data: function () {
    return {
      adwordsEnums: adwordsEnums,
      expandedRows: [],
      isMobilePreview: true
    };
  },
  props: {
    viewType: { default: "Changes" }
  },
  computed: {
    ...mapState([
      "adwordsEntitiesResponse"
    ]),
    adgroupsPivot: function () {
      const adwordsAdgroups = this.adwordsAdgroups();
      var record = adwordsAdgroups.map(ag => {
        return {
          name: ag.name,
          ads: ag.ads,
          adsCount: ag.ads.length,
          type: adwordsEnums.adgroupType[ag.type],
          adExtensions: ag.adExtensions,
          keywords: ag.keywords
        }
      })
      return record
    }
  },
  methods: {
    getFailurereason (failureReasonString) {
      let message = [];
      let failureReason = failureReasonString ? JSON.parse(failureReasonString) : null;
      if (failureReason && failureReason.length > 0) {
        failureReason.forEach(reason => {
          if (!reason.Message) {
            message.push(reason)
          } else {
            message.push(reason.Message)
          }
        })
      }
      return message.join(',');
    },
    togglePreview (isMobilePreview) {
      this.isMobilePreview = isMobilePreview;
    },
    toggle (id) {
      const index = this.expandedRows.indexOf(id);
      if (index > -1) {
      	this.expandedRows.splice(index, 1)
      } else {
        this.expandedRows = [];
      	this.expandedRows.push(id)
      }
    },
    adwordsAdgroups () {
      if (JSON.stringify(this.adwordsEntitiesResponse) === "{}") return []

      var adwordsAdgroups = []
      var adwordsCampaigns = this.adwordsEntitiesResponse.campaigns;
      adwordsCampaigns.forEach(campaign => {
        adwordsAdgroups = adwordsAdgroups.concat(campaign.adgroups)
      });
      if (this.viewType == "Changes") {
        let newAdGroups = [];
        for (let i = 0; i < adwordsAdgroups.length; i++) {
          if (!adwordsAdgroups[i].id) {
            adwordsAdgroups[i].adExtensions = this.getAdExtensions(adwordsAdgroups[i].adGroupFeedExtensions, adwordsAdgroups[i].adgroupAssets);
            newAdGroups.push(adwordsAdgroups[i]);
          } else {
            let adgroupCopy = JSON.parse(JSON.stringify(adwordsAdgroups[i]));
            adgroupCopy.keywords = adgroupCopy.keywords.filter(k => !k.id);
            adgroupCopy.ads = adgroupCopy.ads.filter(a => !a.id || (a.id && (a.processingStatus == adwordsEnums.exportStatus.Edited || a.processingStatus == adwordsEnums.exportStatus.Failed)));
            adgroupCopy.adgroupAssets = adgroupCopy.adgroupAssets.filter(e => !e.id);
            adgroupCopy.adGroupFeedExtensions = adgroupCopy.adGroupFeedExtensions.filter(e => !e.id);
            adgroupCopy.adExtensions = this.getAdExtensions(adgroupCopy.adGroupFeedExtensions, adgroupCopy.adgroupAssets);
            if (adgroupCopy.keywords.length > 0 || adgroupCopy.ads.length > 0 || adgroupCopy.adExtensions != '-') {
              newAdGroups.push(adgroupCopy);
            }
          }
        }
        return newAdGroups;
      }
      for (let adgroup of adwordsAdgroups) {
        adgroup.adExtensions = this.getAdExtensions(adgroup.adGroupFeedExtensions, adgroup.adgroupAssets);
      }
      return adwordsAdgroups;
    },
    getAdExtensions (adGroupFeedExtensions, adgroupAssets) {
      var adExtensions = []
      for (let adGroupFeedExtension of adGroupFeedExtensions) {
        adExtensions.push(adwordsEnums.feedItemAdExtensionType[adGroupFeedExtension.extensionType])
      }
      for (let adgroupAsset of adgroupAssets) {
        adExtensions.push(adwordsEnums.assetType[adgroupAsset.fieldType])
      }
      return adExtensions && adExtensions.length > 0 ? [...new Set(adExtensions)].join(", ") : "-"
    }
  },
  created () {
    if (this.adgroupsPivot[0]) {
      this.expandedRows.push(0);
    }
  }
};
</script>

<style scoped>
.title-sticky {
  padding: 0px 5px;
  top: 100px;
  background: white;
  z-index: 10;
  border: 1px solid #eee;
}
.element-label {
  font-size: large;
  padding: 10px 10px 10px 0;
}
.data-right {
  text-align: right;
}
.data-center {
  text-align: center;
  vertical-align: middle;
}
.upper-case {
  text-transform: uppercase;
}
.ad-brief-row:hover {
  background-color: #f6f7f8 !important;
  cursor: pointer;
}
.preview-icon{
  padding-left: 10px;
  cursor: pointer;
}
.disable{
    color: #979898;
}
.active{
  color: var(--main-bg-color);
  font-size: 16px !important;
}
.preview-table-header{
  border:1px solid #ddd;
  padding:5px;
}

.ad-header{
  display: inline;
}
.preview-icons{
  display: inline;
  float: right;
}

</style>
