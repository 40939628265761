import axiosInstance from '../../../Api';

let ApiService = {
  getBusinessProfiles () {
    var url = deltax.apiBaseUrl.concat(`Portals/${deltax.portalId}/BusinessProfileUsers`);
    var promise = axiosInstance({
      method: "GET",
      url: url
    });
    return promise;
  },
  getBusinessProfileById (id) {
    var url = deltax.apiBaseUrl.concat(`BusinessProfiles/${id}`);
    var promise = axiosInstance({
      method: "GET",
      url: url
    });
    return promise;
  },
  getBPUsers (id) {
    var url = deltax.apiBaseUrl.concat(`businessProfiles/${id}/users`);
    var promise = axiosInstance({
      method: "GET",
      url: url
    });
    return promise;
  },
  getCategories () {
    var url = deltax.apiBaseUrl.concat(`Categories`);
    var promise = axiosInstance({
      method: "GET",
      url: url
    });
    return promise;
  },

  getSubCategories () {
    var url = deltax.apiBaseUrl.concat(`SubCategories`);
    var promise = axiosInstance({
      method: "GET",
      url: url
    });
    return promise;
  },

  UploadLogo (id, formData) {
    var url = deltax.apiBaseUrl.concat(`BusinessProfiles/${id}/UploadLogo`);
    const promise = axiosInstance({
      method: "POST",
      url: url,
      data: formData
    });
    return promise;
  },
  UpdateBusinessProfile (id, data) {
    var url = deltax.apiBaseUrl.concat(`BusinessProfiles/${id}`);
    const promise = axiosInstance({
      method: "PATCH",
      url: url,
      data: data,
      headers: {
        'Content-Type': 'text/json'
      }
    });
    return promise;
  },
  EditBPusers (id, data) {
    var url = deltax.apiBaseUrl.concat(`admin/BusinessProfiles/${id}/Users`);
    const promise = axiosInstance({
      method: "POST",
      url: url,
      data: data
    });
    return promise;
  },
  deleteBusinessProfile (id, value) {
    var url = deltax.apiBaseUrl.concat(`admin/BusinessProfiles/${id}/Update`);
    var promise = axiosInstance({
      method: "PATCH",
      url: url,
      data: value
    });
    return promise;
  },
  getPath () {
    var prefixPath = "https://s3.amazonaws.com/";
    var postfixPath = deltax.storageContainer + "/AllFiles/Uploads/Logos/";
    var path = prefixPath + postfixPath;

    return path;
  },
  getPortalUsers () {
    var url = deltax.apiBaseUrl.concat(`portals/${deltax.portalId}/Users`);
    var promise = axiosInstance({
      method: "GET",
      url: url
    });
    return promise;
  },
  getAllBpRoles () {
    var url = deltaXApiUrl.roles.getBpRoles
      .replace('{portalId}', deltax.portalId);
    var promise = axiosInstance({
      method: "GET",
      url: url
    });
    return promise;
  },
  updateBpUsers (bpId, data) {
    var url = deltax.apiBaseUrl.concat(`BusinessProfiles/${bpId}/Users`);
    const promise = axiosInstance({
      method: "POST",
      url: url,
      data: data
    });
    return promise;
  },
  deleteBpAccess (bpId, data) {
    var url = deltax.apiBaseUrl.concat(`BusinessProfiles/${bpId}/Users`);
    const promise = axiosInstance({
      method: "Delete",
      url: url,
      data: data
    });
    return promise;
  }

};

export { ApiService };
