import axiosInstance from '../Api'

const batchApiUrl = deltax.batchApiUrl;

let GlobalAPIService = {
  batchApiRequests: async (requests) => {
    return axiosInstance.post(`${batchApiUrl}batch`, {
      requests
    });
  }
}

export { GlobalAPIService };
