<template>
  <div>
    <button v-if="!inPromoDashboard" class="btn btn-success btn-sm" v-on:click="openCampaignConfigModal()">
      <i v-if="isPromotionMode" class="fa fa-pencil"></i>
      <i v-else class="fa fa-plus"></i>
    </button>
    <Modal
      v-model="openConfigurationModal"
      width="75%"
      :scrollable="true"
      :styles="{top: '20px'}"
      :mask-closable="false"
      :closable="!inPromoDashboard"
    >
      <h3 class="ccHeader" slot="header">
          Campaign Configuration
          <i class="fa fa-spinner fa-spin" v-show="processing"></i>
        <!-- <alert
          class="error-message"
          v-model="actionSummary.show"
          :type="actionSummary.type"
          dismissable
        >
          <span v-html="actionSummary.message"></span>
        </alert> -->
      </h3>
      <div class="config-modal dx-tab-style">
        <Tabs type="card" v-model="selectedTab" :class="{ 'hide-tab': isPromotionFlow }">
          <TabPane label="Create or Edit" name="New Configuration">
            <Form
              ref="preFormCampaignConfig"
              label-position="right"
              :label-width="200"
              :model="preConfig"
              v-show="!isPromotionFlow"
            >
              <Form-Item label="Campaign Mode" prop="campaignConfigMode">
                <Row>
                  <Row-Col span="18">
                    <RadioGroup v-model="preConfig.campaignConfigMode">
                      <Radio label="New" :disabled="isPromotionFlow || isReadOnlyMode"></Radio>
                      <Radio label="Edit Existing" :disabled="isPromotionFlow || isReadOnlyMode"></Radio>
                      <!-- <Radio label="Clone Existing"></Radio>-->
                    </RadioGroup>
                  </Row-Col>
                </Row>
              </Form-Item>
              <Form-Item prop="selectedCampaignLauncherConfigurationId">
                <Row>
                  <Row-Col span="18">
                    <Select
                      :placeholder="configsDropdownPlaceholder"
                      not-found-text="No results"
                      v-model="preConfig.selectedCampaignLauncherConfigurationId"
                      filterable
                      :disabled="isPromotionFlow || isReadOnlyMode">
                      <Option
                        v-for="config in campaignConfigurations"
                        :key="config.Id"
                        :value="config.Id"
                        :disabled="config.isAssetClaimRequired && selectedPublisher == 5"
                      >{{ config.Name }}</Option>
                    </Select>
                  </Row-Col>
                </Row>
              </Form-Item>
            </Form>
            <facebook-config
              v-if="selectedPublisher == publisher.Facebook"
              :isEditPromoMode="isEditPromoMode"
              :isPromotionFlow="isPromotionFlow"
              :loadExistingConfigId="preConfig.selectedCampaignLauncherConfigurationId"
              :showConfigForm="isConfigSelectedInEditMode"
              :isExistingConfig="ifNotNewConfiguration"
              :defaultFormConfig="defaultFormConfig"
              :inPromoDashboard="inPromoDashboard"
              :nomenclatureMacro="getFacebookNomenclature"
              :openConfigurationModal="openConfigurationModal"
              @fetchingPageRelatedInformation="fetchingPageRelatedInformation = $event"
              ref="facebookConfig">
            </facebook-config>
            <adwords-configuration
              v-if="selectedPublisher == publisher.Adwords && !deltax.isAdwordsCampaignLaucherFlow"
              :loadExistingConfigId="preConfig.selectedCampaignLauncherConfigurationId"
              :showConfigForm="isConfigSelectedInEditMode"
              :isExistingConfig="ifNotNewConfiguration"
              :nomenclatureMacro="getNomenclatureMacros(publisher.Adwords)"
              ref="adWordsConfig"
            >
            </adwords-configuration>
            <adwords-configuration-new
              v-if="selectedPublisher == publisher.Adwords && deltax.isAdwordsCampaignLaucherFlow"
              :loadExistingConfigId="preConfig.selectedCampaignLauncherConfigurationId"
              :showConfigForm="isConfigSelectedInEditMode"
              :isExistingConfig="ifNotNewConfiguration"
              :nomenclatureMacro="getNomenclatureMacros(publisher.Adwords)"
              ref="adWordsConfig"
            >
            </adwords-configuration-new>
            <ams-sponsProducts-configuration
              v-if="selectedPublisher == publisher.AMS.SponsoredProducts"
              :loadExistingConfigId="preConfig.selectedCampaignLauncherConfigurationId"
              :showConfigForm="isConfigSelectedInEditMode"
              :openConfigurationModal="openConfigurationModal"
              :isExistingConfig="ifNotNewConfiguration"
              :nomenclatureMacro="getNomenclatureMacros(publisher.AMS.id)"
              ref="amsSPConfig"
            >
            </ams-sponsProducts-configuration>
            <ams-sponsBrands-configuration
              v-if="selectedPublisher == publisher.AMS.SponsoredBrands"
              :loadExistingConfigId="preConfig.selectedCampaignLauncherConfigurationId"
              :showConfigForm="isConfigSelectedInEditMode"
              :openConfigurationModal="openConfigurationModal"
              :isExistingConfig="ifNotNewConfiguration"
              :nomenclatureMacro="getNomenclatureMacros(publisher.AMS.id)"
              ref="amsSBConfig"
            >
            </ams-sponsBrands-configuration>
            <ams-sponsDisplay-configuration
              v-if="selectedPublisher == publisher.AMS.SponsoredDisplay"
              :loadExistingConfigId="preConfig.selectedCampaignLauncherConfigurationId"
              :showConfigForm="isConfigSelectedInEditMode"
              :openConfigurationModal="openConfigurationModal"
              :isExistingConfig="ifNotNewConfiguration"
              :nomenclatureMacro="getNomenclatureMacros(publisher.AMS.id)"
              ref="amsSDConfig"
            >
             </ams-sponsDisplay-configuration>
            <dbm-config
              v-if="selectedPublisher == publisher.DV360"
              :loadExistingConfigId="preConfig.selectedCampaignLauncherConfigurationId"
              :showConfigForm="isConfigSelectedInEditMode"
              :isExistingConfig="ifNotNewConfiguration"
              :nomenclatureMacro="getNomenclatureMacros(publisher.DV360)"
              ref="dbmConfig"
            >
            </dbm-config>
            <linkedin-config
              v-if="selectedPublisher == publisher.LinkedIn"
              :loadExistingConfigId="preConfig.selectedCampaignLauncherConfigurationId"
              :showConfigForm="isConfigSelectedInEditMode"
              :isExistingConfig="ifNotNewConfiguration"
              :nomenclatureMacro="getNomenclatureMacros(publisher.LinkedIn)"
              ref="linkedInConfig"
            >
            </linkedin-config>
            <twitter-config
              v-if="selectedPublisher == publisher.Twitter"
              :loadExistingConfigId="preConfig.selectedCampaignLauncherConfigurationId"
              :showConfigForm="isConfigSelectedInEditMode"
              :isExistingConfig="ifNotNewConfiguration"
              :nomenclatureMacro="getNomenclatureMacros(publisher.Twitter)"
              ref="twitterConfig"
            >
            </twitter-config>
            <moloco-config
              v-if="selectedPublisher == publisher.Moloco"
              :loadExistingConfigId="preConfig.selectedCampaignLauncherConfigurationId"
              :showConfigForm="isConfigSelectedInEditMode"
              :isExistingConfig="ifNotNewConfiguration"
              :nomenclatureMacro="getNomenclatureMacros(publisher.Moloco)"
              ref="molocoConfig"
            >
            </moloco-config>
          </TabPane>
          <!-- <TabPane v-if="!isPromotionFlow" label="Library" name="Configuration Library">
            <campaign-configuration-library
              ref="campaignConfigLibrary"
              :searchBy="librarySearchSelect"
              :searchFor="librarySearchValue"
              :isLibraryVisible="selectedTab == 'Configuration Library'">
            </campaign-configuration-library>
          </TabPane> -->
          <div
            v-show="selectedTab == 'Configuration Library'"
            slot="extra">
              <i-input size="small" style="width: 400px;" v-model="librarySearchValue" :placeholder="librarySearchPlaceholder">
                <Select size="small" v-model="librarySearchSelect" slot="prepend" style="width: 120px">
                  <Option value="Configuration">Configuration</Option>
                  <Option value="User Name">User Name</Option>
                </Select>
                <i-button size="small" slot="append" icon="ios-search"></i-button>
              </i-input>
          </div>
        </Tabs>
      </div>
      <div slot="footer">
        <Checkbox
          v-show="selectedTab !== 'Configuration Library' && !isPromotionFlow"
          class="continue-creating"
          v-model="continueCreating"
          label="Continue creating"
        >Continue creating</Checkbox>
        <input
          v-show="selectedTab !== 'Configuration Library' && !isPromotionFlow"
          type="button"
          class="btn btn-white mediaplan-campaign-popup-save-padding"
          v-on:click='resetAllFields()'
          value="Reset"
        />
        <input type="button" class="btn btn-white mediaplan-campaign-popup-save-padding" v-on:click="close()" value="Close" />
        <input
          type="button"
          class="btn btn-success mediaplan-campaign-popup-save-padding"
          v-on:click="handleSubmit()"
          value="Save"
          :disabled="processing == true || fetchingPageRelatedInformation"
          v-if="!isReadOnlyMode"
        />
      </div>
    </Modal>
  </div>
</template>
<script>
import {
  Divider,
  Row,
  Col,
  Modal,
  Tabs,
  TabPane,
  RadioGroup,
  Radio,
  Form,
  FormItem,
  Select,
  Option,
  Input,
  Checkbox,
  Button

} from "iview";
import { mapState, mapMutations, mapGetters } from "vuex";
import { APIService } from "./../ApiService.js";
import ModalMessageMixin from "../../Promotion/Mixins/ModalMessageMixin.js";
// import CampaignConfigurationLibrary from './CampaignConfigurationLibrary.vue'
import AdWordsConfiguration from "./AdWordsConfiguration.vue"
import AdWordsConfigurationNew from "./AdWordsConfigurationNew.vue"
import AmsSBConfiguration from "./AMS/SponsoredBrandsConfig.vue"
import AmsSPConfiguration from "./AMS/SponsoredProductsConfig.vue"
import AmsSDConfiguration from "./AMS/SponsoredDisplayConfig.vue"
import DbmConfiguration from "./DBM/DBMCampaignConfig.vue"
import LinkedInConfiguration from "./LinkedIn/LinkedInCampaignConfig.vue"
import FacebookCampaignConfig from "./FacebookCampaignConfig.vue"
import TwitterConfiguration from "./Twitter/TwitterCampaignConfig.vue"
import CLHelperMethodsMixin from "../Mixins/CLHelperMethods.js"
import FacebookCampaignLauncherConfig from "../Mixins/FacebookCampaignLauncherConfig.js"
import MolocoCampaignConfig from './Moloco/MolocoCampaignConfig.vue';

export default {
  props: {
    openConfigurationModalProp: {
      type: Boolean,
      default: false
    },
    defaultFormConfig: Object,
    campaignConfigurations: Array,
    inPromoDashboard: {
      type: Boolean,
      default: false
    },
    isEditPromoMode: {
      type: Boolean,
      default: false
    },
    isReadOnlyMode: { type: Boolean, default: false }
  },
  components: {
    // CampaignConfigurationLibrary,
    'adwords-configuration': AdWordsConfiguration,
    'adwords-configuration-new': AdWordsConfigurationNew,
    'ams-sponsBrands-configuration': AmsSBConfiguration,
    'ams-sponsProducts-configuration': AmsSPConfiguration,
    'ams-sponsDisplay-configuration': AmsSDConfiguration,
    'dbm-config': DbmConfiguration,
    'linkedin-config': LinkedInConfiguration,
    'twitter-config': TwitterConfiguration,
    'facebook-config': FacebookCampaignConfig,
    'moloco-config': MolocoCampaignConfig,
    Row,
    "Row-Col": Col,
    Modal,
    Tabs,
    TabPane,
    RadioGroup,
    Radio,
    Form,
    FormItem,
    Select,
    Option,
    'i-input': Input,
    Checkbox,
    'i-button': Button
  },
  mixins: [ModalMessageMixin, CLHelperMethodsMixin, FacebookCampaignLauncherConfig],
  mounted () {
    this.openConfigurationModal = this.openConfigurationModalProp
  },
  data () {
    return {
      selectedTab: "New Configuration",
      deltax: deltax,
      config: this.defaultFormConfig,
      preConfig: {
        campaignConfigMode: "New",
        selectedCampaignLauncherConfigurationId: ""
      },
      librarySearchValue: '',
      librarySearchSelect: 'Configuration',
      processing: false,
      createAnother: false,
      actionSummary: {
        show: false,
        message: ""
      },
      continueCreating: false,
      openConfigurationModal: false,
      fetchingPageRelatedInformation: false
    }
  },
  methods: {
    ...mapMutations(["SET_BPSEID"]),
    scrollToTop () {
      Object.values(
        document.getElementsByClassName("ivu-tabs-tabpane")
      ).forEach(elem => (elem.scrollTop = 0));
    },
    saveConfigCallback (response) {
      if (!this.continueCreating) { this.openConfigurationModal = false; }
      // this.actionSummary.message = this.config.name + " successfully created";
      // this.actionSummary.type = "success";
      // this.actionSummary.show = true;
      this.$Message.success({
        background: true,
        content:
          this.config.name +
          ` successfully ${this.ifNotNewConfiguration ? "saved" : "created"}`
      });
      if (this.isPromotionMode) {
        response.mediaPlanCampaignConfig.ConfigJson = JSON.stringify(this.config);
      }
      var newCampaignConfig = {};
      if ((deltax.isAdwordsCampaignLaucherFlow && this.selectedPublisher == this.publisher.Adwords) || (this.selectedPublisher == this.publisher.Moloco)) {
        newCampaignConfig = response.data;
        newCampaignConfig.Id = newCampaignConfig.id;
        newCampaignConfig.BusinessProfiles_SearchEngineId = newCampaignConfig.businessProfileSearchEngineId;
        newCampaignConfig.ConfigJson = newCampaignConfig.configJson;
        newCampaignConfig.Status = newCampaignConfig.status;
        newCampaignConfig.Name = newCampaignConfig.name;
        newCampaignConfig.UpdatedAt = newCampaignConfig.updatedAt;
        newCampaignConfig.UpdatedByUserId = newCampaignConfig.updatedByUserId;
        newCampaignConfig.BpseId = newCampaignConfig.businessProfileSearchEngineId;
      } else {
        newCampaignConfig = response.mediaPlanCampaignConfig;
        if (newCampaignConfig.BusinessProfiles_SearchEngineId) {
          newCampaignConfig.BpseId = newCampaignConfig.BusinessProfiles_SearchEngineId;
          newCampaignConfig.id = newCampaignConfig.Id;
          newCampaignConfig.name = newCampaignConfig.Name;
        }
      }
      newCampaignConfig.searchEngineTypeId = this.selectedPublisher
      this.$emit("newCampaignConfigSaved", {
        newCampaignConfig: newCampaignConfig,
        isNewConfig: this.preConfig.campaignConfigMode === "New",
        continueCreating: this.continueCreating
      });
      this.resetAllFields();
      this.scrollToTop();
    },
    saveConfigAlwaysCallback (response) {
      this.processing = false;
    },
    saveConfigErrorCallback (response) {
      this.$Message.error({
        background: true,
        content: "There was a problem while processing your request"
      });
    },
    async save () {
      this.processing = true;
      let postData = {}
      let url = "/App/CampaignLauncher/SaveMediaPlanCampaignConfiguration/" + this.businessProfileId
      switch (this.selectedPublisher) {
        case this.publisher.Adwords:
          let adwordsConfig = await this.$refs["adWordsConfig"].getConfig();
          if (this.isPromotionFlow || this.isWizardFlow) {
            // API expects null if bidAdjustmentType is not selected
            adwordsConfig.configJson.campaign.bidAdjustmentType = !adwordsConfig.configJson.campaign.bidAdjustmentType ? null : adwordsConfig.configJson.campaign.bidAdjustmentType;
          } else {
            adwordsConfig.campaign.bidAdjustmentType = !adwordsConfig.campaign.bidAdjustmentType ? null : adwordsConfig.campaign.bidAdjustmentType;
          }
          this.config.name = adwordsConfig.name
          postData = {
            bpseId: adwordsConfig.bpseId,
            businessProfileSearchEngineId: adwordsConfig.bpseId,
            name: adwordsConfig.name,
            status: 1,
            configJson: JSON.stringify(adwordsConfig),
            adwordsConfig: adwordsConfig,
            configId: this.ifNotNewConfiguration
              ? this.preConfig.selectedCampaignLauncherConfigurationId
              : -1,
            isReachAndFrequencyConfig: false
          };
          if (deltax.isAdwordsCampaignLaucherFlow) {
            if (this.ifNotNewConfiguration) {
              url = deltax.apiEndPoint + `businessProfiles/${this.businessProfileId}/CampaignLauncherConfigurations/${this.preConfig.selectedCampaignLauncherConfigurationId}`;
              postData = JSON.stringify(JSON.stringify({
                data: {
                  configJson: adwordsConfig
                }
              }));
            } else {
              url = deltax.apiEndPoint + `businessProfiles/${this.businessProfileId}/CampaignLauncherConfigurations`;
            }
          }
          break;
        case this.publisher.Facebook:
          const facebookConfig = await this.$refs["facebookConfig"].getConfig();
          this.config = facebookConfig.configJson
          if (this.inPromoDashboard || this.isPromotionMode) {
            facebookConfig.configJson = this.getConfigWithMacros(facebookConfig.configJson, this.promotionObject.name);
            if (this.inPromoDashboard) {
              this.$emit('savePromoCLconfig', facebookConfig);
              return;
            }
          } else {
            if (facebookConfig.configJson.isAdsetBudget) {
              facebookConfig.configJson.adset.spendLimit.min = ""
              facebookConfig.configJson.adset.spendLimit.max = ""
            }
            facebookConfig.configJson = JSON.stringify(facebookConfig.configJson)
          }
          postData = facebookConfig;
          break;
        case this.publisher.AMS.SponsoredProducts:
          const amsSPConfig = this.$refs["amsSPConfig"].getConfig();
          this.config.name = amsSPConfig.name
          postData = {
            bpseId: amsSPConfig.bpseId,
            name: amsSPConfig.name,
            configJson: JSON.stringify(amsSPConfig),
            configId: this.ifNotNewConfiguration
              ? this.preConfig.selectedCampaignLauncherConfigurationId
              : -1,
            isReachAndFrequencyConfig: false
          };
          break;
        case this.publisher.AMS.SponsoredBrands:
          const amsSBConfig = this.$refs["amsSBConfig"].getConfig();
          this.config.name = amsSBConfig.name
          postData = {
            bpseId: amsSBConfig.bpseId,
            name: amsSBConfig.name,
            configJson: JSON.stringify(amsSBConfig),
            configId: this.ifNotNewConfiguration
              ? this.preConfig.selectedCampaignLauncherConfigurationId
              : -1,
            isReachAndFrequencyConfig: false
          };
          break;
        case this.publisher.AMS.SponsoredDisplay:
          const amsSDConfig = this.$refs["amsSDConfig"].getConfig();
          this.config.name = amsSDConfig.name
          postData = {
            bpseId: amsSDConfig.bpseId,
            name: amsSDConfig.name,
            configJson: JSON.stringify(amsSDConfig),
            configId: this.ifNotNewConfiguration
              ? this.preConfig.selectedCampaignLauncherConfigurationId
              : -1,
            isReachAndFrequencyConfig: false
          };
          break;
        case this.publisher.DV360:
          const dbmConfig = this.$refs["dbmConfig"].getConfig();
          this.config.name = dbmConfig.name
          postData = {
            bpseId: dbmConfig.bpseId,
            name: dbmConfig.name,
            configJson: JSON.stringify(dbmConfig),
            configId: this.ifNotNewConfiguration
              ? this.preConfig.selectedCampaignLauncherConfigurationId
              : -1,
            isReachAndFrequencyConfig: false
          };
          break;
        case this.publisher.LinkedIn:
          const linkedInConfig = this.$refs["linkedInConfig"].getConfig();
          this.config.name = linkedInConfig.name
          postData = {
            bpseId: linkedInConfig.bpseId,
            name: linkedInConfig.name,
            configJson: JSON.stringify(linkedInConfig),
            configId: this.ifNotNewConfiguration
              ? this.preConfig.selectedCampaignLauncherConfigurationId
              : -1,
            isReachAndFrequencyConfig: false
          };
          break;
        case this.publisher.Twitter:
          const twitterConfig = this.$refs["twitterConfig"].getConfig();
          this.config.name = twitterConfig.name
          postData = {
            bpseId: twitterConfig.bpseId,
            name: twitterConfig.name,
            configJson: JSON.stringify(twitterConfig),
            configId: this.ifNotNewConfiguration
              ? this.preConfig.selectedCampaignLauncherConfigurationId
              : -1,
            isReachAndFrequencyConfig: false
          };
          break;
        case this.publisher.Moloco:
          const molocoConfig = this.$refs["molocoConfig"].getConfig();
          this.config.name = molocoConfig.name
          postData = {
            bpseId: molocoConfig.bpseId,
            businessProfileSearchEngineId: molocoConfig.bpseId,
            status: 1,
            molocoConfig: molocoConfig,
            name: molocoConfig.name,
            configJson: JSON.stringify(molocoConfig),
            configId: this.ifNotNewConfiguration
              ? this.preConfig.selectedCampaignLauncherConfigurationId
              : -1,
            isReachAndFrequencyConfig: false
          };
          if (this.ifNotNewConfiguration) {
            url = deltax.apiEndPoint + `businessProfiles/${this.businessProfileId}/CampaignLauncherConfigurations/${this.preConfig.selectedCampaignLauncherConfigurationId}`;
            postData = JSON.stringify(JSON.stringify({
              data: {
                configJson: molocoConfig
              }
            }));
          } else {
            url = deltax.apiEndPoint + `businessProfiles/${this.businessProfileId}/CampaignLauncherConfigurations`;
          }
          break;
      }
      if ((deltax.isAdwordsCampaignLaucherFlow && this.selectedPublisher == this.publisher.Adwords && this.ifNotNewConfiguration) || (this.selectedPublisher == this.publisher.Moloco && this.ifNotNewConfiguration)) {
        APIService.patch(url,
          postData,
          this.saveConfigCallback,
          this.saveConfigErrorCallback,
          this.saveConfigAlwaysCallback
        );
      } else {
        APIService.post(url,
          postData,
          this.saveConfigCallback,
          this.saveConfigErrorCallback,
          this.saveConfigAlwaysCallback
        );
      }
    },
    handleSubmit () {
      if (this.selectedTab === "Configuration Library") {
        this.$refs.campaignConfigLibrary.pushUpdatedStatus()
        return
      }
      switch (this.selectedPublisher) {
        case this.publisher.Adwords:
          this.$refs["adWordsConfig"].isAdwordsFormValid(this.save)
          break;
        case this.publisher.Facebook:
          this.$refs["facebookConfig"].resetAlerts();
          this.$refs["facebookConfig"].isFacebookFormvalid(this.save);
          break;
        case this.publisher.AMS.SponsoredProducts:
          this.$refs["amsSPConfig"].isAMSFormValid(this.save)
          break;
        case this.publisher.AMS.SponsoredBrands:
          this.$refs["amsSBConfig"].isAMSFormValid(this.save)
          break;
        case this.publisher.AMS.SponsoredDisplay:
          this.$refs["amsSDConfig"].isAMSFormValid(this.save)
          break;
        case this.publisher.DV360:
          this.$refs["dbmConfig"].isDBMFormValid(this.save)
          break;
        case this.publisher.LinkedIn:
          this.$refs["linkedInConfig"].isLinkedInFormValid(this.save)
          break;
        case this.publisher.Twitter:
          this.$refs["twitterConfig"].isTwitterFormValid(this.save)
          break;
        case this.publisher.Moloco:
          this.$refs["molocoConfig"].isMolocoFormValid(this.save)
          break;
      }
    },
    resetFormFields () {
      switch (this.selectedPublisher) {
        case this.publisher.Adwords:
          this.$refs["adWordsConfig"].resetFields();
          break;
        case this.publisher.Facebook:
          this.$refs["facebookConfig"].resetFields();
          break;
        case this.publisher.AMS.SponsoredProducts:
          this.$refs["amsSPConfig"].resetFields();
          break;
        case this.publisher.AMS.SponsoredBrands:
          this.$refs["amsSBConfig"].resetFields();
          break;
        case this.publisher.AMS.SponsoredDisplay:
          this.$refs["amsSDConfig"].resetFields();
          break;
        case this.publisher.DV360:
          this.$refs["dbmConfig"].resetFields();
          break;
        case this.publisher.LinkedIn:
          this.$refs["linkedInConfig"].resetFields();
          break;
        case this.publisher.Twitter:
          this.$refs["twitterConfig"].resetFields();
          break;
        case this.publisher.Moloco:
          this.$refs["molocoConfig"].resetFields();
          break;
      }
    },
    resetAllFields () {
      this.$refs.preFormCampaignConfig.resetFields();
      switch (this.selectedPublisher) {
        case this.publisher.Adwords:
          this.$refs["adWordsConfig"].resetFields();
          break;
        case this.publisher.Facebook:
          this.$refs["facebookConfig"].resetFields();
          break;
        case this.publisher.AMS.SponsoredProducts:
          this.$refs["amsSPConfig"].resetFields();
          break;
        case this.publisher.AMS.SponsoredBrands:
          this.$refs["amsSBConfig"].resetFields();
          break;
        case this.publisher.AMS.SponsoredDisplay:
          this.$refs["amsSDConfig"].resetFields();
          break;
        case this.publisher.DV360:
          this.$refs["dbmConfig"].resetFields();
          break;
        case this.publisher.LinkedIn:
          this.$refs["linkedInConfig"].resetFields();
          break;
        case this.publisher.Twitter:
          this.$refs["twitterConfig"].resetFields();
          break;
        case this.publisher.Moloco:
          this.$refs["molocoConfig"].resetFields();
          break;
      }
    },
    openCampaignConfigModal () {
      this.$emit("campaignConfigModalOpen");
      this.openConfigurationModal = true;
    },
    close () {
      if (this.inPromoDashboard) {
        this.showConfirmModal({
          content: 'Unsaved changes will be lost. Are you sure you want to cancel?',
          onOk: () => { this.openConfigurationModal = false }
        })
        return
      }
      this.openConfigurationModal = false;
      this.actionSummary.show = false;
      this.actionSummary.message = "";
      this.actionSummary.type = "";
      this.adformatValidatorRequired = false;
      if (this.selectedPublisher == this.publisher.Facebook) {
        this.$refs["facebookConfig"].clearMobileOSData();
        this.$refs["facebookConfig"].resetAlerts();
      }
    }
  },
  computed: {
    ...mapState([
      "campaignLauncherConfigs",
      "businessProfilesSearchEngineId",
      "businessProfileId",
      "accounts",
      "accountAssetsMapper",
      "selectedPublisher",
      "isPromotionMode",
      "isWizardFlow",
      "promotionObject",
      "selectedCampaignLauncherConfig",
      "publisher",
      "accountMacros",
      "nomenclatureSettings",
      "bpCurrencySymbol"
    ]),
    ...mapGetters([
      "getNomenclatureMacros",
      "getFacebookNomenclature"
    ]),
    configsDropdownPlaceholder () {
      return this.preConfig.campaignConfigMode == "New"
        ? "Load from existing"
        : "Select a configuration";
    },
    ifNotNewConfiguration () {
      return this.preConfig.campaignConfigMode !== "New";
    },
    isPromotionFlow () {
      return this.isPromotionMode || this.inPromoDashboard;
    },
    librarySearchPlaceholder () {
      if (this.librarySearchSelect === "Configuration") {
        return "Search for a configuration"
      }
      return "Search for a User"
    },
    isConfigSelectedInEditMode () {
      return (
        this.preConfig.campaignConfigMode === "New" ||
        this.preConfig.selectedCampaignLauncherConfigurationId
      )
    }
  },
  watch: {
    openConfigurationModal () {
      this.$emit("modalStateChanged", this.openConfigurationModal);
      if (this.openConfigurationModal == false) {
        this.selectedTab = "New Configuration";
        this.librarySearchValue = ""
        this.librarySearchSelect = 'Configuration'
        if (this.inPromoDashboard) { return }
        setTimeout(() => {
          // if (!this.isPromotionFlow) { this.$refs.campaignConfigLibrary.resetLibraryState() }
          this.resetAllFields();
          this.scrollToTop();
        }, 0)
      } else {
        if (this.isPromotionMode) {
          const configId = this.promotionObject.campaignLauncherConfiguration[this.selectedPublisher].id;
          this.preConfig.selectedCampaignLauncherConfigurationId = configId;
        } else if (this.inPromoDashboard) {
          this.ignoreConfigModeChange = true;
          this.loadingExistingConfig = true
          this.preConfig.campaignConfigMode = "Edit Existing";
          this.preConfig.selectedCampaignLauncherConfigurationId = 1;
          this.$nextTick(() => {
            this.loadingExistingConfig = false
          })
        }
      }
    },
    openConfigurationModalProp () {
      this.openConfigurationModal = this.openConfigurationModalProp;
    },
    isPromotionMode: {
      immediate: true,
      handler: function () {
        if (this.isPromotionMode) {
          this.ignoreConfigModeChange = true;
          this.loadingExistingConfig = true
          this.preConfig.campaignConfigMode = "Edit Existing";
          const configId = this.promotionObject.campaignLauncherConfiguration[this.selectedPublisher].id;
          this.preConfig.selectedCampaignLauncherConfigurationId = configId;
          this.$nextTick(() => {
            this.loadingExistingConfig = false
          })
        }
      }
    },
    // "config": function () {
    //   this.resetFormFields();
    // },
    "preConfig.campaignConfigMode" () {
      if (this.ignoreConfigModeChange) {
        this.ignoreConfigModeChange = false;
      } else {
        this.preConfig.selectedCampaignLauncherConfigurationId = "";
        this.resetFormFields();
      }
    }
  }
}
</script>
<style src="../../../Styles/dx-iview.css"></style>
<style>
.ccHeader {
  margin: 0px;
  font-size: 20px !important;
}
/* .ivu-form-item-content {
  padding-left: 50px;
}
.ivu-form-item-error-tip {
  margin-left: 50px;
} */
.config-modal .ivu-tabs {
  height: inherit;
}
.config-modal .ivu-tabs-content {
  height: inherit;
  padding-bottom: 50px;
}
.config-modal .ivu-tabs-tabpane {
  overflow: auto;
  height: 100%;
}
.config-modal span, .config-modal label, .config-modal input {
  font-size: 13px !important;
}
.config-modal label {
  color:#979898;
}
.config-modal .ivu-form-item {
  margin-bottom :15px !important
}
.config-modal .ivu-form-item-error-tip{
  padding-top: 0px !important;
}
.config-modal .ivu-checkbox-checked .ivu-checkbox-inner, .continue-creating .ivu-checkbox-checked .ivu-checkbox-inner{
  border-color:  var(--main-bg-color) !important;
  background-color: var(--main-bg-color) !important;
}
.config-modal .ivu-checkbox-indeterminate .ivu-checkbox-inner{
  border-color:  var(--main-bg-color) !important;
  background-color: var(--main-bg-color) !important;
}
.config-modal .ivu-radio-checked .ivu-radio-inner{
  border-color: var(--main-bg-color) !important;
}
.config-modal .ivu-radio-checked .ivu-radio-inner::after{
  background-color: var(--main-bg-color) !important;
}
.config-modal .ivu-tabs-tab-active, .config-modal .ivu-tabs-tab-focused{
  color: #2C2E2F !important;
}
.config-modal .ivu-checkbox-wrapper > .ivu-checkbox, .continue-creating > .ivu-checkbox {
  padding-right: 5px;
}
.hide-tab .ivu-tabs-bar {
  display: none;
}
.hide-tab .ivu-tabs-content {
  padding-bottom: 0px !important;
}
</style>
<style scoped>
.no-app-info-wrapper {
  margin: 0 0 20px 250px;
}
.no-app-info {
  padding: 11px 12px 12px 12px;
  height: auto;
  width: 50%;
  border-radius: 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px,
  	inset 0 -1px 0 0 #dadde1,
    inset 0 1px 0 0 #dadde1,
    inset -1px 0 0 0 #dadde1;
  border-color: rgb(218, 221, 225) #CCD0D5 #CCD0D5 #FFBA00;
  border-style: solid;
  border-width: 0px 0px 0px 4px;
}
.no-app-icon {
  font-size: 13px;
  float: left;
  color: #ffc017;
  line-height: 19px;
}
.no-app-info-msg {
  margin-left: 24px;
}
.no-app-info-bold {
  font-weight: bold;
  display: block;
  color: #6a6c6f !important;
  margin-bottom: 10px;
}
.no-app-info-text {
  color: #7a7c7e !important;
}
.no-app-info-link {
  color: #216FDB;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #ffffff;
}
::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.inclusion-exclusion-filter {
  padding: 0.25rem 0rem;
}
.iview-icons {
  cursor: pointer;
}
.add-btn {
  color: green;
}
.close-btn {
  color: maroon;
}
.primary-text-color{
  color: #ffffff !important;
}
.day-button{
  margin: 0.11rem;
}
.schedule-btn-pointer{
 cursor: pointer;
}
.add-time-slot{
  margin: 0px;
}
.add-schedule-button{
  margin-top: 1rem;
}
.schedule-block{
  padding-top:15px
}
::v-deep .tooltip-inner {
    max-width: 350px !important;
    width: 350px !important;
}
</style>
