<template>
  <div>
    <v-row id="targeting-key-value-comp">
        <v-col :span="campaignConfiguration ? 24 : 24">
            <Card dis-hover>
                <div slot="title">
                  <v-row>
                    <v-col :span="campaignConfiguration ? 20 : 24"><p id="card-title">Key-Value Pairs</p></v-col>
                    <v-col v-if="campaignConfiguration" span="4" id="impression-cap-header"><p id="card-title">Impression Caps</p></v-col>
                  </v-row>
                </div>
                <ImpressionCapRow v-for="(val, index) in impressionCaps" :key="val.id"
                :rowLength="impressionCaps.length"
                :index="index"
                :id="val.id"
                :campaignConfiguration="campaignConfiguration"
                :molocoTargetingKeyValuePairs="molocoTargetingKeyValuePairs"
                :selectedConfigKey="selectedConfigKey"
                :selectedConfigIsValue="selectedConfigIsValue"
                :selectedCondition="selectedCondition"
                :sameFieldWarning="sameFieldWarning[index]"
                :impressionCaps="impressionCaps"
                @remove-targeting-key-comp="removeTargetingKeyComp(index)"
                @update-impression-data="e => updateImpressionCaps(e, index)"
                @config-selected-key="setConfigSelectedKey"
                @config-selected-is-value="setConfigSelectedIsValue"
                :editTargetModeTriggered="editTargetModeTriggered"
                />
                <v-row>
                    <v-col v-if="impressionCaps.length===0" span="6" class="button-section">
                        <Button @click="addTargetingKeyComp"><span v-if="campaignConfiguration">Add Condition</span><span v-else>Add Option</span></Button>
                    </v-col>
                    <v-col v-else span="6" class="button-section">
                        <Button @click="addTargetingKeyComp('AND')" v-if="selectedCondition!=='OR'">+AND</Button>
                        <Button @click="addTargetingKeyComp('OR')" v-if="selectedCondition!=='AND'">+OR</Button>
                    </v-col>
                    <v-col v-if="campaignConfiguration" span="14" class="empty-col-section"><div>&nbsp;</div></v-col>
                    <v-col v-if="campaignConfiguration" span="4" class="empty-col-section empty-col-section-border"><div>&nbsp;</div></v-col>
                </v-row>
            </Card>
        </v-col>
    </v-row>
    <div v-if="campaignConfiguration && impressionCapWarning" id="impression-cap-warning-message">Impression Cap values must be valid and less than Campaign Maximum Impressions </div>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Card,
  Button
} from "iview";
import ImpressionCapRow from "./ImpressionCapRow.vue";
import { APIService } from "../../ApiService";
import * as molocoEnums from "../../Constants/MolocoEnums.js";
import { mapState } from "vuex";

export default {
  props: {
    campaignConfiguration: {
      type: Boolean,
      required: true
    },
    maximumImpressions: {
      type: Number,
      required: false
    }
  },
  components: {
    "v-row": Row,
    "v-col": Col,
    Card,
    Button,
    ImpressionCapRow
  },
  data () {
    return {
      impressionVal: {},
      selectedCondition: "",
      impressionCaps: [],
      selectedConfigKey: "",
      selectedConfigIsValue: "IS",
      sameFieldWarning: [],
      molocoTargetString: "",
      impressionCapWarning: false,
      editTargetModeTriggered: 0
    };
  },
  computed: {
    ...mapState([
      "molocoTargetingKeyValuePairs"
    ])
  },
  methods: {
    addTargetingKeyComp (value) {
      // stop the ImpressionCapRow watcher from getting hit as we don't auto populate now.
      this.editTargetModeTriggered = 0;
      this.impressionCaps.push({ id: Date.now(), impressionCapValue: 0 });
      this.sameFieldWarning.push(false);
      this.impressionCapWarning = true;
      if (this.impressionCaps.length > 1) {
        this.selectedCondition = value;
      }
      this.validateMolocoCreatedTarget();
    },
    removeTargetingKeyComp (index) {
      // stop the ImpressionCapRow watcher from getting hit as we don't auto populate now.
      this.editTargetModeTriggered = 0;
      if (this.campaignConfiguration && index === 0) {
        this.impressionCaps = [];
        this.sameFieldWarning = [];
        this.impressionCapWarning = false;
        this.$store.state.molocoImpressionCaps = [];
      } else {
        this.impressionCaps.splice(index, 1);
        this.sameFieldWarning.splice(index, 1);
      }
      if (this.impressionCaps.length < 2) {
        this.selectedCondition = "";
      }
      this.validateMolocoCreatedTarget();
      // handle edge case - when below fields are removed after creating duplicates
      this.validateDuplicateFields();
      this.molocoTargetString = this.generateTargetString(this.impressionCaps, this.selectedCondition);
    },
    async fetchMolocoTargetingKeyValuePairs () {
      if (!Object.keys(this.molocoTargetingKeyValuePairs).length) {
        this.$store.state.molocoTargetingKeyValuePairs = await APIService.GetMolocoTargetingKeyValuePairs();
      }
    },
    // updates impressioncaps array data for any changes
    updateImpressionCaps (impressionData, index) {
      this.$set(this.impressionCaps, index, { ...this.impressionCaps[index], ...impressionData });
      if (this.impressionCaps.length > 1) {
        this.validateDuplicateFields();
      }
      // check for impression cap too
      this.handleImpressionCapValues(index);
      this.molocoTargetString = this.generateTargetString(this.impressionCaps, this.selectedCondition);
      this.validateMolocoCreatedTarget();
    },
    // sets config key to pass in props for showing is disabled key fields
    setConfigSelectedKey (val) {
      this.selectedConfigKey = val;
    },
    // sets config "IS" consition to pass in props for showing is disabled "IS" fields
    setConfigSelectedIsValue (val) {
      this.selectedConfigIsValue = val;
    },
    // verify and gives warning for selected values for same keys
    validateDuplicateFields () {
      for (let i = 0; i < this.sameFieldWarning.length; i++) {
        this.$set(this.sameFieldWarning, i, false);
      }
      for (let i = 0; i < this.impressionCaps.length; i++) {
        for (let j = i + 1; j < this.impressionCaps.length; j++) {
          if (this.impressionCaps[i].selectedMolocoTargetKey === this.impressionCaps[j].selectedMolocoTargetKey) {
            this.impressionCaps[i].selectedTargetingValues && this.impressionCaps[i].selectedTargetingValues.forEach(element => {
              if (this.impressionCaps[j].selectedTargetingValues.includes(element)) {
                this.sameFieldWarning[j] = true;
                this.$set(this.sameFieldWarning, i, true);
                this.$set(this.sameFieldWarning, j, true);
              }
            });
          }
        }
      }
    },
    // sets store variable to false for invalid targets
    validateMolocoCreatedTarget () {
      if (this.impressionCapWarning) {
        this.$store.state.isValidMolocoTarget = false;
      }
      if (this.impressionCaps.length < 1 || this.sameFieldWarning.length < 1) {
        this.$store.state.isValidMolocoTarget = false;
      }
      if (this.sameFieldWarning.length < 1 || this.sameFieldWarning.includes(true)) {
        this.$store.state.isValidMolocoTarget = false;
      } else {
        this.$store.state.isValidMolocoTarget = true;
      }
      for (let obj of this.impressionCaps) {
        if (!obj.selectedTargetingValues || (obj.selectedTargetingValues && obj.selectedTargetingValues.length < 1)) {
          this.$store.state.isValidMolocoTarget = false;
          break;
        }
      }
      // update state variable for moloco impression Caps
      this.$store.state.molocoImpressionCaps = this.impressionCaps;
    },
    generateTargetString (impressionCaps, selectedCondition) {
      let molocoTargetObj = this.createTargetObject(impressionCaps, selectedCondition);
      return JSON.stringify(molocoTargetObj);
    },
    createTargetObject (impressionCaps, selectedCondition) {
      if (selectedCondition === "") {
        selectedCondition = "and";
      }
      selectedCondition = selectedCondition.toLowerCase();
      let targetObj = {
        [selectedCondition]: {
          "pairs": {}
        }
      };

      impressionCaps.forEach(item => {
        let molocoKey = item.selectedMolocoTargetKey;
        let isValue = molocoEnums.TargetingKeyValueCondition[item.selectedIsValue];
        let values = item.selectedTargetingValues;

        if (!targetObj[selectedCondition].pairs[molocoKey]) {
          targetObj[selectedCondition].pairs[molocoKey] = {};
        }

        targetObj[selectedCondition].pairs[molocoKey][isValue] = {
          "values": values
        };
      });
      return targetObj;
    },
    // handles impression cap input field change and sets warning value
    handleImpressionCapValues (index) {
      // console.log(this.impressionCaps[index]);
      if (this.impressionCaps[index].impressionCapValue > this.maximumImpressions || this.impressionCaps[index].impressionCapValue < 1) {
        this.impressionCapWarning = true
      } else {
        this.impressionCapWarning = false
      }
      this.validateMolocoCreatedTarget();
    }
  },
  watch: {
    // watches changes to maximum impression and checks for valid impression caps
    maximumImpressions () {
      for (let index = 0; index < this.impressionCaps.length; index++) {
        this.handleImpressionCapValues(index);
      }
    },
    selectedCondition (newVal) {
      this.$store.state.molocoTargetingSelectedCondition = newVal;
    }
  },
  mounted () {
    this.fetchMolocoTargetingKeyValuePairs();
  }
};
</script>

<style scoped>
::v-deep .ivu-btn, .ivu-btn:active, .ivu-btn:focus {
    outline: 0;
    padding: 0px 5px;
    margin: 5px 5px 5px 0px;
    color: #51b848;
    background-color: #fff;
    border-color: #51b848;
}
::v-deep .ivu-card-head p, .ivu-card-head-inner {
    display: inline-block;
    line-height: 20px;
    font-size: 12px;
    color: #515a6e;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
    padding: 0px;
    font-weight: 300;
}
::v-deep .ivu-card-head {
    border-bottom: 2px solid #51b848;
    padding: 0px;
    line-height: 1;
    background-color: #f7f7f7;
}
::v-deep .ivu-card-body {
    padding: 0px;
}
::v-deep .ivu-card {
    border-radius: 0px;
}
::v-deep .ivu-card-bordered{
    border: none;
    border-right: 1px solid #d6d6d6;
}
::v-deep .ivu-icon-ios-close:before {
    content: "\F178";
    font-weight: bolder;
}
#impression-cap-warning-message {
  color:#b94a48 !important;
}
.button-section {
    border-right: 1px solid #d6d6d6;
    padding: 8px 8px;
}
.empty-col-section {
    padding: 8px 8px;
}
.empty-col-section-border {
    border-left: 1px solid #d6d6d6;
}
#card-title {
    padding: 5px;
}
.card-body {
    padding: 0px;
}
#campaign-conf-card {
    border: none;
}
#targeting-key-value-comp {
    border: 1px solid #d6d6d6 ;
}
#impression-cap-header {
border-left: 1px solid #d6d6d6 ;
}
</style>
