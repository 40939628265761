<template>
  <div class="col-md-2 auth-faction">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <input v-if="isEditEnabled" class="edit-name-box" v-model="name" ref="nameInput">
          <span v-if="isEditEnabled">
            <i
              class="fa fa-pencil edit-name-pencil"
              v-tooltip="'Edit'"
              @click.stop="focusNameInput(self)"
            ></i>
            <i
              class="fa fa-trash remove-faction"
              v-tooltip="'Delete'"
              @click.stop="removeAuthFlowStageFaction({index : flowIndex, stageIndex: stageIndex, factionIndex: factionIndex})"
            ></i>
          </span>
          <label v-if="!isEditEnabled" class="edit-name-label">
            {{name}}
            <span v-if="!isEditEnabled">
              <i :class="statusIcon"></i>
              <i :class="stagePermissionType" aria-hidden="true"></i>
            </span>
          </label>
        </div>
        <div class="row">
          <div v-if="!isEditEnabled && approvals.length > 0" class="col-md-12">
            <ul class="approvals">
              <li
                class="approval green"
                v-for="(approval, index) in approvals"
                :key="index"
              >{{approval}}</li>
            </ul>
          </div>
          <div v-if="!isEditEnabled && rejections.length > 0" class="col-md-12">
            <ul class="approvals">
              <li
                class="approval red"
                v-for="(rejection, index) in rejections"
                :key="index"
                v-html="rejection"
              ></li>
            </ul>
          </div>
          <vue-multiselect
            v-if="isEditEnabled && (approvals.length <= 0 && rejections.length <= 0)"
            :multiple="true"
            :disabled="!isEditEnabled"
            :showNoResults="false"
            selectedLabel="✔"
            placeholder="Select Users"
            selectLabel
            deselectLabel
            :close-on-select="false"
            :options="bpusers"
            v-model="users"
            track-by="Id"
            class="user-select"
            :custom-label="customUserLabel"
            :limitText="count => ` + ${count}`"
            :limit="5"
          >
            <template slot="tag" slot-scope="{ option, remove }">
              <span class="multiselect__tag">
                <span>{{ option.Initials }}</span>
                <i aria-hidden="true" class="multiselect__tag-icon" @click="remove(option)"></i>
              </span>
            </template>
          </vue-multiselect>
          <div v-else class="avatar-list-authflow">
            <div v-for="user in usersWithStatus" class="factions-list" :key="user.Id">
              <div class="faction-user-list">
                {{ user.Initials }}
              <span v-if="user.approvalType" class="user-approved-check">
                <icon class="status-icon" :type="getUserApprovalIcon(user)" slot="count" :color="getIconColor(user)" size="16" />
              </span>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapState, mapActions, mapGetters } from "vuex";
import { Icon, locale } from 'iview';
import lang from 'iview/dist/locale/en-US';

locale(lang); // iview is default chinese

export default {
  components: {
    "vue-multiselect": Multiselect,
    Icon
  },
  mounted: function () {
    this.$nextTick(function () { });
    // this.getBpUser()
  },
  props: {
    flowIndex: { default: -1 },
    stageIndex: { default: -1 },
    factionIndex: { default: -1 },
    permissionType: { default: -1 },
    focusNameInput: { defult: null }
  },
  data: function () {
    return {
      self: this
    };
  },
  computed: {
    ...mapGetters(["getFactionByIndex", "getFactionStatus"]),
    ...mapState(["isEditEnabled", "bpusers"]),
    authFaction: function () {
      return this.getFactionByIndex(
        this.flowIndex,
        this.stageIndex,
        this.factionIndex
      );
    },
    name: {
      get () {
        return this.authFaction.Name;
      },
      set (value) {
        this.setAuthFlowStageFactionName({
          index: this.flowIndex,
          stageIndex: this.stageIndex,
          factionIndex: this.factionIndex,
          name: value
        });
      }
    },
    users: {
      get () {
        return this.authFaction.Users;
      },
      set (value) {
        this.changeAuthFlowStageFactionUsers({
          index: this.flowIndex,
          stageIndex: this.stageIndex,
          factionIndex: this.factionIndex,
          users: value
        });
      }
    },
    status: function () {
      return this.getFactionStatus(
        this.flowIndex,
        this.stageIndex,
        this.factionIndex
      );
    },
    statusIcon: function () {
      return this.status == "APPROVED"
        ? "fa-check-circle green faction-status"
        : this.status == "REJECTED"
          ? "fa-times-circle red faction-status"
          : "fa-clock-o yellow faction-status";
    },
    stagePermissionType: function () {
      return this.permissionType == 1
        ? "fa fa-users stage-permission"
        : this.permissionType == 2
          ? "fa fa-user stage-permission"
          : this.permissionType == 3
            ? "fa fa-ban stage-permission"
            : "";
    },
    statusTooltip: function () {
      return this.status == "APPROVED"
        ? `Approved by ${this.users.filter(x => this.authFaction.Approvals.map(y => y.UserId).includes(x.Id)).map(x => x.Initials)}`
        : this.status == "REJECTED"
          ? `Rejected by ${this.users.filter(x => this.authFaction.Rejections.map(y => y.UserId).includes(x.Id)).map(x => x.Initials)}`
          : "In Review";
    },
    approvals: function () {
      var approvals = [];
      if (this.authFaction.Approvals) {
        approvals = [...this.authFaction.Approvals];
      }
      if (this.bpusers.length > 0) {
        approvals = approvals.map(x => `Approved by ${this.bpusers.filter(y => y.Id == x.UserId)[0].Initials} ${this.getDisplayDateTime(x.Time)}`);
        return approvals;
      }
      return []
    },
    rejections: function () {
      var rejections = [];
      if (this.authFaction.Rejections) {
        rejections = [...this.authFaction.Rejections];
      }
      if (this.bpusers.length > 0) {
        rejections = rejections.map(x => `Rejected by ${this.bpusers.filter(y => y.Id == x.UserId)[0].Initials} ${this.getDisplayDateTime(x.Time)} <br /><i>Comment</i>: ${x.Comment}`);
        return rejections;
      }
      return []
    },
    usersWithStatus: function () {
      var self = this
      if (this.users.length > 0) {
        var usersList = this.users
        usersList.forEach(el => {
          if (self.authFaction.Approvals && self.authFaction.Approvals.find(x => x.UserId === el.Id)) {
            el.approvalType = 1 // approved
          }
          if (self.authFaction.Rejections && self.authFaction.Rejections.find(x => x.UserId === el.Id)) {
            el.approvalType = 2 // rejected
          }
        })
        return usersList
      }
      return []
    }
  },
  methods: {
    ...mapActions([
      "removeAuthFlowStageFaction",
      "setAuthFlowStageFactionName",
      "changeAuthFlowStageFactionUsers",
      "setBpUsers"
    ]),
    customUserLabel: function (user) {
      return `${user.FirstName} ${user.LastName} - ${user.Initials}`;
    },
    getDisplayDateTime: function (date) {
      var createdAt = moment(new Date(date));
      var today = moment();
      var yesterday = moment().subtract(1, "day");
      if (createdAt.isSame(today, "day")) { return "Today " + createdAt.format("hh:mm a"); }
      if (createdAt.isSame(yesterday, "day")) { return "Yesterday " + createdAt.format("hh:mm a"); }
      return "on " + createdAt.format("MMMM DD, YYYY hh:mm a");
    },
    getBpUser () {
      this.$api.GetBPUsers(this.setStoreUsers);
    },
    setStoreUsers (apiResponse) {
      if (apiResponse.Success) {
        this.setBpUsers(apiResponse.Data.BPUsers);
      }
    },
    getUserApprovalIcon (user) {
      if (user.approvalType == 1) {
        return "md-checkmark-circle"
      } else if (user.approvalType == 2) {
        return "md-close-circle"
      } else {
        return ""
      }
    },
    getIconColor (user) {
      if (user.approvalType == 1) {
        return "#51b848"
      } else if (user.approvalType == 2) {
        return "#ee364f"
      } else {
        return ""
      }
    }
  }
};
</script>

<style scoped>
.edit-name-box {
  width: auto;
  border: none !important;
}
.edit-name-label {
  padding-left: 5px !important;
  padding-top: 2px;
  background-color: #f6f7f8;
  margin-bottom: 0px;
}
.approvals {
  padding-left: 10px;
}
.auth-faction {
  border: 1px solid #cccccc;
  margin-right: 15px;
  box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.2);
}
.faction-status {
  float: right;
  font-size: 1.6em;
  cursor: default;
}
.remove-faction {
  margin-left: 5px;
}
::v-deep .multiselect__select {
  width: 20px !important;
  height: 30px !important;
  padding: 4px 4px !important;
}
::v-deep .multiselect__tags {
  border-radius: 0px !important;
  padding: 8px 20px 0 8px !important;
}
::v-deep .multiselect__tag {
  padding: 5px 20px 5px 5px !important;
  border-radius: 0px !important;
  background: #51b848 !important;
  font-size: 0.8em !important;
  margin-right: 5px !important;
}
::v-deep .multiselect__tag-icon {
  width: 20px !important;
}
::v-deep .multiselect__content-wrapper {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  font-size: 0.8em !important;
}
::v-deep .multiselect__option--highlight {
  background: #51b848 !important;
}
::v-deep .multiselect__input {
  font-size: 0.8em !important;
}
::v-deep .multiselect__input:focus {
  width: 100% !important;
}
::v-deep .multiselect__option--selected.multiselect__option--highlight {
  background: #b94a48 !important;
}
::v-deep .multiselect--disabled .multiselect__select {
  visibility: hidden;
}
::v-deep .multiselect--disabled .multiselect__tag-icon {
  visibility: hidden;
}
::v-deep .multiselect--disabled .multiselect__tag {
  padding: 5px !important;
}
::v-deep .user-avatar-authflow{
  margin: 10px;
}
.faction-user-list {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #51b848;
  padding: 10px;
  position: relative;
  text-align: center;
  line-height: 20px;
  color: white;
  font-size: 15px;
  white-space: nowrap;
}
.status-icon {
  align-content: center;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
  border: 2px solid rgb(255, 255, 255);
}
.avatar-list-authflow {
  padding: 10px;
  display: inline-flex;
  flex-wrap: wrap;
}
.user-approved-check {
  position: absolute;
  height: 20px;
  top: -5px;
  width: 20px;
}
.factions-list {
  padding: 0px 0px 10px 10px;
}
.stage-permission {
  float: right;
  font-size: 1.5rem;
  padding-right: 10px;
  cursor: default;
}

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
