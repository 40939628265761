import { FacebookConfig } from "./Facebook/Config.js";
import { LinkedInConfig } from "./LinkedIn/Config.js";
import { TwitterConfig } from "./Twitter/Config.js";
import { DV360Config } from "./DV360/Config.js";
import { AmazonSpConfig } from "./AmazonSp/config.js";
import { AmazonSDConfig } from "./AmazonSD/Config.js";
import { AdwordsConfig } from "./Adwords/Config.js";
import { MolocoConfig } from "./Moloco/Config.js";
class FactoryConfig {
  constructor (Config) {
    this.config = Config
  }
  getService () {
    return this.serviceHelper()
  }
  serviceHelper () {
    var engineType = this.config.searchEngineTypeId;
    let configObj = null
    switch (engineType) {
      case 1:
        configObj = new AdwordsConfig(this.config);
        break;
      case 5:
        configObj = new FacebookConfig(this.config);
        break;
      case 7:
        configObj = new LinkedInConfig(this.config);
        break;
      case 6:
        configObj = new TwitterConfig(this.config);
        break;
      case 9:
        configObj = new DV360Config(this.config);
        break;
      case 11:
        configObj = new AmazonSpConfig(this.config);
        break;
      case 13:
        configObj = new AmazonSDConfig(this.config);
        break;
      case 45:
        configObj = new MolocoConfig(this.config);
        break;
      default:
        configObj = new FacebookConfig(this.config);
        break;
    }
    return configObj;
  }
}
export { FactoryConfig }
