<template>
    <div v-bind:class="[isCampaignLauncher ? 'launcher-grouping-css' : 'settings-grouping-css']">
        <Row>
            <Row-Col style="authTypeText">
                {{stage.name}}
                <RadioGroup class="permissionRadioGroup" v-model="stagePermissionType">
                <Radio
                    v-for="type in factionAuthorizationType"
                    :label="type.id"
                    :key="type.id"
                >
                    <span> {{ type.name }} </span>
                </Radio>
                </RadioGroup>
            </Row-Col>
            <Row-Col style="inputBox">
                 <Select v-model="userIds" filterable multiple v-bind:class="[isCampaignLauncher ? 'extraWidth' : 'parentWidth']">
                    <Option v-for="user in users" :value="user.id" :key="user.id">{{ user.firstName }} {{ user.lastName }}</Option>
                </Select>
            </Row-Col>
        </Row>
    </div>
</template>

<script>

import { Row, Col, Select, Option, RadioGroup, Radio, locale } from "iview";
import lang from "iview/dist/locale/en-US";
import { mapGetters } from "vuex";
locale(lang);
export default {
  components: {
    RadioGroup,
    Radio,
    Select,
    Option,
    Row,
    "Row-Col": Col
  },
  mounted: function () {
    this.updateUsersInSelectBox()
  },
  props: {
    users: {
      type: Array,
      default: function () {
        return []
      }
    },
    stage: { default: "" },
    isCampaignLauncher: { default: false },
    selectedData: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data: function () {
    return {
      stagePermissionType: 2,
      userIds: []
    };
  },
  computed: {
    ...mapGetters('Settings', [
      'factionAuthorizationType', 'authTypes'
    ])
  },
  watch: {
    'userIds': {
      handler (newValue) {
        this.$emit("updatedAuthFlow", { userIds: newValue, stagePermissionType: this.stagePermissionType, stageId: this.stage.id });
      },
      deep: true
    },
    'stagePermissionType': {
      handler (newValue) {
        this.$emit("updatedAuthFlow", { userIds: this.userIds, stagePermissionType: newValue, stageId: this.stage.id });
      },
      deep: true
    },
    'selectedData': {
      handler (newValue) {
        this.updateUsersInSelectBox()
      },
      deep: true
    }
  },
  methods: {
    updateUsersInSelectBox () {
      if (this.selectedData[this.stage.name]) {
        this.userIds = this.selectedData[this.stage.name].users.map(x => x.Id)
        this.stagePermissionType = this.selectedData[this.stage.name].permissionType || 2
      }
    }
  }
}
</script>

<style scoped>
.selectionComponents {
  padding-right: 30px
}
.parentWidth {
    width: 244px;
    height: fit-content;
}
.extraWidth {
    width: 300px
}
.inputBox {
    padding: 10px 0px 10px 0px;
}
.authTypeText {
    padding-top: 2px;
}
.settings-grouping-css{
    padding-right: 43px;
}
.launcher-grouping-css {
    padding: 10px 0px 10px 0px;
}
.permissionRadioGroup {
  float: right;
}
</style>
