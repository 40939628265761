<template>
  <hot-table class="dc-hot" ref="hot" :settings="buildHotSetting()"></hot-table>
</template>

<script>
import { mapState } from "vuex";
import { HotTable } from "@handsontable/vue";
import Handsontable from "handsontable";

export default {
  components: {
    "hot-table": HotTable
  },
  props: {
    hotSettings: {
      default: null
    },
    hotData: {
      default: null
    }
  },
  data: function () {
    return {
      hotSettingsObj: JSON.parse(JSON.stringify(this.hotSettings))
    };
  },
  computed: {
    ...mapState(["builtImageIdUrlMap", "imageIdUrlMap"])
  },
  mounted: function () {
    if (this.hotData) {
      this.setColumnData(this.hotData);
    }
  },
  methods: {
    clearTable () {
      this.$refs["hot"].hotInstance.clear();
    },
    getColumnData (colIndex) {
      return this.$refs["hot"].hotInstance
        .getDataAtCol(colIndex)
        .filter(cellData => cellData);
    },
    getTableData () {
      return this.$refs["hot"].hotInstance.getData();
    },
    setTableData (htData) {
      this.$nextTick(() => {
        const updatedSettings = {
          data: htData
        };
        this.$refs["hot"].hotInstance.updateSettings(updatedSettings);
      });
    },
    setColumnData (colData, colIndex = 0) {
      const ht = this.$refs["hot"].hotInstance
      const changes = colData.map((value, index) => [index, colIndex, value])
      ht.setDataAtRowProp(changes)
    },
    clearAndSetColData (colData, colIndex = 0) {
      const colsCount = this.$refs["hot"].hotInstance.countRows()
      colData.push(...Array(colsCount).fill(null))
      this.setColumnData(colData)
    },
    getRowData () {
      var gridData = this.$refs["hot"].hotInstance.getData();
      var filledRowsData = [];
      gridData.forEach((elements) => {
        if (elements[1] !== null) {
          filledRowsData.push(elements)
        }
      });
      return filledRowsData
    },
    imageColRenderer (instance, td, row, col, prop, value, cellProperties) {
      value = Handsontable.helper.stringify(value);

      if (value in this.imageIdUrlMap) {
        let img = td.querySelector("img");
        if (img && img.src == this.imageIdUrlMap[value]) {
          return td;
        }
        img = document.createElement("IMG");
        if (value.split("-")[0] === "DXC") {
          img.src = this.imageIdUrlMap[value]["src"];
        } else {
          img.src = this.imageIdUrlMap[value]["src"];
        }
        img.classList.add("hot-img");
        Handsontable.dom.addEvent(img, "mousedown", function (event) {
          event.preventDefault();
        });

        Handsontable.dom.empty(td);
        td.appendChild(img);
      } else {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
      }
      return td;
    },
    buildHotSetting () {
      this.hotSettingsObj["columns"] = index => {
        let colSetting = {};
        if (this.hotSettingsObj.colHeaders[index] === "Image/Video" || this.hotSettingsObj.colHeaders[index] === "Video" || (this.hotSettingsObj.colHeaders[index] && this.hotSettingsObj.colHeaders[index].includes("Video Thumbnail"))) {
          colSetting.renderer = this.imageColRenderer;
        }
        return colSetting;
      };
      return this.hotSettingsObj;
    },
    setRowData (rowData, rowIndex) {
      const ht = this.$refs["hot"].hotInstance
      const changes = rowData.map((value, index) => [rowIndex, index, value])
      ht.setDataAtRowProp(changes)
    }
  }
};
</script>

<style scoped></style>

<style></style>

<style
  src="../../../../../../node_modules/handsontable/dist/handsontable.full.css"
></style>
