<template>
  <div class="cl-widget">
    <Card>
      <div slot="title">
        <div class="row">
          <h4 class="col-md-5" style="float: left;">{{headerTitle}} <i v-show="processingData" class="fa fa-spinner fa-spin" /></h4>
          <i @click="setActiveTab('Images')" :class="{'selected-category': isTabActive('Images')}" class=" col far fa-image float-right header-icon"></i>
          <picker-api
            :class="{'disable-click': businessProfilesSearchEngineId == 0}"
            @click.native="setActiveTab('Images')"
            @saveImage="uploadMedia"
            class="col float-right header-icon">
          </picker-api>
        </div>
      </div>
      <transition name="clw-alert">
        <div v-show="showAlert" class="alert alert-warning" role="alert" style="margin: 0px 5px 15px 5px;">
          <!-- <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            @click="showAlert = false"
          >×</button> -->
          {{ alertMessage }}
        </div>
      </transition>
      <div
        v-if="businessProfilesSearchEngineId == 0"
        class="alert alert-info"
        style="margin: 10px 10px 18px"
      >Please select a campaign configuration.</div>
      <template v-else>
        <div class="row" style="margin-left:-10px;margin-right:-10px">
          <div class="col-md-5 search-bar">
            <Input v-if="isTabActive('Images')" v-model="imageSearchWord" search placeholder="Search" />
          </div>
          <Upload
            v-show="isTabActive('Images', 'Videos')"
            id='mediaInput'
            class="upload-style col-md-7"
            type="drag"
            :before-upload="uploadMedia"
            :accept="fileTypeToAccept"
            multiple
            action="">
            <b>Choose a file</b> or drag it here
          </Upload>
        </div>
        <VuePerfectScrollbar v-show="!isTabActive('Mails')" class="widget-body">
          <Row
            v-for="file in getUploadingFiles"
            :key="file.key"
            class="cl-ivu-row"
            style="padding-bottom: 0px !important; margin-top: 10px;"
          >
            <Row-Col span="1">
              <i class="fas fa-upload"></i>
            </Row-Col>
            <Row-Col span="1" offset="1">
              <i class="fas fa-spinner fa-spin upload-green"></i>
            </Row-Col>
            <Row-Col span="14" offset="1">
              <Tooltip max-width="400" :content="file.uploadingFileName" placement="right-start" :transfer="true">
                <span>{{ file.uploadingFileName }}</span>
              </Tooltip>
            </Row-Col>
            <Row-Col span="3" offset="3" v-show="file.showProgress">
              <span class="upload-green"> {{ file.uploadingProgress }}% </span>
            </Row-Col>
          </Row>
          <div v-show="isTabActive('Images')" style="margin-top:10px">
            <div
              v-if="filteredImagesData.length == 0 && !processingData"
              class="alert alert-info no-data"
            >
              There are no saved images yet.
            </div>
            <template v-else>
              <Row class="cl-ivu-row" v-for="(media) in filteredImagesData" :key="media['id']">
                <Row-Col span="1">
                  <i v-tooltip="'Click to copy ID'"
                    data-placement="right"
                    class="fas fa-copy action-icon"
                    @click="copyId(media['id'])">
                  </i>
                </Row-Col>
                <Row-Col class="cl-widget-text" span="18" offset="1">
                  <Tooltip max-width="400" :content="media['name']" placement="right-start" :transfer="true">
                    <span> {{ media['name'] }} </span>
                  </Tooltip>
                  <i class="fas fa-exclamation-triangle list-icon alert-icon display-none"></i>
                </Row-Col>
                <Row-Col span="3" offset="1" class="list-image-wrapper">
                  <img v-lazy="media['src']" class="list-image">
                </Row-Col>
              </Row>
            </template>
          </div>
        </VuePerfectScrollbar>
      </template>
    </Card>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from "vuex"
import { Input, Upload, locale, Card, Row, Col, Tooltip } from 'iview'
import lang from "iview/dist/locale/en-US"
import { APIService } from '../../ApiService.js'
import VueLazyload from 'vue-lazyload'
import PickerApi from "../PickerApi.vue"
import { linkedInEnums } from "../../Constants/LinkedInEnums.js";
import ApiService from '../../../Promotion/ApiService.js'

import VuePerfectScrollbar from 'vue-perfect-scrollbar'

locale(lang)
Vue.use(VueLazyload, {
  attempt: 1
})

export default {
  components: {
    Input,
    Upload,
    PickerApi,
    Card,
    "Row-Col": Col,
    Row,
    VuePerfectScrollbar,
    Tooltip
  },
  props: {
    fetchMediaUrl: { type: String, default: "" },
    saveImageURL: { type: String, default: "" }
  },
  data: function () {
    return {
      processingData: false,
      images: true,
      imageSearchWord: '',
      uploadingFiles: {
        Images: {}
      },
      uniqueId: 0,
      headerTitle: "Images",
      showAlert: false,
      alertMessage: "",
      linkedInEnums,
      allImages: {},
      recipeSelected: 2,
      currentFileIndex: null
    }
  },
  mounted () {
  },
  computed: {
    ...mapState([
      "businessProfileId",
      "businessProfilesSearchEngineId",
      "imageIdUrlMap",
      "deltax",
      "selectedPublisher",
      "imagesData",
      "recipeType",
      "currentAdSheet"
    ]),
    filteredImagesData: function () {
      if (this.processingData) {
        return []
      }
      let query = this.imageSearchWord.toLowerCase().trim()
      return this.allImages.filter(media => {
        return media.name.toLowerCase().indexOf(query) != -1
      })
    },
    fileTypeToAccept: function () {
      if (this.headerTitle == 'Images') {
        return 'image/*'
      }
      return null;
    },
    getUploadingFiles: function () {
      if (!this.isTabActive('Images')) { return [] }
      const files = this.uploadingFiles[this.headerTitle]
      return Object.keys(files)
        .sort()
        .reverse()
        .map(key => {
          return { key, ...files[key] };
        })
    }
  },
  watch: {
    businessProfilesSearchEngineId: {
      immediate: true,
      handler () {
        this.fetchMedia();
      }
    },
    headerTitle () {
      this.showAlert = false
    }
  },
  methods: {
    ...mapMutations(["SET_imageIdUrlMap", "set_imagesData", "set_recipeType"]),
    fetchMedia () {
      if (this.businessProfilesSearchEngineId > 0) {
        this.processingData = true
        this.initUploadingFiles()
        this.$store.commit("set_builtImageIdUrlMap", false)
        this.$store.commit("set_isOauthExpired", false)
        APIService.getApiData(
          this.deltax.apiEndPoint +
          "businessProfiles/" +
          this.businessProfileId +
          "/accounts/" +
          this.businessProfilesSearchEngineId +
          "/Images"
        ).then(response => {
          if (!response) {
            this.processingData = false
            this.$store.commit("set_isOauthExpired", true)
            return
          }
          this.set_imagesData(response.data.data);
          this.$store.commit("set_isOauthExpired", false)
          this.formatData()
        })
      }
    },
    uploadImage (image) {
      let fileType = /image.*/;
      if (!image.type.match(fileType)) {
        this.alertUser('Incorrect file format. Please choose an image')
        return
      }
      const fileIndex = this.uniqueId++;
      this.currentFileIndex = fileIndex;
      this.$set(this.uploadingFiles['Images'], fileIndex, {
        uploadingProgress: 0,
        uploadingFileName: image.name,
        tooltip: 'Uploading image',
        showProgress: true
      })
      var self = this;
      const selectedRecipeType = this.recipeType
      APIService.uploadImage(this.deltax.apiEndPoint +
        "businessProfiles/" +
        this.businessProfileId +
        "/accounts/" +
        this.businessProfilesSearchEngineId +
        "/Images/upload",
      this.uploadPercentCallback,
      image,
      window.currentUserId,
      selectedRecipeType
      ).then(function (response) {
        let data = response[0]
        data["linkedInRecipeType"] = selectedRecipeType;
        let formattedImageData = self.formatImageData(data)
        self.set_imagesData([formattedImageData, ...self.imagesData]);
        let assetUrlMap = self.imageIdUrlMap;
        assetUrlMap[formattedImageData.id] = { src: formattedImageData.src };
        self.SET_imageIdUrlMap(assetUrlMap);

        if (!self.allImages) {
          self.$set(self.allImages, selectedRecipeType, []);
        }
        self.$set(self.allImages, self.allImages.length, formattedImageData);
      })
        .catch(function () {
          alert('Unable to upload image')
        })
        .finally(function () {
          self.$delete(self.uploadingFiles['Images'], fileIndex)
        });
    },
    uploadPercentCallback (progressEvent) {
      this.uploadingFiles['Images'][this.currentFileIndex].uploadingProgress =
          Math.round((progressEvent.loaded / progressEvent.total) * 100)
    },
    uploadMedia (file) {
      if (this.headerTitle == 'Images') {
        this.uploadImage(file);
      }
      return false;
    },
    copyId (id) {
      let textarea = document.createElement("textarea")
      textarea.textContent = id
      textarea.style.position = "fixed"
      document.body.appendChild(textarea)
      textarea.select()
      try {
        document.execCommand("copy")
      } catch (e) {
        alert('Unable to copy to clipboard. Please copy manually.')
      }
      document.body.removeChild(textarea)
    },
    formatImageData (imageData) {
      return {
        id: `DXC-${Number.parseInt(this.businessProfileId).toString(36)}-${imageData.id}`,
        name: (imageData.name != null) ? imageData.name.substring(imageData.name.indexOf("_") + 1) : 'Sample Image',
        src: imageData.url,
        linkedInRecipeId: imageData.linkedInRecipeType ? imageData.linkedInRecipeType : 2
      }
    },
    formatVideoData (videoData) {
      var data = {
        id: `DXV-${Number.parseInt(this.businessProfileId).toString(36)}-${videoData.Id}`,
        name: (videoData.Title != null) ? videoData.Title : 'Sample Video',
        src: videoData.Picture
      }
      data.nameId = data.name;
      return data;
    },
    formatData () {
      let data = []
      this.SET_imageIdUrlMap({});
      let assetUrlMap = {};
      for (let imageData of this.imagesData) {
        let formattedImageData = this.formatImageData(imageData)
        data.push(formattedImageData)
        assetUrlMap[formattedImageData.id] = { src: formattedImageData.src, hash: formattedImageData.hash };
      }
      this.allImages = data
      this.set_imagesData(data);

      this.SET_imageIdUrlMap(assetUrlMap);

      this.processingData = false
      this.$store.commit("set_builtImageIdUrlMap", true)
    },
    setActiveTab (name) {
      this.headerTitle = name;
    },
    isTabActive (...names) {
      return names.includes(this.headerTitle);
    },
    initUploadingFiles () {
      this.uploadingFiles = {
        Images: {}
      }
      this.uniqueId = 0
    },
    alertUser (message) {
      this.showAlert = true
      this.alertMessage = message
      setTimeout(() => {
        this.showAlert = false
      }, 4 * 1000);
    }
  }
}
</script>

<style scoped>
.alert-info {
  margin-top: 10px;
}
.display-none {
  display: none;
}
.clw-container {
  display: flex;
  flex-flow: column;
  padding: 0 10px 0 10px;
  height: 100%;
}

.header-container {
  height: 3.5rem;
  line-height: 3.5rem;
}
.header-name {
  font-size: 1.5rem;
}
.float-right {
  float: right;
  margin-right: 15px;
}
.header-icon {
  padding-top: 4px;
  display: inline-block;
  line-height: 3rem;
  font-size: 18px;
  cursor: pointer;
  color: #7D7F7F;
}
.hr-style {
  /* width: calc(100% + 30px); */
  /* margin: 0 0 0 -15px; */
  margin: 0px -15px 0px -15px;
}

.cl-widget-text {
  font-size: 12px;
}
.search-container {
  padding: 8px 0 8px 0;
}
.search-bar {
  max-width: calc(100% - 15px);
  padding: 0 0 0 15px;
}
.upload-style {
  line-height: 3.1rem;
  height: 3.1rem;
}

.list-container {
  height: 139px;
}
.list-container .float-right {
  margin-right: -8px;
}
.no-data {
  margin-top: 0px;
  margin-left: -5px;
  width: calc(100% + 10px);
}
.list-item {
  display: flex;
  width: 100%;
  height: 2.2rem;
  line-height: 2.2rem;
  margin: 8px 0 8px 0;
}
.list-item:first-child {
  margin-top: 0px;
}
img[lazy=loading] {
  min-height: 2rem;
  min-width: 2rem;
  animation: placeholder-animate 1.5s ease-in-out infinite;
  background:rgba(0, 0, 0, 0.08);
}
img[lazy=loading] ~ .fa-copy {
  pointer-events: none;
}
img[lazy=error] {
  min-height: 2rem;
  min-width: 2rem;
  border-radius: 3px;
  border: 1px dotted #555;
}
img[lazy=loaded]:hover {
  transform: scale(20);
  display: block;
  padding-right: 10px;
}
img[lazy=error] ~ .fa-exclamation-triangle {
  display: initial;
}
img[lazy=error] ~ .fa-copy {
  display: none;
}

.clw-alert-enter-active {
  transition: all 0.25s ease-out;
}
.clw-alert-leave-active {
  transition: all 0.25s ease-in;
}
.clw-alert-enter, .clw-alert-leave-to {
  transform: translate(0px, -15px);
  opacity: 0;
}

.list-text-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 59%;
}
.list-text-id {
  margin-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 21%;
  font-size: 1.2rem;
}
.list-icon {
  margin-top: -2px;
  padding: 5px 5px 5px 15px;
  font-size: 14px;
}
.list-upload-icon {
  min-width: 2rem;
  min-height: 2rem;
  line-height: 2rem;
  margin-right: 10px;
  font-size: 14px;
  text-align: center;
}
.upload-green {
  color: green;
}
.upload-spinner {
  line-height: 11px;
  margin: 5px 5px 5px 15px;
  font-size: 14px;
}
.alert-icon {
  color: #ed5a5a;
}
.spinner {
  text-align: center;
  margin-top: 6rem;
}
.video-spinner {
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin-top: 15px;
}

/* placeholder */
@keyframes placeholder-animate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.selected-category{
    color: #555;
}

.creative-builder-iframe {
  position: relative;
  width: 100%;
  height: 85vh;
}

.creative-builder-spinner {
  position: relative;
  width: 100%;
  height: 50%;
  text-align: center;
  top: 45%;
}

::v-deep .ivu-modal-body {
  padding: 0% !important;
  height: 85vh;
}

.recipeSelect{
  max-width: calc(100% - 15px);
  padding: 0 0 10px 15px;
}
</style>

<style>
.upload-style .ivu-upload {
  height: inherit;
  border: 1px dotted #aaa;
  background-color: #e9eaed;
}
.list-image-poptip {
  height:200px;
}
.list-image-wrapper {
  position: relative;
  width: 2rem;
  height: 2rem;
  float: right;
}
.list-image {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center;
  transition: transform .05s linear;
  transform: translate(-50%, -50%);
}
</style>
<style src="../../../../Styles/dx-iview.css"></style>
