<template>
  <div class="sidebar-navigation-page-container">
    <div class="sidebar-navigation-list">
      <VuePerfectScrollbar
        class="sidebar-vuebar-scrolling-area sidebar-nav-scroll"
        :settings="{ maxScrollbarLength: 50, suppressScrollX: true }"
      >
      <slot name="navigator"></slot>
      </VuePerfectScrollbar>
    </div>
    <div class="sidebar-content-list">
      <div v-if="isFixedVisible">
        <slot name="fixed"></slot>
      </div>
      <div v-show="!isFixedVisible">
        <VuePerfectScrollbar
          ref="scroll"
          class="sidebar-vuebar-scrolling-area sidebar-content-scroll"
          :settings="{ maxScrollbarLength: 50, suppressScrollX: true }"
          @ps-scroll-y="elementInViewport"
        >
          <slot></slot>
        </VuePerfectScrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import EventBus from './event-bus.js';
export default {
  data(){
    return {
      currentIndex: 0,
      isNavClicked: false,
      elements: null,
      isFixedVisible : false,
    };
  },
  components: {
    VuePerfectScrollbar,
  },
  methods: {
    elementInViewport() {
      var smallestElementHeight = Math.min(
        ...Array.from(this.elements).map((e) => e.clientHeight)
      );
      for (var index = this.elements.length - 1; index >= 0; index--) {
        var element = this.elements[index];
        var container = element.parentElement;

        let cTop = container.scrollTop;
        let cBottom =
          cTop +
          Math.min(smallestElementHeight * 0.8, container.clientHeight * 0.4);

        let eTop = element.offsetTop;
        let eBottom = eTop + element.clientHeight;

        let isTotal = eTop >= cTop && eBottom <= cBottom;
        let isPartial =
          (eTop < cTop && eBottom > cTop) ||
          (eBottom > cBottom && eTop < cBottom);

        if (isTotal || isPartial) {
          this.currentIndex = index;
          EventBus.$emit("setNavActive", this.currentIndex);
          break;
        }
      }
    },
    updateLastElementMargin() {
      var lastElement = this.elements[this.elements.length - 1];
      var container = lastElement.parentElement;
      var heightDiff = container.clientHeight - lastElement.clientHeight;
      if (heightDiff > 0) {
        lastElement.style.marginBottom = heightDiff + "px";
      }
    },
  },
  watch: {
    currentIndex(value) {
      if(this.isNavClicked){
        this.$nextTick(function() {
          this.$refs.scroll.$el.scrollTop = this.elements[value].offsetTop;
          this.isNavClicked = false;
        });
      }
    },
  },
  mounted() {
    this.elements = document.getElementsByClassName("sidebar-content-container");
    this.updateLastElementMargin();
    EventBus.$on("showFixedBlock", (show) => {
      this.isFixedVisible = show;
    });
    EventBus.$emit("setNavActive", this.currentIndex);
    EventBus.$on("setScrollActive", (index, navClicked) => {
      this.currentIndex = index;
      this.isNavClicked = navClicked;
    });
  }
};
</script>

<style>
.sidebar-navigation-page-container{
  width: 100%;
  padding: 0px;
  height: calc(100vh - 21rem);
}

.sidebar-navigation-list {
  float: left;
  width: 14%;
  background-color: white;
  padding: 0px 0px 16px;
  max-height: calc(100vh - 21rem);
}

.sidebar-content-list {
  float: right;
  width: 85%;
  overflow-x: hidden;
  max-height: calc(100vh - 21rem);
}

.sidebar-vuebar-scrolling-area {
  position: relative !important;
  margin: auto;
  width: 100%;
}

.sidebar-nav-scroll{
  padding: 0px 20px;
  max-height: calc(100vh - 21rem);
}

.sidebar-content-scroll{
  height: calc(100vh - 21rem);
}

@media only screen and (max-height: 650px) {
  .sidebar-content-list {
    max-height: 60vh;
  }
}
</style>
