<template>
  <div>
    <div style="text-align: center;">
      <label class="preview-label">Placement Preview</label>
      <i-select class="preview-select" v-model="selectedPreviewPlacement">
        <i-option
          v-for="item in filteredOptions"
          :value="item.value"
          :key="item.value"
        >{{ item.label }}</i-option>
      </i-select>
      <i-select class="preview-select" v-model="languages.selected" v-if="languageCustomizationEnabled">
        <i-option
          v-for="item in languages.options"
          :value="item.key"
          :key="item.key"
        >{{ item.name }}</i-option>
      </i-select>
    </div>
    <div v-html="previewHtml" class="preview-container"></div>
  </div>
</template>

<script>
import ApiService from './ApiService.js';
import { Select, Option, locale } from 'iview';
import lang from 'iview/dist/locale/en-US';
import { mapState } from "vuex";

locale(lang); // iview is default chinese

export default {
  components: {
    'i-select': Select,
    'i-option': Option
  },
  mounted: function () {
    this.$nextTick(function () {

    });
  },
  props: {
    accountId: { default: '' },
    creativeId: { default: '' },
    fromCampaignLauncher: { default: false },
    accountIdFromCL: { default: "" },
    creativeSpec: { default: function () { return {} } },
    businessProfilesSearchEngineId: { default: 0 },
    languageCustomizationEnabled: false,
    previewAdId: { default: '' },
    isNewFlow: false
  },
  data: function () {
    return {
      previewLoaded: false,
      spinner: '<i class="fa-2x fa-spin fa-spinner"></i>',
      previewHtml: this.spinner,
      selectedPreviewPlacement: "MOBILE_FEED_STANDARD",
      previewPlacements: {
        options: [
          { value: "RIGHT_COLUMN_STANDARD", label: 'Desktop Right Column', height: 300, width: 400, platform: 'Facebook', platformPosition: 'Right hand column' },
          { value: "DESKTOP_FEED_STANDARD", label: 'Desktop News Feed', height: 500, width: 520, platform: 'Facebook', platformPosition: 'Feed' },
          { value: "MOBILE_FEED_STANDARD", label: 'Mobile News Feed', height: 400, width: 400, platform: 'Facebook', platformPosition: 'Feed' },
          { value: "MOBILE_FEED_BASIC", label: 'Basic Mobile Feed', height: 300, width: 400, platform: 'Facebook', platformPosition: 'Feed' },
          // { value: "INSTANT_ARTICLE_STANDARD", label: 'Instant Articles', height: 500, width: 400, platform: 'Facebook', platformPosition: 'Instant article' },
          { value: "MARKETPLACE_MOBILE", label: 'Mobile Marketplace', height: 300, width: 400, platform: 'Facebook', platformPosition: 'Marketplace' },
          { value: "FACEBOOK_STORY_MOBILE", label: 'Facebook Story', height: 580, width: 400, platform: 'Facebook', platformPosition: 'Story' },
          { value: "INSTAGRAM_STANDARD", label: 'Instagram Feed', height: 500, width: 400, platform: 'Instagram', platformPosition: 'Stream' },
          { value: "INSTAGRAM_STORY", label: 'Instagram Story', height: 580, width: 400, platform: 'Instagram', platformPosition: 'Story' },
          { value: "MOBILE_INTERSTITIAL", label: 'Audience Network Interstitial', height: 580, width: 400, platform: 'Audience network', platformPosition: 'Classic' },
          { value: "MOBILE_BANNER", label: 'Audience Network Banner', height: 300, width: 400, platform: 'Audience network', platformPosition: 'Classic' },
          { value: "MOBILE_MEDIUM_RECTANGLE", label: 'Audience Network Medium Rectangle', height: 500, width: 400, platform: 'Audience network', platformPosition: 'Classic' },
          { value: "MOBILE_NATIVE", label: 'Audience Network Native', height: 500, width: 400, platform: 'Audience network', platformPosition: 'Classic' },
          { value: "AUDIENCE_NETWORK_INSTREAM_VIDEO", label: 'Audience Network Instream Video', height: 500, width: 500, platform: 'Audience network', platformPosition: 'Instream video' },
          // {value:"AUDIENCE_NETWORK_OUTSTREAM_VIDEO",label:'Audience Network Outstrem Video',height: 500,width:500 , platform: 'Audience network' ,platformPosition: 'feed' },
          { value: "AUDIENCE_NETWORK_REWARDED_VIDEO", label: 'Audience Network Rewarded Video', height: 500, width: 500, platform: 'Audience network', platformPosition: 'Rewarded video' },
          // {value:"INSTREAM_VIDEO_DESKTOP",label:'Desktop Instream Video',height: 500,width:400 , platform: 'Facebook' ,platformPosition: 'Feed' },
          // {value:"INSTREAM_VIDEO_MOBILE",label:'Mobile Instream Video',height: 500,width:400 , platform: 'Facebook' ,platformPosition: 'Feed' },
          // {value:"SUGGESTED_VIDEO_DESKTOP",label:'Desktop Suggested Video',height: 280,width:280 , platform: 'Facebook' ,platformPosition: 'feed' },
          // {value:"SUGGESTED_VIDEO_MOBILE",label:'Mobile Suggested Video',height: 400,width:400 , platform: 'Facebook' ,platformPosition: 'Feed' },
          // {value:"WATCH_FEED_MOBILE",label:'Mobile Watch Feed',height: 500,width:500 , platform: 'Facebook' ,platformPosition: 'Feed' },
          { value: 'MESSENGER_MOBILE_STORY_MEDIA', label: 'Messenger Stories', height: 600, width: 400, platform: 'Messenger', platformPosition: 'Story' },
          { value: 'MESSENGER_MOBILE_INBOX_MEDIA', label: 'Messenger Inbox', height: 400, width: 400, platform: 'Messenger', platformPosition: 'Sponsored Messages' },
          { value: 'FACEBOOK_REELS_MOBILE', label: 'Facebook Reels', height: 580, width: 400, platform: 'Facebook', platformPosition: 'Reels' },
          { value: 'INSTAGRAM_REELS', label: 'Instagram Reels', height: 580, width: 400, platform: 'Instagram', platformPosition: 'Reels' }
        ]
      },
      placements: [],
      languages: {
        options: [],
        selected: ""
      }
    };
  },
  computed: {
    ...mapState([
      "selectedCampaignLauncherConfigId",
      "campaignLauncherConfigs",
      "facebookLanguages",
      "adSheetCustomizationSettings"
    ]),
    iFrameHeight: function () {
      var option = this.previewPlacements.options.find(x => x.value == this.selectedPreviewPlacement)
      return option ? option.height : 100
    },
    iFrameWidth: function () {
      var option = this.previewPlacements.options.find(x => x.value == this.selectedPreviewPlacement)
      return option ? option.width : 100
    },
    filteredOptions: function () {
      if (this.$store && this.$store.state && this.$store.state.selectedCampaignLauncherConfigId) {
        if (this.selectedCampaignLauncherConfigId) {
          var config = JSON.parse(this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config);
          this.placements = config.adset.placements;
          var selectedPlacementOption = config.adset.selectedPlacementOption;
          if (selectedPlacementOption == "Automatic") {
            return this.previewPlacements.options;
          } else {
            return this.previewPlacements.options.filter(x => this.ifPlacementPlatformEnabled(x.platform) && this.ifPlatformPositionEnabled(x.platform, x.platformPosition));
          }
        } else {
          return this.previewPlacements.options;
        }
      } else {
        return this.previewPlacements.options
      }
    }
  },
  methods: {
    getFacebookAdPreview: function (language) {
      this.previewHtml = this.spinner;
      if (this.fromCampaignLauncher) {
        var creativeJsonObj = { "ad_format": this.selectedPreviewPlacement, height: this.iFrameHeight, width: this.iFrameWidth }
        if (this.languageCustomizationEnabled && !language) {
          // get languageIds
          if (this.adSheetCustomizationSettings && this.adSheetCustomizationSettings.selectedLanguages) {
            var languageIds = this.adSheetCustomizationSettings.selectedLanguages;
            var languagesSelectedForCustomization = this.facebookLanguages.filter(e => {
              return languageIds.includes(e.key);
            })
            this.languages.options = languagesSelectedForCustomization;
            this.languages.selected = languagesSelectedForCustomization[0].key;
          }
          creativeJsonObj["dynamic_asset_label"] = languagesSelectedForCustomization[0].name.toLowerCase()
        } else if (language) {
          var selectedLanguage = this.facebookLanguages.filter(e => {
            return this.languages.selected == e.key
          });
          creativeJsonObj["dynamic_asset_label"] = selectedLanguage[0].name.toLowerCase()
        }
        if (this.isNewFlow) {
          if (!this.previewAdId && 'noVal' in this.creativeSpec) return
          if (!this.previewAdId) {
            creativeJsonObj = { ...creativeJsonObj, ...this.creativeSpec }
          }
          let creativeJSONstring = JSON.stringify(creativeJsonObj, (key, value) => {
            if (value !== null) { return value }
          })
          ApiService.GenerateMetaAdPreview(this.previewAdId, creativeJSONstring, this.businessProfilesSearchEngineId, this.campaignLauncherAdPreviewCallback, this.adPreviewFailureCallback);
        } else {
          if ('noVal' in this.creativeSpec) return
          creativeJsonObj = { ...creativeJsonObj, ...this.creativeSpec }
          let creativeJSONstring = JSON.stringify(creativeJsonObj, (key, value) => {
            if (value !== null) { return value }
          })
          ApiService.GenerateAdPreview(creativeJSONstring, this.businessProfilesSearchEngineId, this.campaignLauncherAdPreviewCallback, this.adPreviewFailureCallback, this.accountId);
        }
      } else {
        var data = JSON.stringify({ ad_format: this.selectedPreviewPlacement, height: this.iFrameHeight, width: this.iFrameWidth });
        ApiService.GetPreviews(this.accountId, this.creativeId, data, this.adPreviewSuccessCallback, this.adPreviewFailureCallback);
      }
    },
    campaignLauncherAdPreviewCallback: function (response) {
      try {
        if (this.isNewFlow) {
          let preview = response.data.data
          this.previewHtml = preview[0].body
        } else {
          let preview = JSON.parse(response.data);
          if (this.fromCampaignLauncher && this.accountId !== "") {
            this.previewHtml = preview[0].body
          } else {
            this.previewHtml = preview.data[0].body
          }
        }
        this.$nextTick(function () {
          $("iframe").attr({ "scrolling": "no", "height": "800" });
        });
        this.previewLoaded = true;
      } catch {
        this.adPreviewFailureCallback(response);
      }
    },
    adPreviewSuccessCallback: function (response) {
      var previews = JSON.parse(response.previews);
      var iframe = previews[0].body;
      this.previewHtml = iframe;
      this.$nextTick(function () {
        $("iframe").attr("scrolling", "no");
      });
      this.previewLoaded = true;
    },
    adPreviewFailureCallback: function (response) {
      this.previewHtml = "Failed to load preview";
      try {
        var responseObj = this.isNewFlow ? response.response.data : JSON.parse(response.data);
        if ("message" in responseObj) {
          this.previewHtml = `<div class="preview-error">${responseObj.message}</div>`;
        }
      } catch {}
      this.previewLoaded = false;
    },
    ifPlacementPlatformEnabled: function (platform) {
      return this.placements.some(e => e.title == platform && (e.checked || e.indeterminate));
    },
    ifPlatformPositionEnabled: function (platform, position) {
      var platFormPositions = this.placements.filter(e => e.title == platform)[0];
      return platFormPositions.children.some(e => e.title == position && e.checked);
    }
  },
  watch: {
    creativeSpec () {
      this.getFacebookAdPreview();
    },
    "languages.selected": function () {
      this.getFacebookAdPreview(this.languages.selected);
    },
    selectedPreviewPlacement: {
      immediate: true,
      handler: function (value) {
        if (this.accountId != '' || this.fromCampaignLauncher) {
          this.getFacebookAdPreview();
        }
      }
    },
    accountId: {
      immediate: true,
      handler: function (value) {
        this.selectedPreviewPlacement = "MOBILE_FEED_STANDARD";
        if (this.accountId != '') {
          this.getFacebookAdPreview();
        }
      }
    },
    creativeId: {
      immediate: true,
      handler: function (value) {
        this.selectedPreviewPlacement = "MOBILE_FEED_STANDARD";
        if (this.accountId != '') {
          this.getFacebookAdPreview();
        }
      }
    },
    previewAdId: {
      immediate: true,
      handler: function (value) {
        this.selectedPreviewPlacement = "MOBILE_FEED_STANDARD";
        this.getFacebookAdPreview();
      }
    }
  }
}
</script>

<style scoped>
::v-deep .ivu-select-dropdown-list {
  padding: 0;
}
::v-deep .ivu-select-group-wrap > ul {
  padding: 0;
}
.preview-label {
  font-size: 12px;
  font-weight: bold;
  padding: 10px;
}
.preview-select {
  width: 250px;
}
.preview-container {
  text-align: center;
  width: 100%;
}
</style>
<style src="../../../../Styles/dx-iview.css">
/*
    For default iview css, use
    iview/dist/styles/iview.css
  */
</style>

<style>
.preview-error {
  margin: 10px;
  padding: 10px;
  text-align: left;
  font-size: 12px;
  max-width: 270px;
  border: 1px solid #dd3c10;
  background-color: #ffebe8;
}
</style>
