import axiosInstance from "../../Api";

const baseUrl = deltax.apiBaseUrl.concat(
  `businessProfiles/${deltax.businessProfileId}`
);

let APIService = {
  GetScheduledReports (requestData) {
    const promise = axiosInstance.get(baseUrl + '/reports/scheduledreports', {
      params: {
        offset: requestData.offset,
        limit: requestData.limit,
        search: requestData.search,
        type: requestData.type,
        base: requestData.base,
        schedule: requestData.schedule,
        format: requestData.format
      }
    });
    return promise;
  },
  DeleteScheduleReport (reportTemplateId) {
    const promise = axiosInstance.delete(baseUrl + '/reports/scheduledreports/' + reportTemplateId);
    return promise;
  },
  DownloadPresentationReport (requestData) {
    var pUrl = deltax.downloadPresentation;
    const promise = axiosInstance.get(pUrl, {
      params: {
        reportTypeId: requestData.reportTypeId,
        reportTemplateId: requestData.reportTemplateId
      }
    })
    return promise;
  },
  PublishScheduleReport (requestData) {
    const url = '/App/ReportDesigner/PublishReport/' + deltax.businessProfileId;
    const promise = axiosInstance.get(url, {
      params: {
        reportId: requestData.reportId,
        reportConfigurationId: requestData.reportConfiguration,
        reportTemplateId: requestData.reportTemplateId
      }
    });
    return promise;
  },
  GetCustomReports (requestData) {
    const promise = axiosInstance.get(baseUrl + '/reports/customreports', {
      params: {
        offset: requestData.offset,
        limit: requestData.limit,
        search: requestData.search
      }
    });
    return promise;
  },
  DeleteCustomReport (reportTypeId) {
    const promise = axiosInstance.delete(baseUrl + '/reports/customreports/' + reportTypeId);
    return promise;
  },
  GetStandardReports (requestData) {
    const promise = axiosInstance.get(baseUrl + '/reporttemplate', {
      params: {
        reportType: requestData.reportType,
        param: requestData.param

      }
    });
    return promise;
  },
  PublishStandardReport (requestData) {
    const url = `/App/ReportDesigner/PublishReport/${deltax.businessProfileId}?reportId=${requestData.reportId}&reportConfigurationId=${requestData.reportConfigurationId}`
    const promise = axiosInstance.get(url, {
      params: {
        reportId: requestData.reportId,
        reportConfigurationId: requestData.reportConfiguration
      }
    });
    return promise
  },
  GetUsers () {
    var url = deltax.apiBaseUrl + 'users/' + deltax.userId;
    const promise = axiosInstance.get(url);
    return promise;
  },
  GetDxDateFormats () {
    var url = "/App/Users/GetAllDateFormats";
    const promise = axiosInstance.get(url);
    return promise;
  },
  GetReportExports (params) {
    return axiosInstance.get(`${baseUrl}/reports/exports`, {
      params: params
    });
  },
  GetReportExport (reportSchedulerId) {
    return axiosInstance.get(`${baseUrl}/reports/exports/${reportSchedulerId}`);
  },
  GetReportExportsStatus (reportSchedulerIds) {
    return axiosInstance.get(`${baseUrl}/reports/exports/status?reportSchedulerIds=${reportSchedulerIds}`);
  },
  GetBusinessProfileUsers () {
    return axiosInstance.get(`${deltax.apiBaseUrl}users?businessProfileIds=${deltax.businessProfileId}`);
  },
  GetBpSettings () {
    return axiosInstance.get(`${deltax.apiBaseUrl}businessProfiles/${deltax.businessProfileId}/settings?keyprefixes=BusinessProfile&includeinternal=true`);
  },
  GetPreSignedurl (reportSchedulerId) {
    return axiosInstance.get(`${deltax.apiBaseUrl}businessProfiles/${deltax.businessProfileId}/reports/exports/${reportSchedulerId}/Download`);
  },
  GetScheduledReportsInfo (reportTypeId) {
    const promise = axiosInstance.get(`${deltax.apiBaseUrl}businessProfiles/${deltax.businessProfileId}/reports/customreports/scheduleinfo/${reportTypeId}`);
    return promise;
  },
  GetInternalPortalDomains () {
    const url = deltax.apiBaseUrl.concat("Portals/InternalPortals");
    let result = axiosInstance.get(url);
    return result;
  }
}
export { APIService };
